
import { useState, useEffect } from 'react';
import { getCountryFlagSrc } from '../../components/CommonMethod';
import { DROPDOWN_DATA, fetchData } from '../../components/Service';

const Currency = () => {
    window.document.title = "Currency";

    const [data, setData] = useState('');

    useEffect(() => {
        fetchData(DROPDOWN_DATA + '?type=currency', 'GET', '', true, true, (res) => {
            if (res.records) {
                setData(res.records.currency)
            }
        })
    }, []);

    return (
        <div className="container-fluid">
            <div className="page-head sticky_top_padding">
            <div className="row">
                <div className="col-sm-12">
                    <h1 className="h3 text-dim pb-4">Currency</h1>
                </div>
            </div>
            </div>
            <div className="row align-items-center gx-4 gy-3 mb-3">
                <div className="col-sm">
                    <h4 className="fw-bold m-0">Set currency rates</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-xl-11 col-xxl-7">
                    <div className="alert alert-warning fw-bold">
                        <svg className="icon ts-1p6 me-3 text-warning"><use href="#icon_exclamation"></use></svg>
                        Changing the following rates will affect the price of all workbooks in the shop.
                    </div>
                    <div className="row g-4 pt-2">
                        <div className="col-md-6">
                            <div className="d-flex">
                                <div className="mmwh-48">
                                    <img src={`../images/vector/${getCountryFlagSrc('AUD')}`} className="w-100" alt="AUD" />
                                </div>
                                <div className="ps-4 pt-2">
                                    <h4 className="fw-bold">$ Australian Dollar</h4>
                                    <h2 className="fw-bold mb-0">AUD $1.00 =</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card rounded-4 bg-grey border-0">
                                <div className="card-body p-4">
                                    { data && data.map((item, i) => {
                                        return (
                                            <div className="d-flex mb-4" key={i}>
                                                <div className="mmwh-48">
                                                    <img src={`../images/vector/${item && getCountryFlagSrc(item.currency_type)}`} className="w-100" alt="country-flags" />
                                                </div>
                                                <div className="ps-4 pt-2">
                                                    <h4 className="fw-bold">{item && item.currency_type === 'INR' ? '₹ Indian Rupees' : item.currency_type === 'USD' ? '$ US Dollars' : item.currency_type === 'GBP' ? '£ British Pounds' : '€ Euros'}</h4>
                                                    <input type="number" readOnly placeholder={item && item.currency_type === 'INR' ? '₹ 0.00' : item.currency_type === 'USD' ? '$ 0.00' : item.currency_type === 'GBP' ? '£ 0.00' : '€ 0.00'} defaultValue={item.currency_price.toFixed(2)} className="form-control form-control-md maxw-110"/>
                                                </div>
                                            </div>
                                        )
                                    }) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Currency;