const ExamLoading = (props) => {

    const examStart = () => {
        if(props.questions){
            props.setStartExam(true)
            props.manageCounter(props.mode === 'exam')
        }
    }

    return (
        <div className="row d-flex justify-content-center exam-body">
            <div className="col-lg-4 col-md-12 col-sm-12 mt-lg-5">
                <div className="box border border-blue rounded-4 border-2 position-relative bg-sky-light mt-3 mt-lg-5 p-4">
                    <div className="triangle border rounded-2 border-blue d-md-flex d-sm-flex-block bg-sky-light" ></div>
                    <h4 className="text-blue fw-bold ">Hey there! Remember...</h4>
                    <div className="d-md-flex d-sm-flex-block mb-4">
                        <img src="/images/thumbbutton.png" className="img-fluid" alt="male" />
                        <p>If you’re unsure about an answer, click ‘Why’ to see my explanation.</p>
                    </div>
                    <div className="d-md-flex d-sm-flex-block">
                        <img src="/images/chair.png" className="img-fluid" alt="male" />
                        <p>A scientific calculator and periodic table is available for you to use while you work.</p>
                    </div>
                    <button type="button" className={`btn btn-theme-primary btn-lg my-2 w-100 text-white ${ !props.questions ? 'disabled' : ''}`} onClick={examStart}>
                        { props.questions ? `${props.action} ${props && props.mode}` : <>
                            <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>
                            Workbook loading 
                        </> }
                    </button>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 loading-exam">
                <img src="/images/Male-4.png" className="img-fluid m-auto d-block" alt="male" />
            </div>
            {props.children}
        </div>
    )
}

export default ExamLoading