import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchData, GET_AUTH_USER_INFO, VERIFY_LOGIN_TOKEN } from "../../components/Service";
import queryString from 'query-string'
import { useContext } from "react";
import { Context } from "../../components/Context";

const styles = {
    logo: {
        justifyContent: 'center',
        display: 'flex',
        padding: '0 0 25px 0',
    },
    mainSection: {
        margin: '100px 0 100px 0'
    },
    section: {
        width: '100%',
        maxWidth: ' 800px',
        margin: 'auto',
        boxShadow: '5px 10px 17px #e5d6d6'
    },
    h1: {
        textAlign: 'center',
    },
    p: {
        textAlign: 'center',
    },
    content: {
        padding: '100px 0 100px 0',
    },
    h4: {
        textAlign: 'center',
        color: 'green',
        fontSize: '23px',
    }
}

const Authenticate = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const [, setContext] = useContext(Context)

    useEffect(() => {
        let query = queryString.parse(location.search)
        if (query && query.token) {
            fetchData(VERIFY_LOGIN_TOKEN + '?token=' + query.token, 'GET', '', false, false, (res) => {
                if (res.records) {
                    localStorage.removeItem('waae-admin-token')
                    localStorage.setItem('waae-token', res.records.accessToken)

                    fetchData(GET_AUTH_USER_INFO, 'GET', '', true, false, (res_auth) => {
                        if (res_auth.records) {
                            setContext(prevState => ({
                                ...prevState,
                                auth: res_auth.records
                            }))

                            if (res_auth.records.user_type === '2') {
                                navigate('/my-assignments')
                            } else {
                                if (query.redirect && query.redirect === 'analytics') {
                                    navigate('/cms/assignment-analytics', { state: { id: res.records.assignment_id ?? '', title: res.records.assignment_title ?? '' } })
                                } else {
                                    navigate('/cms/create-assignment')
                                }
                            }
                        }
                    });
                }
            })
        }
        // eslint-disable-next-line
    }, [location.search])

    return (
        <div className="main-section" style={styles.mainSection}>
            <div className="logo" style={styles.logo}>
                <svg xmlns="http://www.w3.org/2000/svg" width="209" height="75" viewBox="0 0 209 75" fill="none">
                    <rect x="9.83203" y="46.0625" width="27.9855" height="27.9388" rx="13.9694" fill="#12D76C"></rect>
                    <rect x="32.5234" y="4.53125" width="15.0337" height="15.0086" rx="7.50429" fill="#6FCAFD"></rect>
                    <rect y="34.7344" width="18.4547" height="5.0164" rx="2.5082" fill="#B6FD6F"></rect>
                    <path fillRule="evenodd" clip-rule="evenodd" d="M198.168 62.6251C198.624 64.9284 200.661 66.6655 203.097 66.6655C205.868 66.6655 208.122 64.4196 208.122 61.6492C208.122 58.8787 205.868 56.6328 203.097 56.6328C202.92 56.6328 202.744 56.642 202.572 56.66C202.634 56.9757 202.667 57.3021 202.667 57.636C202.667 60.2289 200.692 62.3623 198.168 62.6251Z" fill="#1E90FF"></path>
                    <path d="M36.3003 66.4489V43.1917H51.5093V46.6138H40.5602V52.9929H50.5109V56.3154H40.5602V63.0267H51.5093V66.4489H36.3003Z" fill="#111111"></path>
                    <path d="M55.4405 66.4489V42.5272H59.7003V66.4489H55.4405Z" fill="#111111"></path>
                    <path d="M72.0735 66.8476C70.4095 66.8476 68.934 66.4932 67.6472 65.7844C66.3604 65.0756 65.3509 64.0788 64.6187 62.7942C63.8866 61.5095 63.5205 60.0255 63.5205 58.3421C63.5205 56.6366 63.8755 55.1193 64.5855 53.7903C65.3176 52.4613 66.316 51.4314 67.5807 50.7004C68.8675 49.9474 70.3762 49.5708 72.1067 49.5708C73.7264 49.5708 75.1574 49.9252 76.3999 50.634C77.6423 51.3428 78.6074 52.3174 79.2952 53.5578C80.0052 54.776 80.3602 56.1382 80.3602 57.6444C80.3602 57.888 80.3491 58.1427 80.3269 58.4085C80.3269 58.6743 80.3158 58.9512 80.2936 59.2391H67.7471C67.8358 60.5238 68.2795 61.5316 69.0783 62.2626C69.8992 62.9935 70.8865 63.359 72.0402 63.359C72.9055 63.359 73.6265 63.1707 74.2034 62.7942C74.8024 62.3955 75.2461 61.886 75.5346 61.2658H79.861C79.5504 62.3069 79.029 63.2593 78.2968 64.1231C77.5868 64.9648 76.6994 65.6293 75.6344 66.1166C74.5916 66.6039 73.4047 66.8476 72.0735 66.8476ZM72.1067 53.0262C71.064 53.0262 70.1432 53.3252 69.3445 53.9232C68.5458 54.4991 68.0355 55.3851 67.8136 56.5812H76.0338C75.9672 55.4958 75.5679 54.632 74.8357 53.9897C74.1035 53.3473 73.1939 53.0262 72.1067 53.0262Z" fill="#111111"></path>
                    <path d="M83.943 66.4489V49.9695H87.7036L88.0697 52.1956C88.6022 51.3982 89.3011 50.7669 90.1663 50.3018C91.0538 49.8145 92.0744 49.5708 93.2281 49.5708C95.7796 49.5708 97.5878 50.5565 98.6527 52.5278C99.2518 51.6197 100.05 50.8998 101.049 50.3682C102.069 49.8366 103.179 49.5708 104.377 49.5708C106.529 49.5708 108.182 50.2132 109.336 51.4978C110.489 52.7825 111.066 54.6652 111.066 57.146V66.4489H106.806V57.5447C106.806 56.1271 106.529 55.0418 105.974 54.2887C105.442 53.5356 104.61 53.1591 103.478 53.1591C102.325 53.1591 101.393 53.5799 100.683 54.4216C99.995 55.2633 99.6511 56.4372 99.6511 57.9434V66.4489H95.3913V57.5447C95.3913 56.1271 95.114 55.0418 94.5593 54.2887C94.0046 53.5356 93.1504 53.1591 91.9967 53.1591C90.8652 53.1591 89.9445 53.5799 89.2345 54.4216C88.5467 55.2633 88.2028 56.4372 88.2028 57.9434V66.4489H83.943Z" fill="#111111"></path>
                    <path d="M123.131 66.8476C121.467 66.8476 119.992 66.4932 118.705 65.7844C117.418 65.0756 116.408 64.0788 115.676 62.7942C114.944 61.5095 114.578 60.0255 114.578 58.3421C114.578 56.6366 114.933 55.1193 115.643 53.7903C116.375 52.4613 117.374 51.4314 118.638 50.7004C119.925 49.9474 121.434 49.5708 123.164 49.5708C124.784 49.5708 126.215 49.9252 127.457 50.634C128.7 51.3428 129.665 52.3174 130.353 53.5578C131.063 54.776 131.418 56.1382 131.418 57.6444C131.418 57.888 131.407 58.1427 131.384 58.4085C131.384 58.6743 131.373 58.9512 131.351 59.2391H118.805C118.893 60.5238 119.337 61.5316 120.136 62.2626C120.957 62.9935 121.944 63.359 123.098 63.359C123.963 63.359 124.684 63.1707 125.261 62.7942C125.86 62.3955 126.304 61.886 126.592 61.2658H130.918C130.608 62.3069 130.086 63.2593 129.354 64.1231C128.644 64.9648 127.757 65.6293 126.692 66.1166C125.649 66.6039 124.462 66.8476 123.131 66.8476ZM123.164 53.0262C122.121 53.0262 121.201 53.3252 120.402 53.9232C119.603 54.4991 119.093 55.3851 118.871 56.5812H127.091C127.025 55.4958 126.625 54.632 125.893 53.9897C125.161 53.3473 124.251 53.0262 123.164 53.0262Z" fill="#111111"></path>
                    <path d="M135 66.4489V49.9695H138.761L139.094 52.7604C139.604 51.7858 140.336 51.0105 141.29 50.4347C142.267 49.8588 143.409 49.5708 144.718 49.5708C146.759 49.5708 148.346 50.2132 149.477 51.4978C150.609 52.7825 151.175 54.6652 151.175 57.146V66.4489H146.915V57.5447C146.915 56.1271 146.626 55.0418 146.049 54.2887C145.473 53.5356 144.574 53.1591 143.354 53.1591C142.156 53.1591 141.168 53.5799 140.392 54.4216C139.637 55.2633 139.26 56.4372 139.26 57.9434V66.4489H135Z" fill="#111111"></path>
                    <path d="M162.603 66.4489C160.872 66.4489 159.486 66.028 158.443 65.1863C157.4 64.3446 156.879 62.8495 156.879 60.701V53.5245H154.05V49.9695H156.879L157.378 45.5507H161.139V49.9695H165.598V53.5245H161.139V60.7342C161.139 61.5316 161.305 62.0854 161.638 62.3955C161.993 62.6834 162.592 62.8274 163.435 62.8274H165.498V66.4489H162.603Z" fill="#111111"></path>
                    <path d="M174.946 66.8476C173.526 66.8476 172.362 66.6261 171.452 66.1831C170.542 65.7179 169.866 65.1088 169.422 64.3557C168.978 63.6026 168.756 62.772 168.756 61.8639C168.756 60.3356 169.355 59.0952 170.553 58.1427C171.751 57.1903 173.549 56.7141 175.945 56.7141H180.138V56.3154C180.138 55.1858 179.816 54.3551 179.173 53.8235C178.529 53.292 177.731 53.0262 176.777 53.0262C175.911 53.0262 175.157 53.2366 174.514 53.6574C173.87 54.0561 173.471 54.6542 173.316 55.4516H169.156C169.267 54.2555 169.666 53.2144 170.354 52.3284C171.064 51.4425 171.973 50.7669 173.083 50.3018C174.192 49.8145 175.434 49.5708 176.81 49.5708C179.162 49.5708 181.014 50.1578 182.368 51.3317C183.721 52.5056 184.398 54.1669 184.398 56.3154V66.4489H180.77L180.371 63.7909C179.883 64.6769 179.195 65.4078 178.308 65.9837C177.442 66.5596 176.322 66.8476 174.946 66.8476ZM175.911 63.5251C177.132 63.5251 178.075 63.1264 178.74 62.329C179.428 61.5316 179.861 60.546 180.038 59.372H176.411C175.279 59.372 174.469 59.5825 173.981 60.0033C173.493 60.402 173.249 60.9004 173.249 61.4984C173.249 62.1407 173.493 62.6391 173.981 62.9935C174.469 63.3479 175.113 63.5251 175.911 63.5251Z" fill="#111111"></path>
                    <path d="M188.463 66.4489V42.5272H192.723V66.4489H188.463Z" fill="#111111"></path>
                    <path d="M11.5279 33.2244L5.37109 9.96722H9.93045L14.1237 28.4733L19.0492 9.96722H23.7417L28.534 28.4733L32.7273 9.96722H37.3199L30.9967 33.2244H25.9381L21.3122 15.9809L16.5532 33.2244H11.5279Z" fill="#111111"></path>
                    <path d="M46.5728 33.6231C44.9088 33.6231 43.4334 33.2687 42.1465 32.5599C40.8597 31.8511 39.8502 30.8544 39.1181 29.5697C38.3859 28.285 38.0198 26.801 38.0198 25.1176C38.0198 23.4121 38.3748 21.8948 39.0848 20.5658C39.8169 19.2369 40.8153 18.2069 42.08 17.476C43.3668 16.7229 44.8755 16.3463 46.6061 16.3463C48.2257 16.3463 49.6567 16.7007 50.8992 17.4095C52.1416 18.1183 53.1067 19.0929 53.7945 20.3333C54.5045 21.5515 54.8595 22.9137 54.8595 24.4199C54.8595 24.6635 54.8484 24.9182 54.8262 25.184C54.8262 25.4498 54.8151 25.7267 54.7929 26.0147H42.2464C42.3351 27.2993 42.7789 28.3071 43.5776 29.0381C44.3985 29.769 45.3858 30.1345 46.5395 30.1345C47.4048 30.1345 48.1258 29.9462 48.7027 29.5697C49.3017 29.171 49.7455 28.6615 50.0339 28.0413H54.3603C54.0497 29.0824 53.5283 30.0348 52.7961 30.8987C52.0862 31.7403 51.1987 32.4048 50.1337 32.8921C49.091 33.3794 47.904 33.6231 46.5728 33.6231ZM46.6061 19.8017C45.5633 19.8017 44.6425 20.1007 43.8438 20.6987C43.0451 21.2746 42.5348 22.1606 42.3129 23.3567H50.5331C50.4665 22.2714 50.0672 21.4075 49.335 20.7652C48.6029 20.1228 47.6932 19.8017 46.6061 19.8017Z" fill="#111111"></path>
                    <path d="M72.0632 33.6231C70.6432 33.6231 69.4784 33.4016 68.5688 32.9586C67.6591 32.4934 66.9824 31.8843 66.5387 31.1312C66.095 30.3781 65.8731 29.5475 65.8731 28.6394C65.8731 27.1111 66.4721 25.8707 67.6702 24.9182C68.8683 23.9658 70.6654 23.4896 73.0616 23.4896H77.2549V23.0909C77.2549 21.9613 76.9331 21.1307 76.2897 20.5991C75.6463 20.0675 74.8476 19.8017 73.8936 19.8017C73.0283 19.8017 72.2739 20.0121 71.6305 20.4329C70.9871 20.8316 70.5878 21.4297 70.4325 22.2271H66.2725C66.3834 21.031 66.7827 19.9899 67.4705 19.104C68.1805 18.218 69.0902 17.5424 70.1995 17.0773C71.3088 16.59 72.5513 16.3463 73.9269 16.3463C76.2786 16.3463 78.1312 16.9333 79.4846 18.1072C80.838 19.2812 81.5147 20.9424 81.5147 23.0909V33.2244H77.8872L77.4878 30.5664C76.9997 31.4524 76.3119 32.1833 75.4245 32.7592C74.5592 33.3351 73.4387 33.6231 72.0632 33.6231ZM73.0283 30.3006C74.2486 30.3006 75.1915 29.9019 75.8571 29.1045C76.5449 28.3071 76.9775 27.3215 77.155 26.1476H73.5275C72.396 26.1476 71.5862 26.358 71.0981 26.7788C70.6099 27.1775 70.3659 27.6759 70.3659 28.2739C70.3659 28.9163 70.6099 29.4146 71.0981 29.769C71.5862 30.1234 72.2296 30.3006 73.0283 30.3006Z" fill="#111111"></path>
                    <path d="M85.5798 33.2244V16.745H89.3737L89.7731 19.8349C90.3721 18.7717 91.1819 17.93 92.2025 17.3098C93.2453 16.6675 94.4656 16.3463 95.8633 16.3463V20.8316H94.6652C93.7334 20.8316 92.9014 20.9756 92.1692 21.2636C91.4371 21.5515 90.8602 22.0499 90.4387 22.7587C90.0393 23.4674 89.8396 24.4531 89.8396 25.7156V33.2244H85.5798Z" fill="#111111"></path>
                    <path d="M106.503 33.6231C104.839 33.6231 103.363 33.2687 102.077 32.5599C100.79 31.8511 99.7802 30.8544 99.0481 29.5697C98.3159 28.285 97.9498 26.801 97.9498 25.1176C97.9498 23.4121 98.3048 21.8948 99.0148 20.5658C99.7469 19.2369 100.745 18.2069 102.01 17.476C103.297 16.7229 104.805 16.3463 106.536 16.3463C108.156 16.3463 109.587 16.7007 110.829 17.4095C112.072 18.1183 113.037 19.0929 113.725 20.3333C114.435 21.5515 114.789 22.9137 114.789 24.4199C114.789 24.6635 114.778 24.9182 114.756 25.184C114.756 25.4498 114.745 25.7267 114.723 26.0147H102.176C102.265 27.2993 102.709 28.3071 103.508 29.0381C104.328 29.769 105.316 30.1345 106.469 30.1345C107.335 30.1345 108.056 29.9462 108.633 29.5697C109.232 29.171 109.675 28.6615 109.964 28.0413H114.29C113.98 29.0824 113.458 30.0348 112.726 30.8987C112.016 31.7403 111.129 32.4048 110.064 32.8921C109.021 33.3794 107.834 33.6231 106.503 33.6231ZM106.536 19.8017C105.493 19.8017 104.573 20.1007 103.774 20.6987C102.975 21.2746 102.465 22.1606 102.243 23.3567H110.463C110.397 22.2714 109.997 21.4075 109.265 20.7652C108.533 20.1228 107.623 19.8017 106.536 19.8017Z" fill="#111111"></path>
                    <path d="M131.993 33.6231C130.573 33.6231 129.408 33.4016 128.499 32.9586C127.589 32.4934 126.912 31.8843 126.469 31.1312C126.025 30.3781 125.803 29.5475 125.803 28.6394C125.803 27.1111 126.402 25.8707 127.6 24.9182C128.798 23.9658 130.595 23.4896 132.992 23.4896H137.185V23.0909C137.185 21.9613 136.863 21.1307 136.22 20.5991C135.576 20.0675 134.778 19.8017 133.824 19.8017C132.958 19.8017 132.204 20.0121 131.561 20.4329C130.917 20.8316 130.518 21.4297 130.362 22.2271H126.202C126.313 21.031 126.713 19.9899 127.401 19.104C128.111 18.218 129.02 17.5424 130.129 17.0773C131.239 16.59 132.481 16.3463 133.857 16.3463C136.209 16.3463 138.061 16.9333 139.415 18.1072C140.768 19.2812 141.445 20.9424 141.445 23.0909V33.2244H137.817L137.418 30.5664C136.93 31.4524 136.242 32.1833 135.354 32.7592C134.489 33.3351 133.369 33.6231 131.993 33.6231ZM132.958 30.3006C134.179 30.3006 135.121 29.9019 135.787 29.1045C136.475 28.3071 136.908 27.3215 137.085 26.1476H133.457C132.326 26.1476 131.516 26.358 131.028 26.7788C130.54 27.1775 130.296 27.6759 130.296 28.2739C130.296 28.9163 130.54 29.4146 131.028 29.769C131.516 30.1234 132.16 30.3006 132.958 30.3006Z" fill="#111111"></path>
                    <path d="M145.51 33.2244V9.30273H149.77V33.2244H145.51Z" fill="#111111"></path>
                    <path d="M154.122 33.2244V9.30273H158.382V33.2244H154.122Z" fill="#111111"></path>
                </svg>
            </div>
            <div className="section" style={styles.section}>
                <div className="content" style={styles.content}>
                    <h4 style={styles.h4}>Please wait...</h4>
                    <p style={styles.p}>We are authenticating your login details.</p>
                </div>
            </div>
        </div>
    )
}

export default Authenticate