import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExamLoading from "./partials/ExamLoading";
import ExamCalculatorAndTable from "./partials/ExamCalculatorAndTable";
import ExamQuestionList from "./partials/ExamQuestionList";
import ExamTitle from "./partials/ExamTitle";
import ExamButton from "./partials/ExamButton";
import ExamQuestion from "./partials/ExamQuestion";
import { EXAM_INFO, SET_EXAM_RECORD, fetchData, RESTART_EXAM, QUIT_EXAM, SUBMIT_ANSWER, PAGE_CONTENT, END_EXAM } from "../../components/Service";
import ExamTime from "./partials/ExamTime";
import _, { floor } from "lodash";
import { useCallback } from "react";
import MovingRocket from "../../components/MovingRocket";
import PeriodicTable from "../../components/PeriodicTable";
import ExamCalculator from "../../components/ExamCalculator";
import { hideAllModals, showModel, stopBackgroundScroll } from "../../components/CommonMethod";
import { Context } from "../../components/Context";
import { useTimer } from 'react-timer-hook';
import { useStopwatch } from 'react-timer-hook';
import Modal from "./partials/Modal";
import ProgressBar from "./partials/ProgressBar";
import { useIdleTimer } from 'react-idle-timer'

const Exam = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [course] = useState(location && location.state && location.state.course)
    const [workbook] = useState(location && location.state && location.state.workbook)
    const [workbookAttemptType] = useState((location && location.state && location.state.workbook_attempt_type && location.state.workbook_attempt_type === 'assignment') ? 'assignment' : 'workbook')
    const [assignment_id] = useState((location && location.state && location.state.assignment && location.state.assignment.id) ? location.state.assignment.id : '')
    const [mode] = useState(location && location.state && location.state.mode)
    const [questions, setQuestions] = useState(false);
    const [startExam, setStartExam] = useState(false);
    const [resumeExam, setResumeExam] = useState(false);
    const [activeQuestion, setActiveQuestion] = useState(1);
    const [prevQuestionDetails, setPrevQuestionDetails] = useState([]);
    const [prevAnswerDetails, setPrevAnswerDetails] = useState([]);
    const [progress, setProgress] = useState(0)
    const [action, setAction] = useState(location.state && _.upperFirst(location.state.action))
    const [progressData, setProgressData] = useState({ index: 0, value: '', correctText: ['Well Done!'], incorrectText: ['Not quite..'] })
    const [animation, setAnimation] = useState('')
    const [examId, setExamId] = useState(null)
    const [context] = useContext(Context)
    const timer = useTimer({ autoStart: false, expiryTimestamp: new Date(), onExpire: () => autoTimeOut() });
    const stopwatch = useStopwatch({ autoStart: false });

    const activeScreenManager = (active) => {
        if (startExam) {
            if (active) {
                let time_offset = new Date();
                time_offset.setSeconds(time_offset.getSeconds() + stopwatch.totalSeconds + 1);
                if (mode === 'exam') timer.resume();
                else stopwatch.reset(time_offset);
            } else {
                if (mode === 'exam') timer.pause();
                else stopwatch.pause();
            }
        }
    }
    const idleTimer = useIdleTimer({ timeout: (60 * 1000), onIdle: activeScreenManager, onActive: activeScreenManager })

    const timeCounter = useMemo(() => {
        if (startExam) {
            let time = mode === 'exam' ? { hours: timer.hours, minutes: timer.minutes, seconds: timer.seconds } : { hours: stopwatch.hours, minutes: stopwatch.minutes, seconds: stopwatch.seconds };
            let s = time.seconds > 9 ? time.seconds : `0${time.seconds}`;
            let m = time.minutes > 9 ? time.minutes : `0${time.minutes}`;
            let h = time.hours > 9 ? time.hours : `0${time.hours}`;
            let exam_time = time.hours ? `${h}:${m}:${s}` : `${m}:${s}`;
            localStorage.setItem('exam_time', exam_time);
            let question_counter = localStorage.getItem('question_counter');
            localStorage.setItem('question_counter', (parseInt(question_counter) + 1));
            return exam_time;
        } else {
            localStorage.setItem('question_counter', "-1");
        }
    }, [timer.seconds, timer.minutes, timer.hours, stopwatch.seconds, stopwatch.minutes, stopwatch.hours, mode, startExam]);

    window.document.title = "Begin practice";

    const navigateFromThisPage = () => {
        if (context && context.auth && context.auth.user_type === '2') {
            if (location && location.state && location.state.assignment && workbookAttemptType === 'assignment') {
                navigate('/my-assignments/workbooks', { state: { ...location.state.assignment } });
            } else {
                navigate('/my-workbooks');
            }
        } else {
            navigate('/cms/assigned-workbook');
        }
    }

    const manageCounter = (exam = false) => {
        if (exam || resumeExam) {
            let timeOffset = new Date();
            let time = exam ? (parseInt(workbook.time_limit) * 60) : parseInt(resumeExam);
            timeOffset.setSeconds(timeOffset.getSeconds() + time);
            if (exam) timer.restart(timeOffset);
            else stopwatch.reset(timeOffset);
        } else stopwatch.start();
    }

    const filterToggle = () => {
        document.getElementById('mobileViewBox').classList.toggle('active');
        stopBackgroundScroll("mobileViewBox");
    }

    useEffect(() => {
        if (!location.state) {
            navigateFromThisPage()
        }
        if (workbook && course && mode) {

            fetchData(`${PAGE_CONTENT}?type=workbook-content`, 'GET', '', false, false, (res) => {
                if (res.records) {
                    let msg = JSON.parse(res.records)
                    setProgressData({
                        ...progressData,
                        correctText: msg.correct_answer && msg.correct_answer.length > 0 ? msg.correct_answer : ['Well Done!'],
                        incorrectText: msg.incorrect_answer && msg.incorrect_answer.length > 0 ? msg.incorrect_answer : ['Not quite..'],
                    })
                }
            }, false, false, '', false);

            fetchData(`${EXAM_INFO}/${workbook.id}/${mode}`, 'get', '', true, false, (res) => {
                if (res.records) {
                    setQuestions(res.records)
                }
            });

            fetchData(`${SET_EXAM_RECORD}/${workbook.id}?workbook_attempt_type=${workbookAttemptType}&assignment_id=${assignment_id}`, 'get', '', true, false, (res) => {
                if (res.records && res.records.id) {
                    setPrevQuestionDetails(res.records.question_details)
                    setPrevAnswerDetails(res.records.answer_details)
                    setActiveQuestion(res.records.question_position + 1)
                    setProgress(res.records.answer_details.length)
                    setResumeExam(res.records.consume_time)
                    setAction('Resume')

                    let questions_details = res.records.question_details
                    let ans_sq = { value: '', index: 0 }
                    for (let i in questions_details) {
                        if (ans_sq.value === questions_details[i].correct) {
                            ans_sq = { value: questions_details[i].correct, index: ans_sq.index + 1 }
                        } else {
                            ans_sq = { value: questions_details[i].correct, index: 0 }
                        }
                    }
                    setProgressData({ ...progressData, ...ans_sq })
                }
            }, false, false, '', false);

        }
        // eslint-disable-next-line
    }, [workbook, course, mode, location])


    const restartExam = () => {
        stopwatch.reset()
        setActiveQuestion(1)
        setPrevQuestionDetails([])
        setPrevAnswerDetails([])
        setProgress(0)
        setStartExam(false)
        setResumeExam(false)
        setAction('Restart')
        setProgressData({ ...progressData, index: 0, value: '' })
        fetchData(RESTART_EXAM, 'POST', { workbook_id: workbook.id, end_time: timeCounter, workbook_attempt_type: workbookAttemptType, assignment_id: assignment_id }, true, false, (res) => { });
        document.body.classList.remove('body-overflowhidden');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const quitExam = () => {
        if (startExam) {
            fetchData(QUIT_EXAM, 'POST', { workbook_id: workbook.id, end_time: timeCounter, workbook_attempt_type: workbookAttemptType, assignment_id: assignment_id }, true, false, (res) => {
                if (res.records) navigateFromThisPage()
            });
        } else navigateFromThisPage()
    }

    const autoTimeOut = () => {
        if (startExam) {
            hideAllModals();
            setTimeout(() => showModel('timeOutModal'), 100);
            fetchData(QUIT_EXAM, 'POST', { workbook_id: workbook.id, end_time: timeCounter, workbook_attempt_type: workbookAttemptType, assignment_id: assignment_id }, true, false, (res) => { });
            setTimeout(() => {
                let closeTimeoutPopup = document.querySelector('#timeOutModal [data-bs-dismiss="modal"]');
                if (closeTimeoutPopup) closeTimeoutPopup.click();
            }, 3000);
        }
    }

    const saveAnswer = useCallback((data, answer = null) => {
        if (mode === 'practice' && data.correct && progressData.value && (progressData.index === 1 || progressData.index === 3)) {
            if (progressData.index === 1) {
                setAnimation('amazing_work')
                setTimeout(() => { setAnimation('') }, 3000);
            } else {
                setAnimation('rocket')
                setTimeout(() => { setAnimation('') }, 6000);
            }
        }
        setProgressData({ ...progressData, index: progressData.value === data.correct ? progressData.index + 1 : 0, value: data.correct })
        let exam_time = localStorage.getItem('exam_time')
        localStorage.setItem('question_counter', 0)
        let answer_details = [...prevAnswerDetails, answer];
        let complete_exam = data.order === questions.length ? true : false
        setProgress(answer_details.length)
        fetchData(SUBMIT_ANSWER, 'POST', { ...data, answer: answer, complete_exam: complete_exam, workbook_id: workbook.id, exam_mode: mode, end_time: exam_time, workbook_attempt_type: workbookAttemptType, assignment_id: assignment_id }, true, false, (res) => {
            if (res && res.records && res.records.exam_id) {
                setExamId(res.records.exam_id)
            }
        }, false, false, '', false);
        // eslint-disable-next-line
    }, [questions, prevQuestionDetails, prevAnswerDetails, workbook, mode]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (questions.length === activeQuestion - 1) {
            let total_time = timeCounter
            let state = { questions: questions, questions_details: prevQuestionDetails, answer_details: prevAnswerDetails, total_time: total_time, course: course, workbook: workbook, mode: mode, workbook_attempt_type: workbookAttemptType, assignment: location.state.assignment }
            if (examId) fetchData(END_EXAM, 'POST', { exam_id: examId, end_time: timeCounter }, true, false, (res) => { });
            if (mode === 'exam') {
                let remain_time = ((parseInt(workbook.time_limit) * 60) - timer.totalSeconds)
                let minutes = floor((remain_time / 60))
                let seconds = floor(remain_time % 60)
                seconds = seconds > 9 ? seconds : `0${seconds}`;
                minutes = minutes > 9 ? minutes : `0${minutes}`;

                total_time = minutes + ':' + seconds
                navigate(`/workbook/${_.kebabCase(workbook && workbook.title)}/review-performance`, { state: { ...state, total_time: total_time, exam_id: examId } })
            } else {
                navigate(`/workbook/${_.kebabCase(workbook && workbook.title)}/review-performance`, { state: { ...state, clapping_time: (Date.now() + 2000), exam_id: examId } })
            }
        }
        // eslint-disable-next-line
    }, [activeQuestion])

    return (<>
        {location.state &&
            <>
                <nav className="navbar navbar-expand-lg navbar-front bg-white position-sticky top-0 zindex-100 shadow-none z-index-responsive-top" >
                    <div className="container py-4">
                        <ExamTitle workbook={workbook} course={course} />
                        <button type="button" className="btn btn-link p-2 ms-auto d-block d-lg-none" onClick={() => filterToggle()}>
                            <svg className="icon ts-1p4"><use href="#icon_filter"></use></svg>
                        </button>
                        <div className="col-lg-4 d-lg-inline d-none text-end" id="asideMobile">
                            <ExamButton startExam={startExam} restartExam={restartExam} quitExam={quitExam} mode={mode} />
                        </div>
                    </div>
                </nav>

                {startExam && <>
                    <div className="container d-none">
                        <div className="row g-4 exam-progress">
                            <div className="col-lg-9 bg-white">
                                <ProgressBar mode={mode} progressData={progressData} progress={progress} questions={questions} />
                            </div>
                        </div>
                    </div>
                </>}

                {startExam && <>
                    <div className="container">
                        <div className="row g-4 exam-sidebar justify-content-end">
                            <div className="col-lg-3 pt-3" id="side_scrooling">
                                <div className="aside-filter" id="mobileViewBox">
                                    <div className="aside-innerfilter">
                                        <div className="d-flex d-lg-none align-items-center justify-content-between pb-3">
                                            <button type="button" className="btn" onClick={() => filterToggle()} title="Close">
                                                <svg className="icon ts-1p5 pe-none"><use href="#icon_clear"></use></svg>
                                            </button>
                                        </div>
                                        <div className="ms-auto d-lg-inline d-none">
                                            <ExamTime mode={mode} workbook={workbook} timeCounter={timeCounter} />
                                        </div>
                                        <ExamQuestionList mode={mode} questions={questions} activeQuestion={activeQuestion} setActiveQuestion={setActiveQuestion} prevQuestionDetails={prevQuestionDetails} />
                                        <ExamCalculatorAndTable course={course} filterToggle={filterToggle} mode={mode} questions={questions} activeQuestion={activeQuestion} />
                                        <div className="d-lg-none mt-2">
                                            <ExamButton startExam={startExam} restartExam={restartExam} quitExam={quitExam} filterToggle={filterToggle} mode={mode} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}

                <div className="container">
                    {startExam ? <>
                        <div className="row g-4 exam-body">
                            <div className="col-lg-9 bg-white1">
                                <div className="progress-sticky">
                                    <ProgressBar mode={mode} progressData={progressData} progress={progress} questions={questions} />
                                </div>
                                <div className="d-lg-none">
                                    <ExamTime mode={mode} workbook={workbook} timeCounter={timeCounter} />
                                </div>
                                <ExamQuestion animation={animation} mode={mode} questions={questions} activeQuestion={activeQuestion} setActiveQuestion={setActiveQuestion} setPrevQuestionDetails={setPrevQuestionDetails} setPrevAnswerDetails={setPrevAnswerDetails} saveAnswer={saveAnswer} setProgress={setProgress} progressData={progressData} questions_details={prevQuestionDetails} answer_details={prevAnswerDetails} />
                            </div>
                        </div>
                    </> : <>
                        <ExamLoading mode={mode} action={action} questions={questions} setStartExam={setStartExam} manageCounter={manageCounter}>
                            <div className="aside-filter d-lg-none" id="mobileViewBox">
                                <div className="aside-innerfilter">
                                    <div className="d-flex d-lg-none align-items-center justify-content-between pb-3">
                                        <button type="button" className="btn" onClick={() => filterToggle()} title="Close"><svg className="icon ts-1p5"><use href="#icon_clear"></use></svg></button>
                                    </div>
                                    <ExamButton startExam={startExam} restartExam={restartExam} quitExam={quitExam} filterToggle={filterToggle} />
                                </div>
                            </div>
                        </ExamLoading>
                    </>}
                    {animation === 'rocket' && <MovingRocket />}
                </div>

                {course.periodic_table && <PeriodicTable image={course.periodic_table} heading="Periodic Table" />}
                {course.calculator === "yes" && <ExamCalculator />}
                {mode && questions && activeQuestion && questions[activeQuestion - 1] && <PeriodicTable video={questions[activeQuestion - 1].video_url} heading="Video Solution" />}
                <Modal restartExam={restartExam} quitExam={quitExam} navigateFromThisPage={navigateFromThisPage} />
            </>
        }
    </>)
}

export default Exam