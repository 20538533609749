import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { validateForm, ButtonSpinner, initialFormState } from "../../components/CommonMethod";
import { DROPDOWN_DATA, fetchData, WORKBOOK_CREATE, WORKBOOK_UPDATE, GET_LAST_WORKBOOK_ID } from "../../components/Service";
import { useEffect } from "react";
import QuestionTypeModel from "./partials/QuestionTypeModel";
import ManageQuestion from "./partials/ManageQuestion";
import { CKEditor } from 'ckeditor4-react';
import ReactHtmlParser from "react-html-parser"

const CreateWorkbook = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let workbookInfo = location.state

    let title = workbookInfo ? "Update Workbook" : "Create Workbook"

    window.document.title = title;

    const [tinyloader, setTinyloader] = useState(false);
    const [questionType, setQuestionType] = useState(false);
    const [questionTypeList, setQuestionTypeList] = useState([]);
    const [selectedQuestionType, setSelectedQuestionType] = useState([]);
    const [serialNumber, setSerialNumber] = useState('')
    const [reloadQuestions] = useState(false);
    const [learningOutcomes, setLearningOutcomes] = useState(workbookInfo && workbookInfo.learning_outcomes ? ReactHtmlParser(workbookInfo.learning_outcomes) : '')

    const createWorkbook = (e) => {
        let formdata = new FormData(document.getElementById('addWorkbookForm'))
        formdata.append("learning_outcomes", learningOutcomes);
        if (validateForm(e, 'addWorkbookForm')) {
            setTinyloader(true);

            fetchData(workbookInfo ? WORKBOOK_UPDATE : WORKBOOK_CREATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);

                if (res.success) {
                    navigate('../update-workbook', {state: {...res.records, redirectTo: 'question'}})
                }
            })
        }
    }

    // const saveQuestions = (e) => {
    //     let formdata = new FormData(document.getElementById('addQuestionForm'))
    //     formdata.append('workbook_id', workbookInfo && workbookInfo.id)

    //     if (validateForm(e, 'addQuestionForm')) {
    //         setTinyloader(true);

    //         fetchData(QUESTION_CREATE, 'POST', formdata, true, true, (res) => {
    //             setTinyloader(false);

    //             if (res.success) {
    //                 setReloadQuestions(now)
    //             }
    //         })
    //     }
    // }

    useEffect(() => {
        if(!workbookInfo) {
            let configuration_box_btn = document.getElementById("configurationBoxBtn")
            if(configuration_box_btn) configuration_box_btn.click()
            let queries_box = document.getElementById("queriesBox")
            if(queries_box) queries_box.classList.remove('active')
            initialFormState("addWorkbookForm")
            
            fetchData(GET_LAST_WORKBOOK_ID, 'GET', '', true, false, (res) => {
                if (res.records) {
                setSerialNumber(res.records.last_id)
                }
            })
        }

        if(workbookInfo) {
            fetchData(DROPDOWN_DATA + '?type=question_type', 'GET', '', true, false, (res) => {
                if (res.records) {
                    setQuestionTypeList(res.records.question_type)
                }
            })
        }

        if(workbookInfo && workbookInfo.redirectTo === 'question') {
            document.getElementById('questionTab').click();
        }
    }, [workbookInfo])

    return (
        <div className="container-fluid">
            <div className="page-head sticky_top_padding">
            <div className="row">
                <div className="col-sm-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                            <li className="breadcrumb-item"><Link to="/cms/manage-workbooks">Manage Workbooks</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{title}</li>
                            {workbookInfo && workbookInfo.title && <li className="breadcrumb-item text-green" aria-current="page">{workbookInfo && workbookInfo.title}</li> }
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="row gx-4 mb-1 align-items-lg-end align-items-md-end  align-items-center">
                <div className="col-lg-6 col-sm mb-3 col-6 mb-3">
                    <h1 className="h3 text-dim mb-0">{title}</h1>
                </div>
                <div className="col-lg-6 col-auto mb-3 col-6  mb-3">
                    { questionType ? ''
                    // <button type="button" className="btn btn-theme-primary btn-lg float-end" onClick={(e) => saveQuestions(e)} disabled={tinyloader}>
                    //     <ButtonSpinner load={tinyloader} btnName="Save Questions" />
                    // </button>
                    :
                    <button type="button" className="btn btn-theme-primary btn-lg float-end" onClick={(e) => createWorkbook(e)} disabled={tinyloader}>
                        <ButtonSpinner load={tinyloader} btnName={ workbookInfo ? 'Update and next': 'Save and next' } />
                    </button> }
                </div>
            </div></div>

            <div className="row">
                <div className="col-sm-12">
                    <nav>
                        <div className="nav nav-tabs nav-bar mb-2" id="nav-tab" role="tablist">
                            <button id="configurationBoxBtn" className="nav-link active" data-bs-toggle="tab" data-bs-target="#configurationBox" type="button" role="tab" aria-selected="true" onClick={() => setQuestionType(false)}>Configuration</button>
                            { workbookInfo && <button className="nav-link" data-bs-toggle="tab" data-bs-target="#queriesBox" type="button" role="tab" aria-selected="false" id="questionTab" onClick={() => setQuestionType(true)}>Questions</button> }
                        </div>
                    </nav>
                    <div className="tab-content pt-4">
                        <div className="tab-pane fade show active" id="configurationBox" role="tabpanel" tabIndex="0">
                            <form action="" className="needs-validation" id="addWorkbookForm" noValidate autoComplete="off">
                                <h5 className="fw-bold fs-18 mb-4">Details</h5>

                                <input type="hidden" name="id" defaultValue={workbookInfo && workbookInfo.id} />
                                <div className="form-floating maxw-360">
                                    <input type="text" className="form-control form-control-md" defaultValue={workbookInfo && workbookInfo.title} placeholder="Workbook title" name="title" id="title" required />
                                    <div className="invalid-feedback">
                                        The Workbook title field is required .
                                    </div>
                                    <label>Workbook Title<span className="text-danger">*</span></label>
                                </div>

                                <h5 className="fw-bold fs-18 my-4">Workbook serial No.</h5>
                                <div className="form-floating maxw-360">
                                    <input type="number" min="1" step="1" className="form-control form-control-md active" defaultValue={workbookInfo && workbookInfo.serial_no ? workbookInfo.serial_no : serialNumber} placeholder="Workbook Serial No." name="serial_no" id="serial_no" required/>
                                    <div className="invalid-feedback">
                                        The Workbook serial number field is required .
                                    </div>
                                    <label>Serial No.<span className="text-danger">*</span></label>
                                </div>

                                <h5 className="fw-bold fs-18 mt-4">Time Limit</h5>
                                <p className="mb-4">
                                    Set a time limit for the completion of this textbook in exam mode.
                                </p>

                                <div className="form-floating maxw-220">
                                    <select className="form-select form-select-md" name="time_limit" defaultValue={workbookInfo && workbookInfo && workbookInfo.time_limit} required>
                                        <option value="30">30 minutes</option>
                                        <option value="40">40 minutes</option>
                                        <option value="45">45 minutes</option>
                                        <option value="50">50 minutes</option>
                                    </select>
                                    <label>Time Limit<span className="text-danger">*</span></label>
                                </div>

                                <h5 className="fw-bold fs-18 mt-4">Expired In</h5>
                                <p className="mb-4">
                                    This workbook is valid on default 2 years after assign/purchase any group or student.
                                </p>

                                <div className="form-floating maxw-220">
                                    <select className="form-select form-select-md" name="expired_on" defaultValue={workbookInfo && workbookInfo && workbookInfo.expired_on} required>
                                        <option value="2">2 years</option>
                                        <option value="3">3 years</option>
                                        <option value="4">4 years</option>
                                        <option value="5">5 years</option>
                                    </select>
                                    <label>Expired In<span className="text-danger">*</span></label>
                                </div>
                                <h5 className="fw-bold fs-18 mt-4">Learning outcomes</h5>
                                <p className="mb-3">Add key learning outcomes for this workbook.</p>
                                <div className="form-floating maxw-800">
                                    <CKEditor
                                        initData={ReactHtmlParser(learningOutcomes)}
                                        editorUrl = '../ckeditor/ckeditor.js'
                                        onChange={(e) => setLearningOutcomes(e.editor.getData())}
                                    />
                                </div>
                            </form>
                        </div>

                        { selectedQuestionType && <ManageQuestion selectedQuestionType={selectedQuestionType} workbookInfo={workbookInfo} questionTypeList={questionTypeList} reloadQuestions={reloadQuestions} setSelectedQuestionType={setSelectedQuestionType}/> }
                    </div>
                </div>
            </div>

            { workbookInfo && <QuestionTypeModel setSelectedQuestionType={setSelectedQuestionType} questionTypeList={questionTypeList}/> }
        </div>
    )
}

export default CreateWorkbook