import React from "react";
import { ASSET_ENDPOINT } from "../components/Service";
import { noImageHandle } from "../components/CommonMethod"
const QuestionSolution = (props) => {
    return (<>
        <div className="modal fade" id="solutionModal" tabIndex="-1" data-bs-backdrop="static">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border border-2 border-blue p-2 rounded-4">
                    <div className="modal-header border-0 ">
                    <h3 className="fw-bold text-blue">Solution</h3>
                    {/* <span className="d-inline-block bg-blue text-white fw-bold text-uppercase rounded-pill px-3 ms-2 py-1">HSC</span> */}
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="col-sm-12">
                            <img src={props && props.image  ? ASSET_ENDPOINT+props.image : '../images/chemistry-formulae.png'} className="img-fluid" style={{objectfit:'cover'}} onError={noImageHandle} alt={props.heading && props.heading} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default QuestionSolution;