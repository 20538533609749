import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchData, GET_WEBSITE_CONTENT, UPSERT_HOME_CONTENT, SETTING_PAGE, DROPDOWN_DATA } from "../../components/Service";
import { validateForm, ButtonSpinner, removeMsg } from "../../components/CommonMethod";  
import ImageDropdown from "../../components/ImageDropdown";
import Question from "../Workbook/partials/Question";
import { CKEditor } from 'ckeditor4-react';

const HomePage = () => {
    window.document.title = "Edit Home Page";
    const [tinyloader, setTinyLoader] = useState('')
    const [data, setData] = useState('')
    const [imageData, setImageData] = useState([]);
    const [questionTypeList, setQuestionTypeList] = useState([]);
    const [questionData, setQuestionData] = useState([]);

    const saveHomeContent = (e) => {
        let formdata = new FormData(document.getElementById('addHomeContent'))
        
        if (validateForm(e, "addHomeContent")) {
            setTinyLoader(true);
            fetchData(UPSERT_HOME_CONTENT, 'POST', formdata, true, true, (res) => {
                setTinyLoader(false);
                if (res.success) {
                    removeMsg()
                }
            });
        };
    }

    // const addMissingWord = (e) => {
    //     e.preventDefault();
    //     const word = document.getElementById('option_word')
    //     if(word.value === ''){
    //         document.getElementById('word_error').style.display = "block"
    //         return false;
    //     }
    //     document.getElementById('word_error').style.display = "none"
    //     const wordDiv = document.getElementById('word_div')
    //     wordDiv.style.display = "block" 
    //     wordDiv.insertAdjacentHTML('afterbegin',`<div class="d-flex justify-content-between align-items-center fw-bold py-1 my-1 bg-light rounded"><input type="hidden" name="option[]" value="${word.value}"><span class="form-control fw-bold border-0 ms-1">${word.value}</span><button type="button" class="btn btn-sm mx-1 remove" title="Delete"><svg class="icon ts-1p4"><use href="#icon_delete"></use></svg></button></div>`);
    //     word.value = '';
    //     document.querySelectorAll(".remove").forEach(function (el) {
    //         el.addEventListener('click', (e) => {
    //             el.parentElement.remove();

    //             if(wordDiv.childElementCount === 0){
    //                 wordDiv.style.display = "none";
    //             }
    //         })
    //     });
    // }

    // const removeWord = (id) => {
    //     const wordDiv = document.getElementById('missing_word_'+id)
    //     wordDiv.remove()
    // } 

    useEffect(() => {
        fetchData(GET_WEBSITE_CONTENT +'/home-content', 'GET', '', true, false, (res) => {
            if (res.records) {
                res.records && setData(JSON.parse(res.records.value));
                let question = JSON.parse(res.records.value).question;
                setTimeout(() => {
                    if(question){
                        setQuestionData(JSON.parse(res.records.value))
                    }else{
                        setQuestionData({question: []})
                    }
                }, 200);
            }
        });

        fetchData(SETTING_PAGE, 'GET', '', true, false, (res) => {
            if(res.records){
                setImageData(JSON.parse(res.records.value));
            }
        });

        fetchData(DROPDOWN_DATA + '?type=question_type', 'GET', '', true, false, (res) => {
            if (res.records) {
                setQuestionTypeList(res.records.question_type)
            }
        })
    }, [])

    return(
        <>
            <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row">
                    <div className="col-sm-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                                <li className="breadcrumb-item"><Link to="/cms/website-content">Website Content</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Home page</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row gx-4 mb-1 align-items-lg-end align-items-md-end  align-items-center">
                    <div className="col-lg-6 col-sm  col-6 mb-3">
                        <h1 className="h3 text-dim mb-0">Edit ‘Home Page’ content</h1>
                    </div>
                    <div className="col-lg-6 col-auto mb-3 col-6">
                        <button type="button" className="btn btn-theme-primary btn-lg float-end" onClick={saveHomeContent} disabled={tinyloader}>
                            <ButtonSpinner load={tinyloader} btnName="Save" />
                        </button>
                    </div>
                </div>
              </div>
                <div className="row">
                    <form className="needs-validation" noValidate  id="addHomeContent">
                        <div className="col-sm-12">
                            <div className="maxw-560 mb-5">
                                <h4 className="fw-bold mb-5">Title caption</h4>
                                <div className="form-floating mt-30 mb-5">
                                    <input type="text" name="title" id="title" className="form-control form-control-md" defaultValue={data && data.title ? data.title : ''} required placeholder="Title" />
                                    <div className="invalid-feedback">Please fill title.</div>
                                    <label>Title <strong className="text-danger">*</strong></label>
                                </div>
                                <div className="form-floating mt-30">
                                    <input type="text" name="sub_title" id="sub_title" className="form-control form-control-md" defaultValue={data && data.sub_title ? data.sub_title : ''} required placeholder="Subtitle" />
                                    <div className="invalid-feedback">Please fill subtitle.</div>
                                    <label>Subtitle <strong className="text-danger">*</strong></label>
                                </div>
                            </div>
                            <div className="mb-5">
                                <h4 className="fw-bold">Interactive demo question</h4>
                                <div className="card border-secondary rounded-3">
                                    <div className="card-body p-sm-4">
                                        <div className="d-none"><CKEditor initData={''} editorUrl = '../ckeditor/ckeditor.js' /></div>
                                        <input type="hidden" name="solution_image" value={data && data.question && data.question.solution_image ? data.question.solution_image : ''} />
                                        {questionData && questionData.question && <Question from_home_page="home_page" title="Drag and drop to fill in the blanks." questionType='drag_and_fill' questionElementId='dummy_question' questionTypeList={questionTypeList} questionData={questionData.question} />}
                                    </div>

                                    {/* <div className="card-body p-sm-4">
                                        <div className="maxw-480 mb-4">
                                            <div className="d-flex justify-content-between align-items-center mb-1">
                                                <span className="fs-14 fw-bold text-uppercase">Question Type</span>
                                            </div>
                                            <div className="card border-dark rounded-3 p-4">
                                                <h4 className="fs-18 fw-bold">Drag and drop to fill in the blanks.</h4>
                                                <div className="row align-items-center mt-1">
                                                    <div className="col-7">
                                                        <p className="fs-14 m-0">
                                                            Click and drag a potential answer to a dropzone. To remove an answer you've dropped into a dropzone, click on it again.
                                                        </p>
                                                    </div>
                                                    <div className="col-5 text-end">
                                                        <img src="/images/ex-dragdrop.png" className="img-fluid" alt="Example" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <span className="fs-14 fw-bold text-uppercase">Question</span>
                                            <p className="fs-14 mb-4">Enter a question and a phrase.</p>
                                            <div className="form-floating">
                                                <input type="text" className="form-control form-control-md" name="question" id="question" required placeholder="Question" defaultValue={data && data.questions ? data.questions.question : ''} />
                                                <div className="invalid-feedback">The question field is required.</div>
                                                <label>Question</label>
                                            </div>
                                        </div>
                                        <div className="d-block">
                                            <span className="fs-14 fw-bold text-uppercase">Answer</span>
                                            <p className="fs-14 mb-4">From the dropdown, choose a number of words from the phrase to act as `missing words`. Explain the correct answer through reasoning.</p>
                                            <div className="form-floating">
                                                <textarea type="text" className="form-control form-control-md h-90" name="phrase" id="phrase" required placeholder="Phrase" defaultValue={data && data.questions ? data.questions.phrase : ''}></textarea>
                                                <div className="invalid-feedback">The phrase field is required.</div>
                                                <label>Phrase</label>
                                            </div>
                                            <div className="my-3 py-2 maxw-480">
                                                <div className="d-flex align-items-baseline mb-3">
                                                    <div className="form-floating w-100">
                                                        <input type="text" className="form-control form-control-md" id="option_word" placeholder="Question" />
                                                        <div className="invalid-feedback" style={{display:"none"}} id="word_error">The missing word field is required.</div>
                                                        <label>Missing word</label>
                                                    </div>
                                                    <div>
                                                        <button type="button" className="btn btn-outline-default minw-90 ms-3" onClick={(e) => addMissingWord(e)}>Add</button>
                                                    </div>
                                                </div>
                                                <div className="card border-dark rounded-3 px-3 py-2" id="word_div" style={{display:`${data && data.questions && data.questions.option && data.questions.option !== '' ? 'block' : 'none'}`}}>
                                                    {data && data.questions && data.questions.option && data.questions.option.map((item,key) => {
                                                        return(
                                                            <div key={key} id={`missing_word_${key}`}>
                                                                <div className="d-flex justify-content-between align-items-center fw-bold py-1 my-1 bg-light rounded">
                                                                    <input type="hidden" name="option[]" defaultValue={item} />
                                                                    <span className="form-control fw-bold border-0 ms-1">{item}</span>
                                                                    <button type="button" className="btn btn-sm mx-1 remove" title="Delete" onClick={() => removeWord(key)}>
                                                                        <svg className="icon ts-1p4"><use href="#icon_delete"></use></svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className="form-floating">
                                                <textarea type="text" className="form-control form-control-md h-90" name="reasoning" id="reasoning" placeholder="Reasoning" defaultValue={data && data.questions ? data.questions.reasoning : ''}></textarea>
                                                <label>Reasoning </label>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="maxw-560 mb-5 pt-3">
                                <h4 className="fw-bold mb-3">Testimonials</h4>
                                { data.testimonials && data.testimonials.length > 0 && data.testimonials.map((ele, num) => {
                                    return(
                                        <div className="row mb-4 pb-2" key={num}>
                                            <div className="col-md-12">
                                                <p className="fs-18 fw-bold mb-2">Testimonial {num + 1} <strong className="text-danger">*</strong></p>
                                                <textarea className="form-control form-control-md  mb-4" required name={`testimonials[${num}][student_message]`} defaultValue={ele.student_message ?? ''} placeholder={`Testimonial ${num + 1}`} rows="4"></textarea>
                                                <div className="invalid-feedback">Please fill testimonial message.</div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating mt-32">
                                                    <input type="text" required className="form-control form-control-md" name={`testimonials[${num}][student_name]`} defaultValue={ele.student_name ?? ''} placeholder="Testimonial giver first name" />
                                                    <div className="invalid-feedback">Please fill testimonial giver name.</div>
                                                    <label>Testimonial giver first name <strong className="text-danger">*</strong></label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="fs-12 text-dim fw-bold mt-14 ps-3">Choose Image</div>
                                                <ImageDropdown value={ele.student_image} imageList={imageData} name={`testimonials[${num}][student_image]`} id={`testimonials.${num}.student_image`} />
                                            </div>
                                        </div>
                                    )
                                }) }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default HomePage
