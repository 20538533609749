import { noImageHandle } from "../../../components/CommonMethod";

const QuestionCard = (props) => {
    return (
        <div className={`card rounded-3 p-4 ${props.border ?? ''}`}>
            <h4 className="fs-18 fw-bold">{props.cardDetails.title ?? 'Workbook title'}</h4>
            <div className="row mt-2">
                <div className="col-7">
                    <p className="fs-14 m-0">
                        {props.cardDetails.description ?? ''}
                    </p>
                </div>
                <div className="col-5 text-end">
                    <img src={`../images/${props.cardDetails.image ?? 'ex-dragdrop.png' }`} className="img-fluid" alt="Example" onError={noImageHandle} />
                </div>
            </div>
        </div>
    )
}

export default QuestionCard