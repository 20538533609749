import React, { useState, useEffect } from "react";
import { Popover, Tooltip } from "bootstrap";
import QuestionSolution from "../../../../components/QuestionSolution";
import MathElement from "../../../../components/MathElements";

const MultipleOption = (props) => {
    
    const [question] = useState(props.question ?? '')
    const [option] = useState(props.question && props.question.option ? props.question.option : '')
    const [checkAnswerClass, setCheckAnswerClass] = useState(false)
    const [serialNo] = useState(props.serialNo ?? 0)
    const [defaultChecked] = useState(props.answer_details && props.answer_details.answer ? props.answer_details.answer : false)
    const [progressData] = useState(props.progressData ?? {})
    const [progressText, setProgressText] = useState(props.progressText ?? '')
    const [optionAtRow, setOptionAtRow] = useState('select-option w-auto col-12 mb-4')

    const saveAnswer = (check=false) => {
        let is_correct = false
        let answer = []

        document.querySelectorAll(`#${question.question_type}${question.order} .option[type=radio]:checked`).forEach((e) => {
            answer = [...answer, {'answer': e.dataset.value}]
            is_correct = e.value === 'true' ? true : false
            if(check){
                if(is_correct){
                    e.nextSibling.classList.add('right-answer') 
                    e.nextSibling.insertAdjacentHTML('afterend', `<span class="answer-response"><img src="/images/checkbox.png" alt="Right" /></span>`)
                }else{
                    e.nextSibling.classList.add('wrong-answer') 
                    e.nextSibling.insertAdjacentHTML('afterend', `<span class="answer-response"><img src="/images/crossbox.png" alt="Wrong" /></span>`)
                }
                if(e.dataset.reasoning){
                    e.parentElement.parentElement.insertAdjacentHTML('afterend', `<button type="button" class="btn ms-0 p-0 border-white text-blue text-decoration-underline ms-2 fw-bold" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="focus hover" data-bs-template='<div class="popover shadow maxw-900 border-blue border border-2" role="popover"><div class="popover-arrow  border-blue "></div><div class="popover-inner"><div class="row "><div class="col-sm-4"><img src="/images/why-male.png"  alt="Male"/></div><div class="col-sm-8"><p class="mb-0 pt-2 px-3 fw-medium text-blue"></p><p class="mb-0 px-3 text-blue fw-medium">${e.dataset.reasoning}</p></div></div></div></div>'>Why?</button>`);
                }
            }
        })

        document.querySelectorAll(`#${question.question_type}${question.order} .option[type=radio]`).forEach((e) => {
            if(check){
                if(!e.checked){
                    if(e.value === 'true'){
                        e.nextSibling.insertAdjacentHTML('afterend', `<span class="answer-response"><img src="/images/checkbox.png" alt="Right" /></span>`)
                    }else{
                        e.nextSibling.insertAdjacentHTML('afterend', `<span class="answer-response"><img class="filer-grayscale" src="/images/crossbox.png" alt="Wrong" /></span>`)
                    } 
                }
            }
            e.nextSibling.classList.add('event-none')
        })
          
        if(props.mode !== 'review'){
            let index = progressData.value === is_correct ? progressData.index + 1 : 0
            let progress_text = is_correct ? (progressData.correctText[index] ?? progressData.correctText[0]) : (progressData.incorrectText[index] ?? progressData.incorrectText[0])
            let question_time = localStorage.getItem('question_counter');
            let submitAnswerInfo = {question_id: question.id, question: question.question, correct: is_correct, order: question.order, question_type: question.question_type, progress_text: progress_text, time: question_time}
            setProgressText(progress_text)
            props.setPrevQuestionDetails((prev) => ([...prev, submitAnswerInfo]))
            props.setPrevAnswerDetails((prev) => ([...prev, {answerArr: answer}]))
            props.saveAnswer(submitAnswerInfo, {answerArr: answer})
            // props.setProgress(question.order)
            Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
            Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
        }
        return is_correct;
    }

    const checkAnswer = () => {
        let is_correct = saveAnswer(true)
        setCheckAnswerClass(is_correct ? 'right' : 'wrong')
    }

    const submitAnswer = () => {
        if(props.mode && props.mode === 'exam'){
            saveAnswer()
        }
        props.setActiveQuestion((prev) => prev+1)
    }

    const selectOption = () => {
        let check_options = document.querySelectorAll(`#${question.question_type}${question.order} .option[type=radio]:checked`).length
        if(check_options > 0){
            let check_btn = document.getElementById(props.mode === 'exam' ? `${question.order}_submit_answer` :  `${question.order}_check_answer`)
            if(check_btn) check_btn.classList.remove('disabled')
        }
    }

    const reviewAnswer = () => {
        let is_correct = saveAnswer(true)
        setCheckAnswerClass(is_correct ? 'right' : 'wrong')
    }


    useEffect(() => {
        if(props.mode === 'review'){
            reviewAnswer()
        }
        if(props.question && props.question.one_row_option){
            if(props.question.one_row_option === '1') setOptionAtRow('select-option col-sm-12 mb-4')
            else if(props.question.one_row_option === '2') setOptionAtRow('select-option col-md-6 col-sm-12 mb-4')
            else if(props.question.one_row_option === '4') setOptionAtRow('select-option col-lg-3 col-md-6 col-sm-12 mb-4')
            else setOptionAtRow('select-option w-auto col-12 mb-4')
        }
        Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
        Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
        // eslint-disable-next-line
    },[])


    return (
        <>
            <div className={`fs-18 question border border-3 p-3 rounded-4 mb-4 ${checkAnswerClass === 'right' ? 'border-green' : checkAnswerClass ? 'border-danger' : 'border-blue'}`}>
                <h6 className={`fs-18 fw-bold pb-2 pt-2 ${checkAnswerClass === 'right' ? 'text-green' : checkAnswerClass ? 'text-danger' : 'text-blue'}`}>
                    Question {serialNo}
                    <span>
                        <button type="button" className="btn ms-2 p-0 border-0" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="focus hover" data-bs-template="<div class=&quot;popover shadow&quot; role=&quot;popover&quot;><div class=&quot;popover-arrow&quot;></div><div class=&quot;popover-inner pt-3 px-3&quot;>Click to select one of the carded options as the correct response. You may only select one response.<div class=&quot;row px-3 my-3 &quot;><div class=&quot;col-sm-12 &quot;><div class='d-flex gap-2'><div><img src='/images/ex-select.png'class='img-fluid'></img></div></div></div></div>"><svg className="icon ts-1p5"><use href="#icon_query"></use></svg></button>
                    </span>
                </h6>

                <span className="raw-question"><MathElement ele_id={`question_${question.order}_div`} elements={question.question}/></span>

                <div className="col-12 mt-4">
                    <div className="row mb-4" id={`${question.question_type}${question.order}`}>
                        {option.length > 0 && option.map((item, index) => {
                            return (
                                <div key={index} className={optionAtRow}>
                                    <input type="radio" className="btn-check click-statement2 option" id={`option_${question.order}_${index}`} name='option' data-value={item.option} data-reasoning={item.reasoning} value={item.is_correct} defaultChecked={defaultChecked === item.option ? true : false} onClick={selectOption} />
                                    <label htmlFor={`option_${question.order}_${index}`} className="d-flex align-items-center shadow-md w-100 h-100"><MathElement ele_id={`question_${question.order}_option_${index}`} elements={item.option}/></label>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="button d-flex justify-content-between mt-4">
                    {checkAnswerClass && question && question.solution_image && <button className="btn btn-outline-default btn-lg minw-90 m-2 ms-0" data-bs-target="#solutionModal" data-bs-toggle="modal">Solution</button>}
                    {checkAnswerClass && <span className={`fw-bold mt-3 fs-5 me-2 ${checkAnswerClass === 'right' ? 'text-green' : 'text-danger'}`}>{progressText}</span>}
                    {props.mode && props.mode === 'practice' && !checkAnswerClass && <button type="button" id={`${question.order}_check_answer`} className="btn btn-lg ms-auto btn-theme-primary m-2 me-0 disabled" onClick={checkAnswer}>Check</button>}
                    {(checkAnswerClass || props.mode === 'exam') &&  <button id={`${question.order}_submit_answer`} className={`btn btn-lg m-2 ms-auto me-0 ${props.mode === 'exam' && 'btn-theme-primary disabled'} ${checkAnswerClass === 'right' ? 'btn-green' : checkAnswerClass ? 'btn-danger' : 'border-blue'}`} onClick={() => submitAnswer()}>Next</button>}
                </div>
            </div>
            {question.solution_image && <QuestionSolution image={question.solution_image} />}
        </>
    )
}
export default MultipleOption;
