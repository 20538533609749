import { Link } from "react-router-dom"
import { ButtonSpinner, noImageHandle, __echoText } from "../../components/CommonMethod"
import { format } from 'date-fns';
import _ from 'lodash'
import { ASSET_ENDPOINT } from '../../components/Service';
import { DataTable } from "../../components/DataTable";

export const ListingHeader = (props) => {

    return (
        <>
            <input type='hidden' name='listing_type' id="listing_type" defaultValue={props.listType} />
            <input type='hidden' name='id' id="id" defaultValue={props.listInfo && props.listInfo.id} />
            <div className="page-head sticky_top_padding">
            <div className="row">
                <div className="col-sm-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                            <li className="breadcrumb-item"><Link to="/cms/listings">Manage Listings</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{props.listTitle}</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row gx-4 mb-1 d-flex align-items-center  position-relative">
                <div className="col-lg-4 col-md-4 col-sm-2 mb-3 col-3">
                    <h1 className="h3 text-dim mb-0">{props.listTitle}</h1>
                </div>
                <div className="col-lg-8  col-md-8 col-sm-10 mb-3 col-9">
                    <div className="button_font float-end">
                    <div className="d-inline-flex align-items-center me-3">
                        <strong>Status</strong>
                        <select className="form-select form-select-md ms-3 minh-48" name="status" id="status" defaultValue={props.listInfo && props.listInfo.status} required>
                            <option value='unpublished'>Unpublished</option>
                            <option value='published'>Published</option>
                        </select>
                    </div>
                    <button type="button" className="btn btn-theme-primary btn-lg mt-check-small" onClick={(e) => props.createListing(e)}>
                        <ButtonSpinner load={props.tinyloader} btnName="Save and Exit" />
                    </button>
                    </div>
                </div>
            </div>
            </div>
        </>
    )

}


export const ListingType = (props) => {

    return (
        <>
            <div className="col-md-12">
                <h4 className="fw-bold">Listing type</h4>
                <p className="mb-1">You can sell workbooks as standalone products, or package multiple workbooks.</p>
            </div>
            <div className="col-md-6 col-xl-4 col-xxl-3 mt-2 pb-2 grid-desk" role="presentation" onClick={() => props.setListType('single')}>
                <div className="card border-dark card-tab" data-bs-toggle="collapse" data-bs-target="#standaloneBox" role="tab" aria-expanded={props.listType === 'single' ? true : false}>
                    <div className="card-body d-flex p-sm-4">
                        <img src="/images/icon-plus.png" alt="plus" className="img-plus-cms mmwhcms-100"/>
                        <div className="ps-3">
                            <h5 className="fw-bold text-uppercase mb-0">Standalone</h5>
                            <p>Create a new workbook listing</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-xl-4 col-xxl-3 mt-2 pb-2 grid-desk" role="presentation" onClick={() => props.setListType('package')}>
                <div className="card border-dark h-100 card-tab" data-bs-toggle="collapse" data-bs-target="#packageBox" role="tab" aria-expanded={props.listType === 'package' ? true : false}>
                    <div className="card-body d-flex p-sm-4">
                        <img src="/images/icon-plus-more.png" alt="plus" className="img-plus-cms mmwhcms-100"/>
                        <div className="ps-3">
                            <h5 className="fw-bold text-uppercase mb-0">Package</h5>
                            <p className="mb-0">Collect multiple existing listings to form a new package.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}


export const Workbook = (props) => {

    return (
        <>
            <div className={`collapse no-transition ${props.listType === 'single' && 'show'}`} id="standaloneBox" data-bs-parent="#accordionTabs">
                <h4 className="fw-bold pt-3 mt-4">Workbook<span className="text-danger">*</span></h4>
                <p className="mb-1">Choose a workbook from your created workbooks</p>
                <div className="table-responsive">
                    <table className="table align-middle table-common">
                        <thead>
                            <tr>
                                <th><span>Workbook Title</span></th>
                                <th><span>Questions</span></th>
                                <th><span>Time Limit</span></th>
                                <th><span>Date Created</span></th>
                                <th><span>Last Edited</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.listType === 'single' && (props.workbook && props.workbook.length > 0 ? props.workbook.map((item, i) => {
                                return <tr key={i}>
                                    <td><input type='hidden' name='workbook[]' defaultValue={item.id} />{item.title}</td>
                                    <td>{item.total_questions}</td>
                                    <td>{item.time_limit}</td>
                                    <td>{format(new Date(item.created_at), 'MM/dd/yyyy')}</td>
                                    <td>{format(new Date(item.updated_at), 'MM/dd/yyyy')}</td>
                                </tr>
                            }) : <tr><td colSpan="5" className="text-center text-danger"><input type='text' className="d-none" name='workbook' required />No workbook selected</td></tr>)
                            }
                        </tbody>
                    </table>
                </div>
                <button type="button" className="btn btn-theme-primary btn-lg mth-5t" data-bs-toggle="modal" data-bs-target="#add_workbook_singular_modal">{props.workbook ? 'Change' : 'Add'} workbook</button>
            </div>
        </>
    )

}


export const Listing = (props) => {

    return (
        <>
            <div className={`collapse no-transition ${props.listType === 'package' && 'show'}`} id="packageBox" data-bs-parent="#accordionTabs">
                <h4 className="fw-bold pt-3 mt-4">Listings<span className="text-danger">*</span></h4>
                <p className="mb-1">Choose existing workbook listings to add to this package.</p>
                <div className="table-responsive">
                    <table className="table align-middle table-common">
                        <colgroup>
                            <col width="74" />
                            <col width="74" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th><span>Order</span></th>
                                <th></th>
                                <th><span>Listing Title</span></th>
                                <th><span>Course Filters</span></th>
                                <th><span>Price</span></th>
                                <th><span>Type</span></th>
                                <th><span>Published on</span></th>
                                {/* <th width="120"></th> */}
                            </tr>
                        </thead>
                        <tbody id="packageSORT">
                            {props.listType === 'package' && (props.listing && props.listing.length > 0 ? props.listing.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td><input type='hidden' name='workbook[]' defaultValue={item.id} />{item.id}</td>
                                        <td> <div className="ratio ratio-1x1 mmw-56 rounded-3 shadow-md overflow-hidden" >
                                            <img src={ASSET_ENDPOINT + item.image} alt="topic" onError={noImageHandle} />
                                        </div></td>
                                        <td>{item.title}</td>
                                        <td>{`${item.course_abbr} > ${item.topic_name} > ${item.section_name}`}</td>
                                        <td> ${item.price}</td>
                                        <td>{_.startCase(item.type)}</td>
                                        <td>{item.publish_date ? format(new Date(item.publish_date), 'MM / dd / yyyy') : ''}</td>
                                        {/* <td>
                                            <button className="btn ms-2 handler" title="Set Ordering">
                                                <svg className="icon ts-1p5 opacity-50"><use href="#icon_drag"></use></svg>
                                            </button>
                                        </td> */}
                                    </tr>
                                )
                            }) : <tr><td colSpan="8" className="text-center text-danger"><input type='text' className="d-none" name='workbook' required />No listing selected</td></tr>)
                            }
                        </tbody>
                    </table>
                </div>
                <button type="button" className="btn btn-theme-primary btn-lg" data-bs-toggle="modal" data-bs-target="#add_workbook_listing_modal">Add workbook listing</button>

            </div>
        </>
    )

}


export const SelectWorkbook = (props) => {

    return (
        <>
            <div className="modal fade" id="add_workbook_singular_modal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content overflow-hidden border border-2 border-blue rounded-4">
                        <div className="modal-header bg-grey p-4">
                            <h3 className="modal-title fw-bold">Select workbook for singular listing</h3>
                            <div className="d-flex align-items-center">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <div className="modal-body p-4">
                            <DataTable
                                execute={true}
                                dt={props.workbookList}
                                edit_column={{
                                    created_at: (records) =>
                                        __echoText(`${format(new Date(records.created_at), 'MM/dd/yyyy')}`),
                                    updated_at: (records) =>
                                        __echoText(`${format(new Date(records.updated_at), 'MM/dd/yyyy')}`),

                                    action: (records) =>
                                        <button type="button" className={`btn btn-lg btn-outline-default minw-160 btn_workbook ${props.workbook && (props.workbook[0].id === records.id) && 'active'}`} onClick={() => props.setWorkbook([records])}>{props.workbook && (props.workbook[0].id === records.id) ? 'Selected' : 'Select'}</button>
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}


export const SelectListing = (props) => {

    return (
        <>
            <div className="modal fade" id="add_workbook_listing_modal" tabIndex="-1">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content overflow-hidden border border-2 border-blue rounded-4">
                        <div className="modal-header bg-grey p-4">
                            <h3 className="modal-title fw-bold">Select workbook listings for package listing</h3>
                            <div className="d-flex align-items-center">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                        <div className="modal-body p-4">
                            <DataTable
                                execute={props.refresh}
                                dt={props.listings}
                                edit_column={{
                                    id: (records) =>
                                        <input className="form-check-input mmwh-20 m-0 list_checkbox" type="checkbox" defaultValue={records.id} name="lists[]" onChange={(e) => props.selectList(e)} checked={props.listData.includes(records.id.toString())} />,

                                    image: (records) =>
                                        <div className="ratio ratio-1x1 mmw-56 rounded-3 shadow-md overflow-hidden" >
                                            <img src={ASSET_ENDPOINT + records.image} alt={records.title} onError={noImageHandle} />
                                        </div>,

                                    title: (records) =>
                                        __echoText(`${records.title}`),

                                    status: (records) =>
                                        __echoText(`${_.startCase(records.status)}`),

                                    course_abbr: (records) => {
                                        return records.additional_data.course && records.additional_data.course.length > 0 &&
                                            records.additional_data.course.map((item, index) => {
                                                return (
                                                    <div key={index} className="minw-75 text-white fw-bold text-uppercase d-inline-block rounded-pill px-3 py-1 m-2 text-center" style={{ background: `hsl(${item.accent_color}, 100%, 50%)` }}>
                                                        {__echoText(`${item.course_abbr}`)}
                                                    </div>
                                                )
                                            })
                                    },

                                    topic_name: (records) => {
                                        return records.additional_data.course_filter && records.additional_data.course_filter.length > 0 &&
                                            records.additional_data.course_filter.map((item, index) => <div key={index} className="mb-2" dangerouslySetInnerHTML={{ __html: item }}></div>)
                                    }
                                }}
                            />
                        </div>
                        <div className="modal-footer justify-content-between px-4">
                            <div className="alert alert-info fw-bold w-100">
                                <svg className="icon ts-1p6 me-3"><use href="#icon_info"></use></svg>
                                Showing both unpublished and published listings
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export const SingleListingDetails = (props) => {

    return (
        <>
            <h4 className="fw-bold pt-3 mt-5">Question types</h4>
            <p className="mb-4">Select what question type this workbook should be categorised as</p>
            <div className="form-floating w-100 mt-24">
                <select className="form-select form-select-md" name="listing_question_type" defaultValue={props.listInfo && props.listInfo.question_type} required>
                    <option value="0">Key concepts</option>
                    <option value="1">Numerical problems</option>
                </select>
                <div className="invalid-feedback">
                    Question type field is required.
                </div>
                <label>Question type<span className="text-danger">*</span></label>
            </div>

            <div className="col-sm-12 mt-32" id="learning_outcomes_div">
                <h4 className="fw-bold pt-3 mt-5">Learning outcomes</h4>
                <p className="mb-1">Add key learning outcomes for this workbook<span className="text-danger">*</span></p>
                <div className="form-check-label fw-bold">Show learning outcomes</div>
                <div className="d-flex gap-4">
                    <label className="form-check form-check-custom  d-flex gap-1">
                        <input className="form-check-input" type="radio" name="learning_outcomes_check" value="1" checked={(props.outcome === 1 || props.outcome === '1')} onChange={(e) => props.setOutcomeStatus(e.target.value)} />
                        <div className="form-check-label fw-bold">Yes</div>
                    </label>
                    <label className="form-check form-check-custom d-flex gap-1">
                        <input className="form-check-input" type="radio" name="learning_outcomes_check" value="0" checked={(props.outcome === 0 || props.outcome === '0')} onChange={(e) => props.setOutcomeStatus(e.target.value)} />
                        <div className="form-check-label fw-bold">No</div>
                    </label>
                </div>

                <textarea className="form-control mt-3 border border-dark rounded-3 p-3" rows='6' name='learning_outcomes' id='learning_outcomes' placeholder='Learning outcomes' defaultValue={props.listInfo && props.listInfo.learning_outcomes} />
                <div className="invalid-feedback">
                    Outcome field is required.
                </div>
            </div>
        </>
    )

}


export const SingleListingCourseDetails = (props) => {

    return (
        <>
            <h4 className="fw-bold pt-3">Course</h4>
            <p className="mb-1">Choose a course and sub-levels that this workbook falls under.</p>
            <div className="row mt-24">
                <div className="col-12">
                    <div className="form-floating w-100">
                        <select className="form-select form-select-md" name="course_id" id="course_id" required onChange={(e) => props.getCourseTopics(e.target.value)}>
                            <option value="">Select Course Type</option>
                            {props.course && props.course.length > 0 && props.course.map((item, i) => {
                                return <option value={item.id} selected={props.listInfo && props.listInfo.course_id === item.id ? true : false} key={i}>{item.course_term}</option>
                            })}
                        </select>
                        <div className="invalid-feedback">
                            Course field is required.
                        </div>
                        <label>Course<span className="text-danger">*</span></label>
                    </div>
                </div>
                <div className="col-sm-6 mt-32">
                    <div className="form-floating w-100">
                        <select className="form-select form-select-md" name="topic_id" id="topic_id" required onChange={(e) => props.getTopicSections(e.target.value)}>
                            <option value="">Select Topic</option>
                            {props.topic && props.topic.length > 0 && props.topic.map((item, i) => {
                                return <option value={item.id} selected={props.listInfo && props.listInfo.course_topic_id === item.id ? true : false} key={i}>{item.topic_name}</option>
                            })}
                        </select>
                        <div className="invalid-feedback">
                            Topic field is required.
                        </div>
                        <label>Topic<span className="text-danger">*</span></label>
                    </div>
                </div>
                <div className="col-sm-6 mt-32">
                    <div className="form-floating w-100">
                        <select className="form-select form-select-md" name="section_id" id="section_id" required>
                            <option value="">Select Section</option>
                            {props.section && props.section.length > 0 && props.section.map((item, i) => {
                                return <option value={item.id} selected={props.listInfo && props.listInfo.course_section_id === item.id ? true : false} key={i}>{item.section_name}</option>
                            })}
                        </select>
                        <div className="invalid-feedback">
                            Section field is required.
                        </div>
                        <label>Section<span className="text-danger">*</span></label>
                    </div>
                </div>
            </div>
        </>
    )

}


export const ListingDetails = (props) => {
    const checkTaxNameAndTaxRate = (e, input, span) => {
        if (e.target.value) {
            document.getElementById(span).style.visibility = 'visible'
            document.getElementById(input).required = true;
        } else {
            document.getElementById(span).style.visibility = 'hidden'
            document.getElementById(input).required = false;
            document.getElementById(input).value = '';
        }
    }
    return (
        <>
            <h4 className="fw-bold pt-3 mt-5">Title and description</h4>
            <p className="mb-1">Write a title and description for this workbook.</p>
            <div className="row mt-24">
                <div className="col-12 col-md-6">
                    <div className="form-floating w-100">
                        <input className="form-control form-control-md" placeholder="Title" name="listing_title" required defaultValue={props.listInfo && props.listInfo.title} />
                        <div className="invalid-feedback">
                            Title field is required.
                        </div>
                        <label>Title<span className="text-danger">*</span></label>
                    </div>
                </div>
                <div className="col-sm-12 mt-32">
                    <div className="form-floating">
                        <textarea type="text" className="form-control form-control-md h-140" placeholder="Description" name="listing_description" defaultValue={props.listInfo && props.listInfo.description} required />
                        <div className="invalid-feedback">
                            Description field is required.
                        </div>
                        <label>Description<span className="text-danger">*</span></label>
                    </div>
                </div>
            </div>

            <h4 className="fw-bold pt-3 mt-5">Upload cover image</h4>
            <p className="mb-1">Upload a 1080 x 1080px cover image for this workbook.</p>
            <label htmlFor="coverImage" className="ratio ratio-1x1 maxw-120 rounded-2 overflow-hidden shadow-md my-3 d-block">
                <img src={props.imageb64 ? props.imageb64 : props.listInfo && props.listInfo.image ? ASSET_ENDPOINT + props.listInfo.image : ''} className="img-fluid" alt="Cover" onError={noImageHandle} />
            </label>

            <div className="overflow-hidden position-relative">
                <input type="file" id="coverImage" name="coverImage" accept=".jpg,.jpeg,.png" className="position-absolute opacity-0" onChange={(e) => props.imageSet(e)} />
                <div className="invalid-feedback">
                    Cover image is required.
                </div>
            </div>

            <label htmlFor="coverImage" className="btn btn-theme-primary btn-lg mt-1"> Change image</label>

            {props.children ?? ''}

            <h4 className="fw-bold pt-3 mt-5">Set price</h4>
            <p className="mb-1">Set the price of this workbook. Price will be displayed in the user’s localised currency.</p>
            <div className="row">
                <div className="col-sm-6 pb-2">
                    <div className="form-floating mt-24">
                        <input pattern="[0.00-9.00]+" className="form-control form-control-md mb-4" name="price" id="price" onInput={(e) => props.setPrice(e.target.value)} defaultValue={props.listInfo && props.listInfo.price} placeholder="Australian dollar $" required />
                        <div className="invalid-feedback">
                            Price field is required and must be numeric.
                        </div>
                        <label>Price in Australian dollar $<span className="text-danger">*</span></label>
                    </div>
                </div>
                <div className="col-sm-6">
                    {props.currency && props.currency && props.currency.map((item, i) => {
                        return <div className="form-floating mt-24" key={i}>
                            <input className="form-control form-control-md mb-4 " disabled value={(props.price * item.currency_price).toFixed(2)} />
                            <label>
                                {item.currency_type === 'INR' && 'Price in Indian Rupee ₹'}
                                {item.currency_type === 'USD' && 'Price in US dollar $'}
                                {item.currency_type === 'GBP' && 'Price in British Pound £'}
                                {item.currency_type === 'EUR' && 'Price in Euros €'}
                            </label>
                        </div>
                    })}
                </div>
            </div>

            <h4 className="fw-bold pt-3 mt-5">Set tax amount</h4>
            <p className="mb-1">Set the tax amount for this workbook</p>
            <div className="row">
                <div className="col">
                    <div className="form-floating mt-24">
                        <input className="form-control form-control-md mb-4" defaultValue={props.listInfo && props.listInfo.tax_name} placeholder="tax" name="tax_name" id="tax_name" onChange={(e) => checkTaxNameAndTaxRate(e, 'tax_rate', 'taxRate')} />
                        <div className="invalid-feedback">
                            Tax name field is required.
                        </div>
                        <label>Name of tax<span className="text-danger" id="taxName" style={{ visibility: 'hidden' }}>*</span></label>
                    </div>
                </div>
                <div className="col-auto ps-1">
                    <div className="form-floating maxw-120 mt-24">
                        <input type="number" min="0.00" step="0.01" className="form-control form-control-md" placeholder="Tax rate" name="tax_rate" id="tax_rate" defaultValue={props.listInfo && props.listInfo.tax_rate && props.listInfo.tax_rate !== '0.00' && props.listInfo.tax_rate} onChange={(e) => checkTaxNameAndTaxRate(e, 'tax_name', 'taxName')} />
                        <div className="invalid-feedback">
                            Tax rate is required.
                        </div>
                        <label>Tax rate<span className="text-danger" id="taxRate" style={{ visibility: 'hidden' }}>*</span></label>
                    </div>
                </div>
                <div className="col-auto align-self-center ps-0">%</div>
            </div>
        </>
    )

}