const ExamTitle = (props) => {
    return (
        <>
            <div className="d-flex align-items-center">
                <span className="bg-blue text-white fw-bold text-uppercase rounded-pill px-3 py-2 " style={{ background: `hsl(${props.course.accent_color}, 100%, 50%)` }}>
                    {props.course.course_abbr}
                </span>
                <span className="h3 fw-bold text-blue align-sub ms-2 small-fontsize text-ellips_heading mb-0">
                    {props.workbook.title}
                </span>
            </div>
        </>
    )
}

export default ExamTitle