import { useState, useEffect } from "react";
import { fetchData, ASSET_ENDPOINT, ADD_TO_CART, REMOVE_FROM_CART, GET_WEBSITE_CONTENT, GET_LEARNING_OUTCOMES } from "../../components/Service";
import { Link } from "react-router-dom";
import { now } from 'lodash';
import { getCartItem, noImageHandle } from '../../components/CommonMethod';
import { PageNavigation } from "../../components/DataTable";
import { ShowPrice } from "../../components/CountryFilter";
import { addGuestCart, isGuest, removeGuestCart } from "../../components/GuestHelper";

export const ProductFilter = (props) => {

    return (
        <div className="aside-filter" id="asideFilterBox">
            <div className="aside-innerfilter">
                <div className="d-flex d-lg-none align-items-center justify-content-between pb-3">
                    <strong className="fs-17">Filter By</strong>
                    <button type="button" className="btn" onClick={() => props.filterToggle()} title="Close"><svg className="icon ts-1p5"><use href="#icon_clear"></use></svg></button>
                </div>
                {props.children}
            </div>
        </div>
    )

}

export const FilteredItem = (props) => {
    const [testimonial, setTestimonial] = useState('')
    const guest = isGuest()


    var index = 0;

    const addToCart = (e, id) => {
        e.preventDefault()
        fetchData(ADD_TO_CART, 'POST', { list_id: id }, true, false, (res) => {
            if (res.success) {
                getCartItem((res) => { })
                props.filter(now)
            }
        });
    }

    const removeFromCart = (e, id) => {
        e.preventDefault()
        fetchData(REMOVE_FROM_CART, 'POST', { id: id }, true, false, (res) => {
            if (res.success) {
                getCartItem((res) => { })
                props.filter(now)
            }
        });
    }

    const guestCart = (e, id, add = true) => {
        e.preventDefault()
        if (add) {
            addGuestCart(id)
        } else {
            removeGuestCart(id)
        }
        props.filter(now)
    }

    const showLearningOutcomes = (e, workbook_id, type) => {
        e.preventDefault();
        fetchData(GET_LEARNING_OUTCOMES, 'POST', { id: workbook_id, type: type }, true, false, (res) => {
            if (res.records) {
                props && props.setOutcomes(res.records && res.records)
            }
        })
    }


    useEffect(() => {
        fetchData(GET_WEBSITE_CONTENT + '/shop-content', 'GET', '', true, false, (res) => {
            if (res.records) {
                setTestimonial(JSON.parse(res.records.value) && JSON.parse(res.records.value).testimonials);
            }
        });
    }, [])

    return (
        <>
            {props.listData && props.listData.length > 0 ? props.listData.map((item, key) => {
                return (
                    <Link to='/product-details' state={{ item: item, course: props.courseData, currencyData: props.currencyData }} key={key} className="text-decoration-none text-dark">
                        <div className="card border-blue-hover rounded-4 mb-4">
                            <div className="card-body p-md-4 d-md-flex">
                                <div className="shadow-md rounded-3 overflow-hidden me-3 me-md-4 mmwh-180">
                                    <img src={item.image ? ASSET_ENDPOINT + item.image : '../images/ionic-purple.jpg'} className="h-100 ratio maxw-180 hm-180" alt={item.title} onError={noImageHandle} />
                                </div>
                                <div className="d-block d-sm-flex mt-3 mt-md-0">
                                    <div className="pe-sm-4">
                                        <h3 className="fw-bold h4 listing-heading">{item.title} {item.type !== 'single' ? '(Package)' : ''}</h3>
                                        <div className="d-flex flex-wrap  mb-3 pt-1 listing-small-text">
                                            <div className="d-inline-flex mb-2 w-100 me-3 mb-md-2">
                                                {item.type === 'single' && <>
                                                    <div className="mmwh-24 text-white d-flex justify-content-center align-items-center rounded-circle me-2" style={{ background: `hsl(190, 100%, 50%)` }}>
                                                        <svg className="icon ts-1p1"><use href="#icon_class"></use></svg>
                                                    </div>
                                                    <div className="d-icon-all">
                                                        {item.details && item.details.length > 0 && item.details.map((item, index) => <span className="fw-bold" key={index}>{index > 0 && index < 3 && ', '}{index < 3 && item.section_name}</span>)}
                                                    </div>
                                                </>}
                                            </div>
                                            <div className="d-inline-flex me-3 mb-2" >
                                                <div className="mmwh-24 text-white d-flex justify-content-center align-items-center rounded-circle me-2" style={{ background: `hsl(270, 100%, 50%)` }}>
                                                    <svg className="icon ts-1p1"><use href="#icon_help"></use></svg>
                                                </div>
                                                <span className="fw-bold">{item.total_question ?? 0} questions</span>
                                            </div>
                                            {item.type === 'single' &&
                                                <div className="d-inline-flex me-3 mb-2">
                                                    <div className="mmwh-24 text-white d-flex justify-content-center align-items-center rounded-circle me-2" style={{ background: `hsl(5, 100%, 50%)` }}>
                                                        <svg className="icon ts-1p1"><use href="#icon_timer"></use></svg>
                                                    </div>
                                                    {item.details && item.details.length > 0 && item.details.map((item, index) => <span className="fw-bold" key={index}>{item.time_limit} minutes</span>)}
                                                </div>
                                            }

                                            {item.type === 'package' &&
                                                <div className="d-inline-flex me-3">
                                                    <div className="mmwh-24 text-white d-flex justify-content-center align-items-center rounded-circle me-2" style={{ background: `hsl(120, 100%, 50%)` }}>
                                                        <svg className="icon ts-1p1"><use href="#icon_books"></use></svg>
                                                    </div>
                                                    <span className="fw-bold">{item.details && item.details.length ? item.details.length : 0} Workbooks</span>
                                                </div>
                                            }
                                        </div>
                                        {item && item.workbook_id ? <button type="button" className="btn btn-theme-primary text-nowrap" onClick={(e) => showLearningOutcomes(e, item.workbook_id, item.type)} data-bs-toggle="modal" data-bs-target="#learningOutcomesModal" title="Learning outcomes">Learning outcomes</button> : ''}
                                    </div>
                                    <div className="ms-auto text-sm-end mt-3 mt-sm-0 pb-2">
                                        <h4 className="fw-bold"><ShowPrice currencyData={props.currencyData} price={item.price} /></h4>
                                        {props.context && props.context.auth && props.context.auth.user_type === '2' ?
                                            props.context.auth.group_details ?
                                                <p className="fw-bold text-green mb-0">You are a group student.</p>
                                                : item.workbook_status ?
                                                    <>
                                                        {item.workbook_status === 'active' && <button type="button" className="btn btn-yellow pe-none btn-lg text-nowrap" title="Purchased" style={{ cursor: 'default' }} onClick={(e) => e.preventDefault()}>Purchased</button>}
                                                        {item.workbook_status === 'cart-in' && <button type="button" className="btn btn-green btn-lg text-nowrap" title="Remove from Cart" onClick={(e) => removeFromCart(e, item.id)}>Remove from Cart</button>}
                                                        {item.workbook_status === 'cart-out' && <button type="button" className="btn btn-theme-primary btn-lg text-nowrap" title="Add to Cart" onClick={(e) => addToCart(e, item.id)}>Add to Cart</button>}
                                                    </>
                                                    : <button type="button" className="btn btn-theme-primary btn-lg text-nowrap" title="Add to Cart" onClick={(e) => addToCart(e, item.id)}>Add to Cart</button>
                                            : ''
                                        }
                                        {guest && <>
                                            <p className="fw-bold text-green mb-2">You are a guest user.</p>
                                            {guest.cart_items.shop_listing_id.includes(item.id) ? <>
                                                <button type="button" className="btn btn-green btn-lg text-nowrap" title="Remove from Cart" onClick={(e) => guestCart(e, item.id, false)}>Remove from Cart</button>
                                            </> : <>
                                                <button type="button" className="btn btn-theme-primary btn-lg text-nowrap" title="Add to Cart" onClick={(e) => guestCart(e, item.id)}>Add to Cart</button>
                                            </>}
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {((props.listData.length === key + 1) || ((key + 1) % 3 === 0)) && testimonial[index] &&
                            <div className="box rounded-4 bg-sky d-block d-md-flex d-md-inline d-none  align-items-center mb-4 gap-2 px-5 text-white fw-bold" onClick={(e) => e.preventDefault()}>
                                <img src={testimonial[index].student_image ? ASSET_ENDPOINT + testimonial[index].student_image : 'images/fly.png'} className="minw-180 h-140" onError={noImageHandle} alt="ionic" />
                                <p className="mb-0">
                                    &#8220;  {testimonial[index] && testimonial[index].student_message} &#8221;
                                    <span className="text-capitalize"> {testimonial[index] && testimonial[index].student_name}</span>
                                    <span className='d-none'>{index++}</span>
                                </p>
                            </div>}
                    </Link>
                )
            }) : <div className="row mt-1"><h4 className="fw-bold my-3 text-danger text-center">Workbook Not Found</h4></div>}

            {props.total_item > 0 && <PageNavigation active_page={props.currentPage - 1} per_page={10} filter_recoards={props.total_item} setCurrentPage={props.setCurrentPage} />}
        </>
    )
}



export const SingleListOutcomes = (props) => {

    return (
        <div className="row mt-5 pe-0">
            <div className="col-sm-12 ">
                <h3 className="fw-bold h4 py-1">Learning outcomes</h3>
            </div>
            <div className="col-sm-12 col-lg-6">
                <div className="box rounded-1 border border-1 border-dark p-2 py-4 mt-2">
                    <span className="mb-0 p-1 fw-bold ">{props.section}</span>
                    <p className="mb-0 p-1 fw-normal"> {props.learning_outcomes}</p>
                </div>
            </div>
            {props.children}
        </div>
    )
}

export const PackageListOutcomes = (props) => {

    return (
        <div className="row mt-5">
            <div className="col-sm-12">
                <h3 className="fw-bold h4 py-1">Learning outcomes</h3>
            </div>
            {props.data && props.data.length > 0 && props.data.map((workbook, index) => {
                return (
                    <div key={index} className="mt-4">
                        <div className="col-sm-12 col-lg-6">
                            <span className="mb-0 ps-1 fw-bold">Workbook {index + 1}</span>
                            <div className="box rounded-1 border border-1 border-dark p-2 py-4 mt-2">
                                <span className="mb-0 p-1 fw-bold ">{workbook.section_name}</span>
                                <p className="mb-0 p-1 fw-normal"> {workbook.learning_outcomes}</p>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
export const PackageWorkbooks = (props) => {

    return (
        <div className="row mt-sm-5 pt-sm-5">
            <h3 className="ps-md-4">Workbooks in this pack </h3>
            {props.data && props.data.length > 0 && props.data.map((workbook, index) => {
                return (
                    <div className="card border border-0" key={index}>
                        <div className="card-body p-md-4 d-md-flex">
                            <div className="shadow-md rounded-3 mmwh-180 overflow-hidden me-3 me-md-4">
                                <img src={workbook.image ? ASSET_ENDPOINT + workbook.image : '../images/ionic-purple.jpg'} className="ratio mmwh-180" alt={workbook && workbook.title} onError={noImageHandle} />
                            </div>
                            <div className="d-block d-sm-flex mt-3 mt-md-0">
                                <div className="pe-sm-4">
                                    <h3 className="fw-bold h4">[{index + 1}] {workbook && workbook.title}</h3>
                                    {/* <p className="fw-bold fs-14 text-danger">Valid for {workbook && workbook.expired_on} years</p> */}
                                    <div className="d-flex flex-wrap  mb-3 pt-1">
                                        <div className="d-inline-flex w-100 me-3">
                                            <div className="mmwh-24 text-white d-flex justify-content-center align-items-center rounded-circle me-2 mb-2" style={{ background: `hsl(${workbook && workbook.accent_color}, 100%, 50%)` }}>
                                                <svg className="icon ts-1p1"><use href="#icon_class"></use></svg>
                                            </div>
                                            <span className="fw-bold">{workbook && workbook.section_name}</span>
                                        </div>
                                        <div className="d-inline-flex w-100 me-3">
                                            <div className="mmwh-24 text-white d-flex justify-content-center align-items-center rounded-circle me-2  mb-2" style={{ background: `hsl(${workbook && workbook.accent_color}, 100%, 50%)` }}>
                                                <svg className="icon ts-1p1"><use href="#icon_help"></use></svg>
                                            </div>
                                            <span className="fw-bold">{workbook && workbook.total_question ? workbook.total_question : 0} questions</span>
                                        </div>
                                        <div className="d-inline-flex w-100 me-3">
                                            <div className="mmwh-24 text-white d-flex justify-content-center align-items-center rounded-circle me-2  mb-2" style={{ background: `hsl(${workbook && workbook.accent_color}, 100%, 50%)` }}>
                                                <svg className="icon ts-1p1"><use href="#icon_timer"></use></svg>
                                            </div>
                                            <span className="fw-bold">{workbook && workbook.time_limit} minutes</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}