import { minutesFormatDuration } from "../../../components/CommonMethod";

export const ItemBox = ({ title, data, bgcolor, time }) => {

    return (
        <>
            <div className="col-sm-6 col-md-4 col-lg-3 col-xxl-2 border py-3" style={{ background: bgcolor }}>
                <p className="mb-1">
                    <b>{title}</b>
                </p>
                <h1>
                    <b>{data?.average ? (time ? minutesFormatDuration(data.average) : `${data.average}%`) : "0%"}</b>
                </h1>
                <p className={`compare-avg ${data?.trend} mb-0`}>
                    {data?.trend === 'arrowUp' && <>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                        </svg>
                    </>}
                    {data?.trend === 'arrowDown' && <>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                        </svg>
                    </>}
                    <b>{data?.percentage}%</b>
                </p>
            </div>
        </>
    )
}