import { useState, useEffect, useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { GET_AVERAGE_WORKBOOK_ANALYTIC, GROUP_SELECTION, fetchData } from "../../components/Service";
import { ItemBox } from "./Component/Component";
import Canvas from "./Component/Canvas";
import { formatDuration } from "../../components/CommonMethod";
import { Context } from "../../components/Context";

const FullReview = () => {
    window.document.title = "Graph Analytics";
    const [data, setData] = useState(null);
    const [options, setOptions] = useState(null);
    const [query, setQuery] = useState('');
    const [bgcolors] = useState(['#6ce5e8', '#ff66c4', '#ff4e7b', '#d84c39', '#ed9a4c']);
    const [loading, setLoading] = useState(true);
    const [context] = useContext(Context);
    const [group, setGroup] = useState('');
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        if (context && context.auth) {
            if (context.auth.group_details) {
                let group_code = context.auth.group_details.group_code;
                setGroup(group_code);
            } else {
                fetchData(GROUP_SELECTION, 'GET', '', true, false, (res) => {
                    if (res.records) {
                        setGroups(res.records);
                    }
                })
            }
        }
    }, [context])

    useEffect(() => {
        if (query) {
            const controller = new AbortController();
            const signal = controller.signal;
            setData(null);
            setLoading(false);
            fetchData(`${GET_AVERAGE_WORKBOOK_ANALYTIC}?group_code=${group}&${query}`, 'GET', '', true, false, (res) => {
                if (res && res.records) {
                    setData(res.records);
                    setOptions({
                        chart: {
                            toolbar: {
                                show: false,
                                offsetX: 0,
                                offsetY: 0,
                            },
                            height: 350,
                            type: 'line',
                            zoom: {
                                enabled: false
                            },
                        },
                        events: {
                            mounted: function (chartContext, config) {
                                setLoading(false);
                            },
                            updated: function (chartContext, config) {
                                setLoading(false);
                            },
                        },
                        colors: bgcolors,
                        dataLabels: {
                            enabled: false,
                        },
                        forecastDataPoints: {
                            count: 0,
                            fillOpacity: 0.5,
                            strokeWidth: 2,
                            dashArray: 4,
                        },

                        markers: {
                            size: 5,
                            // colors: ['red', 'green'],
                            strokeColors: '#fff',
                            strokeWidth: 2,
                            strokeOpacity: 0.9,
                            strokeDashArray: 0,
                            fillOpacity: 1,
                            discrete: [],
                            shape: "circle",
                            radius: 2,
                            offsetX: 0,
                            offsetY: 0,
                            onClick: undefined,
                            onDblClick: undefined,
                            showNullDataPoints: true,
                            hover: {
                                size: undefined,
                                sizeOffset: 2
                            }
                        },

                        legend: {
                            tooltipHoverFormatter: function (val, opts) {
                                let value = opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex];
                                value = value ?? 0;
                                return val + ' - <strong>' + ((val === 'Consume Time') ? formatDuration(value * 60) : `${value}%`) + '</strong>'
                            },
                            height: 100,
                        },
                        xaxis: {
                            categories: res.records.chart_title,
                            labels: {
                                formatter: function (val) {
                                    return val;
                                },
                                rotate: -80,
                                // rotateAlways: true,
                                style: {
                                    cssClass: "custom-label",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                }
                            }
                        },
                        yaxis: {
                            show: false,
                        },
                        grid: {
                            borderColor: '#f1f1f1',
                            xaxis: {
                                lines: {
                                    show: true
                                }
                            },
                            yaxis: {
                                lines: {
                                    show: false
                                }
                            },
                            column: {
                                colors: undefined,
                                opacity: 1
                            },
                            padding: {
                                top: 5,
                                bottom: 5,
                                left: 50,
                                right: 50,
                            },
                        },
                        tooltip: {
                            y: {
                                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                                    let val = value ?? 0;
                                    return (seriesIndex === 3) ? formatDuration(value * 60) : `${val}%`;
                                }
                            }
                        },
                        noData: {
                            text: "No data available",
                            align: 'center',
                            verticalAlign: 'middle',
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                color: 'undefined',
                                fontSize: '16px',
                                fontFamily: undefined,
                            }
                        }
                    });
                }
            }, signal)
        }
    }, [query, bgcolors, group])

    const onGroupChange = (group_code) => {
        setGroup(group_code);
    }

    return (
        <>
            <div className="container-fluid">
                <div className="page-head sticky_top_padding">
                    <div className="row gy-2">
                        <div className="col-sm-6">
                            <h1 className="h3 text-dim">Graph Analytics</h1>
                        </div>
                        <div className="col-sm-6 mb-2 text-end">
                            <div className="d-flex justify-content-end">
                                {(context?.auth?.user_type === '1' || context?.auth?.user_type === '3') &&
                                    <select value={group} onChange={(e) => onGroupChange(e.target.value)} className="form-select form-select-md maxw-300 me-2">
                                        <option value="">No Group</option>
                                        {groups.map((item, index) => <option key={index} value={item.group_code} >{`${item.group_name} (${item.group_code})`}</option>)}
                                    </select>
                                }
                                <button className="btn btn-lg border" style={{whiteSpace:'nowrap'}} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                    <svg className="icon me-2"><use href="#icon_filter"></use></svg> Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row px-0 mx-2 pt-4 justify-content-center text-center" id="box-container">
                    <ItemBox title={"Usage Rate"} data={data?.usage_rate} bgcolor={bgcolors[0]} />
                    <ItemBox title={"Completion Rate"} data={data?.completion_rate} bgcolor={bgcolors[1]} />
                    <ItemBox title={"Average Mark"} data={data?.student_marks} bgcolor={bgcolors[2]} />
                    <ItemBox title={"Average Time"} data={data?.consume_time} bgcolor={bgcolors[3]} time />
                    <ItemBox title={"Confidence Score"} data={data?.confidence_score} bgcolor={bgcolors[4]} />
                </div>
                {loading && <div className="w-100 text-center">
                    <div className="spinner-border spinner-border-sm position-absolute top-50" role="status"></div>
                </div>}
                <div className={`row mt-4 pt-4 bg-light ${loading && 'opacity-25'}`}>
                    <div className="col-md-12">
                        {/* <div className="table-responsive"> */}
                            {data && <ReactApexChart options={options} series={data.chart_data} type="line" height={610} style={{minWidth:'850px'}} />}
                        {/* </div> */}
                    </div>
                </div>
            </div>
            <Canvas setQuery={setQuery} />
        </>
    )
}
export default FullReview;