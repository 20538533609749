import { DROPDOWN_DATA, fetchData, UPSERT_BILLING_DETAILS, PAYMENT, REMOVE_FROM_CART, STRIPE_CHECKOUT, GET_WEBSITE_CONTENT, ASSET_ENDPOINT } from '../../components/Service';
import { useState, useEffect } from 'react';
import { validateForm, removeMsg, showAlertMsg, ButtonSpinner, noImageHandle } from "../../components/CommonMethod";
import { now } from "lodash";
import { Link, useNavigate } from 'react-router-dom';
import { format } from 'date-fns/esm';
import { ShowPrice } from '../../components/CountryFilter';
import { addGuestBillingDetails, isGuest, removeGuestCart } from '../../components/GuestHelper';

export const BillerDetails = (props) => {
    const [data, setData] = useState(props.data)
    const [country, setCountry] = useState('')
    const guest = isGuest()

    const handleInputChange = (e) => setData({ ...data, [e.target.name]: e.target.value })

    const submitBillerDetailsForm = (e) => {
        if (validateForm(e, 'billerDetailsForm')) {
            let formdata = { ...data, price: parseFloat(props.currencyData.currency_price * props.totalPrice).toFixed(2), currency_type: props.currencyData.currency_type }
            if (guest) {
                addGuestBillingDetails(formdata)
                removeMsg()
                props.setShowModule({ payment: true })
            } else {
                fetchData(UPSERT_BILLING_DETAILS, 'POST', formdata, true, false, (res) => {
                    if (res && res.records) {
                        removeMsg()
                        props.setOrderId(res.records.id)
                        props.setShowModule({ payment: true })
                    }
                }, false, false, 'billerDetailsForm');
            }
        }
    }

    useEffect(() => {
        fetchData(DROPDOWN_DATA + '?type=currency', 'GET', '', true, true, (res) => {
            if (res.records) {
                if (res.records) {
                    setCountry(res.records.currency)
                }
            }
        })

    }, [])

    return (
        <div className="rounded-2 border border-2 border-blue p-4 my-3">
            <div className="col-sm-12 d-flex">
                <div className="account d-flex ">
                    <h4 className="rounded-circle border border-blue text-blue text-center fs-24 px-2" style={{ height: '30px', width: '30px' }}>{props.section}</h4><h4 className=" fw-bold fs-24  text-blue ms-2">Biller details</h4>
                </div>
                <div className="ms-auto">
                    {props.showModule && props.showModule.payment && <button type="button" className="btn mmwh-48 d-inline-flex justify-content-center align-items-center" title="Edit" onClick={() => props.setShowModule({ biller_details: true })}>
                        <svg className="icon ts-1p5"><use href="#icon_pencil"></use></svg>
                    </button>}
                </div>
            </div>

            {props.showModule && props.showModule.biller_details &&
                <form className="needs-validation" method="post" id="billerDetailsForm" noValidate autoComplete="off">
                    <div className="row py-2">
                        <p className="py-2">If you are under 18 this purchase will be billed to your nominated parent or guardian.</p>

                        <div className="col-sm-6 mb-2">
                            <div className='form-floating'>
                                <input type='text' id="biller_first_name" name="biller_first_name" placeholder="Biller First name" className="form-control form-control-md" value={data.biller_first_name} onChange={handleInputChange} required />
                                <div className="invalid-feedback">The biller first name field is required.</div>
                                <label>Biller first name <span className="text-danger">*</span></label>
                            </div>
                        </div>
                        <div className="col-sm-6 mb-2 mt-check">
                            <div className='form-floating'>
                                <input type='text' id="biller_last_name" name="biller_last_name" placeholder="Biller Last name" className="form-control form-control-md" value={data.biller_last_name} onChange={handleInputChange} required />
                                <div className="invalid-feedback">The biller last name field is required.</div>
                                <label>Biller last name <span className="text-danger">*</span></label>
                            </div>
                        </div>

                        <div className="col-sm-7 mt-3 mb-2">
                            <div className='form-floating'>
                                <input type='email' id="biller_email" name="biller_email" placeholder="Biller Email" className="form-control form-control-md" value={data.biller_email} onChange={handleInputChange} required />
                                <div className="invalid-feedback">The email field is required and must be a valid email address.</div>
                                <label>Biller Email <span className="text-danger">*</span></label>
                            </div>
                        </div>
                        <p className='text-secondary'>This email address may be used for billing purposes only.</p>
                    </div>

                    <div className="row py-2">
                        <div className="col-sm-7 mb-2">
                            <div className='form-floating'>
                                <select className="form-select form-select-md" name="country" id="country" value={data.country} onChange={handleInputChange} required>
                                    <option value="">Country</option>
                                    {country && country.length > 0 && country.map((item, index) => <option className="text-dark" value={item.name} key={index} >{item.name}</option>)}
                                </select>
                                <div className="invalid-feedback">The country field is required.</div>
                                <label>Country <span className="text-danger">*</span></label>
                            </div>
                        </div>
                    </div>
                    <div className="row py-2">
                        <div className="col-sm-12 mb-2">
                            <div className='form-floating'>
                                <input type="text" className="form-control form-control-md" placeholder="Street address" id="address" name="address" value={data.address} onChange={handleInputChange} required />
                                <div className="invalid-feedback">The address field is required.</div>
                                <label>Street Address <span className="text-danger">*</span></label>
                            </div>
                        </div>
                    </div>
                    <div className="row py-2">
                        <div className="col-sm-7 mb-2">
                            <div className='form-floating'>
                                <input type="text" className="form-control form-control-md" placeholder="Suburb" id="suburb" name="suburb" value={data.suburb} onChange={handleInputChange} required />
                                <div className="invalid-feedback">The suburb field is required.</div>
                                <label>Suburb<span className="text-danger">*</span></label>
                            </div>
                        </div>
                    </div>
                    <div className="row py-2">
                        <div className="col-sm-4 mb-2">
                            <div className='form-floating'>
                                <input type="number" min={99} max={999999999} className="form-control form-control-md" placeholder="postcode" id="postcode" name="postcode" value={data.postcode} onChange={handleInputChange} required />
                                <div className="invalid-feedback"> The postcode field is required and must be a valid postcode.</div>
                                <label>Postcode<span className="text-danger">*</span></label>
                            </div>
                        </div>
                        <div className="col-sm-3 mb-2 mth-4 ">
                            <div className='form-floating'>
                                <input type="text" className="form-control form-control-md" placeholder="State" id="state" name="state" value={data.state} onChange={handleInputChange} required />
                                <div className="invalid-feedback">The state field is required.</div>
                                <label>State<span className="text-danger">*</span></label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 py-3 d-flex justify-content-end">
                            <button type="button" className="btn btn-theme-primary minw-90 me-3" title="Next" onClick={submitBillerDetailsForm}>Next</button>
                        </div>
                    </div>
                </form>}

            {props.showModule && props.showModule.payment &&
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row py-2 ps-5">
                            <div className="col-sm-6 fw-bold">FIRST NAME:</div>
                            <div className="col-sm-6">{data.biller_first_name}</div>
                        </div>
                        <div className="row py-2 ps-5">
                            <div className="col-sm-6 fw-bold">LAST NAME:</div>
                            <div className="col-sm-6">{data.biller_last_name}</div>
                        </div>
                        <div className="row py-2 ps-5">
                            <div className="col-sm-6 fw-bold">EMAIL:</div>
                            <div className="col-sm-6">{data.biller_email}</div>
                        </div>
                        <div className="row py-2 ps-5">
                            <div className="col-sm-6 fw-bold">ADDRESS:</div>
                            <div className="col-sm-6">{data.address}</div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}


export const Payment = (props) => {
    const [cartPayment, setCartPayment] = useState(false)
    const [, setStripeTinyloader] = useState(false);
    const [tinyloader, setTinyloader] = useState(false);
    const [data, setData] = useState({ card_number: '', cvv: '', expiry_date: '', name_on_card: '' })
    const navigate = useNavigate();

    useEffect(() => {
        if (props.showModule && props.showModule.payment) {
            setCartPayment(true)
            setData({ card_number: '', cvv: '', expiry_date: '', name_on_card: '' })
        } else {
            setCartPayment(false)
        }
    }, [props.showModule])

    const purchase = (e, payment_method = 'stripe', validate = true) => {
        if (payment_method === 'cart') validate = validateForm(e, 'puchaseForm')
        if (validate) {
            if (props.cartId.length === 0) {
                showAlertMsg({ error: 'Please add minimum one item in the cart.' })
                return
            }
            let formdata = {
                ...data,
                price: parseFloat(props.currencyData.currency_price * props.totalPrice).toFixed(2),
                aud_price: parseFloat(props.totalPrice).toFixed(2),
                currency_type: props.currencyData.currency_type,
                cart_id: props.cartId,
                order_id: props.orderId,
                payment_method: payment_method,
            }

            if (payment_method === 'cart') {
                setTinyloader(true);
                fetchData(PAYMENT, 'POST', formdata, true, false, (res) => {
                    setTinyloader(false);
                    if (res && res.success) {
                        removeMsg()
                        let cart = document.getElementById('cart-item-count')
                        if (cart) cart.innerText = 0
                        let cart_mobile = document.getElementById('cart-item-mobile-count')
                        if (cart_mobile) cart_mobile.innerText = 0
                        navigate('/checkout-successful', { state: { payment: true } });
                    }
                }, false, false, 'puchaseForm');
            } else {
                setStripeTinyloader(true)
                fetchData(STRIPE_CHECKOUT, 'POST', formdata, true, false, (res) => {
                    setStripeTinyloader(false);
                    if (res && res.success) {
                        window.location = res.records;
                    }
                }, false, false, 'puchaseForm');
            }

        }
    }

    const handlecvv = (e) => {
        let cvv = e.target.value
        if (cvv.match(/^\d{0,4}$/g)) {
            setData(prevState => ({ ...prevState, cvv }))
        } else {
            e.target.value = data.cvv
        }
    }

    useEffect(() => {
        let ccNumberInput = document.querySelector('.cc-number-input'),
            ccNumberPattern = /^\d{0,16}$/g,
            ccNumberSeparator = " ",
            ccNumberInputOldValue,
            ccNumberInputOldCursor,

            ccExpiryInput = document.querySelector('.cc-expiry-input'),
            ccExpiryPattern = /^\d{0,4}$/g,
            ccExpirySeparator = "/",
            ccExpiryInputOldValue,
            ccExpiryInputOldCursor,

            mask = (value, limit, separator) => {
                var output = [];
                for (let i = 0; i < value.length; i++) {
                    if (i !== 0 && i % limit === 0) {
                        output.push(separator);
                    }

                    output.push(value[i]);
                }

                return output.join("");
            },
            unmask = (value) => value.replace(/[^\d]/g, ''),
            checkSeparator = (position, interval) => Math.floor(position / (interval + 1)),
            ccNumberInputKeyDownHandler = (e) => {
                let el = e.target;
                ccNumberInputOldValue = el.value;
                ccNumberInputOldCursor = el.selectionEnd;
            },
            ccNumberInputInputHandler = (e) => {
                let el = e.target,
                    newValue = unmask(el.value),
                    newCursorPosition;

                if (newValue.match(ccNumberPattern)) {
                    newValue = mask(newValue, 4, ccNumberSeparator);

                    newCursorPosition =
                        ccNumberInputOldCursor - checkSeparator(ccNumberInputOldCursor, 4) +
                        checkSeparator(ccNumberInputOldCursor + (newValue.length - ccNumberInputOldValue.length), 4) +
                        (unmask(newValue).length - unmask(ccNumberInputOldValue).length);

                    el.value = (newValue !== "") ? newValue : "";
                } else {
                    el.value = ccNumberInputOldValue;
                    newCursorPosition = ccNumberInputOldCursor;
                }

                el.setSelectionRange(newCursorPosition, newCursorPosition);
                setData(prevState => ({ ...prevState, card_number: el.value }))
            },
            ccExpiryInputKeyDownHandler = (e) => {
                let el = e.target;
                ccExpiryInputOldValue = el.value;
                ccExpiryInputOldCursor = el.selectionEnd;
            },
            ccExpiryInputInputHandler = (e) => {
                let el = e.target,
                    newValue = el.value;

                if (e.inputType !== "deleteContentBackward" && ((newValue.length === 1 && newValue > 1) || (newValue.length === 2 && newValue > 12))) {
                    newValue = `0${newValue.substr(0, 1)}/`;
                } else {
                    if (e.inputType === "deleteContentBackward" && (ccExpiryInputOldCursor === 3 || ccExpiryInputOldCursor === 4)) {
                        newValue = newValue.substr(0, 2);
                    } else {
                        newValue = unmask(newValue);

                        if (newValue.match(ccExpiryPattern)) {
                            var output = [];
                            for (let i = 0; i < newValue.length; i++) {
                                output.push(newValue[i]);
                                if (i === 1) {
                                    output.push('/');
                                }
                            }

                            newValue = output.join("");
                        } else {
                            newValue = ccExpiryInputOldValue;
                        }
                    }
                }
                el.value = newValue
                setData(prevState => ({ ...prevState, expiry_date: el.value }))
            };

        if (cartPayment) {
            ccNumberInput.removeEventListener('keydown', ccNumberInputKeyDownHandler);
            ccNumberInput.removeEventListener('input', ccNumberInputInputHandler);
            ccExpiryInput.removeEventListener('keydown', ccExpiryInputKeyDownHandler);
            ccExpiryInput.removeEventListener('input', ccExpiryInputInputHandler);

            ccNumberInput.addEventListener('keydown', ccNumberInputKeyDownHandler);
            ccNumberInput.addEventListener('input', ccNumberInputInputHandler);
            ccExpiryInput.addEventListener('keydown', ccExpiryInputKeyDownHandler);
            ccExpiryInput.addEventListener('input', ccExpiryInputInputHandler);
        }

    }, [cartPayment])

    return (
        <div className="rounded-2 border border-2 border-blue p-4 my-2">
            <div className="col-sm-12 d-flex">
                <div className="account d-flex ">
                    <h4 className=" rounded-circle border border-blue text-blue fs-24 px-2 ">{props.section}</h4><h4 className=" fw-bold fs-24  text-blue ms-2">Payment</h4>
                </div>
            </div>
            <style jsx="true">{`
            .react-datepicker-popper{
                z-index:2;
            }
        `}</style>
            {props.showModule && props.showModule.payment &&
                <div className="row py-2">
                    <div className="col-sm-12">
                        <p className="fw-bold"> Pay by</p>
                        {/* <button className="btn btn-outline-default minw-90 me-2" title="Credit or Debit card" onClick={() => setCartPayment(!cartPayment)}>Card</button>
                        <button className="btn btn-outline-default minw-90" disabled={cartPayment} onClick={(e) => purchase(e)}>
                            <ButtonSpinner load={stripeTinyloader} btnName="Stripe" />
                        </button> */}
                        {cartPayment && <>
                            <form className="needs-validation" method="post" id="puchaseForm" noValidate autoComplete="off">
                                <div className="col-sm-7 my-4">
                                    <div className='form-floating'>
                                        <input type="text" className="form-control form-control-md" autoComplete='off' placeholder="Name on card" id="name_on_card" name="name_on_card" value={data.name_on_card} required onChange={(e) => setData(prevState => ({ ...prevState, name_on_card: e.target.value }))} />
                                        <div className="invalid-feedback">The name field is required.</div>
                                        <label>Name on card<span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-sm-7 my-4">
                                    <div className='form-floating'>
                                        <input type="text" className="form-control form-control-md cc-number-input" autoComplete='off' placeholder="Card Number" id="card_number" name="card_number" required />
                                        <div className="invalid-feedback">The card number field is required.</div>
                                        <label>Card Number<span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-3 mb-2">
                                        <div className='form-floating'>
                                            <input type="test" maxLength={4} className="form-control form-control-md" autoComplete='off' placeholder="CVV" id="cvv" name="cvv" onInput={handlecvv} required />
                                            <div className="invalid-feedback">The cvv field is required.</div>
                                            <label>CVV<span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 mb-2 ms-2">
                                        <div className='form-floating'>
                                            <input type="text" className="form-control form-control-md cc-expiry-input" placeholder="Expiry date" id="expiry_date" name="expiry_date" required />
                                            <div className="invalid-feedback">The expiry date field is required.</div>
                                            <label>MM/YY<span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 py-3 d-flex justify-content-end">
                                    <button type="button" className="btn btn-theme-primary minw-90 me-3" onClick={(e) => purchase(e, 'cart')}>
                                        <ButtonSpinner load={tinyloader} btnName="Stripe" />
                                    </button>
                                </div>
                            </form>
                        </>}
                    </div>
                </div>}
        </div>
    )
}

export const Cart = (props) => {
    const [testimonial, setTestimonial] = useState('')
    const guest = isGuest()

    const removeFromCart = (id) => {
        if (guest) {
            removeGuestCart(id)
            props.setRefresh(now)
        } else {
            fetchData(REMOVE_FROM_CART, 'POST', { id: id }, true, false, (res) => {
                if (res.success) {
                    props.setRefresh(now)
                }
            });
        }
    }

    useEffect(() => {
        fetchData(GET_WEBSITE_CONTENT + '/checkout-content', 'GET', '', true, false, (res) => {
            if (res.records) {
                setTestimonial(JSON.parse(res.records.value));
            }
        });
    }, [])

    return (

        <div className="col-lg-6 col-sm-12 col-md-12">
            <div className="d-flex justify-content-between pt-3 pb-2 px-2 mx-2">
                <h4 className="fs-24 fw-bold ">Your cart </h4>
                <h4 className="fs-24 fw-bold">{props.cartData ? props.cartData.length : 0} item</h4>
            </div>
            {props.cartData && props.cartData.length > 0 && props.cartData.map((item, index) => {
                return (
                    <div className="d-sm-flex d-block justify-content-between pb-5  " key={index}>
                        <div className="d-flex ">
                            <img src="images/ionic-purple.jpg" className="img-fluid border mmwh-180 mb-2 h-100 me-2" alt="ionic" />
                            <div>
                                <p className="fw-bold">{item.title}</p>
                                <span className="bg-purple text-white fw-bold text-uppercase rounded-pill px-3 py-2 me-2">{item.course_abbr}</span>
                                <h6 className="mt-3 text-green bold">{(item.cart_updated && item.cart_updated !== '0') && `This item has been updated on ${format(new Date(item.cart_updated), 'MM/dd/yyyy')}.`}</h6>
                            </div>
                        </div>
                        <div className="">
                            <div className="col-sm-12 px-3">
                                <div className="row gap-0">
                                    <div className="col">
                                        <p className="fw-bold">Price</p>
                                    </div>
                                    <div className="col text-nowrap">
                                        <p className="fw-bold text-end"><ShowPrice currencyData={props.currencyData} price={item.price} /></p>
                                    </div>
                                </div>
                                {item.tax_name &&
                                    <div className="row gap-0">
                                        <div className="col">
                                            <p className="fw-bold text-nowrap">
                                                {`${item.tax_name}(${item.tax_percentage}%)  `}</p>
                                        </div>
                                        <div className="col text-nowrap">
                                            <p className="fw-bold text-end"><ShowPrice currencyData={props.currencyData} price={item.tax_rate} /></p>
                                        </div>
                                    </div>}
                                <div className="row gap-0 text-end">
                                    <span className="text-dim text-decoration-underline fw-medium" role="button" title='REMOVE' onClick={() => removeFromCart(item.shop_listing_id)}>REMOVE</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}

            <div className="d-flex justify-content-end py-3" style={{ borderTop: '2px solid black' }}>
                <div className="col">
                    <p className="fw-bold fs-24">Total
                        <span className="float-end"><ShowPrice currencyData={props.currencyData} price={props.totalPrice} /></span>
                    </p>
                </div>

            </div>
            <div className="d-sm-flex bg-sky rounded-4 border border-1 p-2 pe-2 text-white fw-bold align-items-center">
                <img src={testimonial.student_image ? ASSET_ENDPOINT + testimonial.student_image : 'images/fly.png'} className="minw-180" onError={noImageHandle} alt="ionic" />
                <p className='ms-2 mb-0'>&#8220;  {testimonial && testimonial.student_message} &#8221;  <span className="fw-bold text-capitalize">{testimonial && testimonial.student_name}</span></p>
            </div>
        </div>
    )
}

export const GuestSignUp = (props) => {

    return (
        <div className="rounded-2 border border-2 border-blue p-4 my-2">
            <div className="col-sm-12 d-flex">
                <div className="account d-flex ">
                    <h4 className=" rounded-circle border border-blue text-blue fs-24 px-2 ">{props.section}</h4><h4 className=" fw-bold fs-24  text-blue ms-2">Get started today!</h4>
                </div>
            </div>
            {props.showModule && props.showModule.payment &&
                <div className="row py-2">
                    <div className="col-sm-12">
                        <div className="px-3">
                            <h5 className="fw-medium mb-4">Receive a free workbook when you create an account.</h5>
                            <div className="d-flex manage_sign_guest_btn gap-3">
                                <Link to="/sign-up" className="btn btn-theme-primary white-space btn-lg w-100"> Sign up</Link>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}