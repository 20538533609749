const ExamButton = (props) => {
    return (
        <>
            {props.reviewStart ? <button className="btn btn-theme-primary me-2 mt-3 " title="Back to Results" onClick={() => props.setReviewStart(false)}>Back to Results</button> : 
            <>
            { props.startExam && props.mode !== 'exam' && <button className={props.review ? "btn btn-outline-default me-2" : "btn btn-yellow me-2"} data-bs-target="#restartModal" data-bs-toggle="modal" title="Restart">Restart</button>}
            <a id="quitButtonAside" data-bs-toggle="modal" className={props.review ? "btn btn-theme-primary" : "btn btn-danger"} title="Quit" href="/workbook-landing-ooops#quitModal">Quit</a>
            </>
            }
        </>
    )
}

export default ExamButton