import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { __echoText, toastNotify, validateForm, ButtonSpinner } from "../../components/CommonMethod";
import { fetchData, USER_LIST, SEND_SINGLE_INVITE, SEND_BULK_INVITE } from "../../components/Service";
import { DataTable } from "../../components/DataTable";
import { format } from 'date-fns';
import { now } from 'lodash'
import { WorkbookSection } from "./GroupComponent";

function ManageGroup() {
    const location = useLocation();
    let groupDetail = location.state
    window.document.title = "Manage Group";
    const [refreshStudent, setRefreshStudent] = useState(false);
    const [tinyloader, setTinyloader] = useState(false);
    const [tinyloader1, setTinyloader1] = useState(false);
    let group_code = groupDetail && groupDetail.item && groupDetail.item.group_code

    const addStudentEmail = (e) => {
        setTinyloader(true);
        let email_id = document.getElementById("student_email").value;
        fetchData(SEND_SINGLE_INVITE, 'POST', { group_code: group_code, email_id: email_id }, true, false, (res) => {
            setTinyloader(false);
            if (res.success) {
                document.querySelector('#staticBackdrop [data-bs-dismiss="modal"]').click()
                document.getElementById("student_email").value = ''
            }
            else {
                if (Object.keys(res.errors).length > 0) {
                    [res.errors].forEach((ele) => {
                        Object.keys(ele).map((v, i) => {
                            return toastNotify('danger', ele[v])
                        });
                    })
                } else {
                    toastNotify('danger', res.error)
                }
            }
            setRefreshStudent(now)
        });
    }

    const sendBulkInvite = (e) => {
        let formdata = new FormData(document.getElementById('uploadInviteForm'))
        if (validateForm(e, 'uploadInviteForm')) {
            setTinyloader1(true);
            fetchData(SEND_BULK_INVITE, 'POST', formdata, true, true, (res) => {
                setTinyloader1(false);
                if (res.success) {
                    document.getElementById('file').value = ''
                    document.getElementById('selected_file').innerText = ''
                    document.getElementById('selected_file').style.display = "none"
                    document.querySelector('#staticBackdrop [data-bs-dismiss="modal"]').click()

                }
            })
        }
        setRefreshStudent(now)
    }

    const getCsvFile = (e) => {
        if (e.target.files.length > 0) {
            var string = e.target.files[0].name;
            document.getElementById('selected_file').innerText = string
            document.getElementById('selected_file').style.display = "block"

            var fileInput = document.getElementById("file")
            var reader = new FileReader()
            reader.onload = function () {
                // document.getElementById('wrapper').innerHTML = reader.result;
            }
            reader.readAsBinaryString(fileInput.files[0]);

        }
        else {
            document.getElementById('wrapper').innerHTML = ''
            document.getElementById('selected_file').innerText = ''
            document.getElementById('selected_file').style.display = "none"
        }
    }

    const studentsListByGroup = {
        id: 'studentsListByGroup',
        fetchurl: USER_LIST + '/2/' + group_code,
        columns: [
            { data: 'id', name: 'id', title: 'ID' },
            { data: 'first_name', name: 'first_name', title: 'STUDENT NAME' },
            { data: 'school_group_code', name: 'school_group_code', title: 'GROUP CODE' },
            { data: 'email', name: 'email', title: 'EMAIL ADDRESS' },
            { data: 'created_at', name: 'created_at', title: 'DATE JOINED' },
            { data: 'action', name: 'action', title: 'ACTION', sorting: false, searching: false },
        ]
    }

    return (<>
        <div className="container-fluid">
        <div className="page-head sticky_top_padding">
            <div className="row">
                <div className="col-sm-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                            <li className="breadcrumb-item"><Link to="/cms/groups">Manage Groups</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{groupDetail && groupDetail.item && groupDetail.item.group_name}</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row gx-4 mb-1 align-items-center ">
                <div className="col-lg-6 col-sm mb-3 col-6">
                    <h1 className="h3 text-dim mb-0">{groupDetail && groupDetail.item && groupDetail.item.group_name} </h1>
                </div>
                <div className="col-lg-6 col-auto mb-3 col-6">
                    <Link to="/cms/update-group" state={{ groupDetail }} className="btn btn-theme-primary btn-lg float-end" title="Edit">Edit group</Link>
                </div>
            </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <nav>
                        <div className="nav nav-tabs nav-bar mb-2" id="nav-tab" role="tablist">
                            <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#workbookBox" type="button" role="tab" aria-selected="true">Workbooks</button>
                            <button className="nav-link" data-bs-toggle="tab" data-bs-target="#studentsBox" type="button" role="tab" aria-selected="false" onClick={() => setRefreshStudent(now) }>Students</button>
                        </div>
                    </nav>
                    <div className="tab-content pt-4">
                        <div className="tab-pane fade show active" id="workbookBox" role="tabpanel" tabIndex="0">
                            <WorkbookSection group_code={group_code} />
                        </div>
                        <div className="row"></div>
                        <div className="tab-pane fade" id="studentsBox" role="tabpanel" tabIndex="0">
                            <div className="text-md-end mb-4 mmt-md-80">
                            { groupDetail && groupDetail.item && groupDetail.item.status !== "block" ?
                                <button type="button" className="btn btn-green btn-lg" data-bs-toggle="modal" data-bs-target="#staticBackdrop">+ Invite Students</button>
                                :
                                <button type="button" className="btn btn-green btn-lg" onClick={() => toastNotify('danger', 'Please activate this group first to invite the students.')}>+ Invite Students</button>
                            }
                            </div>
                            <DataTable execute={refreshStudent} dt={studentsListByGroup}
                                edit_column={{
                                    first_name: (records) => __echoText(`${records.first_name} ${records.last_name}`),
                                    created_at: (records) =>  __echoText(`${format(new Date(records.created_at), 'MM/dd/yyyy')}`),
                                    action: (records) => <Link to="/cms/update-user" state={records} onClick={() => localStorage.setItem('activeTab', '#studentsBox')} className="text-black mmwh-48 d-inline-flex justify-content-center align-items-center" title="Edit" href="#"><svg className="icon ts-1p5"><use href="#icon_pencil"></use></svg></Link>
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* Add Students Modal */}
        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content overflow-hidden border border-2 border-primary rounded-4">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Send student invitation for {groupDetail && groupDetail.item && groupDetail.item.group_name}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                        <div className="row mt-2">
                            <div className="col-lg-8 col-12">
                                <div className="maxw-480 mb-4">
                                    <form id="uploadInviteForm" autoComplete="off" className="needs-validation" noValidate>
                                        <div className=" justify-content-between align-items-center mt-4 mb-1">
                                            <p className="">Download Sample CSV <a style={{margin:"5px"}} href="/images/csv/sample.csv" download><svg className="icon ts-1p6 me-3"><use href="#icon_download"></use></svg> </a></p>
                                            <h4 className="">Add multiple</h4> 
                                            <p>Upload a CSV file containing the emails of the students you would like to upload, then click ‘Send Invitation’.</p>
                                        </div>
                                        <input type="hidden" name="group_code" defaultValue={groupDetail && groupDetail.item && groupDetail.item.group_code} />
                                        <label className="dropzone-wrapper" >
                                            <input type="file" accept=".csv" name="file" id="file" onChange={(e) => getCsvFile(e)} required />
                                            <div className="invalid-feedback">
                                                CSV file is required.
                                            </div>
                                            <img src="/images/csv.png" alt="CSV" />
                                            <h4>Drag a CSV file here or click to upload</h4>
                                        </label>


                                        <div className="d-flex justify-content-between">
                                            <button style={{ display: "none" }} id="selected_file" className="px-5 py-3 border-0 mt-3 text-danger"> </button>
                                            <button type="button" className="btn btn-outline-default minw-100 mt-3" onClick={(e) => sendBulkInvite(e)}><ButtonSpinner load={tinyloader1} btnName="Send Invitation" /></button></div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="row d-flex">
                            <h5>Add single </h5>
                            <p>Enter one email address and click ‘Send Invitation’.</p>
                            <div className="d-flex mb-3 col-lg-8 col-md-12 col-12 ">
                                <div className="form-floating w-100 overfloe_email">
                                    <input type="email" id="student_email" className="form-control form-control-md" placeholder="Student email address" autoComplete="off" />
                                    <div className="invalid-feedback">
                                        Email id is required.
                                    </div>
                                    <label>Student Email</label>
                                </div>
                                <button type="button" className="btn btn-outline-default minw-170 ms-3 btn-widthh0mhn" onClick={(e) => addStudentEmail(e)}>
                                    <ButtonSpinner load={tinyloader} btnName="Send Invitation" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)

}
export default ManageGroup;