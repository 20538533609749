import { useState, useEffect, useContext } from "react";
import { Context } from "../../components/Context";
import { GET_AVERAGE_CLASS_ANALYTIC, GROUP_SELECTION, fetchData } from "../../components/Service";
import { Link } from "react-router-dom";
import Canvas from "./Component/Canvas";
import { encodedText } from "../../components/CommonMethod";

const ClassReview = () => {
    window.document.title = "Class Review";
    const [context] = useContext(Context);
    const [data, setData] = useState([]);
    const [group, setGroup] = useState('');
    const [groups, setGroups] = useState([]);
    const [query, setQuery] = useState(null);
    const [classDetails, setClassDetails] = useState(null);
    const [studentDetails, setStudentDetails] = useState([]);


    useEffect(() => {
        if (context && context.auth) {
            if (context.auth.group_details) {
                let group_code = context.auth.group_details.group_code;
                setGroup(group_code);
            } else {
                fetchData(GROUP_SELECTION, 'GET', '', true, false, (res) => {
                    if (res.records) {
                        setGroups(res.records);
                    }
                })
            }
        }
    }, [context])

    useEffect(() => {
        if (query) {
            fetchData(`${GET_AVERAGE_CLASS_ANALYTIC}?group_code=${group}&${query}`, 'GET', '', true, false, (res) => {
                if (res && res.records) {
                    setData(res.records);
                }
            })
        }
    }, [group, query])

    const onGroupChange = (group_code) => {
        setGroup(group_code);
    }

    const showClassSetails = (data) => {
        let student_data = [];

        data.completed_workbook_data.forEach(item => {
            let student_details = { student: item.student, completed_workbook_count: 0, not_completed_workbook_count: 0, completed_workbook: [], not_completed_workbook: [] };
            if (student_data[item.user_id]) {
                student_details = student_data[item.user_id];
            }

            let workbook = student_details.completed_workbook;
            workbook.push(item.workbook);

            student_details = { ...student_details, completed_workbook: workbook, completed_workbook_count: student_details.completed_workbook_count + 1 };
            student_data[item.user_id] = student_details;
        });

        data.not_completed_workbook_data.forEach(item => {
            let student_details = { student: item.student, completed_workbook_count: 0, not_completed_workbook_count: 0, completed_workbook: [], not_completed_workbook: [] };
            if (student_data[item.user_id]) {
                student_details = student_data[item.user_id];
            }

            let workbook = student_details.not_completed_workbook;
            workbook.push(item.workbook);

            student_details = { ...student_details, not_completed_workbook: workbook, not_completed_workbook_count: student_details.not_completed_workbook_count + 1 };
            student_data[item.user_id] = student_details;
        });

        setStudentDetails(student_data)
        setClassDetails(data);
    }

    console.log(studentDetails);
    return (
        <>
            <style>
                {`
                table, th, td {
                    border:3px solid black;
                    padding: 15px 5px;
                  }
                `}
            </style>

            <div className="container-fluid">
                <div className="page-head sticky_top_padding">
                    <div className="row gy-2">
                        <div className="col-md-6">
                            <div className="h3 text-dim">
                                <span className="">Class Review</span>
                            </div>
                        </div>

                        <div className="col-md-6 mb-2 text-end">
                            <div className="d-flex justify-content-between justify-content-md-end">
                                {(context?.auth?.user_type === '1' || context?.auth?.user_type === '3') &&
                                    <select value={group} onChange={(e) => onGroupChange(e.target.value)} className="form-select form-select-md maxw-300 me-2">
                                        <option value="">No Group</option>
                                        {groups.map((item, index) => <option key={index} value={item.group_code} >{`${item.group_name} (${item.group_code})`}</option>)}
                                    </select>
                                }
                                <button className="btn btn-lg border" style={{ whiteSpace: 'nowrap' }} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                    <svg className="icon me-2"><use href="#icon_filter"></use></svg> Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row  mt-4 pt-4">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="text-center w-100">
                                <tr>
                                    <th style={{ minWidth: '200px' }}>Class</th>
                                    <th style={{ minWidth: '200px' }}>Average Mark</th>
                                    <th style={{ minWidth: '200px' }}>Average Completion Time</th>
                                    <th style={{ minWidth: '200px' }}>Average Confidence Score</th>
                                </tr>
                                {data.length > 0 ? data.map((item, key) => {
                                    return <tr key={key}>
                                        <td><Link to={`/cms/student-review/${encodedText({ class_id: item.class_id, query: query }, process.env.REACT_APP_ENCRYPTED_KEY)}`} className="">{item.class_name} ({item.group_code})</Link></td>
                                        <td>{item.average_marks ?? 0}%</td>
                                        <td>{item.average_completion_time ?? 0}</td>
                                        <td>{item.average_confidence_score ?? 0}%</td>
                                    </tr>
                                }) : <tr><td colSpan={4}>No Record found.</td></tr>}
                            </table>
                        </div>
                    </div>
                </div>

                <div className="row mt-4 pt-4">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="text-center w-100">
                                <tr>
                                    <th style={{ minWidth: '200px' }}>Class</th>
                                    <th style={{ minWidth: '200px' }}>Total Student</th>
                                    <th style={{ minWidth: '200px' }}>Assigned Workbook</th>
                                    <th style={{ minWidth: '200px' }}>Completed Workbook</th>
                                    <th style={{ minWidth: '200px' }}>Incompleted Workbook</th>
                                    <th style={{ minWidth: '200px' }}>Not Started Workbook</th>
                                    <th style={{ minWidth: '200px' }}>View Details</th>
                                </tr>
                                {data.length > 0 ? data.map((item, key) => {
                                    return <tr key={key}>
                                        <td>{item.class_name} ({item.group_code})</td>
                                        <td>{item.student_count ?? 0}</td>
                                        <td>{item.assign_workbook ?? 0}</td>
                                        <td>{item.completed_workbook ?? 0}</td>
                                        <td>{item.not_completed_workbook ?? 0}</td>
                                        <td>{item.not_started_workbook ?? 0}</td>
                                        <td>
                                            <span role="button" className="text-blue me-2" data-bs-toggle="modal" data-bs-target="#class-details-modal" onClick={() => showClassSetails(item)}>
                                                <svg class="icon pe-none"><use href="#icon_eye"></use></svg>
                                            </span>
                                        </td>
                                    </tr>
                                }) : <tr><td colSpan={7}>No Record found.</td></tr>}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Canvas setQuery={setQuery} noCompare />
            <div className="modal fade" id="class-details-modal" tabIndex="-1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content border border-2 border-blue p-2 rounded-4">
                        <div className="modal-header border-0">
                            <h3 className="fw-bold text-blue">{classDetails && `${classDetails.class_name} (${classDetails.group_code})`}</h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="border p-2">
                                <div className="table-responsive">
                                    <table className="text-center w-100 border-width-2">
                                        <tr>
                                            <th style={{ minWidth: '200px' }}>Student</th>
                                            <th style={{ minWidth: '200px' }}>Assigned Workbook</th>
                                            <th style={{ minWidth: '200px' }}>Completed Workbook</th>
                                            <th style={{ minWidth: '200px' }}>Incompleted Workbook</th>
                                            <th style={{ minWidth: '200px' }}>Not Started Workbook</th>
                                        </tr>
                                        {classDetails && studentDetails.length > 0 ? studentDetails.map((item, key) => {
                                            return <tr key={key}>
                                                <td>{item.student.first_name} {item.student.last_name}</td>
                                                <td>{classDetails.assign_workbook}</td>
                                                <td>{item.completed_workbook_count}</td>
                                                <td>{item.not_completed_workbook_count}</td>
                                                <td>{classDetails.assign_workbook - (item.completed_workbook_count + item.not_completed_workbook_count)}</td>
                                            </tr>
                                        }) : <tr><td colSpan={5}>No Record found.</td></tr>}
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0">
                            <button className="btn btn-outline-default btn-lg me-2" title="Close" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ClassReview;