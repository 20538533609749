import { useState } from "react";
import { ButtonSpinner, initialFormState, validateForm } from "../../components/CommonMethod";
import { fetchData, ADD_ENQUIRY } from "../../components/Service";

const ContactUs = () => {
    window.document.title = "ContactUs";
    const [tinyloader, setTinyloader] = useState(false)

    const hideEnquiryBtn = () => {
        document.getElementById('enquiryBtn').style.display = 'none';
        document.getElementById('enquiryForm').style.display = 'block'
    }

    const submitEnquiryForm = (e) => {
        let formData = new FormData(document.getElementById('enquiryModalForm'));
        if (validateForm(e, 'enquiryModalForm')) {
            setTinyloader(true);
            fetchData(ADD_ENQUIRY, 'POST', formData, false, true, (res) => {
                setTinyloader(false);
                if (res.success) {
                   initialFormState('enquiryModalForm') 
                }
            });
        }
    }

    return (
        <section className="pt-5">
            <div className="container container-1040">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-7 order-sm-1">
                                <form id="enquiryModalForm" method="post" className="needs-validation" autoComplete="off" noValidate>
                                    <h1 className="h3 pt-2 pt-sm-5 pb-4 fw-bold">Contact Us</h1>
                                    <p className="fs-18 fw-bold">Have a question? We're here to help you.</p>
                                    <button type="button" className="btn btn-theme-primary btn-lg sm-w-100 mb-4" id="enquiryBtn" onClick={() => hideEnquiryBtn()}>Start your support journey here</button>
                                    <div id="enquiryForm" style={{ display: 'none' }}>
                                        <div className="row g-4" >
                                            <div className="col-sm-6 pt-2">
                                                <div className="form-floating">
                                                    <input type="text" name="name" id="name" className="form-control form-control-md" placeholder="Name" defaultValue="" required />
                                                    <div className="invalid-feedback">
                                                        The name field is required.
                                                    </div>
                                                    <label>Name <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 pt-2">
                                                <div className="form-floating">
                                                    <input type="text" name="phone" id="phone" className="form-control form-control-md" placeholder="Phone" defaultValue="" />
                                                    <div className="invalid-feedback">
                                                        The phone field is required.
                                                    </div>
                                                    <label>Phone </label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 pt-2">
                                                <div className="form-floating">
                                                    <input type="email" name="email" id="email" className="form-control form-control-md" placeholder="Email" defaultValue="" required />
                                                    <div className="invalid-feedback">
                                                        The email field is required.
                                                    </div>
                                                    <label>Email<span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 pt-2">
                                                <div className="form-floating">
                                                    <input type="text" name="subject" id="subject" className="form-control form-control-md" placeholder="Subject" defaultValue="" required />
                                                    <div className="invalid-feedback">
                                                        The subject field is required.
                                                    </div>
                                                    <label>Subject <span className="text-danger">*</span></label>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 pt-2">
                                                <div className="form-floating">
                                                    <textarea type="text" name="message" id="message" className="form-control form-control-md h-140" rows="5" cols="30" placeholder="Message" defaultValue="" required></textarea>
                                                    <div className="invalid-feedback">
                                                        The message field is required.
                                                    </div>
                                                    <label>Message <span className="text-danger">*</span></label>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-end">
                                                <button type="button" className="btn btn-theme-primary btn-lg px-3" disabled={tinyloader} onClick={(e) => submitEnquiryForm(e)}  >
                                                    <ButtonSpinner load={tinyloader} btnName="Submit" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-sm-5">
                                <img src="images/female.png" className="img-fluid" alt="Female" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactUs