import { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../../components/Context";

const GetStarted = () => {
    const [context] = useContext(Context)

    return (
        <section className="home-section7">
            <img src="images/vector/shape4.svg" className="homebg-cover" alt="shape" />
            <div className="container position-relative container-1040">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex align-items-start justify-content-center">
                            <svg className="icon mmwh-35 sm-mmwh-28 text-blue">
                                <use href="#icon_subtract"></use>
                            </svg>
                            <div className="px-3">
                                <h2 className="fw-bold mb-3">Start Your Journey today!</h2>
                                {/* <h4 className="fw-medium mb-4">Receive a free workbook when you create an account.</h4> */}
                                <div className="d-flex justify-content-center">
                                {context && context.auth ?
                                    <Link to={`${context.auth.user_type === '2' ? '/my-workbooks' : '/cms'}`} className="btn btn-theme-primary btn-manage-width btn-lg w-100 mt-2">{context.auth.user_type === '2' ? 'My Workbook' : 'Dashboard'}</Link>
                                    : <Link to="/sign-up" className="btn btn-theme-primary btn-manage-width btn-lg w-100 mt-2"> Sign up</Link>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GetStarted