import React, { useEffect, useState } from 'react';
import { validateForm, ButtonSpinner, initialFormState, removeMsg, ConfirmationModal } from '../../components/CommonMethod';
import { ASSET_ENDPOINT, fetchData, SETTING_PAGE, DELETE_SETTING_IMAGE } from '../../components/Service';
import { now } from 'lodash'

const Setting = () => {
    document.title = "File Manager"
    const [data, setData] = useState('')
    const [tinyLoader, setTinyloader] = useState(false);
    const [deleteSections, setDeleteSections] = useState({ imageIndex: '', name: '', type: ''})
    const [refresh, setRefresh] = useState(now)
    const saveTestimonialImage = (e) => {
        let formdata = new FormData(document.getElementById('testimonialImage'))
        if (validateForm(e, "testimonialImage")) {
            setTinyloader(true);
            fetchData(SETTING_PAGE, 'POST', formdata, true, true, (res) => {
                if (res.success) {
                    initialFormState('testimonialImage')
                    setTinyloader(false)
                    removeMsg()
                    setRefresh(now)
                } else {
                    setTinyloader(false)
                }
            });
        };
    }

    useEffect(() => {
        fetchData(SETTING_PAGE, 'GET', '', true, false, (res) => {
            if (res.records && res.records.value) {
                setData(JSON.parse(res.records.value));
            }
        });
    }, [refresh])

    const deleteImage = (index, type) => {
        var api = (type === 'testimonial_image') ? DELETE_SETTING_IMAGE : ''
        fetchData(api, 'DELETE', { 'index': index }, true, false, (res) => {
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                setRefresh(now)
            }
        })

    }

    
    return (
        <>
            <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row">
                    <div className="col-sm-12">
                        <h1 className="h3 text-dim mb-4 mb-md-5">File Manager</h1>
                    </div>
                </div></div>

                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="fw-bold m-0">Testimonial Image</h4>
                            </div>
                            <div className="card-body">
                                <form className="needs-validation" id="testimonialImage" noValidate>
                                    <div className="mb-3">
                                        <div className="password-wrapper">
                                            <input type="text" placeholder="Image Name" name="image_name" id="image_name" className="form-control form-control-md" required />
                                            <div className="invalid-feedback">
                                                Please fill image name.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="password-wrapper">
                                            <input type="file" name="testimonial_image" accept=".jpg,.jpeg,.png" id="testimonial_image" className="form-control form-control-md" required />
                                            <div className="invalid-feedback">
                                                Please choose image.
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-theme-primary minw-110 mb-3 mb-sm-2 me-auto me-sm-0 order-sm-1" onClick={(e) => saveTestimonialImage(e)} disabled={tinyLoader}>
                                        <ButtonSpinner load={tinyLoader} btnName="Upload Image" />
                                    </button>
                                </form>
                            </div>
                            <div className="card-footer" >
                                <div className="row image-show">
                                    {data && data.length > 0 ? data.map((value, key) => {
                                        return (
                                            <div className='col image-box' key={key}>
                                                <img src={ASSET_ENDPOINT + value.image} className="mmwh-150" alt="testimonials"/>
                                                <p className='mt-2'>{value.name}</p>
                                                <button className="btn btn-sm text-danger btn-lg border border-1 border-danger  btn-del_spacing" title="Delete" data-bs-toggle="modal" data-bs-target="#deletesection_modal" onClick={(e) => setDeleteSections(prevState => ({ ...prevState, imageIndex: key, name: value.name, type: 'testimonial_image' }))} ><svg className="icon fs-5" role="img"><use href="#icon_delete"></use></svg></button>
                                            </div>
                                        )
                                    }) : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmationModal msg={`Delete ${deleteSections.name} ?`} method={() => deleteImage(deleteSections.imageIndex, deleteSections.type)} />
        </>
    )
}

export default Setting