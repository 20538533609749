import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { fetchData, PAGE_CONTENT } from "./Service"

const Footer = () => {
    const [socialContent, setSocialContent] = useState('')

    useEffect(() => {
        fetchData(`${PAGE_CONTENT}?type=social-media`, 'GET', '', false, false, (res) => {
            if (res.records) {
                setSocialContent(JSON.parse(res.records))
            }
        });
    }, []);

    return (
        <>
            <footer>
                <div className="container container-1180">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-social">
                                <a href={socialContent && socialContent.instagram_link} target="_blank" title="Instagram" rel="noreferrer">
                                    <svg className="icon">
                                        <use href="#icon_instagram"></use>
                                    </svg>
                                </a>
                                <a href={socialContent && socialContent.facebook_link} target="_blank" title="Facebook" rel="noreferrer">
                                    <svg className="icon">
                                        <use href="#icon_facebook"></use>
                                    </svg>
                                </a>
                                {socialContent && socialContent.twitter_link &&
                                    <a href={socialContent.twitter_link} target="_blank" title="Twitter" rel="noreferrer">
                                        <svg className="icon">
                                            <use href="#icon_twitter"></use>
                                        </svg>
                                    </a>
                                }
                            </div>
                            <h2 className="footer-slogan">We are all Elemental</h2>
                        </div>
                        <div className="col-12" id="footerk">
                            <div className="row">
                                <div className="col-lg-7 col-md-7 col-12">
                                    <Link to="/about-us" className="footer-link me-4 me-md-4 me-sm-5" title="About us">About us</Link>
                                    <Link to="/contact-us" className="footer-link me-4 me-md-4 me-sm-5" title="Contact us">Contact us</Link>
                                    <Link to="/terms-of-use" className="footer-link me-4 me-md-4 me-sm-5" title="Terms of use">Terms and conditions</Link>
                                    <Link to="/privacy-policy" className="footer-link me-4 me-md-4 me-sm-5" title="Privacy policy">Privacy policy</Link>
                                </div>
                                <div className="col-lg-5 col-md-5 col-12">
                                    <div className="footer-link-copyright  float-end ps-mob-pd ">
                                        <div><span className="me-1">Design by SRDS </span></div>
                                        <div>Development  by <a href="https://xipetech.com/" target="_blank" rel="noreferrer" className=" ms-lg-auto top-pading ms-1" title="XIPE TECH">XIPE TECH</a></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="footer-main py-1">
                <div className="container container-1180">
                    <div className="row align-items-center">
                        <div className=" col-sm-12 col-md py-3">
                            <p className="copyright text-md-start text-center my-0">Copyright©2023 We are all Elemental, All Rights Reserved </p>
                        </div>
                        <div className=" col-sm-12 col-md-auto py-3">
                            <div className="main-copyright">
                                <div className="footer-address pe-3">
                                    <p className="mb-1">We are all Elemental</p>
                                    <p className="mb-0"> Perth, Western Australia</p>
                                </div>
                                <div className="abn ps-3">
                                    <p className="mb-0">ABN: 82127769771</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer