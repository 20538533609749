import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CountryFilter from "../../components/CountryFilter";
import { COURSE_SHOP_LISTING, fetchData } from "../../components/Service";
import { FilteredItem, ProductFilter } from "./ShopComponent";
import { Context } from '../../components/Context';
import WorkbookFilter, { FilterTopic } from "../../components/WorkbookFilter";
import { now } from "lodash";
import { stopBackgroundScroll } from "../../components/CommonMethod";
import LearningOutcomes from "../../components/LearningOutcomes";
import { isGuest } from "../../components/GuestHelper";

const ProductList = () => {
    window.document.title = "Product";
    const location = useLocation()
    const navigate = useNavigate()
    const guest = isGuest()
    const [currencyData, setCurrencyData] = useState(location.state && location.state.currencyData)
    const [courseData] = useState(location.state && location.state.item)
    const [listData, setListData] = useState('');
    const [againFilter, setAgainFilter] = useState(true)
    const [addCartFilter, setAddCartFilter] = useState(true)
    const [totalItem, setTotalItem] = useState(0)
    const [context] = useContext(Context)
    const [currentPage, setCurrentPage] = useState(1)
    const [learningOutcomes, setLearningOutcomes] = useState([])

    window.document.title = "Shop Listing Page";

    const filterToggle = () => {
        document.getElementById('asideFilterBox').classList.toggle('active');
        stopBackgroundScroll("asideFilterBox");
    }
    useEffect(() => {
        setCurrentPage(1)
    }, [againFilter]);

    useEffect(() => {
        if (courseData) {
            let user_id = context && context.auth ? context.auth.id : false
            let formData = new FormData(document.getElementById('filterForm'))
            fetchData(`${COURSE_SHOP_LISTING}?course_id=${courseData.id}&user_id=${user_id}&page=${currentPage}`, 'POST', formData, true, true, (res) => {
                if (res.records.data) {
                    setListData(res.records.data)
                    setTotalItem(res.records.total_records)
                }
            });
        } else {
            navigate('/shop')
        }
    }, [againFilter, context, currentPage, addCartFilter, courseData, navigate]);

    return (<>

        <section className="py-4 py-sm-4">
            <div className="container container-1180">
                <div className="row">
                    <div className="col-lg-4 pe-lg-5">
                        <form id="filterForm">
                            <ProductFilter filterToggle={filterToggle} >
                                {courseData && courseData.topic_with_section && <WorkbookFilter filter={setAgainFilter} module={courseData.topic_with_section} />}

                                <h5 className="fs-18 text-uppercase fw-bold text-blue mt-5 mb-0">Question Type</h5>
                                <FilterTopic name="question_type[]" value="0" label="Key concepts" fun={() => setAgainFilter(now)} />
                                <FilterTopic name="question_type[]" value="1" label="Numerical problems" fun={() => setAgainFilter(now)} />

                                <h5 className="fs-18 text-uppercase fw-bold text-blue mt-5 mb-0">Product type</h5>
                                <FilterTopic name="product_type[]" value="single" label="Workbook" fun={() => setAgainFilter(now)} />
                                <FilterTopic name="product_type[]" value="package" label="Workbook packages" fun={() => setAgainFilter(now)} />
                            </ProductFilter>
                        </form>
                    </div>
                    <div className="col-lg-8">

                        {(guest || (context && context.auth && context.auth.individual_student)) && <h6 className="fw-bold text-danger">Workbook is valid for 2 years</h6>}
                        <div className="d-lg-flex justify-content-between">
                            <div className="d-flex justify-content-between mb-4 mb-md-3 order-1">
                                <CountryFilter currencyData={currencyData} setCurrencyData={setCurrencyData} />
                                <button type="button" className="btn btn-link d-lg-none" onClick={() => filterToggle()}>
                                    <svg className="icon ts-1p4"><use href="#icon_filter"></use></svg>
                                </button>
                            </div>
                            <div className="d-flex flex-wrap align-items-baseline justify-content-between fw-bold mb-3 me-1" id="button_lineheigh">
                                <div className="d-flex align-items-center mb-3 order-sm-0 order-1">
                                    <h3 className="align-items-center fw-bold mb-0 order-sm-0 small-fontsize">{courseData && courseData.course_abbr} Digital Workbooks</h3>
                                    <span className="text-white fw-bold text-uppercase rounded-pill ms-4 px-4 py-1 fs-14" style={{ background: `hsl(${courseData && courseData.accent_color}, 100%, 50%)` }}>{courseData && courseData.course_abbr}</span>
                                </div>
                            </div>
                        </div>
                        <FilteredItem currentPage={currentPage} setCurrentPage={setCurrentPage} listData={listData} courseData={courseData} currencyData={currencyData} filter={setAddCartFilter} context={context} total_item={totalItem} setOutcomes={setLearningOutcomes} />
                    </div>
                </div>
            </div>
        </section>
        <LearningOutcomes record={learningOutcomes} />
    </>)
}
export default ProductList;