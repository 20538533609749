import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { toastNotify, validateForm } from "../../components/CommonMethod";
import {useLocation} from "react-router-dom";
import { fetchData } from "../../components/Service"

const GetCKImages = () => {
    const form = useForm();
    const { register, formState: { errors } } = form;
    const search = useLocation().search;
    const ckFunNum = new URLSearchParams(search).get('CKEditorFuncNum');
    
    const [ reload, setReload ] = useState(false);
    const [tinyloader, setTinyloader] = useState(false);
    const [ ckFuncNum, setCkFuncNum ] = useState(ckFunNum);
    const [images, setImages] = useState({});

    useEffect(() => {
        if(ckFuncNum){
            fetchData(`/get-ckmedia?CKEditorFuncNum=${ckFuncNum}`,'GET', '', true, false, (response)=> {
                if(response.records.success){
                    setCkFuncNum(response.records.function_number);
                    setImages(response.records.images);
                    setReload(true); 
                }
            }); 
        }
    }, [reload])

    async function handleSubmit(e) {
        let formdata = new FormData(document.getElementById('ckImageUploadForm'));
        if (validateForm(e)) {
            setTinyloader(true);
            try {
                fetchData(`/upload-ckmedia?CKEditorFuncNum=${ckFuncNum}`, 'POST', formdata, true, true, (response)=> {
                    setTinyloader(false);
                    if(response.records.success){
                        setImage(response.records.filename)
                    }else{
                        if(response.records.message === 'validation_error'){
                            [response.records.data].forEach((ele) => {
                                Object.keys(ele).map((v, i) => {
                                    return toastNotify('danger', ele[v])
                                });
                            })
                        }
                        else {
                            toastNotify('danger', response.records.message)
                        }
                    }
                }); 
            } catch (err) {
                toastNotify('danger', 'Invalid action perform. Please try again later');
                setTinyloader(false);
            }
        }
    }

    const setImage = (imageName) => {
        var CKEditorFuncNum = ckFuncNum;
        window.opener.CKEDITOR.tools.callFunction(CKEditorFuncNum, imageName);
        window.close();
    }
    const deleteImage = (imageName) => {
        if(imageName){
            const formdata = {
                upload: imageName
            };
            fetchData(`/delete-ckmedia`, 'POST', formdata, true, false, (response)=> {
                if(response.records.success){
                    reload === false ? setReload(true): setReload(false); 
                }
            }); 
        }
    }

    return (
        <div className="p-2">
            <div className="row bg-white">
                <div className="col-md-8 mx-auto">
                    <form className="border border-5 rounded" id='ckImageUploadForm' name='ckImageUploadForm' noValidate>
                        <div className="row"> 
                            <div className="col-sm-12 col-md-8 col-lg-6 mx-auto">
                                <div className="d-flex">
                                    <input className="form-control rounded-0" id='upload' type="file" {...register('upload')} required />
                                    <button type="button" className="btn btn-primary rounded-0" onClick={(e) => handleSubmit(e) } disabled={tinyloader}>
                                        { tinyloader && <span className="spinner-border spinner-border-sm mr-1"></span> } Submit  
                                    </button>
                                </div>
                            </div>
                        </div> 
                    </form>
                </div>
            </div>
            {( images && images.length >0 ) ? 
                <div className="row m-1 mx-auto bg-light">
                    { images.map((image, ind) => 
                        <div key={ind} className="col-sm-3 col-md-4">
                            <div className="image-container">
                                <div className="img-over custom-thumb" style={{'background-image' : `url(${image.thumbSrc})`, 'background-repeat': 'no-repeat, repeat','background-position': 'center','background-size': 'cover'}}>
                                    <p className="title">{image.title}</p>
                                    <div className="overlay"></div>
                                    <div className="del-button">
                                        <a onClick={ (e) => deleteImage(image.fileBaseName) } className="cursor-pointer" title="Delete">
                                            <img src="/icons-delete-48.png" alt="Delete" />
                                        </a>
                                    </div>
                                    <div className="button">
                                        <a onClick={ (e) => setImage(image.thumbSrc) } className="cursor-pointer me-1" title="Import">
                                            <svg className="icon ts-1p5 text-white"><use href="#icon_download"></use></svg>
                                        </a>
                                    </div>
                                </div> 
                            </div>
                        </div>                  
                    )}
                </div> 
            :
                <div className="row m-1 mx-auto white">
                    <div className="col-md-8 bg-white mx-auto">
                            <div className="mx-auto text-center p-5"><img src="/loader.gif" width={180} alt="Please Wait" /> Loading...</div>
                        </div>
                </div> 
            }
        </div>
    )
}

export default GetCKImages;
