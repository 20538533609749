import { toastNotify } from "../../../components/CommonMethod";
import QuestionCard from "./QuestionCard";

const QuestionTypeModel = (props) => {
    const submitQuestion = (e) => {
        if(e.target.getAttribute('data-question_slug')){
            var slug_name = e.target.getAttribute('data-question_slug')
            props.setSelectedQuestionType([{question_type: slug_name}])
            document.querySelector('#querytypemodal [data-bs-dismiss="modal"]').click()
        }else{
            toastNotify('danger', 'Please select one question type')
        }
        
    }

    return (
        <div className="ipad_responsive_model modal fade " id="querytypemodal" tabIndex="-1">
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content overflow-hidden border border-2 border-blue rounded-4">
                    <div className="modal-header bg-grey p-4">
                        <h3 className="modal-title fw-bold">Select question type<span className="text-danger">*</span></h3>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="row row-cols-1 row-cols-md-2 g-4">
                            { props.questionTypeList && props.questionTypeList.map((item, i) => {
                                return (
                                    <div className="col" key={i} onClick={(e) => document.querySelector('#modal-submit').setAttribute('data-question_slug', item.slug)} >
                                        <label className="label-cardwrap">
                                            <input type="radio" name="query-type" defaultValue={item.id ?? ''} />
                                            { item && <QuestionCard cardDetails={item} /> }
                                        </label>
                                    </div>
                                )
                            }) }
                        </div>
                    </div>
                    <div className="modal-footer justify-content-between p-4 ">
                        <button type="button" className="btn btn-outline-default btn-lg mfont-size" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-green btn-lg mfont-size mb-2" id="modal-submit" onClick={(e) => submitQuestion(e)}>Use selected question type</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuestionTypeModel