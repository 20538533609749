import _ from "lodash";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExamButton from "./partials/ExamButton";
import ExamTitle from "./partials/ExamTitle";
import { Clapping } from "../../components/MovingRocket";
import $ from 'jquery';
import { Context } from "../../components/Context";
import { REVIEW_SUBMIT, fetchData } from "../../components/Service";

const ReviewPerfoemance = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [examId] = useState(location && location.state && location.state.exam_id)
    const [course] = useState(location && location.state && location.state.course)
    const [workbook] = useState(location && location.state && location.state.workbook)
    const [workbookAttemptType] = useState((location && location.state && location.state.workbook_attempt_type && location.state.workbook_attempt_type === 'assignment') ? 'assignment' : 'workbook')
    const [reviewStart, setReviewStart] = useState(false)
    const [animation, setAnimation] = useState(false)
    const [slide, setSlide] = useState(0)
    const [context] = useContext(Context)

    const navigateFromThisPage = () => {
        if (context && context.auth && context.auth.user_type === '2') {
            if (location && location.state && location.state.assignment && workbookAttemptType === 'assignment') {
                navigate('/my-assignments/workbooks', { state: { ...location.state.assignment } });
            } else {
                navigate('/my-workbooks');
            }
        } else {
            navigate('/cms/assigned-workbook');
        }
    }

    window.document.title = "Workbook Review Performance";

    const restartExam = () => {
        let mode = location.state.mode
        navigate(`/workbook/${_.kebabCase(workbook && workbook.title)}/${mode}`, { state: { action: mode === 'exam' ? 'start' : 'restart', mode: mode, course: course, workbook: workbook, workbook_attempt_type: workbookAttemptType, assignment: location.state.assignment } })
    }

    const quitExam = () => {
        navigateFromThisPage()
    }

    useEffect(() => {
        window.history.forward()
        if (!location.state) {
            navigateFromThisPage()
        }
        if (location.state && location.state.clapping_time && location.state.clapping_time > Date.now()) {
            setAnimation(true)
            setTimeout(() => $("#result-bgclap").fadeOut('slow'), 1500)
        }
        // eslint-disable-next-line
    }, [location, navigate])

    const gotoReview = () => {
        navigate(`/workbook/${_.kebabCase(workbook && workbook.title)}/review`, { state: location.state })
    }

    function moveSlider(e) {
        let value = e.target.value;
        setSlide(e.target.value);

        let slider_input = document.getElementById('slider_input')
        let slider_thumb = document.getElementById('slider_thumb')
        let slider_line = document.getElementById('slider_line')

        let bulletPosition = (value / 100)
        let space = slider_input.offsetWidth - slider_thumb.offsetWidth;

        slider_thumb.style.left = (bulletPosition * space) + 'px';
        slider_line.style.width = value + '%';
    }

    const submitPerformanceReview = () => {
        fetchData(REVIEW_SUBMIT, 'POST', { performance_percentage: slide, exam_id: examId }, true, false, (res) => { }, false, false, '', false);
    }

    return (<>
        {location.state &&
            <>
                <nav className="navbar navbar-expand-lg navbar-front bg-white position-sticky top-0 zindex-100 shadow-none" >
                    <div className="container py-4">
                        <ExamTitle workbook={workbook} course={course} />
                        <div className="col-lg-4 d-lg-inline d-none text-end" id="asideMobile">
                            <ExamButton startExam={true} restartExam={restartExam} review={true} reviewStart={reviewStart} setReviewStart={setReviewStart} />
                        </div>
                    </div>
                </nav>

                <div className="container">
                    <div className="row mt-5">
                        <div className=" col-lg-2 position-relative d-md-inline d-none">
                            <img src="/images/Male1.png" className="position-absolute" alt="" style={{ top: ' 16%', left: '-66%' }} />
                        </div>
                        <div className="col-sm-12 col-lg-10 ">
                            <div className="question border p-3  p-md-5 rounded-4 border-blue border-3">
                                <div className="row d-flex align-items-center justify-content-center">
                                    <div className="col-sm-12 text-center mb-2 mb-md-5">
                                        <h2 className="range__box__title"><strong>How confident do you feel about this concept?</strong></h2>
                                        <div className="range__box__inner">
                                            <div className="range__box__imog">
                                                <img src="/images/sad.png" alt="" />
                                                <span>Not Confident</span>
                                            </div>
                                            <div className="range__box__slide" style={{'--color': `hsl(${course.accent_color}, 100%, 50%)`}} >
                                                <div className="range-slider">
                                                    <div id="slider_thumb" className="range-slider_thumb">{slide}</div>
                                                    <div className="range-slider_line">
                                                        <div id="slider_line" className="range-slider_line-fill"></div>
                                                    </div>
                                                    <input id="slider_input" className="range-slider_input" type="range" value={slide} min="0" max="100" onChange={moveSlider} />
                                                </div>
                                            </div>
                                            <div className="range__box__imog">
                                                <img src="/images/smile1.png" alt="" />
                                                <span>Very Confident</span>
                                            </div>
                                        </div>
                                        <div className="range__box__cta">
                                            <button className="btn btn-outline-default btn-lg me-2 mt-2" title="Submit Performance" onClick={submitPerformanceReview}>Submit Performance</button>
                                            <button className="btn btn-lg me-2 mt-2 rounded-5 text-white" title="Review performance" onClick={gotoReview} style={{background: `hsl(${course.accent_color}, 100%, 50%)`}}><b> Review Performance {`>`} </b></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {animation && <Clapping />}
                </div>

                <div className="modal fade" id="restartModal" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content border border-2 border-blue p-2 rounded-4">
                            <div className="modal-header border-0">
                                <h3 className="fw-bold text-blue">Restart this Workbook</h3>
                            </div>
                            <div className="modal-body border-0">
                                <p>Your progress so far will not be saved.</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-outline-default btn-lg me-2" title="Keep Working" data-bs-dismiss="modal">Keep Working</button>
                                <button className="btn btn-yellow btn-lg me-2" title="Restart" data-bs-dismiss="modal" onClick={restartExam}>Restart</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="quitModal" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content border border-2 border-blue p-2 rounded-4">
                            <div className="modal-header border-0">
                                <h3 className="fw-bold text-blue">Quit this Workbook</h3>
                            </div>
                            <div className="modal-body">
                                <p>Your progress will be saved. You will resume from the last question you answered the next time you open this workbook.</p>
                            </div>
                            <div className="modal-footer border-0">
                                <button className="btn btn-outline-default btn-lg me-2" title="Keep Working" data-bs-dismiss="modal">Keep Working</button>
                                <button className="btn btn-danger btn-lg me-2" title="Quit" data-bs-dismiss="modal" onClick={() => quitExam()}>Quit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </>)
}

export default ReviewPerfoemance
