import { DragDropContainer } from 'react-drag-drop-container';
import MathElement from '../../../../../components/MathElements';

const DragOption = (props) => {
    return (
        <span className="me-3">
            <DragDropContainer
                targetKey={props.targetKey}
                dragData={{label: props.label, dragDataId: props.drag_option_id}}
                dragClone={true}
                disappearDraggedElement={false}
                customDragElement={props.customDragElement}
                // onDragStart={}
                // onDrag={}
                // onDragEnd={}
                // onDrop={}
            >
                <button type="button" id={props.drag_option_id} className={`fs-18 d-flex align-items-center btn btn-outline-secondary drag-button my-2 text-nowrap p-2 fw-unset ${props.isHalfDragButton && 'half-drag'}`}>
                    <MathElement ele_id={`option_${props.d_id}label_${props.drag_option_id}`} elements={props.label}/>
                    <svg className="icon opacity-50 ps-2">
                        <use href="#icon_drag"></use>
                    </svg>                                            
                </button>
            </DragDropContainer>
        </span>
      );
}

export default DragOption