import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";


const FIlterContent = ({ compare, query, setQuery, reset }) => {
    const days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const date = new Date();
    const [current, setCurrent] = useState(query);

    const setCurrentFixedValue = (e) => {
        let value = e.target.dataset.value;
        setCurrent({ type: 'fixed', start: null, end: null, days: '', value: value });
    }

    const setCurrentDateValue = (e) => {
        setCurrent({ type: 'days', start: null, end: null, days: e.target.value, value: e.target.dataset.value });
    }

    const setCustumDateValue = (date) => {
        setCurrent({ type: 'custom', start: date[0], end: date[1], days: '', value: null });
    }

    useEffect(() => {
        setCurrent(query);
    }, [query])

    useEffect(() => {
        let query = '';
        for (let key in current) {
            let tmp_key = compare ? `compare_${key}` : key;
            let tmp_value = ((key === 'start' || key === 'end') && current[key]) ? moment(current[key]).format("YYYY-MM-DD") : current[key];
            query += `${tmp_key}=${tmp_value}&`;
        }
        if (current.type === 'custom') {
            if (current.start && current.end) {
                setQuery(query);
            }
        } else {
            setQuery(query);
        }
    }, [setQuery, current, compare])


    useEffect(() => {
        if(reset > 1){
            setCurrent({ type: null, start: null, end: null, days: '', value: null });
        }
    }, [reset])

    return (
        <>
            <div className="customCanvas_wrapper">
                <ul className="customCanvas_menu">
                    {compare && <li><h5 className="customCanvas_menu_link" role="button">Compare</h5></li>}
                    <li className={`${current.value === 'today' && 'active'}`} onClick={setCurrentFixedValue}>
                        <span className="customCanvas_menu_link" data-value='today' role="button">Today</span>
                    </li>
                    <li className={`${current.value === 'yesterday' && 'active'}`} onClick={setCurrentFixedValue}>
                        <span className="customCanvas_menu_link" data-value='yesterday' role="button">Yesterday</span>
                    </li>
                    <li className={`${current.value === 'thisWeek' && 'active'}`} onClick={setCurrentFixedValue}>
                        <span className="customCanvas_menu_link" data-value='thisWeek' role="button">This week ({days[1]} - {days[date.getDay()]})</span>
                    </li>
                    <li className={`${current.value === 'last7Days' && 'active'}`} onClick={setCurrentFixedValue}>
                        <span className="customCanvas_menu_link" data-value='last7Days' role="button">Last 7 days</span>
                    </li>
                    <li className={`${current.value === 'last14Days' && 'active'}`} onClick={setCurrentFixedValue}>
                        <span className="customCanvas_menu_link" data-value='last14Days' role="button">Last 14 days</span>
                    </li>
                    <li className={`${current.value === 'thisMonth' && 'active'}`} onClick={setCurrentFixedValue}>
                        <span className="customCanvas_menu_link" data-value='thisMonth' role="button">This month</span>
                    </li>
                    <li className={`${current.value === 'last30Days' && 'active'}`} onClick={setCurrentFixedValue}>
                        <span className="customCanvas_menu_link" data-value='last30Days' role="button">Last 30 days</span>
                    </li>
                </ul>
                <div className="customCanvas_input_days">
                    <div className="customCanvas_input_day d-flex align-items-center">
                        <input type="number" value={current.value === 'upToToday' && current.days} data-value='upToToday' onChange={setCurrentDateValue} />
                        <span>days up to today</span>
                    </div>
                    <div className="customCanvas_input_day d-flex align-items-center">
                        <input type="number" value={current.value === 'upToYesterday' && current.days} data-value='upToYesterday' onChange={setCurrentDateValue} />
                        days up to yesterday
                    </div>
                </div>
                <ul className="customCanvas_menu mt-4">
                    <li className={`${current.type === 'custom' && 'active'}`}>
                        <span className="customCanvas_menu_link" role="button">Custom</span>
                        <span className="customCanvas_menu_link pt-0">
                            <DatePicker
                                isClearable
                                peekNextMonth
                                showYearDropdown
                                showMonthDropdown
                                autoComplete="off"
                                dropdownMode="select"
                                dateFormat="dd MMM yyyy"
                                data-value='custom'
                                className={`form-control mimw-300px`}
                                placeholderText={'Select Date Range'}
                                selectsRange
                                endDate={current.end}
                                startDate={current.start}
                                onChange={setCustumDateValue}
                                maxDate={new Date()}
                            />
                        </span>
                    </li>
                </ul>
            </div>
        </>
    )
}
export default FIlterContent;