import React, { useContext, useState } from "react";
import { Context } from '../../components/Context';
import { validateForm, removeMsg, ButtonSpinner } from "../../components/CommonMethod";
import { fetchData, UPDATE_ADMIN_PROFILE } from "../../components/Service";

const AdminProfile = () => {
    document.title = "Profile"

    const [tinyLoader, setTinyLoader] = useState(false);
    const [context] = useContext(Context)

    const updateProfile = (e) => {
        let formdata = new FormData(document.getElementById('updateProfileForm'));

        if (validateForm(e, 'updateProfileForm')) {
            setTinyLoader(true)

            fetchData(UPDATE_ADMIN_PROFILE, 'POST', formdata, true, true, (res) => {
                setTinyLoader(false)

                if (res.success) {
                    removeMsg()
                }
            })
        }
    }

    return (
        <div className="container-fluid">
            <div className="page-head sticky_top_padding">
            <div className="row g-2 align-items-center">
                <div className="col-lg-6 col-6 mb-3">
                    <h1 className="h3 text-dim mb-0">Profile</h1>
                </div>
                {context && context.auth && context.auth.user_type !== '5' && 
                <div className="col-lg-6 col-6 mb-3">
                    <button type="button" className="btn btn-theme-primary btn-lg px-3 float-end" onClick={(e) => updateProfile(e)} disabled={tinyLoader}>
                        <ButtonSpinner load={tinyLoader} btnName="Update Profile" />
                    </button>
                </div>
                }
            </div>
            </div>
            <div className="row mt-3">
                {context && context.auth && <form action="" className="needs-validation" id="updateProfileForm" noValidate autoComplete="off">
                    <div className="col-sm-12 col-lg-4">

                        {context.auth.user_type !== '5' &&
                            <div className="form-floating mt-32">
                                <input type="text" name="name" id="name" defaultValue={context.auth.first_name + ' ' + context.auth.last_name} className="form-control form-control-md" required placeholder="Full name" />
                                <div className="invalid-feedback">Please fill full name.</div>
                                <label>Full Name <strong className="text-danger">*</strong></label>
                            </div>
                        }

                        {/* Groups Details */}
                        {context.auth.group_details && <>
                            {/* <div className="d-flex flex-column mb-3 mt-32">
                                <label htmlFor="group_logo" className="maxw-200 mb-3 img-circle">
                                    <img src='../images/groups.png' className="rounded-circle mmwh-200" alt="Group Logo" />
                                </label> 
                            </div> */}
                            <div className="form-floating my-4">
                                <input type="text" name="group_name" id="group_name" defaultValue={context.auth.group_details && context.auth.group_details.group_name}  className="form-control form-control-md" placeholder="Group name" required readOnly/>
                                <div className="invalid-feedback">
                                    The Group name field is required.
                                </div>
                                <label>Group name <strong className="text-danger">*</strong></label>
                            </div>

                            <div className="form-floating mt-32 mb-4">
                                <input type="number" name="user_limit" id="user_limit" defaultValue={context.auth.group_details && context.auth.group_details.user_limit } className="form-control form-control-md" placeholder="Group name" required readOnly/>
                                <div className="invalid-feedback">
                                    The enrollment size field is required .
                                </div>
                                <label>Enrollment Size <strong className="text-danger">*</strong></label>
                            </div>

                            <div className="form-floating mt-32 mb-3">
                                <input type="text" name="course_type" id="course_type" defaultValue={context.auth.group_details && context.auth.group_details.course_abbr + ' (' + context.auth.group_details.course_term + ')' }  className="form-control form-control-md" placeholder="Course Name" required  readOnly/>
                                <div className="invalid-feedback">
                                    The Course type field is required .
                                </div>
                                <label>Course Name<strong className="text-danger">*</strong></label>
                            </div>

                            <div className="d-flex ">
                                <div className="form-floating w-100 mt-32">
                                    <input type="text" name="group_code" id="group_code" className="form-control form-control-md text-dim" placeholder="Group code" defaultValue={context.auth.group_details && context.auth.group_details.group_code } readOnly required />
                                    <div className="invalid-feedback">
                                        The Group code field is required .
                                    </div>
                                    <label>Group code</label>
                                </div>
                            </div>
                        </>
                        }

                        <div className="form-floating mt-32">
                            <input type="email" name="email" id="email" className="form-control form-control-md  mt-4" placeholder="Email" defaultValue={context.auth.email} disabled required />
                            <label>Email</label>
                        </div>

                    </div>
                </form>}
            </div>
        </div>
    )
}

export default AdminProfile
