import { useEffect, useContext, useState } from "react";
import { Context } from '../../components/Context';
import { fetchData, MY_ASSIGNMENT } from "../../components/Service";
import { PageNavigation } from "../../components/DataTable";
import { Link } from "react-router-dom";
import { format } from "date-fns/esm";
import { Popover } from "bootstrap";

function MyAssignments() {
    const [context] = useContext(Context)
    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    window.document.title = "My Assignments";

    useEffect(() => setCurrentPage(1), [])
    useEffect(() => {
        if (context && context.auth) {
            fetchData(`${MY_ASSIGNMENT}?page=${currentPage}`, 'GET', '', true, true, (res) => {
                if (res.records) {
                    setData(res.records ?? [])
                }
            });
        }
    }, [context, currentPage])

    useEffect(() => {
        Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
    })

    return (
        <>
            <section>
                <div className="container container-1180 py-5">
                    {context && context.auth &&
                        <div className="d-flex pb-4">
                            <svg className="icon mmwh-35 sm-mmwh-28 text-lime"><use href="#icon_subtract"></use></svg>
                            <h6 className="title ms-3 text-blue">Hello! {context.auth.first_name} {context.auth.last_name}</h6>
                        </div>}
                    <div className="row g-3">
                        <div className="col-lg-12 body-over_flowx">
                            <div className="d-lg-flex justify-content-between">
                                <div className="d-flex justify-content-between mb-4 mb-md-3 order-1">
                                    <h2 className="h3 fw-bold">My assignments</h2>
                                </div>
                            </div>

                            <div className="row">
                                {data && data.data && data.data.length > 0 && data.data.map((item, index) => {
                                    return (
                                        <div className="col-lg-3 mb-4" key={index}>
                                            <div className="card border-blue-hover rounded-3 assignment-expired1">
                                                <div className="card-body p-md-4">
                                                    <h4 className="fw-bold workbook-heading-smallsize ">
                                                        {item && item.title} <span>
                                                            <button type="button" className="btn border-0" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-template={`<div class='popover shadow' role='popover'><div class='popover-arrow'></div><div class='popover-inner py-3 px-3 fw-medium'>${item.instruction}<div class='row'><div class='col-sm-12'><div></div></div></div>`}><svg className="icon ts-1p5"><use href="#icon_query"></use></svg></button>
                                                        </span>
                                                    </h4>
                                                    <div>{item.workbook_count} Workbooks</div>
                                                    <div className={`fw-normal text-green`}>{item.complate_exam_attempt} Workbooks Completed</div>

                                                    <div className="fw-bold zoom-text">{format(new Date(item.deadline), 'MM/dd/yyyy hh:mm aa')}</div>

                                                    <div className={`fw-normal ${item.allow_late_submission === 'yes' ? 'text-green' : 'text-danger'}`}>{item.allow_late_submission === 'yes' ? 'Late submission allowed' : 'Late submission not allowed'}</div>

                                                    <div className="mt-4 d-flex">
                                                        <div className="col-6">
                                                            {(item.complate_exam_attempt === item.workbook_count && item.workbook_count > 0) && 
                                                                <button type="button" className="px-0 btn btn-success1 border-0" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-template={`<div class='popover shadow' role='popover'><div class='popover-arrow'></div><div class='popover-inner py-3 px-3 fw-medium'>Assignment Completed<div class='row'><div class='col-sm-12'><div></div></div></div>`}><svg className="icon ts-1p5 text-green"><use href="#icon_checkcircle"></use></svg></button>
                                                            }
                                                        </div>
                                                        <div className="col-6 text-end">
                                                        {item.workbook_count && (item.status === 'published' || item.allow_late_submission === 'yes') ? 
                                                            <Link to="/my-assignments/workbooks" state={item} className="btn btn-theme-primary w-70 w-sm-50" title="Start" href="#">Start</Link>
                                                        :   <button disabled className="btn btn-theme-primary w-70 w-sm-50" title="Start">Start</button>
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            {data && data.data && data.data.length === 0 && <div className="row mt-1"><h4 className="fw-bold my-3 text-danger text-center">Assignments not found.</h4></div>}

                            {data && data.total ? <PageNavigation active_page={data.current_page - 1} per_page={data.per_page} filter_recoards={data.total} setCurrentPage={setCurrentPage} /> : ''}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default MyAssignments