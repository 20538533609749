import { useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useEffect } from "react";

const Exam = (props) => {
    const [mode, setMode] = useState('practice');
    const [course, setCourse] = useState('');
    const [workbook, setWorkbook] = useState('');

    useEffect(() => {
        setCourse(props.data && props.data.course)
        setWorkbook(props.data && props.data.workbook)
        setMode(props.data && props.data && props.data.workbook && props.data && props.data.workbook.practice_questions_count && props.data.workbook.practice_questions_count > 0 ? 'practice' : 'exam')
    }, [props])

    const closeMode = () => {
        document.querySelector('#examModal [data-bs-dismiss="modal"]').click()
    }

    return (
        <div className="modal fade" id="examModal" tabIndex="-1" data-bs-backdrop="static">
            <div className="modal-dialog">
                <form className="modal-content border border-2 border-blue p-4 rounded-4">
                    <div className="modal-header border-0">
                        <h3 className="modal-title fw-bold text-blue">Practice or exam mode?</h3>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="col-sm-12">
                            <p>Choose a mode to complete this workbook.</p>
                        </div>
                        <div className="box d-sm-flex gap-3">
                            {workbook.practice_questions_count && workbook.practice_questions_count > 0 ? <>
                                <div className="box1 mmwh-200 smt-mmwh-100 border-blue-hover rounded-4  border-1 border py-2 px-3" onClick={() => setMode('practice')}>
                                    <div className="image d-flex justify-content-between">
                                        <div className="image1">
                                            <img src="/images/vector/fire.svg" className="img-fluid  mmwh-30 pb-2" alt="fire" />
                                        </div>
                                        <div className="checkbox">
                                            <label className="form-check form-check-custom mb-3">
                                                <input className="form-check-input form-check-blue" type="checkbox" readOnly checked={mode === 'practice'} />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="para">
                                        <h6 className=" h6 fw-bold fs-6">PRACTICE</h6>
                                        <p className="mb-0 fs-14 fw-bold">Check your answers and learn as you go. Build a streak for every correct answer.</p>
                                    </div>
                                </div>
                            </> : ''}

                            {workbook.exam_questions_count && workbook.exam_questions_count > 0 ? <>
                                <div className="box2 mmwh-200 smt-mmwh-100 border-blue-hover rounded-4  border-1 border py-2 px-3 mth-4" onClick={() => setMode('exam')}>
                                    <div className="image d-flex justify-content-between">
                                        <div className="image1">
                                            <img src="/images/vector/clock.svg" className="img-fluid mmwh-30 pb-2" alt="clock" />
                                        </div>
                                        <div className="checkbox">
                                            <label className="form-check form-check-custom mb-3">
                                                <input className="form-check-input form-check-blue" type="checkbox" readOnly checked={mode === 'exam'} />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="para">
                                        <h6 className=" h6 fw-bold fs-6">EXAM</h6>
                                        <p className="mb-0 fs-14 fw-bold">Complete under timed conditions then check your answers at the end.</p>
                                    </div>
                                </div>
                            </> : ''}
                        </div>
                    </div>
                    <div className="modal-footer border-0 d-flex justify-content-end">
                        <Link to={`/workbook/${_.kebabCase(workbook && workbook.title)}/${mode}`} state={{ action: 'begin', mode: mode, course: course, workbook: workbook, workbook_attempt_type: props.workbookAttemptType, assignment: props.assignment }} role="button" className="btn btn-theme-primary m-2" title="Continue" onClick={closeMode}>Continue</Link>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default Exam;