import _ from "lodash";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ExamButton from "./partials/ExamButton";
import ExamQuestionList from "./partials/ExamQuestionList";
import ExamTitle from "./partials/ExamTitle";
import { Clapping } from "../../components/MovingRocket";
import $ from 'jquery';
import { stopBackgroundScroll } from "../../components/CommonMethod";
import ExamQuestion from "./partials/ExamQuestion";
import { Context } from "../../components/Context";

const Review = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [course] = useState(location && location.state && location.state.course)
    const [workbook] = useState(location && location.state && location.state.workbook)
    const [workbookAttemptType] = useState((location && location.state && location.state.workbook_attempt_type && location.state.workbook_attempt_type === 'assignment') ? 'assignment' : 'workbook')
    const [questions] = useState(location && location.state && location.state.questions)
    const [questions_details] = useState(location && location.state && location.state.questions_details)
    const [answer_details] = useState(location && location.state && location.state.answer_details)
    const [reviewStart, setReviewStart] = useState(false)
    const [correctAnswer, setCorrectAnswer] = useState(0)
    const [activeQuestion, setActiveQuestion] = useState(1);
    const [animation, setAnimation] = useState(false)
    const [sqCount, setSqCount] = useState(0);
    const mode = 'review'
    const [context] = useContext(Context)

    const navigateFromThisPage = () => {
        if(context && context.auth && context.auth.user_type === '2'){
            if (location && location.state && location.state.assignment && workbookAttemptType === 'assignment') {
                navigate('/my-assignments/workbooks', { state: { ...location.state.assignment } });
            } else {
                navigate('/my-workbooks');
            }
        }else{
            navigate('/cms/assigned-workbook');
        }
    }

    window.document.title = "Workbook Review";

    const restartExam = () => {
        let mode = location.state.mode
        navigate(`/workbook/${_.kebabCase(workbook && workbook.title)}/${mode}`, { state: { action: mode === 'exam' ? 'start' : 'restart', mode: mode, course: course, workbook: workbook, workbook_attempt_type: workbookAttemptType, assignment: location.state.assignment } })
    }

    const quitExam = () => {
        navigateFromThisPage()
    }

    const filterToggle = () => {
        document.getElementById('mobileViewBox').classList.toggle('active');
        stopBackgroundScroll("mobileViewBox");
    }

    useEffect(() => {
        if (questions && questions.length === activeQuestion - 1) {
            setActiveQuestion(1)
            setReviewStart(false)
        }
    }, [activeQuestion, questions]);

    useEffect(() => {
        if (!location.state) {
            navigateFromThisPage()
        }
        if (location.state && location.state.clapping_time && location.state.clapping_time > Date.now()) {
            setAnimation(true)
            setTimeout(() => $("#result-bgclap").fadeOut('slow'), 1500)
        }
        let correct = _.filter(questions_details, function (question) { return question.correct });
        setCorrectAnswer(correct.length)
        let ans_sq_count = { correct: 0, tmp: 0 }
        for (let i in questions_details) {
            if (questions_details[i].correct) {
                if (ans_sq_count.tmp === ans_sq_count.correct) {
                    ans_sq_count = { correct: ans_sq_count.tmp + 1, tmp: ans_sq_count.tmp + 1 }
                } else {
                    ans_sq_count = { ...ans_sq_count, tmp: ans_sq_count.tmp + 1 }
                }
            } else {
                ans_sq_count = { ...ans_sq_count, tmp: 0 }
            }
        }
        setSqCount(ans_sq_count.correct)
        // eslint-disable-next-line
    }, [location, questions_details, navigate])

    return (<>
        {location.state &&
            <>
                <nav className="navbar navbar-expand-lg navbar-front bg-white position-sticky top-0 zindex-100 shadow-none" >
                    <div className="container py-4">
                        <ExamTitle workbook={workbook} course={course} />
                        <div className="col-lg-4 d-lg-inline d-none text-end" id="asideMobile">
                            <ExamButton startExam={true} restartExam={restartExam} review={true} reviewStart={reviewStart} setReviewStart={setReviewStart} />
                        </div>
                    </div>
                </nav>

                {reviewStart &&
                    <div className="container">
                        <div className="row g-4 exam-sidebar justify-content-end">
                            <div className="col-lg-3 pt-3">
                                <div className="aside-filter" id="mobileViewBox">
                                    <div className="aside-innerfilter">
                                        <div className="d-flex d-lg-none align-items-center justify-content-between pb-3">
                                            <button type="button" className="btn" onClick={() => filterToggle()} title="Close"><svg className="icon ts-1p5"><use href="#icon_clear"></use></svg></button>
                                        </div>
                                        <ExamQuestionList mode={mode} questions={questions} activeQuestion={activeQuestion} setActiveQuestion={setActiveQuestion} prevQuestionDetails={questions_details} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                <div className="container">
                    {reviewStart ?
                        <div className="row g-4 mt-3">
                            <div className="col-lg-9 mt-0">
                                <ExamQuestion mode={mode} questions={questions} activeQuestion={activeQuestion} setActiveQuestion={setActiveQuestion} questions_details={questions_details} answer_details={answer_details} />
                            </div>
                        </div>
                        :
                        <div className="row mt-5">
                            <div className=" col-lg-2 position-relative d-md-inline d-none">
                                <img src="/images/Male1.png" className="position-absolute" alt="" style={{ top: ' 16%', left: '-66%' }} />
                            </div>
                            <div className="col-sm-12 col-lg-10 ">
                                <div className="question border p-4 p-md-5 rounded-4 border-blue border-3">
                                    <div className="row d-flex align-items-center justify-content-center">
                                        <div className="col-sm-12 col-lg-5 text-center mb-5">
                                            <h2 className="text-blue my-3 ">Well done!</h2>
                                            <div className="mt-4">
                                                <img src={parseInt((correctAnswer / questions.length) * 3) > 0 ? '/images/start2.png' : '/images/start1.png'} className="img-fluid me-2" alt="star" />
                                                <img src={parseInt((correctAnswer / questions.length) * 3) > 1 ? '/images/start2.png' : '/images/start1.png'} className="img-fluid me-2" alt="star" />
                                                <img src={parseInt((correctAnswer / questions.length) * 3) > 2 ? '/images/start2.png' : '/images/start1.png'} className="img-fluid me-2" alt="star" />
                                            </div>
                                            <div className="mt-5">
                                                <button type="button" className="btn btn-outline-secondary mb-3 mt-4" title="Review my answer" onClick={() => setReviewStart(true)}>Review my answers</button>
                                            </div>
                                            <div className="d-sm-inline d-lg-none mb-5">
                                                <ExamButton startExam={true} restartExam={restartExam} quitExam={() => quitExam()} review={true} reviewStart={reviewStart} setReviewStart={setReviewStart} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-lg-7">
                                            <div className=" align-items-center border border-2 rounded-4 border-blue-hover d-md-flex d-sm-flex-block gap-2 p-2 mb-4 " style={{ position: 'relative' }}>
                                                <img src="/images/correct.png" className="img-fluid " style={{ position: 'absolute', top: '-18%', left: '10%' }} alt="correct" />
                                                <div className="circle-progress ratio ratio-1x1 maxw-80">
                                                    <svg viewBox="0 0 36 36">
                                                        <path stroke="#eeeeee" strokeWidth="2" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                                        <path strokeWidth="3" stroke="#1E90FF" id="fillStroke" strokeDasharray={`${(correctAnswer * 100) / questions.length}, 100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                                                        <text x="18" y="21.2" fill="#1E90FF">{_.isInteger((correctAnswer * 100) / questions.length) ? ((correctAnswer * 100) / questions.length) + '%' : ((correctAnswer * 100) / questions.length).toFixed(2) + '%'}</text>
                                                    </svg>
                                                </div>
                                                <div>
                                                    <p>You answered {correctAnswer} out of {questions.length} questions correctly.</p>
                                                </div>
                                            </div>

                                            <div className="border border-2 rounded-4 border-blue-hover p-4 mb-4 " style={{ position: 'relative' }}>
                                                <img src="/images/time-taken.png" className="img-fluid" style={{ position: 'absolute', top: '-18%' }} alt="time" />
                                                <p className="mb-0 text-blue fs-2 fw-bold">{location.state && location.state.total_time}</p>
                                            </div>

                                            {sqCount > 2 &&
                                                <div className=" d-md-flex d-sm-flex-block  gap-2 border border-2 rounded-4 border-blue-hover p-4" style={{ position: 'relative' }}>
                                                    <img src="/images/best-streak.png" className="img-fluid" style={{ position: 'absolute', top: '-18%' }} alt="best" />
                                                    <img src="/images/fire.png" className="img-fluid" alt="fire" />
                                                    <p>You got {sqCount} correct answers in a row</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {animation && <Clapping />}
                </div>

                <div className="modal fade" id="restartModal" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content border border-2 border-blue p-2 rounded-4">
                            <div className="modal-header border-0">
                                <h3 className="fw-bold text-blue">Restart this Workbook</h3>
                            </div>
                            <div className="modal-body border-0">
                                <p>Your progress so far will not be saved.</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-outline-default btn-lg me-2" title="Keep Working" data-bs-dismiss="modal">Keep Working</button>
                                <button className="btn btn-yellow btn-lg me-2" title="Restart" data-bs-dismiss="modal" onClick={restartExam}>Restart</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="quitModal" tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content border border-2 border-blue p-2 rounded-4">
                            <div className="modal-header border-0">
                                <h3 className="fw-bold text-blue">Quit this Workbook</h3>
                            </div>
                            <div className="modal-body">
                                <p>Your progress will be saved. You will resume from the last question you answered the next time you open this workbook.</p>
                            </div>
                            <div className="modal-footer border-0">
                                <button className="btn btn-outline-default btn-lg me-2" title="Keep Working" data-bs-dismiss="modal">Keep Working</button>
                                <button className="btn btn-danger btn-lg me-2" title="Quit" data-bs-dismiss="modal" onClick={() => quitExam()}>Quit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
    </>)
}

export default Review
