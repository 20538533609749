import { useEffect, useState } from "react";
import { fetchData, DROPDOWN_DATA } from "../components/Service"

const CountryDropDown = (props) => {

    const [choice, setChoice] = useState(props.selected)
    const [country, setCountry] = useState({})

    useEffect(() => {
        fetchData(DROPDOWN_DATA + '?type=country', 'GET', '', true, false, (res) => {
            if (res.records) {
                setCountry(res.records.country)
            }
        })
    }, [props.selected]);

    return(
        <>
            <select value={choice} onChange={(e) => setChoice(e.target.value)} className="form-select form-select-md maxw-300" name="country" id="country" required>
                {country && country.length > 0 ? country.map((country, index) => {
                    return (
                        <option key={index} value={country.id} >{country.name}</option>
                    )
                }) : <option value="">Select Country</option>} 
            </select>
        </>
    )

}
export default CountryDropDown