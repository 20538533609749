import React, { useContext, useEffect, useState } from "react";
import { Context } from '../../components/Context';
import { Link } from "react-router-dom";
import { fetchData, GET_UPDATE_STUDENT_DETAILS, ASSET_ENDPOINT, UPDATE_GUARDAIAN_DETAILS, CHANGE_PASSWORD, PAYMENT_DETAILS } from "../../components/Service";
import { ButtonSpinner, validateForm, initialFormState, blobToBase64, noImageHandle, removeMsg, PasswordShow, __echoText } from "../../components/CommonMethod";
import { now } from "lodash";
import { DataTable } from "../../components/DataTable";
import { format } from "date-fns";

function UserProfile() {
    window.document.title = "My Account";
    const [context] = useContext(Context)
    const [userDetails, setUserDetails] = useState('')
    const [refresh, setRefresh] = useState(now)
    const [showProfile, setShowProfile] = useState(true)
    const [paymentData, setPaymentData] = useState(false)

    useEffect(() => {
        fetchData(GET_UPDATE_STUDENT_DETAILS, 'GET', '', true, false, (res) => {
            if (res.records) {
                setUserDetails(res.records)
            }
        })
    }, [context, refresh])


    return (<>

        <div className="container container-1180 py-5">
            <div className="col-sm-12 ">
                <div className="d-flex ">
                    <svg className="icon mmwh-35 sm-mmwh-28 text-lime"><use href="#icon_subtract"></use></svg>
                    <h6 className="title ms-3">{userDetails ? `Hello! ${userDetails.first_name} ${userDetails.last_name}` : ''}</h6>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="nav flex-column user-box mb-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <div className="head-profilename">
                                <h6 className="fw-bold subhead">My Account</h6>
                            </div>
                            <button className="nav-link active" id="my-profile-tab" data-bs-toggle="pill" data-bs-target="#my-profile" type="button" role="tab" aria-controls="my-profile" aria-selected="true" onClick={() => setShowProfile(true)}>My Profile</button>
                            <button className="nav-link" id="parent-details-tab" data-bs-toggle="pill" data-bs-target="#parent-details" type="button" role="tab" aria-controls="parent-details" aria-selected="false" onClick={() => setShowProfile(false)}>Parent Details</button>
                            <Link to="/my-workbooks" className="text-decoration-none nav-link" style={{ color: '#3c3c3c' }}>My Workbooks</Link>
                            { userDetails && !userDetails.school_group_code && 

                            <button className="nav-link" id="my-payment-tab" data-bs-toggle="pill" data-bs-target="#my-payment" type="button" role="tab" aria-controls="my-payment" aria-selected="false" onClick={() => setPaymentData(true)}>My Payment</button>
                            }
                            <button className="nav-link" id="change-password-tab" data-bs-toggle="pill" data-bs-target="#change-password" type="button" role="tab" aria-controls="change-password" aria-selected="false">Change Password</button>

                        </div>
                    </div>
                    <div className="col-lg-8 col-12">
                        <div className="tab-content account-details" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="my-profile" role="tabpanel" aria-labelledby="my-profile-tab">
                                {showProfile && <MyProfile data={userDetails} setRefresh={setRefresh} />}
                            </div>
                            <div className="tab-pane fade" id="parent-details" role="tabpanel" aria-labelledby="parent-details-tab">
                                {!showProfile && <ParentDetails data={userDetails} setRefresh={setRefresh} user_id={context && context.auth && context.auth.id} />}
                            </div>
                            <div className="tab-pane fade" id="my-payment" role="tabpanel" aria-labelledby="my-payment-tab">
                            { userDetails && !userDetails.school_group_code && 
                                <MyPayment paymentData={paymentData} />
                            }
                            </div>
                            <div className="tab-pane fade" id="change-password" role="tabpanel" aria-labelledby="change-password-tab">
                                <ChangePassword />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default UserProfile



export const MyProfile = (props) => {
    const [userDetails, setUserDetails] = useState('')
    const [tinyLoader, setTinyLoader] = useState(false);
    const [imageb64, setImageb64] = useState('')

    const saveStudentDetails = (e) => {
        let formData = new FormData(document.getElementById('userProfileFrom'))
        if (validateForm(e, 'userProfileFrom')) {
            setTinyLoader(true);
            fetchData(GET_UPDATE_STUDENT_DETAILS, 'POST', formData, true, true, (res) => {
                setTinyLoader(false);
                if (res.success) {
                    removeMsg()
                    props.setRefresh(now)
                }
            }, '', false, 'userProfileFrom');
        }
    }

    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        setImageb64(imgb64)
    }

    useEffect(() => {
        setUserDetails(props.data)
    }, [props])

    return (
        <form className="needs-validation" id="userProfileFrom" noValidate autoComplete="off">
            <h5 className="subhead ms-2">My Profile</h5>
            <div className="d-flex align-items-center">
                <div className="d-flex flex-column right-item w-100">
                    <label htmlFor="user_image" className="mmwh-160 rounded-circle overflow-hidden border border-4 border-green mb-4 user-profile" title="Change Profile">
                        <img src={imageb64 ? imageb64 : userDetails && userDetails.user_image ? ASSET_ENDPOINT + userDetails.user_image : '../images/user-avatar.png'} className="rounded-circle mmwh-150" alt="User Logo" onError={noImageHandle} title="Change profile" />
                    </label>
                    <input type="file" name="user_image" id="user_image" accept=".jpg,.jpeg,.png" className="position-absolute end-0 start-0 opacity-0 " onChange={(e) => imageSet(e)} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 my-3">
                    <div className="form-floating">
                        <input type="hidden" name="student_id" defaultValue={userDetails && userDetails.id} />
                        <input type="text" name="first_name" id="first_name" className="form-control form-control-md" placeholder="First name" defaultValue={userDetails && userDetails.first_name} required />
                        <div className="invalid-feedback">
                            The first name field is required.
                        </div>
                        <label>First Name <span className="text-danger">*</span></label>
                    </div>
                </div>

                <div className="col-lg-6 my-3">
                    <div className="form-floating">
                        <input type="text" name="last_name" id="last_name" className="form-control form-control-md" placeholder="Last name" defaultValue={userDetails && userDetails.last_name} required />
                        <div className="invalid-feedback">
                            The last name field is required.
                        </div>
                        <label>Last Name <span className="text-danger">*</span></label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 my-3">
                    <div className="form-floating">
                        <input type="text" name="email" id="email" className="form-control form-control-md" placeholder="Last name" defaultValue={userDetails && userDetails.email} disabled />
                        <label> Email </label>
                    </div>
                </div>

                <div className="col-lg-6 my-3">
                    <div className="form-floating">
                        <input type="text" name="course" id="course" className="form-control form-control-md" placeholder="Course" defaultValue={userDetails && userDetails.course_term} disabled />
                        <label> Course</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 my-3">
                    <div className="form-floating">
                        <input type="text" name="level" id="level" className="form-control form-control-md" placeholder="Level" defaultValue={userDetails && userDetails.levels_name} disabled />
                        <label> Level </label>
                    </div>
                </div>

                <div className="col-lg-6 my-3">
                    <div className="form-floating">
                        <input type="text" name="group_name" id="group_name" className="form-control form-control-md" placeholder="Group Name" defaultValue={userDetails && userDetails.group_name} disabled />
                        <label> Group Name </label>
                    </div>
                </div>
            </div>
            <div className="col my-2">
                <div className="d-flex justify-content-end">
                    <button type="button" className="btn btn-theme-primary btn-lg px-5 py-2 fs-4" onClick={(e) => saveStudentDetails(e)} disabled={tinyLoader}>
                        <ButtonSpinner load={tinyLoader} btnName="Save" />
                    </button>
                </div>
            </div>
        </form>
    )
}



export const ParentDetails = (props) => {
    const [userDetails, setUserDetails] = useState('')
    const [tinyLoader, setTinyLoader] = useState(false);

    const SaveGuardianDetails = (e) => {
        let formdata = new FormData(document.getElementById('guardianDetailsForm'));
        if (validateForm(e, 'guardianDetailsForm')) {
            setTinyLoader(true);
            fetchData(UPDATE_GUARDAIAN_DETAILS, 'POST', formdata, true, true, (res) => {
                setTinyLoader(false);
                if (res.success) {
                    removeMsg()
                }
            }, '', false, 'guardianDetailsForm');
        }
    }


    useEffect(() => {
        setUserDetails(props.data)
    }, [props])

    return (
        <form className="needs-validation" id="guardianDetailsForm" noValidate autoComplete="off">
            <h5 className="subhead ms-2">Parent Details</h5>
            <div className="row">
                <div className="col-lg-6 my-3">
                    <input type="hidden" name="id" defaultValue={props.user_id} />
                    <div className="form-floating">
                        <input type="text" name="first_name" id="first_name" className="form-control form-control-md" placeholder="Guardian’s First name" defaultValue={userDetails && userDetails.p_first_name} required />
                        <div className="invalid-feedback">
                            The first name field is required.
                        </div>
                        <label>Guardian’s first name <span className="text-danger">*</span></label>
                    </div>
                </div>
                <div className="col-lg-6 my-3">
                    <div className="form-floating">
                        <input type="text" name="last_name" id="last_name" className="form-control form-control-md" placeholder="Guardian’s Last name" defaultValue={userDetails && userDetails.p_last_name} required />
                        <div className="invalid-feedback">
                            The last name field is required.
                        </div>
                        <label>Guardian’s last name <span className="text-danger">*</span></label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 my-3">
                    <div className="form-floating">
                        <input type="text" name="relation" id="relation" className="form-control form-control-md" placeholder="Relationship with student" defaultValue={userDetails && userDetails.relation_ship} required />
                        <div className="invalid-feedback">
                            The relationship with student field is required.
                        </div>
                        <label> Relationship with student <span className="text-danger">*</span></label>
                    </div>
                </div>
            </div>
            <div className="col my-2">
                <div className="d-flex justify-content-end">
                    <button type="button" className="btn btn-theme-primary btn-lg px-5 py-2 fs-4" onClick={(e) => SaveGuardianDetails(e)} disabled={tinyLoader}>
                        <ButtonSpinner load={tinyLoader} btnName="Save" />
                    </button>
                </div>
            </div>
        </form>
    )
}

export const ChangePassword = () => {
    const [tinyLoader, setTinyLoader] = useState(false);

    const savePassword = (e) => {
        let data = new FormData(document.getElementById('changePassword'));

        if (validateForm(e, 'changePassword')) {
            setTinyLoader(true)

            fetchData(CHANGE_PASSWORD, 'POST', data, true, true, (res) => {
                setTinyLoader(false)
                if (res.success) {
                    initialFormState('changePassword')
                }
            })
        }
    }
    return (
        <>
            <form className="needs-validation" id="changePassword" noValidate>
                <h5 className="subhead ms-2">Change Password</h5>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-floating mt-3">
                            <input type="password" placeholder="Current Password" name="current_password" id="current_password" className="form-control form-control-md " required />
                            <div className="invalid-feedback">The current password field is required.</div>
                            <label>Current Password <strong className="text-danger">*</strong></label>
                        </div>
                        <div className="form-floating mt-32">
                            <input type="password" placeholder="New Password" className="form-control form-control-md mt-4" name="new_password" id="new_password" required />
                            <div className="invalid-feedback">The new password field is required.</div>
                            <label>New Password <strong className="text-danger">*</strong></label>
                        </div>
                        <div className="form-floating password-wrapper mt-32">
                            <input type="password" placeholder="Confirm Password" id="confirm_password" name="confirm_password" className="form-control form-control-md mt-4" autoComplete="current-password" required />
                            <button type="button" className="btn" onClick={(e) => PasswordShow(e, 'confirm_password')}>
                                <svg className="icon pe-none"><use href="#icon_eye"></use></svg>
                            </button>
                            <div className="invalid-feedback">The confirm password and new password must match.</div>
                            <label>Confirm Password <strong className="text-danger">*</strong></label>
                        </div>
                    </div>
                </div>
                <div className="col my-4">
                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-theme-primary btn-sm px-5 py-2 fs-4" onClick={(e) => savePassword(e)} disabled={tinyLoader}>
                            <ButtonSpinner load={tinyLoader} btnName="Save" />
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
}


export const MyPayment = (props) => {

    const payment = {
        id: 'payment',
        orderby: [3, 'desc'],
        length: 5,
        fetchurl: PAYMENT_DETAILS,
        columns: [
            { data: 'transaction_id', name: 'transaction_id', title: 'TRANSACTION ID', width: 50},
            { data: 'price', name: 'price', title: 'PRICE' },
            { data: 'payment_method', name: 'payment_method', title: 'PAYMENT METHOD' },
            { data: 'payment_date', name: 'payment_date', title: 'PAYMENT DATE', searching: false },
            { data: 'status', name: 'status', title: 'STATUS' },
        ]
    }


    return (
        <>
            <h5 className="subhead ms-2">My Payment</h5>
            <div className="row">
                <div className="col-sm-12">
                    <DataTable
                        execute={props.paymentData} dt={payment}
                        edit_column={{
                            price: (records) => __echoText(<span className="text-nowrap">{records.currency_type} {records.price}</span>),
                            payment_date: (records) => __echoText(`${format(new Date(records.payment_date), 'MM/dd/yyyy')}`),
                        }}
                    />
                </div>
            </div>
        </>
    );
}