import { Routes, Route, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';

import { Context } from './Context';
import { fetchData, GET_AUTH_USER_INFO } from './Service';

import Frontend, { Backend, Blank } from "./Layout";

import Home from '../modules/Page/Home';
import AboutUs from '../modules/Page/AboutUs';
import ContactUs from '../modules/Page/ContactUs';
import TermsOfUse from '../modules/Page/TermsOfUse';
import PrivacyPolicy from '../modules/Page/PrivacyPolicy';
import ErrorPage from '../modules/Page/ErrorPage';

import Dashboard from "../modules/Dashboard/Dashboard";
import Analytics from "../modules/Analytics/Analytics";
import Currency from "../modules/Currency/Currency";

import Users from "../modules/User/Users";
import CreateUser from "../modules/User/CreateUser";
import CreateNewAdmin from "../modules/User/CreateNewAdmin";
import AdminProfile from "../modules/User/AdminProfile";

import Groups from "../modules/Group/Groups";
import CreateGroup from "../modules/Group/CreateGroup";
import ManageGroup from "../modules/Group/ManageGroup";

import Listings from "../modules/Listing/Listings";
import CreateListing from "../modules/Listing/CreateListing";

import ManageCourses from "../modules/Course/ManageCourses";
import CreateCourse from "../modules/Course/CreateCourse";

import Topic from "../modules/Topic/Topic";
import Section from "../modules/Section/Section";

import GetCKImages from '../modules/CKEditor/getImages';

import MyWorkbooks from '../modules/MyWorkbook/MyWorkbooks';

import ManageWorkbooks from "../modules/Workbook/ManageWorkbooks";
import CreateWorkbook from "../modules/Workbook/CreateWorkbook";

import WebsiteContent from "../modules/WebsiteContent/WebsiteContent";
import HomePage from "../modules/WebsiteContent/HomePage";
import ShopPage from "../modules/WebsiteContent/Shop";
import AboutUsPage from "../modules/WebsiteContent/AboutUsPage";
import Checkout from "../modules/WebsiteContent/Checkout";

import Sales from "../modules/Sales/Sales";
import SalesDetails from "../modules/Sales/SalesDetails";
import Bounce from "../modules/Sales/Bounce";

import SocailMedia from "../modules/WebsiteContent/SocialMedia";
import TermAndCondition from "../modules/WebsiteContent/TermAndCondition";
import WorkbookSection from "../modules/WebsiteContent/WorkbookSection";

import SignIn from "../modules/Auth/SignIn";
import SignUp from '../modules/Auth/SignUp';
import ChangePassword from "../modules/Auth/ChangePassword";
import VerifyEmail from "../modules/Auth/VerifyEmail";
import ResetPassword from "../modules/Auth/ResetPassword";

import Enquiry from "../modules/Enquiry/Enquiry";

import Setting from "../modules/Settings/Setting";

import ForgetPassword from "../modules/Auth/ForgetPassword";
import UserProfile from "../modules/User/UserProfile";
import UserSetting from "../modules/User/UserSetting";
import Exam from "../modules/Exam/Exam";
import CheckOut from "../modules/Checkout/Checkout";
import ProductList from "../modules/shop/ProductList";
import Product from "../modules/shop/Product";
import ProductDetails from "../modules/shop/ProductDetails";
import EndCheckout from "../modules/Checkout/EndCheckout";
import CreateTeacher from "../modules/Teacher/CreateTeacher";
import AssignWorkBook from "../modules/Group/AssignWorkbook";
import Review from "../modules/Exam/Review";
import GroupSection from "../modules/GroupSection/GroupSection";
import CreateGroupSection from "../modules/GroupSection/CreateGroupSection";
import ManageAssignment from "../modules/Assignment/ManageAssignment";
import CreateAssignment from "../modules/Assignment/CreateAssignment";
import MyAssignments from "../modules/MyAssignment/MyAssignments";
import AssignmentWorkbook from "../modules/MyAssignment/AssignmentWorkbook";
import AssignmentAnalytics from "../modules/Assignment/AssignmentAnalytics";
import UsersAnalytics from "../modules/Assignment/UsersAnalytics";
import UserAssignment from "../modules/Assignment/UserAssignment";
import SettingData from "../modules/Settings/SettingData";
import Authenticate from "../modules/Auth/Authenticate";
import { isGuest, loginGuest } from "./GuestHelper";
import ReviewPerfoemance from "../modules/Exam/ReviewPerfoemance";
import WorkbookAnalytics from "../modules/Analytics/Workbook/WorkbookAnalytics";
import FullReview from "../modules/WorkbookReview/FullReview";
import ClassReview from "../modules/WorkbookReview/ClassReview";
import StudentReview from "../modules/WorkbookReview/StudentReview";
import ModuleReview from "../modules/WorkbookReview/ModuleReview";
import IndividualStudentReview from "../modules/WorkbookReview/IndividualStudentReview";
import WorkbookReview from "../modules/WorkbookReview/WorkbookReview";
import QuestionReview from "../modules/WorkbookReview/QuestionReview";

const Routing = () => {
    const { pathname } = useLocation();
    const guest = localStorage.getItem('waae-token') ? false : true;
    const [context, setContext] = useState('');
    const [token] = useState(localStorage.getItem('waae-token'));

    useEffect(() => {
        if (token) {
            fetchData(GET_AUTH_USER_INFO, 'GET', '', true, false, (res) => {
                if (res.records.access_token) {
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.records
                    }))
                } else {
                    localStorage.removeItem("waae-token");
                    localStorage.removeItem('waae-admin-token')
                }
            });
        } else {
            if (!isGuest()) {
                loginGuest()
                setContext(null)
            }
        }
    }, [token]);

    useEffect(() => {
        let allPopover = document.querySelectorAll('.popover[role="popover"]');
        [...allPopover].forEach(function (ele) {
            ele.remove();
        });

        document.body.onscroll = function () {
            var myTimeout = 0;
            let allPopoverZ = document.querySelectorAll('.popover[role="popover"]');
            if (allPopoverZ) {
                clearTimeout(myTimeout);
                myTimeout = setTimeout(() => {
                    [...allPopoverZ].forEach(function (ele) {
                        ele.remove();
                    });
                }, 300);
            };
        };
    }, [pathname]);

    return (
        <Context.Provider value={[context, setContext]} >
            <Routes>
                <Route path="/editor/" exact caseSensitive={false} element={<Blank />}>
                    <Route path="get" caseSensitive={false} element={<GetCKImages />} />
                </Route>
                {/* Student, Teacher and Group Admin's common route */}
                {context && context.auth && (context.auth.user_type === '2' || context.auth.user_type === '4' || context.auth.user_type === '5') &&
                    <Route>
                        <Route path="/workbook/:course_name/practice" caseSensitive={false} element={<Exam />} />
                        <Route path="/workbook/:course_name/exam" caseSensitive={false} element={<Exam />} />
                        <Route path="/workbook/:course_name/review-performance" caseSensitive={false} element={<ReviewPerfoemance />} />
                        <Route path="/workbook/:course_name/review" caseSensitive={false} element={<Review />} />
                    </Route>

                }
                <Route path="/" exact caseSensitive={false} element={<Frontend />}>
                    {/* Frontend route */}
                    <Route path="/" caseSensitive={false} element={<Home />} />
                    <Route path="/home" caseSensitive={false} element={<Home />} />
                    <Route path="/about-us" caseSensitive={false} element={<AboutUs />} />
                    <Route path="/contact-us" caseSensitive={false} element={<ContactUs />} />
                    <Route path="/privacy-policy" caseSensitive={false} element={<PrivacyPolicy />} />
                    <Route path="/terms-of-use" caseSensitive={false} element={<TermsOfUse />} />
                    <Route path="/sign-up" caseSensitive={false} element={<SignUp />} />
                    <Route path="/shop" caseSensitive={false} element={<Product />} />
                    <Route path="/shop-list" caseSensitive={false} element={<ProductList />} />
                    <Route path="/product-details" caseSensitive={false} element={<ProductDetails />} />

                    {/* Guest's route */}
                    {guest && <>
                        <Route path="checkout/" caseSensitive={false} element={<CheckOut />} />
                    </>}

                    {/* Student's route */}
                    {context && context.auth && context.auth.user_type === '2' &&
                        <Route>
                            <Route path="/my-account" caseSensitive={false} element={<UserProfile />} />
                            <Route path="/user-setting" caseSensitive={false} element={<UserSetting />} />
                            <Route path="checkout/" caseSensitive={false} element={<CheckOut />} />
                            <Route path="checkout-successful/" caseSensitive={false} element={<EndCheckout />} />
                            <Route path="/my-workbooks" caseSensitive={false} element={<MyWorkbooks />} />
                            <Route path="/my-assignments" caseSensitive={false} element={<MyAssignments />} />
                            <Route path="/my-assignments/workbooks" caseSensitive={false} element={<AssignmentWorkbook />} />
                            <Route path="*" caseSensitive={false} element={<ErrorPage />} />
                        </Route>
                    }
                </Route>

                {/** CMS Route  */}
                {context && context.auth && context.auth.user_type !== '2' &&
                    <Route path="/cms/" exact caseSensitive={false} element={<Backend />}>
                        {context.auth.user_type === '1' || context.auth.user_type === '3' ?
                            <Route path="" caseSensitive={false} element={<Dashboard />} />
                            :
                            <Route path="" caseSensitive={false} element={<Users />} />
                        }

                        {/* Permission Based route */}
                        {context.auth.permission && context.auth.permission.includes('dashboard') && <>
                            <Route path="dashboard" caseSensitive={false} element={<Dashboard />} />
                        </>}

                        {context.auth.permission && context.auth.permission.includes('analytics') && <>
                            <Route path="analytics" caseSensitive={false} element={<Analytics />} />
                        </>}

                        {context.auth.permission && context.auth.permission.includes('currency') && <>
                            <Route path="currency" caseSensitive={false} element={<Currency />} />
                        </>}

                        {context.auth.permission && context.auth.permission.includes('users') && <><Route path="groups" caseSensitive={false} element={<Groups />} />
                            <Route path="create-group" caseSensitive={false} element={<CreateGroup />} />
                            <Route path="update-group" caseSensitive={false} element={<CreateGroup />} />
                            <Route path="manage-group" caseSensitive={false} element={<ManageGroup />} />
                        </>}

                        {context.auth.permission && context.auth.permission.includes('workbooks') && <>
                            <Route path="manage-workbooks" caseSensitive={false} element={<ManageWorkbooks />} />
                            <Route path="create-workbook" caseSensitive={false} element={<CreateWorkbook />} />
                            <Route path="update-workbook" caseSensitive={false} element={<CreateWorkbook />} />
                            <Route path="workbook-analytics" caseSensitive={false} element={<WorkbookAnalytics />} />
                        </>}

                        {context.auth.permission && context.auth.permission.includes('listings') && <>
                            <Route path="listings" caseSensitive={false} element={<Listings />} />
                            <Route path="create-listing" caseSensitive={false} element={<CreateListing />} />
                            <Route path="update-listing" caseSensitive={false} element={<CreateListing />} />
                            <Route path="manage-courses" caseSensitive={false} element={<ManageCourses />} />
                            <Route path="create-course" caseSensitive={false} element={<CreateCourse />} />
                            <Route path="update-course" caseSensitive={false} element={<CreateCourse />} />
                            <Route path="topic" caseSensitive={false} element={<Topic />} />
                            <Route path="section" caseSensitive={false} element={<Section />} />
                        </>}

                        {context.auth.permission && context.auth.permission.includes('website-content') && <>
                            <Route path="website-content" caseSensitive={false} element={<WebsiteContent />} />
                            <Route path="about-us-page" caseSensitive={false} element={<AboutUsPage />} />
                            <Route path="social-media" caseSensitive={false} element={<SocailMedia />} />
                            <Route path="home-page" caseSensitive={false} element={<HomePage />} />
                            <Route path="shop-page" caseSensitive={false} element={<ShopPage />} />
                            <Route path="wc-terms-and-conditions" caseSensitive={false} element={<TermAndCondition />} />
                            <Route path="checkout-page" caseSensitive={false} element={<Checkout />} />
                            <Route path="sales" caseSensitive={false} element={<Sales />} />
                            <Route path="order-details" caseSensitive={false} element={<SalesDetails />} />
                            <Route path="bounce-rate" caseSensitive={false} element={<Bounce />} />
                            <Route path="workbook-page" caseSensitive={false} element={<WorkbookSection />} />
                            <Route path="file-manager" caseSensitive={false} element={<Setting />} />
                        </>}

                        {context.auth.permission && context.auth.permission.includes('customer-enquiries') && <>
                            <Route path="customer-enquiries" caseSensitive={false} element={<Enquiry />} />
                        </>}

                        {/* Super Admin route */}
                        {context.auth.user_type === '1' &&
                            <Route path="settings/" caseSensitive={false} element={<SettingData />} />
                        }

                        {/* Super Admin, Teacher and Partial Admin's common route */}
                        {(context.auth.user_type === '1' || context.auth.user_type === '4' || (context.auth.user_type === '3' && context.auth.permission && context.auth.permission.includes('users'))) && <>
                            <Route path="create-user" caseSensitive={false} element={<CreateUser />} />
                            <Route path="update-user" caseSensitive={false} element={<CreateUser />} />
                        </>}

                        {/* Super Admin, Group Admin's and Partial Admin's common route */}
                        {(context.auth.user_type === '1' || context.auth.user_type === '5' || (context.auth.user_type === '3' && context.auth.permission && context.auth.permission.includes('users'))) && <>
                            <Route path="update-teacher" caseSensitive={false} element={<CreateTeacher />} />
                        </>}

                        {/* Group Admin's route */}
                        {context.auth.user_type === '5' && <>
                            <Route path="group-section" caseSensitive={false} element={<GroupSection />} />
                            <Route path="create-group-section" caseSensitive={false} element={<CreateGroupSection />} />
                            <Route path="update-group-section" caseSensitive={false} element={<CreateGroupSection />} />
                            <Route path="create-teacher" caseSensitive={false} element={<CreateTeacher />} />
                        </>}

                        {/* Teacher's route */}
                        {context.auth.user_type === '4' && <>
                            <Route path="assignments" caseSensitive={false} element={<ManageAssignment />} />
                            <Route path="create-assignment" caseSensitive={false} element={<CreateAssignment />} />
                            <Route path="update-assignment" caseSensitive={false} element={<CreateAssignment />} />
                            <Route path="assignment-analytics" caseSensitive={false} element={<AssignmentAnalytics />} />
                            <Route path="users-analytics" caseSensitive={false} element={<UsersAnalytics />} />
                            <Route path="user-assignment" caseSensitive={false} element={<UserAssignment />} />
                        </>}

                        {/* Teacher and Group Admin's common route */}
                        {(context.auth.user_type === '4' || context.auth.user_type === '5') && <>
                            <Route path="assigned-workbook" caseSensitive={false} element={<AssignWorkBook />} />
                            <Route path="workbook-analytics" caseSensitive={false} element={<WorkbookAnalytics />} />
                        </>}

                        {/* {(context.auth.user_type !== '3' || context.auth.permission.includes('workbooks')) && <> */}
                            <Route path="graph-analytics" caseSensitive={false} element={<FullReview />} />
                            <Route path="class-review/:query?" caseSensitive={false} element={<ClassReview />} />
                            <Route path="student-review/:query" caseSensitive={false} element={<StudentReview />} />
                            <Route path="individual-student-review/:query" caseSensitive={false} element={<IndividualStudentReview />} />
                            <Route path="workbook-review/:query" caseSensitive={false} element={<WorkbookReview />} />
                            <Route path="question-review/:query" caseSensitive={false} element={<QuestionReview />} />
                        {/* </>} */}

                        {/* Default route */}
                        <Route path="users" caseSensitive={false} element={<Users />} />
                        <Route path="profile" caseSensitive={false} element={<AdminProfile />} />
                        <Route path="change-password" caseSensitive={false} element={<ChangePassword />} />

                        <Route path="*" caseSensitive={false} element={<ErrorPage />} />
                    </Route>
                }

                {/* Default route */}
                <Route path="cms/" caseSensitive={false} element={<SignIn />} />
                <Route path="cms/create-new-admin" caseSensitive={false} element={<CreateNewAdmin />} />
                <Route path="forget-password" caseSensitive={false} element={<ForgetPassword />} />
                <Route path="reset-password" caseSensitive={false} element={<ResetPassword />} />
                <Route path="verify-email" caseSensitive={false} element={<VerifyEmail />} />
                <Route path="verification" caseSensitive={false} element={<Authenticate />} />

                {!token ? <Route path="*" element={<ErrorPage />} /> : ''}
            </Routes>
        </Context.Provider>
    )
}

export default Routing