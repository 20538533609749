import { useEffect, useState } from "react";
import FIlterContent from "./FIlterContent";
import { useParams } from "react-router-dom";
import { decodedText } from "../../../components/CommonMethod";

const Canvas = ({ setQuery, noCompare }) => {
    const params = useParams()
    const [prevQuery, setPrevQuery] = useState({ current: { type: null, start: null, end: null, days: '', value: null }, compare: { type: null, start: null, end: null, days: '', value: null } });
    const [compare, setCompare] = useState(false);
    const [currentQuery, setCurrentQuery] = useState('');
    const [compareQuery, setCompareQuery] = useState('');
    const [reset, setReset] = useState(1);

    useEffect(() => {
        if (params && params.query) {
            try {
                let is_compare = false;
                let current = { type: null, start: null, end: null, days: '', value: null };
                let compare = { type: null, start: null, end: null, days: '', value: null };
                let query = JSON.parse(decodedText(params.query, process.env.REACT_APP_ENCRYPTED_KEY));

                query.query.split('&').forEach(element => {
                    let [key, value] = element.split('=', 2);
                    if (key && value) {
                        let real_key = key.replace('compare_', '');

                        if ((real_key === 'start' || real_key === 'end') && value) {
                            value = (!value || value === 'Invalid date' || value === 'null') ? null : new Date(value);
                        }

                        if (key.match('compare')) {
                            is_compare = true
                            compare[real_key] = value;
                        } else {
                            current[real_key] = value;
                        }
                    }
                });
                setCompare(is_compare);
                setPrevQuery({ current, compare });
            } catch (error) {
                console.log(error);
            }
        }
    }, [params])

    useEffect(() => {
        setQuery(compare ? `${currentQuery}${compareQuery}` : `${currentQuery}`);
    }, [setQuery, currentQuery, compareQuery, compare])

    useEffect(() => {
        let box_container = document.getElementById("box-container")
        if(box_container){
            if (compare) {
                box_container.classList.add('show-compare')
            } else {
                box_container.classList.remove('show-compare')
            }
        }
    }, [compare])

    return (
        <>
            <style jsx="true">{`
        .mimw-300px{
            min-width: 300px;
        }
    `}</style>
            <div className="offcanvas custom_offcanvas offcanvas-end" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasLabel">
                <form action="">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasLabel">Filter</h5>
                        <div className="w-100 text-center">
                            <button type="button" className="btn btn-sm btn-secondary" onClick={() => setReset(reset + 1)}>
                                Reset Filter
                            </button>
                        </div>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body p-0">
                        <FIlterContent query={prevQuery.current} setQuery={setCurrentQuery} reset={reset} />
                        {compare && <FIlterContent compare query={prevQuery.compare} setQuery={setCompareQuery} reset={reset} />}
                    </div>
                    <div className="offcanvas-footer">
                        {!noCompare && <>
                            <div className="customCanvas_switcher d-flex align-items-center justify-content-between">
                                <span>Compare</span>
                                <div className="form-check">
                                    <input className="form-check-input d-none" type="checkbox" id="compareCheck" checked={compare} onChange={() => setCompare(!compare)} />
                                    <label className="form-check-label" htmlFor="compareCheck"></label>
                                </div>
                            </div>
                        </>}
                        {/* <div className="p-3">
                            <button type="submit" className="btn btn-theme-primary w-100">Submit</button>
                        </div> */}
                    </div>
                </form>
            </div>
        </>
    )
}
export default Canvas;