const Modal = (props) => {
    return (
        <>
            <div className="modal fade" id="restartModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border border-2 border-blue p-2 rounded-4">
                        <div className="modal-header border-0">
                            <h3 className="fw-bold text-blue">Restart this Workbook</h3>
                        </div>
                        <div className="modal-body border-0">
                            <p>Your progress so far will not be saved.</p>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-outline-default btn-lg me-2" title="Keep Working" data-bs-dismiss="modal">Keep Working</button>
                            <button className="btn btn-yellow btn-lg me-2" title="Restart" data-bs-dismiss="modal" onClick={props.restartExam}>Restart</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="quitModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border border-2 border-blue p-2 rounded-4">
                        <div className="modal-header border-0">
                            <h3 className="fw-bold text-blue">Quit this Workbook</h3>
                        </div>
                        <div className="modal-body">
                            <p>Your progress will be saved. You will resume from the last question you answered the next time you open this workbook.</p>
                        </div>
                        <div className="modal-footer border-0">
                            <button className="btn btn-outline-default btn-lg me-2" title="Keep Working" data-bs-dismiss="modal">Keep Working</button>
                            <button className="btn btn-danger btn-lg me-2" title="Quit" data-bs-dismiss="modal" onClick={props.quitExam}>Quit</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="timeOutModal" tabIndex="-1" data-bs-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border border-2 border-blue p-2 rounded-4">
                        <div className="modal-header border-0">
                            <h3 className="fw-bold text-blue">Workbook exam time limit exceeded.</h3>
                        </div>
                        <div className="modal-body">
                            <p>Workbook exam time limit exceeded. You can restart this workbook again.</p>
                        </div>
                        <div className="modal-footer border-0">
                            <button className="btn btn-danger btn-lg me-2" title="Quit" data-bs-dismiss="modal" onClick={props.navigateFromThisPage}>Go to Workbook</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal