import { useState } from "react";
import { Link } from "react-router-dom";
import { PasswordShow, validateForm, ButtonSpinner, initialFormState } from "../../components/CommonMethod";
import { fetchData, ADMIN_SIGNUP } from '../../components/Service';

const CreateNewAdmin = () => {
    window.document.title = "Create New Admin";
    const [tinyloader, setTinyloader] = useState(false);

    const adminSigUp = (e) => {
        let formdata = new FormData(document.getElementById('adminSignUp'));

        if (validateForm(e, 'adminSignUp')) {
            setTinyloader(true);

            fetchData(ADMIN_SIGNUP, 'POST', formdata, false, true, (res) => {
                setTinyloader(false);
                
                if (res.success) {
                    initialFormState('adminSignUp')
                }
            });
        }
    }
    return (<>
        <section className="d-flex justify-content-center align-items-center w-100 min-vh-100">
            <div className="container">
                <div className="row justify-content-center">
                    <form autoComplete="off" className="col-sm-7 col-md-6 col-lg-5 p-4 px-lg-5 needs-validation" id="adminSignUp" method="post" noValidate>
                        <Link to="/" className="text-decoration-none text-dark">
                            <div className="text-center mb-5 pe-none">
                                <img src="/images/logo.svg" alt="Logo" />
                                <p className="fs-18 fw-bold pt-3">Content management system</p>
                            </div>
                        </Link>
                        <h3 className="fw-bold text-blue">Create new admin</h3>
                        <p className="fw-bold py-2">
                            Already have an account? <Link to="/cms" className="text-body blue-hover">Log in</Link>
                        </p>
                        <div className="form-floating mt-32">
                            <input type="text" placeholder="Full name" name="name" id="name" className="form-control form-control-md" autoComplete="username" required/>
                            <div className="invalid-feedback">
                                The full name field is required.
                            </div>
                            <label>Full name <strong className="text-danger">*</strong></label>
                        </div>

                        <div className="form-floating mt-32">
                            <input type="email" name="email" id="email" placeholder="Email address" className="form-control form-control-md" autoComplete="username" required/>
                            <div className="invalid-feedback">
                                The email field is required and must be a valid email address..
                            </div>
                            <label>Email address <strong className="text-danger">*</strong></label>
                        </div>
                        
                        <div className="form-floating password-wrapper mt-32 mb-4">
                            <input type="password" placeholder="Password" name="password" id="password" className="form-control form-control-md" autoComplete="current-password" required/>
                            <button type="button" className="btn" onClick={(e) => PasswordShow(e, 'password')}>
                                <svg className="icon pe-none"><use href="#icon_eye"></use></svg>
                            </button>
                            <div className="invalid-feedback position-absolute">
                                The password field is required.
                            </div>
                            <label>Password<strong className="text-danger">*</strong></label>
                        </div>

                        <div className="text-end">
                            <button type="button" className="btn btn-theme-primary btn-lg minw-110 mb-3 mb-sm-2 me-auto me-sm-0 order-sm-1 mt-4" onClick={(e) => adminSigUp(e)} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName="Sign Up" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </>)
}

export default CreateNewAdmin;