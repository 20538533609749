import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CountUp from 'react-countup';
import { DataTable } from "../../components/DataTable";
import { __echoText } from "../../components/CommonMethod";
import { fetchData, SALE_SHORT_LIST, USER_LIST, USER_STATUS_CHANGE, GET_SALES_AND_BOUNCE_RATE, USER_COUNT } from "../../components/Service";
import { format } from 'date-fns';
import { now } from 'lodash';
import 'font-awesome/css/font-awesome.min.css';
import { Context } from "../../components/Context";

const Dashboard = () => {
    window.document.title = "Dashboard | WAAE Educational";
    const navigate = useNavigate();
    const [refreshStudent, setRefreshStudent] = useState(true);
    const [refreshSales] = useState(true);
    const [users, setUsers] = useState(0);
    const [sales,setSales] = useState(0);
    const [bounceRate, setBounceRate] = useState(0);
    const [context] = useContext(Context)

    useEffect(() => {
        // window.onpopstate = () => {
        //     navigate("/cms");
        // }

        fetchData(USER_COUNT + '/' + 2, 'GET', '', true, false, (res) => {
            if(res.count){
                setUsers(res.count)
            }
        });

        totalSaleAndBounceRate('success');
        totalSaleAndBounceRate('failed');
    }, [navigate]);

    const students = {
        id: 'students',
        fetchurl: USER_LIST + '/' + 2,
        length: 5,
        columns: [
            { data: 'id', name: 'users.id', title: 'Id', searching: false },
            { data: 'first_name', name: 'users.first_name', title: 'Student Name' },
            { data: 'email', name: 'users.email', title: 'Email Address' },
            { data: 'workbook_count', name: 'workbook_count', title: 'Workbooks', sorting: false, searching: false },
            { data: 'status', name: 'users.status', title: 'Status' },
            { data: 'action', name: 'action', title: 'Action', sorting: false, searching: false }
        ]
    }
    const updateStatus = (id, refresh) => {
        fetchData(USER_STATUS_CHANGE, 'POST', { student_id: id }, true, false, (res) => {
            refresh(now)
        });
    }

    const totalSaleAndBounceRate = (type) => {
        fetchData(`${GET_SALES_AND_BOUNCE_RATE+'/' + type}`, 'GET', '', true, false, (res) => {
            if(res.records){
                if(type === 'success'){
                    setSales(res.records[0].amount)
                }else if(type === 'failed'){
                    setBounceRate(res.records[0].amount)
                }
                
              
               
            } 
        });
    }

    const saleList = {
        id: 'sales_table',
        fetchurl: SALE_SHORT_LIST,
        length: 5,
        orderBy : [0, 'asc'],
        columns: [
            { data: 'id', name: 'student_workbooks.id', title: 'Id', sorting: false, searching: false},
            { data: 'name', name: 'name', class:"w-25 text-break", title: 'Listing Title' },
            { data: 'price', name: 'price', title: 'Price' },
            { data: 'qty', name: ' qty', title: 'Qty' },
            { data: 'total_question', name: 'total_question', title: 'Questions', visible: false },
            { data: 'created_at', name: 'created_at', title: 'Date' },
            { data: 'action', name: 'id', title: 'Action', sorting: false, searching: false, visible: false }
        ]
    }

    return (
        <div className="container-fluid">
                    <div className="page-head sticky_top_padding">
            <div className="row">
                <div className="col-sm-12">
                    <h1 className="h3 text-dim pb-4">Dashboard</h1>
                </div>
            </div>
            <div className="row gx-4 gy-3 mb-3 mb-md-4">
                <div className="col-lg-6 col-sm mb-3 col-6">
                    <h4 className="fw-bold m-0">Key Analytics</h4>
                </div>
                {context && context.auth && context.auth.permission && context.auth.permission.includes('analytics') &&
                    <div className="col-lg-6 col-auto mb-3 col-6 ">
                        <Link to="/cms/analytics" role="button" className="btn btn-theme-primary float-end">View analytics</Link>
                    </div>
                }
            </div>
            </div>
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <div className="ratio ratio-16x9 cbg-info rounded mb-3">
                                <div className="row m-0">
                                    <div className="col-md-6 d-flex align-items-center ps-4">
                                        <div className="img-card">
                                            <img src="/images/circle.953c9ca0.svg" className="card-img-absolute" alt="circle" />
                                            <i className="fa fa-cart-plus"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right d-flex align-items-center justify-content-end">
                                        <div className="details">
                                            <div className="fa-2x fw-bold text-center text-white">
                                                <CountUp 
                                                    end={sales} 
                                                    delay={0} duration={0.5} 
                                                    separator=","
                                                    decimals={2}
                                                    decimal="."
                                                    prefix="$"
                                                    suffix=""  
                                                />
                                            </div>
                                            <div className="fs-14 text-center">Total Revenue</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-body text-decoration-none fs-16 fw-bold mt-2">Sales</div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <div className="ratio ratio-16x9 cbg-success rounded mb-3">
                                <div className="row m-0">
                                    <div className="col-md-6 d-flex align-items-center ps-4">
                                        <div className="img-card">
                                            <img src="/images/circle.953c9ca0.svg" className="card-img-absolute" alt="circle" />
                                            <i className="fa fa-users stat-cards-icon-success"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right d-flex align-items-center justify-content-end">
                                        <div className="details">
                                            <div className="fa-2x fw-bold text-center text-white">
                                                <CountUp end={users} delay={0} duration={0.5} />
                                            </div>
                                            <div className="fs-14 text-center">Total Students</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-body text-decoration-none fs-16 fw-bold mt-2">Students</div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <div className="ratio ratio-16x9 cbg-info rounded mb-3">
                                <div className="row m-0">
                                    <div className="col-md-6 d-flex align-items-center ps-4">
                                        <div className="img-card">
                                            <img src="/images/circle.953c9ca0.svg" className="card-img-absolute" alt="circle" />
                                            <i className="fa fa-money"></i>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right d-flex align-items-center justify-content-end">
                                        <div className="details">
                                            <div className="fa-2x fw-bold text-center text-white">
                                                <CountUp 
                                                    end={bounceRate} 
                                                    delay={0} duration={0.5} 
                                                    separator=","
                                                    decimals={2}
                                                    decimal="."
                                                    prefix="$"
                                                    suffix=""  
                                                />
                                            </div>
                                            <div className="fs-14 text-center">Total Bounce rate</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-body text-decoration-none fs-16 fw-bold mt-2">Bounce rate</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-sm-12 col-md-6 mb-4">
                    <div className="card p-0">
                        <div className="align-items-center d-flex card-header">
                            <h4 className="card-title mb-0 flex-grow-1">
                                Top Selling Workbooks
                            </h4>
                            <div className="flex-shrink-0">
                                {/* <Link to="/cms/sales" title="View All">
                                    <span><i className="fa fa-arrow-circle-right"></i></span>
                                </Link> */}
                            </div>
                        </div>
                        <div className="card-body p-0 short-sammery-table">
                            <div className="bordered head-black">
                                <DataTable
                                    execute={refreshSales}
                                    dt={saleList}
                                    searching={false}
                                    paging={false}
                                    pagination={false}
                                    edit_column={{
                                        created_at: (records) =>
                                            __echoText(`${format(new Date(records.created_at), 'MM/dd/yyyy')}`),
                                        action: (records) =>
                                            <div className="d-flex">
                                                <Link to="/order-details" state={records} className="btn btn-clipboard btn-soft-success" title="View">
                                                    <svg className="icon fs-6" role="img"><use href="#icon_eye" /></svg>
                                                </Link>
                                            </div>

                                    }}
                                />  
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 mb-4">
                    <div className="card p-0">
                        <div className="align-items-center d-flex card-header">
                            <h4 className="card-title mb-0 flex-grow-1">
                                New Students
                            </h4>
                            <div className="flex-shrink-0">
                                <Link to="/cms/users" onClick={() => localStorage.setItem('activeTab', '#studentsBox')} title="View All">
                                    <span><i className="fa fa-arrow-circle-right"></i></span>
                                </Link>
                            </div>
                        </div>
                        <div className="card-body p-0 short-sammery-table">
                            <div className="bordered head-black">
                                <DataTable
                                    execute={refreshStudent}
                                    dt={students}
                                    searching={false}
                                    paging={false}
                                    pagination={false}
                                    perPage={5}
                                    edit_column={{
                                        first_name: (records) =>
                                            __echoText(`${records.first_name} ${records.last_name}`),

                                        workbook_count: (records) =>
                                            <span>{records.workbook_count ? records.workbook_count : 0}</span>,

                                        status: (records) =>
                                            <button type="button" className={`px-3 text-decoration-none btn btn-link badge badge-soft-${records.status === 'active' ? 'success' : 'danger'}`} onClick={() => updateStatus(records.id, setRefreshStudent)}>
                                                {records.status === 'active' ? 'Active' : 'Inactive'}
                                            </button>,
                                        all: (records) =>
                                            __echoText(`${records.first_name} ${records.last_name}`),
                                        created_at: (records) =>
                                            __echoText(`${format(new Date(records.created_at), 'MM/dd/yyyy')}`),
                                        action: (records) =>
                                            <div className="d-flex">
                                                <Link to="/cms/update-user" state={records} className="p-0 btn btn-clipboard btn-soft-info" title="Edit">
                                                    <svg className="icon fs-6" role="img"><use href="#icon_pencil" /></svg>
                                                </Link>
                                            </div>

                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;