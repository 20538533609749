import { useEffect, useState, useContext } from "react";
import CountryFilter, { ShowPrice } from "../../components/CountryFilter";
import { useLocation, useNavigate } from "react-router-dom";
import { ASSET_ENDPOINT, PAGE_CONTENT, fetchData, ADD_TO_CART, REMOVE_FROM_CART, GET_SIMILAR_LISTING } from "../../components/Service";
import { getCartItem, noImageHandle } from "../../components/CommonMethod";
import { Context } from '../../components/Context';
import { PackageListOutcomes, PackageWorkbooks, SingleListOutcomes } from "./ShopComponent";
import WorkbookSlider from "../../components/WorkbookSlider";
import { addGuestCart, isGuest, removeGuestCart } from "../../components/GuestHelper";


function ProductDetails() {
    window.document.title = "Product Detail Single Workbook";

    const location = useLocation()
    const navigate = useNavigate()
    const [context] = useContext(Context)
    const guest = isGuest()
    const [item, setItem] = useState(location.state && location.state.item)
    const [courseDetails, setCourseDetails] = useState(location.state && location.state.course)
    const [currencyData, setCurrencyData] = useState(location.state && location.state.currencyData)
    const [cartStatus, setCartStatus] = useState(location.state && location.state.item && location.state.item.workbook_status)
    const [guestCartStatus, setGuestCartStatus] = useState(false)
    const [similarListing, setSimilarListing] = useState('')
    if (!courseDetails) {
        navigate('/shop')
    }

    const [testimonialData, setTestimonial] = useState()

    useEffect(() => {
        let guest = isGuest()
        if(location.state && location.state.item){
            if (guest) {
                setGuestCartStatus(guest.cart_items.shop_listing_id.includes(location.state.item.id))
            }else{
                setCartStatus(location.state.item.workbook_status)
            }
        }
    }, [location.state])

    useEffect(() => {
        if (location.state) {
            setItem(location.state && location.state.item)
            setCourseDetails(location.state && location.state.course)
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }, [location.state])

    const addToCart = (e, id) => {
        e.preventDefault()
        fetchData(ADD_TO_CART, 'POST', { list_id: id }, true, false, (res) => {
            if (res.success) {
                setCartStatus('cart-in')
                getCartItem((res) => { })
            }
        });
    }

    const removeFromCart = (e, id) => {
        e.preventDefault()
        fetchData(REMOVE_FROM_CART, 'POST', { id: id }, true, false, (res) => {
            if (res.success) {
                getCartItem((res) => { })
                setCartStatus('cart-out')
            }
        });
    }

    const guestCart = (e, id, add = true) => {
        e.preventDefault()
        if (add) {
            addGuestCart(id)
        } else {
            removeGuestCart(id)
        }
        setGuestCartStatus(add)
    }

    useEffect(() => {
        if (courseDetails) {
            let url = context && context.auth && context.auth.id ? `${GET_SIMILAR_LISTING}?course_id=${courseDetails.id}&user_id=${context.auth.id}` : `${GET_SIMILAR_LISTING}?course_id=${courseDetails.id}`
            fetchData(url, 'GET', '', false, false, (res) => {
                if (res.records) {
                    setSimilarListing(res.records)
                }
            });
        }

    }, [courseDetails, context])

    useEffect(() => {
        if (courseDetails) {
            fetchData(`${PAGE_CONTENT}?type=shop-content`, 'GET', '', false, false, (res) => {
                if (res.records) {
                    setTestimonial(JSON.parse(res.records))
                } else {
                    setTestimonial(false);
                }
            });
        }

    }, [courseDetails])


    return (
        <>
            <section className="py-4 py-sm-4">
                <div className="container container-1180">
                    <div className="row ">
                        <div className="col-sm-12">
                            <div className="d-flex justify-content-between mb-4 mb-md-3 order-0">
                                <CountryFilter currencyData={currencyData} setCurrencyData={setCurrencyData}
                                    title={(guest || (context && context.auth && context.auth.individual_student)) && <h6 className="fw-bold pb-4 text-danger">Workbook is valid for 2 years</h6>}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 text-center ">
                            <div className="position-relative d-inline-block">
                                <svg className=" cutcircle icon position-absolute mmwh-150 opacity-60 d-md-inline d-none zindex-minus" style={{ top: '81%', left: '-15%', color: `hsl(${courseDetails && courseDetails.accent_color}, 100%, 50%)` }} ><use href="#icon_cutcircle"></use></svg>

                                <svg className=" circle icon position-absolute mmwh-150 d-md-inline d-none zindex-minus" style={{ top: '9%', left: '80%', color: `hsl(${courseDetails && courseDetails.accent_color}, 100%, 50%)` }}><use href="#icon_circle"></use></svg>

                                <img src={item && item.image ? ASSET_ENDPOINT + item.image : '../images/ionic-purple.jpg'} className="ratio shadow-md mmwh-260  position-relative rounded mb-3 " alt="List" onError={noImageHandle} />
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 mb-5 ">
                            <div className="Product_details_bottom">
                                <span className="text-white fw-bold text-uppercase rounded-pill px-3 py-2 me-2 " style={{ background: `hsl(${courseDetails && courseDetails.accent_color}, 100%, 50%)` }}>{courseDetails && courseDetails.course_abbr}
                                </span>
                                <div className="py-3">
                                    <h3 className="fw-bold h4">{item && item.title}{item && item.type && item.type === 'package' ? ' (Package)' : ''}</h3>
                                    {/* {item.type === 'single' && <p className="fw-bold fs-14 text-danger">Valid for {item.details && item.details[0] && item.details[0].expired_on} years</p>} */}
                                </div>
                                {item.type === 'single' && <>
                                    <div className="d-flex flex-wrap-reverse gap-3 mb-2">
                                        <div className="pe-3 pt-0 mt-0">
                                            <svg className="icon fs-4 text-white fw-bold rounded-pill py-1 me-2" style={{ background: `hsl(${courseDetails && courseDetails.accent_color}, 100%, 50%)` }}><use href="#icon_help"></use></svg>
                                            {item.details && item.details.length > 0 && item.details.map((section, index) => <span className="fw-bold" key={index}>{index > 0 && ', '}{section.section_name}</span>)}
                                        </div>
                                    </div>
                                </>}
                                <div className="d-flex flex-wrap-reverse gap-3">
                                    <div className="pe-3 pt-0 mt-0">
                                        <svg className="icon fs-4 text-white fw-bold rounded-pill py-1 me-2" style={{ background: `hsl(${courseDetails && courseDetails.accent_color}, 100%, 50%)` }}><use href="#icon_timer"></use></svg>
                                        <span className="fw-bold">{item.total_question ?? 0} questions</span>
                                    </div>
                                    {item.type === 'single' &&
                                        <div className="pe-3 pt-0 mt-0 ">
                                            <svg className="icon fs-4 text-white fw-bold rounded-pill py-1 me-2" style={{ background: `hsl(${courseDetails && courseDetails.accent_color}, 100%, 50%)` }}><use href="#icon_class"></use></svg>
                                            {item.details && item.details.length > 0 && item.details.map((item, index) => <span className="fw-bold" key={index}>{item.time_limit} minutes</span>)}
                                        </div>
                                    }

                                    {item.type === 'package' &&
                                        <div className="pe-3 pt-0 mt-0 ">
                                            <svg className="icon fs-4 text-white fw-bold rounded-pill py-1 me-2" style={{ background: `hsl(${courseDetails && courseDetails.accent_color}, 100%, 50%)` }}><use href="#icon_books"></use></svg>
                                            <span className="fw-bold">{item.details && item.details.length ? item.details.length : 0} Workbooks</span>
                                        </div>
                                    }
                                </div>
                                <p className="fw-medium fs-14 py-3">{item && item.description}</p>

                                <div className="pb-2">
                                    <span className="fw-bold h4"><ShowPrice currencyData={currencyData} price={item.price} /></span>
                                    {item && item.save_price && item.save_price > 0 ? <span className="fw-bold h5 text-green ms-2"> SAVE <ShowPrice currencyData={currencyData} price={item.save_price} /></span> : ''}
                                </div>

                                {context && context.auth && context.auth.user_type === '2' ?
                                    context.auth.group_details ? <p className="fw-bold text-green mb-0">You are a group student.</p>
                                        : cartStatus ?
                                            <>
                                                {cartStatus === 'active' && <button type="button" className="btn btn-yellow pe-none btn-lg text-nowrap" title="Purchased" style={{ cursor: 'default' }} onClick={(e) => e.preventDefault()}>Purchased</button>}
                                                {cartStatus === 'cart-in' && <button type="button" className="btn btn-green btn-lg text-nowrap" title="Remove from Cart" onClick={(e) => removeFromCart(e, item.id)}>Remove from Cart</button>}
                                                {cartStatus === 'cart-out' && <button type="button" className="btn btn-theme-primary btn-lg text-nowrap" title="Add to Cart" onClick={(e) => addToCart(e, item.id)}>Add to Cart</button>}
                                            </>
                                            : <button type="button" className="btn btn-theme-primary btn-lg text-nowrap" title="Add to Cart" onClick={(e) => addToCart(e, item.id)}>Add to Cart</button>
                                    : ''
                                }
                                {guest && <>
                                    <p className="fw-bold text-green mb-2">You are a guest user.</p>
                                    {guestCartStatus ? <>
                                        <button type="button" className="btn btn-green btn-lg text-nowrap" title="Remove from Cart" onClick={(e) => guestCart(e, item.id, false)}>Remove from Cart</button>
                                    </> : <>
                                        <button type="button" className="btn btn-theme-primary btn-lg text-nowrap" title="Add to Cart" onClick={(e) => guestCart(e, item.id)}>Add to Cart</button>
                                    </>}
                                </>}
                            </div>
                        </div>
                        {item && item.type === 'single' && item.details && item.learning_outcomes_status === 1 &&
                            <SingleListOutcomes learning_outcomes={item.learning_outcomes} section={item.details[0] && item.details[0].section_name}>
                                {testimonialData &&
                                    <div className="col-sm-12 col-lg-6">
                                        <div className='card-skybar ms-auto margin-img-top-res'>
                                            <div className="card-innerbar">
                                                <img src={testimonialData.detail_image ? ASSET_ENDPOINT + testimonialData.detail_image : 'images/fly.png'} className="me-0 mt-2" alt="fly" onError={noImageHandle} />
                                                <p className="pe-2 text-white fw-bold">&#8220; {testimonialData.detail_message} &#8221;-<span className="fw-bold text-capitalize"> {testimonialData.detail_name}</span></p>
                                            </div>
                                        </div>
                                    </div>}
                            </SingleListOutcomes>
                        }

                        {item && item.type === 'package' &&
                            <>
                                {item.details && <PackageWorkbooks data={item.details} />}

                                {testimonialData &&
                                    <div className="col-sm-12 col-lg-6 mt-5">
                                        <div className='card-skybar ms-auto margin-img-top-res mt-0'>
                                            <div className="card-innerbar">
                                                <img src={testimonialData.detail_image ? ASSET_ENDPOINT + testimonialData.detail_image : 'images/fly.png'} className="me-0" alt="fly" onError={noImageHandle} />
                                                <p className="pe-2 text-white fw-bold">&#8220; {testimonialData.detail_message} &#8221;-<span className="fw-bold text-capitalize"> {testimonialData.detail_name}</span></p>
                                            </div>
                                        </div>
                                    </div>}

                                {item.details && <PackageListOutcomes data={item.details} />}
                            </>
                        }
                    </div>
                </div>
            </section>
            <section className="py-5 ">
                <div className="container container-1180">
                    <div className="row">
                        <div className="col-12">
                            <h4 className="fw-bold fs-24 text-dark my-3">Explore similar workbooks for the {courseDetails.course_abbr} course</h4>
                            {similarListing && <WorkbookSlider data={similarListing} total={similarListing ? similarListing.length : 0} accent_color={courseDetails.accent_color} course_abbr={courseDetails.course_abbr} currencyData={currencyData} />}
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}
export default ProductDetails