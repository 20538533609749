const ExamCalculatorAndTable = (props) => {
    return (
        <>
            { props.course && (props.course.calculator === "yes" || props.course.periodic_table || (props.questions && props.activeQuestion && props.questions[props.activeQuestion-1] && props.questions[props.activeQuestion-1].video_url)) &&
                <div className="exammode border border-2 p-4 mt-32 rounded-4 border-blue-hover">

                    { props.course.periodic_table && 
                    <div className="btn d-flex align-items-center border px-3 py-12 gap-2 rounded-3 canculator_font-size" data-bs-target="#periodicModal" data-bs-toggle="modal">
                        <img src="/images/table.png" className="img-fluid" alt="" />
                        <button className="fw-bold border-0 bg-white" >Periodic table</button>
                    </div> }

                    { props.course.calculator === "yes" && 
                    <div className={`btn d-flex align-items-center border gap-2 px-3 py-12  rounded-3 mt-3 canculator_font-size`} data-bs-target="#calculatorModal" data-bs-toggle="modal">
                        <img src="/images/calculator.png" className="img-fluid" alt="" />
                        <button className="fw-bold border-0 bg-white">Calculator</button>
                    </div> }

                    {props.mode === 'practice' && props.questions && props.activeQuestion && props.questions[props.activeQuestion-1] && props.questions[props.activeQuestion-1].video_url &&
                        <div className="btn d-flex align-items-center border px-3 py-12 gap-2 rounded-3 mt-3" data-bs-target="#videoModal" data-bs-toggle="modal">
                            <img src="/images/play-icon.png" className="img-fluid" alt="" />
                            <button className="fw-bold border-0 bg-white">Video</button>
                        </div>
                    }
                </div> 
            }
            
        </>
    )
}

export default ExamCalculatorAndTable