import { useState } from "react";
import { PasswordShow, ButtonSpinner, validateForm, initialFormState } from "../../components/CommonMethod";
import { fetchData, CHANGE_PASSWORD } from '../../components/Service';

const UserSetting = () => {
    const [tinyLoader, setTinyLoader] = useState(false);
   
    const changePassword = (e) => {
        let data = new FormData(document.getElementById('changePassword'));

        if (validateForm(e, 'changePassword')) {
            setTinyLoader(true)

            fetchData(CHANGE_PASSWORD, 'POST', data, true, true, (res) => {
                setTinyLoader(false)
                if (res.success) {
                    initialFormState('changePassword')
                }
            })
        }
    }
    return (
        <div className="container container-1180 pt-5  ">
            <div className="row d-flex justify-content-center m-4 " >
                <div className="col-lg-5 col-sm-12 shadow p-3 mb-5 bg-body rounded ">
                <h4 className="fw-bold text-center text-blue py-2 mt-2">Change Password</h4>
                <div className="MyProfile">
                    <div className="row">
                        <form className="needs-validation" id="changePassword" noValidate>
                            <div className="col-lg-12">
                                <div className="form-floating mt-32">
                                    <input type="password" placeholder="Current Password" name="current_password" id="current_password" className="form-control form-control-md " required />
                                    <div className="invalid-feedback">The current password field is required.</div>
                                    <label>Current Password <strong className="text-danger">*</strong></label>
                                </div>
                                <div className="form-floating mt-32">
                                    <input type="password" placeholder="New Password" className="form-control form-control-md mt-4" name="new_password" id="new_password" required />
                                    <div className="invalid-feedback">The new password field is required.</div>
                                    <label>New Password <strong className="text-danger">*</strong></label>
                                </div>
                                <div className="form-floating password-wrapper mt-32">
                                    <input type="password" placeholder="Confirm Password" id="confirm_password" name="confirm_password" className="form-control form-control-md mt-4" autoComplete="current-password" required />
                                    <button type="button" className="btn" onClick={(e) => PasswordShow(e, 'confirm_password')}>
                                        <svg className="icon pe-none"><use href="#icon_eye"></use></svg>
                                    </button>
                                    <div className="invalid-feedback">The confirm password and new password must match.</div>
                                    <label>Confirm Password <strong className="text-danger">*</strong></label>
                                </div>
                            </div>
                        </form>
                        <div>
                            <div className="d-flex  my-5">
                                <button type="button" className="btn btn-theme-primary w-100 fs-5" onClick={(e) => changePassword(e)} disabled={tinyLoader}>
                                    <ButtonSpinner load={tinyLoader} btnName="Save" />
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </div>

                </div>
            </div>
              
     </div>
    );
}

export default UserSetting;