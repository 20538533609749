import MyWorkbooks from "../MyWorkbook/MyWorkbooks";

const AssignWorkBook = () => {

    return (
        <>
            <div className="container-fluid">
                <div className="page-head sticky_top_padding">
                    <div className="row g-2 align-items-center">
                        <div className="col-lg-6 col-sm mb-3 col-6">
                            <h1 className="h3 text-dim pb-17px">Workbooks </h1>
                        </div>
                        <div className="col-lg-6 col-auto mb-3 col-6">
                            {/* <Link to="/cms/create-group"  role="button" className="btn btn-green btn-lg px-3 float-end">+ Create new group</Link> */}
                        </div>
                    </div>
                </div>
                <div className="row gx-4 mb-1 align-items-end">
                    <div>
                        <MyWorkbooks groupAdminOrTeacher={true} />
                        {/* <WorkbookSection group_code={context && context.auth && context.auth.group_details && context.auth.group_details.group_code} /> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AssignWorkBook;