import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getCartItem } from "../../components/CommonMethod";
import { fetchData, STRIPE_PAYMENT_VERIFY } from "../../components/Service";

function EndCheckout() {
    window.document.title = "Checkout-Successful";
    const checkout_url = window.location.search
    const navigate = useNavigate()
    const location = useLocation()
    

    useEffect(() => {
        if(checkout_url && checkout_url.includes('stripe_session_id')){
            fetchData(`${STRIPE_PAYMENT_VERIFY}${checkout_url}`, 'GET', '', true, false, (res) => {
                getCartItem((res) => { })
            })
        }else if(location && !location.state){
            navigate('/my-workbooks')
        }
    }, [checkout_url, navigate, location])

    return (
    <section className="setpage-height pt-5">
        <div className="container container-1040">
            <div className="row justify-content-center">
                <div className="col-sm-10 col-md-7 pt-4">
                    <div className="rounded-4 border border-blue border border-2 p-4">
                        <h3 className="fw-bold text-blue">Purchase successful</h3>
                        <p className="h4 text-blue py-3 fw-normal lh-sm">
                            Congratulation! You've unlocked new workbooks. Check them out now in 'My Workbooks'.
                        </p>
                        <Link to="/my-workbooks" className="btn btn-theme-primary btn-lg w-100 mt-2">Go to my workbooks</Link>
                    </div>
                </div>
                <div className="col-sm-8 col-md-5">
                    <img src="images/female-student.png" className="img-fluid" alt="male" />
                </div>
            </div>
        </div>
    </section>
    )
}
export default EndCheckout
