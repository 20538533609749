import { Link } from "react-router-dom";

const WebsiteContent = () => {
    window.document.title = "Website Content";

    return(
        <div className="container-fluid">
              <div className="page-head sticky_top_padding">
            <div className="row">
                <div className="col-sm-12">
                    <h1 className="h3 text-dim mb-4 mb-md-5">Website Content</h1>
                </div>
            </div>
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4 "> 
                <div className="col">
                    <Link to="/cms/home-page" className="d-flex align-items-center p-3 p-md-4 fs-18 fw-bold text-decoration-none rounded-3 border border-current text-dark blue-hover">
                        <span>Home page</span>
                        <svg className="icon ms-auto ts-1p5"><use href="#icon_pencil"></use></svg>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/cms/about-us-page" className="d-flex align-items-center p-3 p-md-4 fs-18 fw-bold text-decoration-none rounded-3 border border-current text-dark blue-hover">
                        <span>About us</span>
                        <svg className="icon ms-auto ts-1p5"><use href="#icon_pencil"></use></svg>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/cms/social-media" className="d-flex align-items-center p-3 p-md-4 fs-18 fw-bold text-decoration-none rounded-3 border border-current text-dark blue-hover">
                        <span className="pe-2">Social media links</span>
                        <svg className="icon ms-auto ts-1p5"><use href="#icon_pencil"></use></svg>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/cms/shop-page" className="d-flex align-items-center p-3 p-md-4 fs-18 fw-bold text-decoration-none rounded-3 border border-current text-dark blue-hover">
                        <span>Shop</span>
                        <svg className="icon ms-auto ts-1p5"><use href="#icon_pencil"></use></svg>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/cms/checkout-page" className="d-flex align-items-center p-3 p-md-4 fs-18 fw-bold text-decoration-none rounded-3 border border-current text-dark blue-hover">
                        <span>Checkout</span>
                        <svg className="icon ms-auto ts-1p5"><use href="#icon_pencil"></use></svg>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/cms/workbook-page" className="d-flex align-items-center p-3 p-md-4 fs-18 fw-bold text-decoration-none rounded-3 border border-current text-dark blue-hover">
                        <span>Workbooks section</span>
                        <svg className="icon ms-auto ts-1p5"><use href="#icon_pencil"></use></svg>
                    </Link>
                </div>
                <div className="col">
                    <Link to="/cms/wc-terms-and-conditions" className="d-flex align-items-center p-3 p-md-4 fs-18 fw-bold text-decoration-none rounded-3 border border-current text-dark blue-hover">
                        <span>Terms and conditions</span>
                        <svg className="icon ms-auto ts-1p5"><use href="#icon_pencil"></use></svg>
                    </Link>
                </div>
            </div>
            </div>
       
    )
}

export default WebsiteContent
