import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Aside from "./Aside";
import $ from 'jquery';

const Frontend = () => {
    return (
        <>
            <Header />
            <div className="body-content">
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export const Backend = () => {
    $(window).scroll(function(){
        if ($(this).scrollTop() > 0) {
           $('.wrapper-box').addClass('sticky-header');
        } else {
           $('.wrapper-box').removeClass('sticky-header');
        }
    });
    return (
        <>
            <Aside />
            <div className="wrapper-box">
                <Outlet />
            </div>
        </>
    )
}

export const Blank = () => {
    return (
        <>
            <Outlet />
        </>
    )
}

export default Frontend;
