import React from 'react';
import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { BalanceOverviewCharts } from "./DashboardCrmCharts";
import { fetchData, BALANCE_OVERVIEW } from '../../../components/Service';
import CountUp from 'react-countup';

const BalanceOverview = () => {
    const [overViewData, setOverViewData] = useState([]);
    const [type, setType] = useState('thisYear');

    useEffect(() => {
        fetchData(BALANCE_OVERVIEW + `?type=${type}` , 'GET', '', true, false, (res) => {
            if (res.records) {
                setOverViewData(res.records)
            }
        });
    },[type])

    return (
        <React.Fragment>
            <Col md={12}>
                <Card className="card-height-100">
                    <CardHeader className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Balance Overview</h4>
                        <div className="flex-shrink-0">
                            <UncontrolledDropdown className="card-header-dropdown">
                                <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                                    <span className="fw-semibold text-uppercase fs-12">Sort by: </span><span className="text-muted">{type === 'thisDay' ? 'Today' : type === 'thisWeek' ? 'This Week' : type === 'thisMonth' ? 'This Month' : 'Current Year'}<i className="mdi mdi-chevron-down ms-1"></i></span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={()=> setType('thisDay')}>Today</DropdownItem>
                                    <DropdownItem onClick={()=> setType('thisWeek')}>This Week</DropdownItem>
                                    <DropdownItem onClick={()=> setType('thisMonth')}>This Month</DropdownItem>
                                    <DropdownItem onClick={()=> setType('thisYear')}>Current Year</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>+
                    </CardHeader>
                    <CardBody className="px-0">
                        <ul className="list-inline main-chart text-center mb-0">
                            <li className="list-inline-item chart-border-left me-0 border-0">
                                <h4 className="text-primary"><CountUp end={overViewData.revenue_sum} delay={0} duration={0.5} prefix='$' separator="," decimals='2' decimal='.'/> <span className="text-muted d-inline-block fs-13 align-middle ms-2">Revenue Sum</span></h4>
                            </li>
                            <li className="list-inline-item chart-border-left me-0">
                                <h4><CountUp end={overViewData.bounce_sum} delay={0} duration={0.5} prefix='$' separator="," decimals='2' decimal='.'/><span className="text-muted d-inline-block fs-13 align-middle ms-2">Bounce Sum</span>
                                </h4>
                            </li>
                            <li className="list-inline-item chart-border-left me-0">
                                <h4><span data-plugin="counterup"><CountUp end={overViewData.profit_ratio} delay={0} duration={0.5} suffix="%" decimals='2' decimal='.' /></span><span className="text-muted d-inline-block fs-13 align-middle ms-2">Profit Ratio</span></h4>
                            </li>
                        </ul>
                        <div id="revenue-expenses-charts" dir="ltr">
                            <BalanceOverviewCharts overViewData={overViewData}/>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default BalanceOverview;