const MovingRocket = () => {
    return (
        <div className="position-fixed h-100 w-100 top-0 start-0 zindex-2000">
            <img src="/images/rocket.png" className="move-rocket" alt="Rocket" />
        </div>
    )
}

const Clapping = () => {
    return (
        <div className="row justify-content-center align-items-center" id="result-bgclap">
            <div className="conbg-img"> 
                <img src="/images/clap.gif" className="img-fluid" alt="Clapping" />
            </div>          
        </div> 
    )
}

export default MovingRocket
export { Clapping };
