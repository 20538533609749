import React, { useEffect, useState } from 'react';
import { validateForm, ButtonSpinner, removeMsg } from '../../components/CommonMethod';
import { fetchData, GET_SETTINGS, UPDATE_SETTINGS } from '../../components/Service';

const SettingData = () => {
    document.title = "Setting Data"
    const [tinyLoader, setTinyLoader] = useState(false);
    const [settings, setSettings] = useState([])

    useEffect(() => {
        fetchData(GET_SETTINGS, 'get', '', true, false, (res) => {
            if(res.records) setSettings(res.records)
        })
    }, [])

    const saveData = (e) => {
        if(validateForm(e, 'settingsForm')){
            setTinyLoader(true)
            let formData = new FormData(document.getElementById('settingsForm'))
            fetchData(UPDATE_SETTINGS, 'POST', formData, true, true, (res) => {
                setTinyLoader(false)
                if (res.success) {
                    removeMsg()
                }
            })
        }
    }
    
    return (
        <>
            <div className="container-fluid">
            <div className="page-head sticky_top_padding">
            <div className="row g-2 align-items-center">
                <div className="col-lg-6 col-6 mb-3">
                    <h1 className="h3 text-dim mb-0">Setting Data</h1>
                </div>
                <div className="col-lg-6 col-6 mb-3">
                    <button type="button" className="btn btn-theme-primary btn-lg px-3 float-end" disabled={tinyLoader} onClick={saveData}>
                        <ButtonSpinner load={tinyLoader} btnName="Save" />
                    </button>
                </div>
            </div>
            </div>
            <div className="row mt-3">
                <form action="" className="needs-validation" id="settingsForm" noValidate autoComplete="off">
                    <div className="row g-4">
                        {settings && settings.length > 0 && settings.map((setting, key) => {
                            if(setting.slug === "site-keyword" || setting.slug === "site-description") return ''
                            else return (
                                <div key={key} className="col-sm-12 col-md-8 col-lg-6">
                                    <div className="form-floating mt-32">
                                        <input type="text" name={setting.slug} id={setting.slug} className="form-control form-control-md  mt-4" placeholder="Email" defaultValue={setting.value} required />
                                        <label>{setting.name}</label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </form>
            </div>
        </div>
        </>
    )
}

export default SettingData