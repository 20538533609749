import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchData, ADD_UPDATE_TERMS_CONDITIONS } from "../../components/Service";
import { validateForm, ButtonSpinner, removeMsg } from "../../components/CommonMethod";
import { CKEditor } from 'ckeditor4-react';

const TermAndCondition = () => {
    window.document.title = "Terms And Conditions";

    const [tinyloader, setTinyloader] = useState('')
    const [contentList, setContentList] = useState([])
    const [editorData, setEditorData] = useState({});
    
    const addContent = (e) => {
        let formdata = new FormData(document.getElementById('contentForm'))

        for (let key in editorData) {
            formdata.append(key, editorData[key])
        }

        if (validateForm(e, "contentForm")) {
            setTinyloader(true);
            fetchData(ADD_UPDATE_TERMS_CONDITIONS, 'POST', formdata, true, true, (res) => {
                if (res.success) {
                    setTinyloader(false);
                    removeMsg()
                } else {
                    setTinyloader(false)
                }
            });
        };
    }

    useEffect(() => {
        fetchData(ADD_UPDATE_TERMS_CONDITIONS, 'GET', '', true, false, (res) => {
            if (res.records) {
                let record = JSON.parse(res.records.value);
                setContentList(record)

                Object.keys(record).length > 0 && Object.keys(record).forEach((ele, num) => {
                    setEditorData(prevState => ({ ...prevState, [`content[${record[ele].section}][text]`]: record[ele].text }))
                })
            }
        });

    }, [])

    
    return (
        <>
            <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row">
                    <div className="col-sm-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                                <li className="breadcrumb-item"><Link to="/cms/website-content">WEBSITE CONTENT</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Terms and conditions</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row gx-4 mb-1 align-items-lg-end align-items-md-end  align-items-center">
                    <div className="col-lg-6 col-sm mb-3 col-9">
                        <h1 className="h3 text-dim mb-0">Edit ‘Terms and conditions’ content</h1>
                    </div>
                    <div className="col-lg-6 col-auto mb-3 col-3">
                       
                        <button type="button" className="btn btn-theme-primary btn-lg ms-2 float-end" onClick={(e) => addContent(e)} disabled={tinyloader}>
                            <ButtonSpinner load={tinyloader} btnName="Save" />
                        </button>
                    </div>
                </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="tab-content pt-4">
                            <form className="needs-validation" noValidate id="contentForm">
                                <div className="accordion d-block" id="allquestions">
                                    {contentList && Object.keys(contentList).length > 0 && Object.keys(contentList).map((ele, num) => {
                                        return (
                                            <div className="rounded-3 p-3 border border-dark mb-4 " id={`section${num}`} key={num}>
                                                <div className="question-controls" id={`query${num}`}>
                                                    <h3 className="fw-bolder">Section {num + 1}</h3>
                                                    <h4 className="fs-14 fw-bolder" data-bs-toggle="collapse" data-bs-target={`#collapseQuery${num}`} aria-expanded="false" aria-controls={`collapseQuery${num}`} role="button">{contentList[ele].section}</h4>
                                                    <button type="button" className="btn mmwh-48 ms-2" data-bs-toggle="collapse" data-bs-target={`#collapseQuery${num}`} aria-expanded="false" aria-controls={`collapseQuery${num}`}>
                                                        <svg className="icon ts-2"><use href="#icon_caretdown"></use></svg>
                                                    </button>
                                                    <div className="text-nowrap ms-auto">
                                                        <input type="hidden" name={`content[${contentList[ele].section}][section]`} defaultValue={contentList[ele].section} />
                                                        
                                                    </div>
                                                </div>
                                                <div className="collapse" id={`collapseQuery${num}`} data-bs-parent="#allquestions" aria-labelledby={`query${num}`}>
                                                    <div className="pt-4">
                                                        <CKEditor
                                                            initData={contentList[ele].text}
                                                            name={`content[${contentList[ele].section}][text]`}
                                                            config={{
                                                                height: '500px',
                                                            }}
                                                            onChange={(e) => setEditorData(prevState => ({ ...prevState, [`content[${contentList[ele].section}][text]`]: e.editor.getData() }))}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TermAndCondition
