import { useState } from "react";
import { Link } from "react-router-dom";
import { fetchData, LISTING_LIST, ASSET_ENDPOINT, LISTING_REMOVE, LISTING_STATUS_UPDATE, LISTING_DUPLICATE } from "../../components/Service";
import { ButtonSpinner, noImageHandle } from "../../components/CommonMethod";
import { format } from 'date-fns';
import _, { now } from 'lodash'
import { __echoText } from "../../components/CommonMethod";
import { DataTable } from "../../components/DataTable";

const Listings = () => {
    window.document.title = "Manage Listings";

    const [tinyloader, setTinyloader] = useState(false)
    const [refreshUnpublishedList, setRefreshUnpublishedList] = useState(true);
    const [refreshPublishedList, setRefreshPublishedList] = useState(false);
    const [listType, setListType] = useState('unpublished');
    const [listingData, setListingData] = useState([])

    let listOfListings = {
        id: 'listOfListings',
        columns: [
            { data: 'id', name: 'id', title: '', sorting: false, searching: false },
            { data: 'image', name: 'image', title: '', sorting: false, searching: false },
            { data: 'title', name: 'title', title: 'LISTING TITLE' },
            { data: 'serial_no', name: 'serial_no', title: 'SERIAL NO', searching: false },
            { data: 'course_abbr', name: 'course_id', title: 'COURSE', searching: false },
            { data: 'topic_name', name: 'course_topic_id', title: 'COURSE FILTERS', searching: false },
            { data: 'price', name: 'price', title: 'PRICE' },
            { data: 'type', name: 'type', title: 'TYPE' },
            { data: 'updated_at', name: 'updated_at', title: 'LAST EDITED' },
            { data: 'action', name: 'action', title: 'Action', sorting: false, searching: false },
        ]
    }

    const handleCheckboxChange = (e) => {
        e.target.checked ? setListingData([...listingData,e.target.value]) : setListingData(listingData.filter(i => i !== e.target.value));
    }

    const listingAction = (action) => {
        setTinyloader(true)
        fetchData(action === 'delete' ? LISTING_REMOVE : LISTING_DUPLICATE, 'POST', { id: listingData }, true, false, (res) => {
            setTinyloader(false)
            if (res.success) {
                document.querySelector((action === 'delete' ? '#deletelisting_modal' : '#duplicate_modal') + ' [data-bs-dismiss="modal"]').click()
                setListingData([])
                setRefreshUnpublishedList(now)
            }
            document.querySelectorAll('.my_checkboxes').forEach((el) => el.checked = false)
        });
    }

    const listingStatusChange = (status) => {
        setTinyloader(true)
        fetchData(LISTING_STATUS_UPDATE, 'POST', { id: listingData, p_status: status }, true, false, (res) => {
            setTinyloader(false)
            if (res.success) {
                document.querySelector('#listingStatus_modal [data-bs-dismiss="modal"]').click()
                setListingData([])
                listType === 'published' ? setRefreshPublishedList(now) : setRefreshUnpublishedList(now)
            }
            document.querySelectorAll('.my_checkboxes').forEach((el) => el.checked = false)
        });
    }

    const selectListType = (type, refresh) => {
        document.querySelectorAll('.my_checkboxes').forEach((el) => el.checked = false)
        setListType(type)
        setListingData([])
        refresh(now)
    }

    const dataList = {
        id: (records) =>
            <input className="form-check-input mmwh-20 m-0 my_checkboxes" type="checkbox" defaultValue={records.id} name="lists[]" onChange={(e) => handleCheckboxChange(e)} checked={listingData.includes(records.id.toString())} />
        ,

        image: (records) =>
            <div className="ratio ratio-1x1 mmw-56 rounded-3 shadow-md overflow-hidden" >
                <img src={ASSET_ENDPOINT + records.image} alt={records.title} onError={noImageHandle} />
            </div>
        ,

        title: (records) =>
            __echoText(<div dangerouslySetInnerHTML={{__html:records.title}}/>)

        ,
        status: (records) =>
            __echoText(`${_.startCase(records.status)}`)

        ,
        price: (records) =>
            '$' + __echoText(`${records.price}`),

        type: (records) =>
            _.startCase(__echoText(`${records.type}`)),

        course_abbr: (records) => {
            return records.additional_data.course && records.additional_data.course.length > 0 &&  
            records.additional_data.course.map((item, index) => {
                return (
                    <div key={index} className="minw-75 text-white fw-bold text-uppercase d-inline-block rounded-pill px-3 py-1 m-2 text-center" style={{ background: `hsl(${item.accent_color}, 100%, 50%)` }}>
                        {__echoText(`${item.course_abbr}`)}
                    </div>
                )
            })
        },
        action: (records) =>
            <div className="d-flex">
                <Link to="/cms/update-listing" state={records} className="btn btn-sm text-black" title="Edit">
                    <svg className="icon fs-5" role="img"><use href="#icon_pencil" /></svg>
                </Link>
            </div>,
        updated_at: (records) =>
            __echoText(`${format(new Date(records.updated_at), 'MM/dd/yyyy')}`),
            
        topic_name: (records) => {
            return records.additional_data.course_filter && records.additional_data.course_filter.length > 0 && 
            records.additional_data.course_filter.map((item, index) => <div key={index} className="mb-2" dangerouslySetInnerHTML={{__html: item}}></div>)
        }
        
    }

    return (
        <>
            <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row g-2 align-items-center">
                    <div className="col-lg-6 col-6 mb-3">
                        <h1 className="h3 text-dim ">Manage Listings</h1>
                    </div>
                    <div className="col-lg-6 col-6 mb-3">
                        <Link to="/cms/create-listing" role="button" className="btn btn-green btn-lg px-3 float-end">+ Create new listing</Link>
                    </div>
                </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <nav>
                            <div className="nav nav-tabs nav-bar mb-2" id="nav-tab" role="tablist">
                                <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#unpublishedBox" type="button" role="tab" aria-selected="true" onClick={() => selectListType('unpublished', setRefreshUnpublishedList)}>Unpublished</button>
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#publishedBox" type="button" role="tab" aria-selected="false" onClick={() => selectListType('published', setRefreshPublishedList)}>Published</button>
                            </div>
                        </nav>
                        <div className="tab-content pt-4">
                            <div className="tab-pane fade show active" id="unpublishedBox" role="tabpanel" tabIndex="0">
                                <div className="alert alert-warning fw-bold">
                                    <svg className="icon ts-1p6 me-3 text-warning"><use href="#icon_exclamation"></use></svg>
                                    Unpublished listings are NOT visible in the shop.
                                </div>
                                { listType === 'unpublished' && listingData.length > 0 && <div className="row bg-grey2 p-2 rounded mx-0 align-items-center mb-3">
                                    <div className="col-auto text-dim fs-18">
                                        <strong>{listingData.length} lists selected</strong>
                                    </div>
                                    <div className="col text-end">

                                        <button type="button" className="btn btn-link fw-bold text-decoration-none me-3" data-bs-toggle="modal" data-bs-target="#duplicate_modal">
                                            <svg className="icon ts-1p5 me-2"><use href="#icon_copy"></use></svg>
                                            Duplicate selected listings
                                        </button>

                                        <button type="button" className="btn btn-link fw-bold text-decoration-none me-3" data-bs-toggle="modal" data-bs-target="#listingStatus_modal">
                                            <svg className="icon ts-1p6 me-3"><use href="#icon_upload"></use></svg>
                                            Publish selected listings
                                        </button>

                                        <button type="button" className="btn btn-link fw-bold text-decoration-none pe-0 text-danger" data-bs-toggle="modal" data-bs-target="#deletelisting_modal">
                                            <svg className="icon ts-1p3 me-2"><use href="#icon_delete"></use></svg> Delete selected listings
                                        </button>
                                    </div>
                                </div> }

                                <DataTable
                                    execute={refreshUnpublishedList}
                                    dt={{...listOfListings, id: 'unpublished_listing', fetchurl: LISTING_LIST + '/unpublished'}}
                                    edit_column={dataList}
                                />
                            </div>
                            <div className="tab-pane fade" id="publishedBox" role="tabpanel" tabIndex="0">
                                { listType === 'published' && listingData.length > 0 && <div className="row bg-grey2 p-2 rounded mx-0 align-items-center mb-3">
                                    <div className="col-auto text-dim fs-18">
                                        <strong>{listingData.length} Lists selected</strong>
                                    </div>
                                    <div className="col text-end">
                                        <button type="button" className="btn btn-link fw-bold text-decoration-none" data-bs-toggle="modal" data-bs-target="#listingStatus_modal">
                                            <svg className="icon ts-1p6 me-3"><use href="#icon_download"></use></svg>
                                            Unpublish selected listings
                                        </button>
                                    </div>
                                </div> }

                                <DataTable
                                    execute={refreshPublishedList}
                                    dt={{...listOfListings, fetchurl: LISTING_LIST + '/published'}}
                                    edit_column={dataList}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Delete confirmation - Modal */}
            <div className="modal fade" id="deletelisting_modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content overflow-hidden border border-2 border-danger rounded-4">
                        <div className="modal-body p-4">
                            <h3 className="fw-bold text-danger">Delete unpublished listing?</h3>
                            <p className="m-0 pt-2">
                                This is a destructive action and cannot be reversed.
                            </p>
                        </div>
                        <div className="modal-footer justify-content-between px-4 pt-0 pb-4 border-0">
                            <button type="button" className="btn btn-outline-default btn-lg" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-lg minw-120" onClick={(e) => listingAction('delete')} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName="Delete" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* List unpublish - Modal */}
            <div className="modal fade" id="listingStatus_modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content overflow-hidden border border-2 border-danger rounded-4">
                        <div className="modal-body p-4">
                            <h3 className="fw-bold text-danger">{ listType === 'unpublished' ? 'Publish' : 'Unpublish' } selected listing?</h3>
                            <p className="m-0 pt-2">
                                Once you confirm, selected lists will { listType === 'unpublished' ? '' : 'not' } be visible in shop.
                            </p>
                        </div>
                        <div className="modal-footer justify-content-between px-4 pt-0 pb-4 border-0">
                            <button type="button" className="btn btn-outline-default btn-lg" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-lg minw-120" onClick={() => listingStatusChange(listType === 'unpublished' ? 'published' : 'unpublished')} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName={ listType === 'unpublished' ? 'Publish' : 'Unpublish' } />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/*Duplicate- Modal */}
            <div className="modal fade" id="duplicate_modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content overflow-hidden border border-2 border-danger rounded-4">
                        <div className="modal-body p-4">
                            <h3 className="fw-bold text-danger">Duplicate selected listing?</h3>
                            <p className="m-0 pt-2">
                                Once you confirm, selected lists will be cloned and will be treated as unpublished listing.
                            </p>
                        </div>
                        <div className="modal-footer justify-content-between px-4 pt-0 pb-4 border-0">
                            <button type="button" className="btn btn-outline-default btn-lg" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-lg minw-120" onClick={(e) => listingAction('duplicate')} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName="Duplicate" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Listings;
