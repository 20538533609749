import { NextArrow, noImageHandle, PrevArrow } from "../components/CommonMethod";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { ASSET_ENDPOINT } from "./Service";
import { Link } from "react-router-dom";

const Section = (props) => {
    const section_name = props.course_abbr ? props.section.filter((section) => {
        return (section.course_abbr === props.course_abbr)
    }) : props.section
    return section_name[0].section_name
}
function WorkbookSlider(props) {

    var settings = {
        centerPadding: '30',
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        cssEase: 'linear',
        pauseOnHover: false,
        touchThreshold: 100,
        wipeToSlide: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        afterChange: function (event, slick, currentSlide, nextSlide) {
            if (event >= 1) {
                document.getElementById('slideABC').classList.add('active');

            } else {
                document.getElementById('slideABC').classList.remove('active');
            }
            document.querySelector("#slideABC .slick-common-right").style.visibility = (event === props.total ? 'hidden' : 'visible')
        },
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerPadding: '20',
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    centerPadding: '20',
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="col-12 slick-visible-arrow product_left_slide" id="slideABC">
            <Slider {...settings}>
                {props.data && props.data.map((item, index) => {
                    return (
                        <div className="px-2" key={index}>
                            <Link className="slick-box slick-hovershow shadow-sm" to='/product-details' state={{ item: item.shop_listing_details, course: item.courseData, currencyData: props.currencyData }}>
                                <img className="slick-imgbox" src={item && item.image ? ASSET_ENDPOINT + item.image : '../images/default_ionic.png'} alt="list" onError={noImageHandle} />
                                <div className="slick-hide pe-none">
                                    <span className="text-white fw-bold text-uppercase rounded-pill px-3 py-1" style={{ background: `hsl(${props.accent_color ?? item.details[0].accent_color}, 100%, 50%)` }}>{props.course_abbr ?? item.details[0].course_abbr}</span>
                                    <h6 className="fw-bold text-white py-2 card-text-ellipse">{item.title}</h6>
                                    <div className="d-flex d-sm-flex ">
                                        <svg className="icon fs-3 text-white rounded-pill px-1" style={{ background: `hsl(190, 100%, 50%)` }}><use href="#icon_class"></use></svg>
                                        <p className="fw-bold text-white ms-2 card-heading-ellipse"><Section section={item.details} course_abbr={props.course_abbr ?? false} /></p>
                                    </div>
                                    <div className="d-flex d-sm-flex">
                                        <svg className="icon fs-3 text-white rounded-pill px-1" style={{ background: `hsl(270, 100%, 50%)` }}><use href="#icon_help"></use></svg>
                                        <p className="fw-bold text-white ms-2">{item.total_question} questions</p>
                                    </div>
                                    <div className="d-flex  ">
                                        {item.type === 'single' ? <>
                                            <svg className="icon fs-3 text-white rounded-pill px-1" style={{ background: `hsl(5, 100%, 50%)` }}><use href="#icon_timer"></use></svg>
                                            <p className="fw-bold text-white ms-2">{item.details[0].time_limit} minutes</p>
                                        </> : <>
                                            <svg className="icon fs-3 text-white rounded-pill px-1" style={{ background: `hsl(120, 100%, 50%)` }}><use href="#icon_books"></use></svg>
                                            <p className="fw-bold text-white ms-2">{item.total_workbook} Workbooks</p>
                                        </>}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )
                })}
            </Slider>
        </div>

    )
}
export default WorkbookSlider