const ProgressBar = ({ mode, progressData, progress, questions }) => {
    return (
        <>
            {mode === 'exam' ? <span className="fw-bold text-blue mb-2">&#128512; Keep going</span> : <>
                {progressData.value === true && <>
                    {progressData.index === 0 && <span className="fw-bold text-blue mb-2">&#128512;  Keep It Up!</span>}
                    {progressData.index === 1 && <span className="fw-bold text-blue mb-2">&#9996;  Nice job</span>}
                    {progressData.index > 1 && <span className="fw-bold text-yellow mb-2">&#128293; {progressData.index + 1} in a row</span>}
                </>}
                {progressData.value === false && <span className="fw-bold text-blue mb-2">&#128566; Ooops!</span>}
                {progressData.value === '' && <span className="fw-bold text-blue mb-2">&#128512; Let's start</span>}
            </>}
            <div className="progress mb-3">
                <div className={`${mode === 'practice' && progressData.value === true && progressData.index > 1 ? 'bg-yellow' : 'bg-sky'} progress-bar`} role="progressbar" aria-label="Basic example" style={{ width: `${(100 / questions.length) * progress}%` }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </>
    )
}

export default ProgressBar