import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchData, UPSERT_CHECKOUT_CONTENT, GET_WEBSITE_CONTENT, SETTING_PAGE } from "../../components/Service";
import { validateForm, ButtonSpinner, removeMsg } from "../../components/CommonMethod";  
import ImageDropdown from "../../components/ImageDropdown";

const Checkout = () => {
    
    window.document.title = "Checkout Page";
    const [tinyloader, setTinyloader] = useState('')
    const [data, setData] = useState('')
    const [imageData, setImageData] = useState('');

    const saveCheckoutData = (e) => {
        let formdata = new FormData(document.getElementById('checkoutForm'))
        if (validateForm(e, "checkoutForm")) {
            setTinyloader(true);
            fetchData(UPSERT_CHECKOUT_CONTENT, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    removeMsg()
                }
            });
        };
    }

    useEffect(() => {
        fetchData(GET_WEBSITE_CONTENT +'/checkout-content', 'GET', '', true, false, (res) => {
            if (res.records) {
                setData(JSON.parse(res.records.value))
            }
        });

        fetchData(SETTING_PAGE, 'GET', '', true, false, (res) => {
            if(res.records){ 
                setImageData(JSON.parse(res.records.value));
            }
        });
    }, [])

    return (
        <>
        <div className="container-fluid">
        <div className="page-head sticky_top_padding">
            <div className="row">
                <div className="col-sm-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                            <li className="breadcrumb-item"><Link to="/cms/website-content">Website Content</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Checkout page</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row gx-4 mb-1 align-items-lg-end align-items-md-end  align-items-center">
                <div className="col-lg-6 col-sm mb-3 col-8">
                    <h1 className="h3 text-dim mb-0">Edit ‘Checkout’ content</h1>
                </div>
                <div className="col-lg-6  col-auto mb-3 col-4">
                    <button type="button" className="btn btn-theme-primary btn-lg float-end" onClick={saveCheckoutData} disabled={tinyloader}>
                        <ButtonSpinner load={tinyloader} btnName="Save" />
                    </button>
                </div>
            </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="maxw-560 mb-2">
                        <h4 className="fw-bold">Listings Page</h4>
                    </div>
                    <form id="checkoutForm" className="needs-validation" noValidate>
                        <div className="maxw-560 mb-5 pt-3">
                            <div className="row mb-4 pb-2">
                                <div className="col-md-12">
                                    <p className="fs-18 fw-bold mb-2">Testimonial</p>
                                    <textarea name="student_message" required id="student_message" className="form-control form-control-md mb-4" defaultValue={data && data.student_message ? data.student_message : ''} placeholder="Testimonial message" rows="4"></textarea>
                                    <div className="invalid-feedback">Please write an message.</div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mt-32">
                                        <input type="text" name="student_name" required id="student_name" className="form-control form-control-md" defaultValue={data && data.student_name ? data.student_name : ''} placeholder="Testimonial giver first name" />
                                        <div className="invalid-feedback">Please Enter First Name.</div>
                                        <label>Testimonial giver first name</label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="fs-12 text-dim fw-bold mt-14 ps-3">Choose Image </div>
                                        <ImageDropdown value={data && data.student_image} imageList={imageData} name="student_image" id="student_image" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Checkout
