import React, { useState } from 'react'
import { useLocation } from 'react-router';
import { ButtonSpinner, validateForm, initialFormState } from '../../components/CommonMethod'
import { fetchData, FORGOT_PASSWORD } from '../../components/Service';

function ForgetPassword() {
    const [tinyLoader, setTinyLoader] = useState(false);
    const [email, setEmail] = useState('')
    const location = useLocation();

    var rollType = location && location.pathname && (['/cms'].includes(location.pathname) || ['/cms/'].includes(location.pathname)) ? 1345 : 2
    
    const forgotPassword = (e) => {
        if (validateForm(e, "forgotPasswordForm")) {
            setTinyLoader(true);

            fetchData(FORGOT_PASSWORD, 'POST', { email: email, user_type: rollType}, false, false, (res) => {
                setTinyLoader(false);
                
                if (res.success) {
                    document.querySelector('#forgotPasswordModal [data-bs-dismiss="modal"]').click()
                    initialFormState("forgotPasswordForm")
                } 
            })

        }
    }

    return (
        <div className="modal fade" id="forgotPasswordModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content border border-2 border-blue p-2 rounded-4">
                    <form action="#" method="post" className="needs-validation" id="forgotPasswordForm" noValidate >
                        <div className="modal-header border-0">
                            <h3 className="modal-title fw-bold text-blue">Forgot Password</h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={(e) => initialFormState('loginFormModal')} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p className="pTest">Enter the email address  that you use to log into your account and we will send you an email with a link to reset your password.</p>
                            <span className="text-danger"></span>
                            <span>
                                <div className="mb-1">
                                    <input type="email" name="email2" id="email2" placeholder="Email address" className="form-control form-control-md" onChange={(e) => setEmail(e.target.value)} autoComplete="off" required />
                                    <div className="invalid-feedback">
                                        The email field is required.
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div className="modal-footer border-0">
                            <button type="button" className="btn btn-theme-primary btn-lg minw-110 mb-3 mb-sm-2 me-auto me-sm-0 order-sm-1" onClick={(e) => forgotPassword(e)} disabled={tinyLoader}>
                                <ButtonSpinner load={tinyLoader} btnName="Submit" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword