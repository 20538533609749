import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { validateForm, ButtonSpinner, removeMsg } from '../../components/CommonMethod';
import { fetchData, UPSERT_SOSIAL_LINK_CONTENT, GET_WEBSITE_CONTENT } from '../../components/Service';

const SocialMedia = () => {
    document.title = "Social Media";
    const [tinyloader, setTinyLoader] = useState(false);
    const [socialLink, setSocialLink] = useState(false);

    const updateSocialLink = (e) => {
        let formdata = new FormData(document.getElementById('updateSocialLinkForm'))
        if (validateForm(e, 'updateSocialLinkForm')) {
            setTinyLoader(true);
            fetchData(UPSERT_SOSIAL_LINK_CONTENT, 'POST', formdata, true, true, (res) => {
                setTinyLoader(false);
                if (res.success) {
                    removeMsg()
                }
            });
        }
    }

    useEffect(() => {
        fetchData(GET_WEBSITE_CONTENT +'/social-media', 'GET', '', true, false, (res) => {
            if (res.records) {
                var content = JSON.parse(res.records.value);
                setSocialLink(content)
            }
        })
    }, []);

    return (
        <>
            <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row">
                    <div className="col-sm-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                                <li className="breadcrumb-item"><Link to="/cms/website-content">Website Content</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Social Media</li>
                            </ol>
                        </nav>
                    </div>
                </div>

                <div className="row gx-4 mb-1 align-items-lg-end align-items-md-end  align-items-center">
                    <div className="col-lg-6 col-sm mb-3 col-8">
                        <h1 className="h3 text-dim mb-0">Edit ‘Social Media’ content</h1>
                    </div>
                    <div className="col-lg-6 col-auto mb-3 col-4">
                        <button type="button" className="btn btn-theme-primary btn-lg px-3 float-end" onClick={updateSocialLink} disabled={tinyloader}>
                            <ButtonSpinner load={tinyloader} btnName="Save" />
                        </button>
                    </div>
                </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <h4 className="fw-bold mb-4">Links to social media accounts</h4>
                    </div>
                    <form action="" method='post' className="needs-validation" id="updateSocialLinkForm" noValidate>
                        <div className="col-md-12 maxw-680">
                            <div className="row align-items-center my-2">
                                <div className="col-auto">
                                    <div className="border mmwh-48 d-flex justify-content-center align-items-center rounded-circle">
                                        <svg className="icon fs-3"><use href="#icon_facebook"></use></svg>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-floating">
                                        <input type="text" name="facebook_link" id="facebook_link" defaultValue={socialLink && socialLink.facebook_link ? socialLink.facebook_link : ''} className="form-control form-control-md" placeholder="Facebook URL" required />
                                        <div className="invalid-feedback">
                                            Please enter valid Facebook URL.
                                        </div>
                                        <label>Facebook</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-2">
                                <div className="col-auto">
                                    <div className="border mmwh-48 d-flex justify-content-center align-items-center rounded-circle mt-4">
                                        <svg className="icon fs-3"><use href="#icon_instagram"></use></svg>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-floating mt-32">
                                        <input type="text" name="instagram_link" id="instagram_link" defaultValue={socialLink && socialLink.instagram_link ? socialLink.instagram_link : ''} className="form-control form-control-md" placeholder="Group name" required />
                                        <div className="invalid-feedback">
                                            Please enter valid Instagram URL.
                                        </div>
                                        <label>Instagram</label>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row align-items-center mb-2">
                                <div className="col-auto">
                                    <div className="border mmwh-48 d-flex justify-content-center align-items-center rounded-circle mt-4">
                                        <svg className="icon fs-3"><use href="#icon_twitter"></use></svg>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-floating mt-32">
                                        <input type="text" name="twitter_link" id="twitter_link" defaultValue={socialLink && socialLink.twitter_link ? socialLink.twitter_link : ''} className="form-control form-control-md" placeholder="Group name" />
                                        <div className="invalid-feedback">
                                            Please enter valid Twitter URL.
                                        </div>
                                        <label>Twitter</label>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default SocialMedia
