import { Toast } from "bootstrap";
import $ from 'jquery';
import { fetchData, CART_ITEM } from "./Service";
import _, { split } from 'lodash';
import { useState } from "react";
import { useEffect } from "react";
import CryptoJS from 'crypto-js';

export function MenuToggle() {
    document.querySelector('body').classList.toggle('menu-toggle');
    document.querySelector('body').classList.toggle('body-overflowhidden');
}

export function PrevArrow(props) {
    const { onClick } = props;
    return (
        <div className="slick-common-left" onClick={onClick}>
            <svg className="icon"><use href="#icon_arrowback"></use></svg>
        </div>
    );
}

export function NextArrow(props) {
    const { onClick } = props;
    
    return (
        <div className="slick-common-right" onClick={onClick}>
            <svg className="icon"><use href="#icon_arrowforward"></use></svg>
        </div>
    );
}

export function PasswordShow(e, elemId) {
    if (document.getElementById(elemId).getAttribute('type') === 'password') {
        document.getElementById(elemId).setAttribute('type', 'text');
        e.target.innerHTML = '<svg class="icon pe-none"><use href="#icon_eyeoff"></use></svg>'
    } else {
        document.getElementById(elemId).setAttribute('type', 'password');
        e.target.innerHTML = '<svg class="icon pe-none"><use href="#icon_eye"></use></svg>'
    }
}

export const toastNotify = (type, message) => {
    const idGenerate = Math.floor((Math.random() * 1000000) + 1);
    var seticon = type === 'success' ? 'squarecheck' : type === 'danger' ? 'cancelcircle' : type === 'warning' ? 'exclamation' : type === 'info' ? 'info' : 'bellfill';
    const htmlToast = `<div id="${idGenerate}" class="toast fade toast-${type}" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="4000">
        <div class="toast-body first-text-uppercase">
            <svg class="icon me-3"><use href="#icon_${seticon}"></use></svg>
            <span>${message}</span>
        </div>
        <button type="button" class="btn-close ms-auto me-3" data-bs-dismiss="toast" aria-label="Close" title="Close"></button>
    </div>`;
    document.getElementById('toastContainer').insertAdjacentHTML("afterbegin", htmlToast);
    var getIdToast = document.getElementById(idGenerate);
    var toast = new Toast(getIdToast);
    toast.show();
    getIdToast.addEventListener('hidden.bs.toast', function () {
        setTimeout(() => {
            this.remove();
        }, 500);
    });
}

export const __echoText = (data) => {
    return (
        data ? data : '-'
    )
}

export function RatingShow(props) {
    const [rating, setRating] =  useState(0)

    useEffect(() => {
        if(props.rating && typeof(props.rating) === 'object'){
            let correct_answer = 0
            for (const key in props.rating) {
                if(props.rating[key].correct) correct_answer++
            }
            let rating = 0
            if(parseInt((correct_answer/props.rating.length) * 3) > 0) rating += 33
            if(parseInt((correct_answer/props.rating.length) * 3) > 1) rating += 33
            if(parseInt((correct_answer/props.rating.length) * 3) > 2) rating += 34

            setRating(rating)
        }else{
            setRating(props.rating)
        }
    }, [props])
    
    return (<>
        <div className={"rating-box " + props.fontsize}>
            <div className="rating-gray">
                <svg className="icon"><use href="#icon_graystar"></use></svg>
                <svg className="icon"><use href="#icon_graystar"></use></svg>
                <svg className="icon"><use href="#icon_graystar"></use></svg>
            </div>
            <div className="rating-yellow" style={{ width: rating + '%' }}>
                <svg className="icon"><use href="#icon_yellowstar"></use></svg>
                <svg className="icon"><use href="#icon_yellowstar"></use></svg>
                <svg className="icon"><use href="#icon_yellowstar"></use></svg>
            </div>
        </div>
    </>)
}

export const validateForm = (e, form_id = false) => {
    let error = 0;
    if (form_id) {
        let form = document.getElementById(form_id)
        if (!form.checkValidity()) {
            error++;
            e.preventDefault();
            e.stopPropagation();
        }
        form.classList.add('was-validated')
    } else {
        let forms = document.querySelectorAll('.needs-validation')
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
                if (!form.checkValidity()) {
                    error++;
                    e.preventDefault();
                    e.stopPropagation();
                }
                form.classList.add('was-validated')
            })
    }

    if (error) {
        return false
    } else {
        return true
    }
}

export const ButtonSpinner = props => {
    return (
        <>
            {
                props.load ?
                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    : props.btnName
            }
        </>
    )
}

export const initialFormState = (formId, setData = false) => {
    [...document.querySelectorAll(`#${formId} .form-control, #${formId} .form-select`)].forEach((ele) => {
        ele.classList.remove('is-invalid');
    });
    document.getElementById(formId).classList.remove('was-validated')
    document.getElementById(formId).reset()
    if (setData) {
        setData(prevState => ({ ...prevState = '' }))
    }
    return setData;
}

export const checkNumberValidation = (e) => {
    let number = e.target.value;

    if (!(e.charCode === 0 || ((e.charCode >= 48 && e.charCode <= 57) || (e.charCode === 46 && number.indexOf('.') < 0)))) {
        e.preventDefault();
    }
    if (number.split('.').length > 1 && number.split('.')[1].length > 1) {
        e.preventDefault();
    }
}

export const passwordValidate = (e, setStrongPassword) => {
    setStrongPassword(true)
    if (e.target.value.length >= 8) {
        document.querySelector(".passhints li:nth-child(1)").classList.add('pwpass')
    } else {
        document.querySelector(".passhints li:nth-child(1)").classList.remove('pwpass')
        document.querySelector(".passhints li:nth-child(1)").classList.add('pwfail')
        setStrongPassword(false)
    }

    if (e.target.value.match(/[a-z]/g)) {
        document.querySelector(".passhints li:nth-child(2)").classList.add('pwpass')
    } else {
        document.querySelector(".passhints li:nth-child(2)").classList.remove('pwpass')
        document.querySelector(".passhints li:nth-child(2)").classList.add('pwfail')
        setStrongPassword(false)
    }

    if (e.target.value.match(/[A-Z]/g)) {
        document.querySelector(".passhints li:nth-child(3)").classList.add('pwpass')
    } else {
        document.querySelector(".passhints li:nth-child(3)").classList.remove('pwpass')
        document.querySelector(".passhints li:nth-child(3)").classList.add('pwfail')
        setStrongPassword(false)
    }

    if (e.target.value.match(/[0-9]/g)) {
        document.querySelector(".passhints li:nth-child(4)").classList.add('pwpass')
    } else {
        document.querySelector(".passhints li:nth-child(4)").classList.remove('pwpass')
        document.querySelector(".passhints li:nth-child(4)").classList.add('pwfail')
        setStrongPassword(false)
    }

    if (e.target.value.match(/[^a-zA-Z0-9 ]+/)) {
        document.querySelector(".passhints li:nth-child(5)").classList.add('pwpass')
    } else {
        document.querySelector(".passhints li:nth-child(5)").classList.remove('pwpass')
        document.querySelector(".passhints li:nth-child(5)").classList.add('pwfail')
        setStrongPassword(false)
    }

    if (e.target.value.length === 0) {
        document.querySelectorAll(".passhints li").forEach(function (el) {
            el.classList.remove('pwfail')
            setStrongPassword(false)
        });
    }
}

export const generateId = (length) => {
    let result = '';
    let characters = 'ABCDEFGHJKLMNOPQRSTUVWXYZ1234567890';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const copyToClipboard = (id) => {
    var textBox = document.getElementById(id);
    textBox.select();
    document.execCommand("copy");
}


export const showAlertMsg = (data, form_id = false) => {
    if (data.error || data.success) {
        const idGenerate = Math.floor((Math.random() * 1000000) + 1);
        var seticon = data.success ? 'squarecheck' : data.error ? 'cancelcircle' : 'bellfill';

        const htmlToast = `<div id="${idGenerate}" class="toast fade toast-${data.success ? 'success' : 'danger'}" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay=${form_id === 'studentForm' ? 8000 : 4000}>
            <div class="toast-body first-text-uppercase">
                <svg class="icon me-3"><use href="#icon_${seticon}"></use></svg>
                <span>${data.error ? data.error : data.success}</span>
            </div>
            <button type="button" class="btn-close ms-auto me-3" data-bs-dismiss="toast" aria-label="Close" title="Close"></button>
        </div>`;

        document.getElementById('toastContainer').insertAdjacentHTML("afterbegin", htmlToast);
        var getIdToast = document.getElementById(idGenerate);

        var toast = new Toast(getIdToast);
        toast.show();
        
        getIdToast.addEventListener('hidden.bs.toast', function () {
            setTimeout(() => {
                this.remove();
            }, 500);
        });

    } else if (data.errors) {
        $(`${form_id && `#${form_id} `}.form-control`).removeClass('is-invalid');
        $(`${form_id && `#${form_id} `}.invalid-feedback`).remove();

        for (let key in data.errors) {
            $(`${form_id && `#${form_id} `}#${key}`).addClass('is-invalid')
            $(`${form_id && `#${form_id} `}#${key}`).after(`<div class="invalid-feedback">${data.errors[key][0]}</div>`)
        }
    }
}

export const removeMsg = () => {
    $('.form-control').removeClass('is-invalid');
    $('.invalid-feedback').remove();
}

export const blobToBase64 = async (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result)
        };
        reader.readAsDataURL(blob);
    });
}

export const noImageHandle = (e) => {
    e.target.src = "/images/no-image.png";
}

export const ConfirmationModal = (props) => {
    return (
        <div className="modal fade" id="deletesection_modal" tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content overflow-hidden border border-2 border-danger rounded-4">
                    <div className="modal-body p-4">
                        <h4 className="fw-bold text-danger">{props.msg}</h4>
                        {/* <p className="m-0 pt-2">
                        This user's progress on this workbook will be deleted.
                        </p> */}
                        <p className="m-0 pt-2">
                            This is a destructive action and cannot be reversed.
                        </p>
                    </div>
                    <div className="modal-footer justify-content-between px-4 pt-0 pb-4 border-0">
                        <button type="button" className="btn btn-outline-default btn-lg" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-danger btn-lg minw-120" onClick={props.method}>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const loadingData = (active) => {
    if(active){
        document.querySelector('body').classList.add('loading-data');
    }else{
        document.querySelector('body').classList.remove('loading-data');
    }
}

export const showModel = (modalId) => {
    const boostrap = require("bootstrap/dist/js/bootstrap")
    let modal = new boostrap.Modal(document.getElementById(modalId));
    modal.show()
    modal.hide()
}

export const hideAllModals = () => {
    let body = document.querySelector("body");
    if (body && body.classList.contains('modal-open')) {
        body.classList.remove('modal-open');
        body.style = "";
        document.querySelectorAll('.modal').forEach(modal => {
            if(modal.classList.contains('show')){
                modal.classList.remove('show');
            }
          });
        if (document.querySelector(`body .modal-backdrop`)) document.querySelector(`body .modal-backdrop`).remove();
    }
};

export const showText = (question_id, question_input) => {
    var inputBoxValue = document.getElementById(question_id).value;
    document.getElementById(question_input).innerHTML = inputBoxValue;
}

export const getCountryFlagSrc = (currencyCode, price) => {
    let flag = 'flag-global.svg';

    switch(currencyCode.toUpperCase()) {
        case "IN":
        case "INR":
            flag = "flag-india.svg"
            break
        case "AU":
        case "AUD":
            flag = "flag-australia.svg"
            break
        case "US":
        case "USD":
            flag = "flag-usa.svg"
            break
        case "UK":
        case "GBP":
            flag = "flag-british.svg"
            break
        case "EUR":
            flag = "flag-euro.svg"
            break
        default:
            flag = "flag-global.svg";
    }

    return flag
}

export const wordCount = (e, id, word) => {
        var word_count = 0;
        var string = e.target.value
        var trimmed = string.trim()
        word_count = trimmed.length > 0 ? trimmed.split(' ').length : 0 

        word_count = word - word_count;

        if(Math.sign(word_count) === -1) {
            e.target.value = string.slice(0, -1)
          }
        document.getElementById(id).innerHTML  = "Words remaining: " + (word_count > 0 ? word_count : 0)    
}
export const gentPassword = (id) => {
    var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 8;
    var new_password = "";
    for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        new_password += chars.substring(randomNumber, randomNumber + 1);
    }
    document.getElementById(id).value = new_password;
}

export const getCartItem = (result) => {
    fetchData(CART_ITEM, 'GET', '', true, false, (res) => {
        let cart = document.getElementById('cart-item-count')
        if(cart) cart.innerText = (_.keysIn(res.records)).length
        let cart_mobile = document.getElementById('cart-item-mobile-count')
        if(cart_mobile) cart_mobile.innerText = (_.keysIn(res.records)).length
        return result(res.records)
    })
}

export const stopBackgroundScroll = (id) => {
    if (document.getElementById(id).classList.contains('active')) {
        document.body.classList.add('body-overflowhidden');
    }else{
        document.body.classList.remove('body-overflowhidden');
    }
}

export const downloadPDF = (url, filename = 'Report-', setLoader = false) => {
    if(setLoader) setLoader(true)
    fetchData(url, 'GET', '', true, false, (res) => {
        var name = filename + new Date().toDateString() + '.pdf';
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        if(setLoader) setLoader(false)
    },false, "blob");
}

export const calculateRangeAnswer = (range, input) => {
    let [from, to] = split(range, '-');
    if(from && to){
        from = parseFloat(from)
        to = parseFloat(to)
        input = parseFloat(input)
        return (from <= input && input <= to);
    }else{
        return range === input;
    }
}

export const minutesFormatDuration = (second) => {
    let minute = (parseFloat(second) / 60).toFixed(2);
    
    return `${minute} min`;
}

export const formatDuration = (second) => {
    let $seconds = parseInt(second)
    
    let $days = Math.floor($seconds / 86400);
    $seconds %= 86400;
    let $hours = Math.floor($seconds / 3600);
    $seconds %= 3600;
    let $minutes = Math.floor($seconds / 60);
    $seconds %= 60;

    let $result = "";
    if ($days > 0) {
        $result += $days +" days, ";
    }
    if ($hours > 0) {
        $result += $hours + " hours, ";
    }
    if ($minutes > 0) {
        $result += $minutes +" minutes, ";
    }
    if ($seconds > 0) {
        $result += $seconds +" seconds";
    }
    
    return $result;
}

export const encodedText = (text, app_key) => {
    // app_key = Laravel APP_KEY in Laravel
    let iv = CryptoJS.lib.WordArray.random(16)
    let key = CryptoJS.enc.Base64.parse(app_key);
    let options = { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 };
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(text), key, options).toString();
    iv = CryptoJS.enc.Base64.stringify(iv);
    let result = { iv: iv, value: encrypted, mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString() };
    result = JSON.stringify(result);
    result = CryptoJS.enc.Utf8.parse(result);
    result = CryptoJS.enc.Base64.stringify(result);
    // $decrypted = json_decode(decrypt(result, false)) // In Laravel
    return result;
}

export const decodedText = (text, app_key) => {
    // app_key = Laravel APP_KEY in Laravel
    // $encrypted = encrypt("Akash", false);   // In Laravel
    let encrypted = window.atob(text);
    encrypted = JSON.parse(encrypted);
    let iv = CryptoJS.enc.Base64.parse(encrypted.iv);
    let key = CryptoJS.enc.Base64.parse(app_key);
    let decrypted = CryptoJS.AES.decrypt(encrypted.value, key, { iv: iv });
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    return decrypted;
}

export const removeBase64ImagesFromHtml = (htmlString) => {
    let tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
    tempElement.querySelectorAll('img').forEach(img => {
        img.src = process.env.REACT_APP_ASSET_ENDPOINT + "no-image.png";
    })

    return tempElement.innerHTML;
}
