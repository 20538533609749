import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'
import './index.scss';
import Routing from './components/Routing';
import Icons from './components/Icons';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
    <>
        <Router>
            <Routing/>
        </Router>
        <Icons />
    </>
);
reportWebVitals();
