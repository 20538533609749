import { useContext, useState } from "react";
import { Context } from "../../components/Context";
import { fetchData, CREATE_TEACHER, UPDATE_TEACHER } from '../../components/Service';
import { validateForm, initialFormState, ButtonSpinner, copyToClipboard, gentPassword, ConfirmationModal, removeMsg, PasswordShow } from "../../components/CommonMethod";
import { useLocation, useNavigate } from "react-router-dom";

const CreateTeacher = () => {

    const location = useLocation();
    let teacherInfo = location.state
    let title = teacherInfo ? "Update Teacher" : "Create Teacher"

    window.document.title = title

    const navigate = useNavigate();

    const [tinyloader, setTinyloader] = useState(false);
    const [context] = useContext(Context)

    const saveTeacher = (e) => {
        let formdata = new FormData(document.getElementById('createTeacherForm'));
        if (validateForm(e, 'createTeacherForm')) {
            setTinyloader(true)

            fetchData(teacherInfo ? UPDATE_TEACHER : CREATE_TEACHER, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    initialFormState('createTeacherForm')
                    removeMsg()
                    navigate('../users')
                }
            })
        }
    }
    return (
        <>
            <div className="container-fluid">
                <div className="page-head sticky_top_padding">
                    <div className="row gx-4 mb-1 align-items-center">
                        <div className="col-lg-6 col-sm-8 mb-3 col-7">
                            <h1 className="h3 text-dim mb-0">{title}</h1>
                        </div>
                        <div className=" col-lg-6  col-sm-4 mb-3 col-5 mb-3">
                            <button type="button" className="btn btn-theme-primary btn-lg px-3 float-end" disabled={tinyloader} onClick={(e) => saveTeacher(e)}>
                                <ButtonSpinner load={tinyloader} btnName="Save and Exit" />
                            </button>
                        </div>
                    </div> </div>
                <div className="row">
                    <form className="needs-validation" method="post" id="createTeacherForm" noValidate autoComplete="off">
                        <div className="col-sm-12">
                            <div className="maxw-600">
                                <h5 className="line-heading text-blue py-2"><span>Teacher details</span></h5>
                                <div className="row g-4">
                                    <div className="col-sm-6 pt-2">
                                        <div className="form-floating">
                                            <input type="hidden" defaultValue={teacherInfo && teacherInfo.id} name="id" id="id" />
                                            <input type="text" name="first_name" id="first_name" className="form-control form-control-md" placeholder="First name" defaultValue={teacherInfo && teacherInfo.first_name} required />
                                            <div className="invalid-feedback">
                                                The first name field is required.
                                            </div>
                                            <label>First name <span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 pt-2">
                                        <div className="form-floating">
                                            <input type="text" name="last_name" id="last_name" className="form-control form-control-md" placeholder="Last name" defaultValue={teacherInfo && teacherInfo.last_name} required />
                                            <div className="invalid-feedback">
                                                The last name field is required.
                                            </div>
                                            <label>Last name <span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 pt-2">
                                        <div className="form-floating">
                                            <input type="hidden" className="form-control form-control-md" name="course_id" id="course_id" defaultValue={context && context.auth && context.auth.group_details && context.auth.group_details.course_id} />
                                            <input type="text" className="form-control form-control-md" placeholder="Course name" defaultValue={context && context.auth && context.auth.group_details && context.auth.group_details.course_abbr ? context.auth.group_details.course_abbr : teacherInfo.course_abbr} disabled />
                                            <label>Course name<span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    <input type="hidden" className="form-control form-control-md" name="year_level" id="year_level" defaultValue={4} />
                                    <div className="col-md-6 pt-2">
                                        <div className="form-floating">
                                            <input type="text" className="form-control form-control-md" name="group_code" id="group_code" placeholder="Course name" defaultValue={context && context.auth && context.auth.group_details && context.auth.group_details.group_code ? context.auth.group_details.group_code : teacherInfo.school_group_code} required readOnly />
                                            <label>Group Code<span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                </div>
                                <h5 className="line-heading text-blue pt-4"><span>Account details</span></h5>
                                <p>
                                    You’ll be able to access your workbooks later by using these details.
                                </p>
                                <div className="row g-3">
                                    <div className="col-lg-6 pt-2">
                                        <div className="form-floating">
                                            <input type="email" name="email" id="email" className="form-control form-control-md" placeholder="Email address" defaultValue={teacherInfo && teacherInfo.email} required autoComplete="new-password" />
                                            <div className="invalid-feedback">
                                                The email field is required.
                                            </div>
                                            <label>Email address <span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-lg-1 pt-2">
                                        <button type="button" className="btn btn-outline-default" onClick={() => copyToClipboard('email')}>
                                            Copy
                                        </button>
                                    </div>
                                    <div className="col-sm-12 m-0"></div>
                                    <div className="col-lg-6 pt-2">
                                        <div className="form-floating password-wrapper">
                                            <input type="text" name="password" placeholder="Password" id="password" className="form-control form-control-md" defaultValue="" required={teacherInfo && teacherInfo.id ? false : true} autoComplete="off" />
                                            <div className="invalid-feedback position-absolute">
                                                The password field is required.
                                            </div>
                                            <div>
                                                <button type="button" className="btn eye-btn" onClick={(e) => PasswordShow(e, 'password')}>
                                                    <svg className="icon pe-none"><use href="#icon_eyeoff"></use></svg>
                                                </button>
                                            </div>
                                            <label>Password <span className="text-danger" id="required_attr">*</span></label>

                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-md-1  col-1 pt-2 tec_btn-left">
                                        <button type="button" className="btn btn-outline-default" onClick={() => copyToClipboard('password')}>Copy</button>
                                    </div>

                                    <div className="col-lg-4 col-md-10 col-9 pt-2 ms-4 small_dev-end" >
                                        <button type="button" className="btn btn-theme-primary " onClick={() => gentPassword('password')}>Generate Password</button>
                                    </div>
                                    <div className="col-lg-8 text-secondary pt-2">Password should be minimum 8 characters!</div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ConfirmationModal />

        </>);
}

export default CreateTeacher;