import { useEffect, useState } from "react";
import Sortable from 'sortablejs';
import { fetchData, QUESTIONS_LIST, QUESTION_DELETE, QUESTION_DUPLICATE } from "../../../components/Service";
import { now } from 'lodash'
import { ConfirmationModal, toastNotify } from "../../../components/CommonMethod";
import Question from "./Question";

export const ManageQuestion = (props) => {
    const [questionList, setQuestionList] = useState(props.selectedQuestionType)
    const [refresh, setRefresh] = useState(now);
    const [reloadQuestions, setReloadQuestions] = useState();
    const [dataToDelete, setDataToDelete] = useState({ questionElementId: '', questionIndex: '', questionId: '' })

    const questionAction = (action, questionId = '') => {
        if(action === 'delete'){
            if(dataToDelete.questionId){
                fetchData(QUESTION_DELETE + '/' + dataToDelete.questionId + '?workbook_id=' + props.workbookInfo.id, 'GET', '', true, true, (res) => {
                    if (res.success) {
                        let questionDiv = document.getElementById(dataToDelete.questionElementId);
                        questionDiv.classList.add("d-none")
                        questionDiv.innerHTML = '';
                        orderReset()
                    }
                })
            }else{
                let questionDiv = document.getElementById(dataToDelete.questionElementId);
                questionDiv.classList.add("d-none")
                questionDiv.innerHTML = '';
                toastNotify('success', 'Question deleted successfully.')
            }
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            orderReset()
        }

        if(action === 'copy' && questionId){
            fetchData(QUESTION_DUPLICATE + '/' + questionId + '?workbook_id=' + props.workbookInfo.id, 'GET', '', true, true, (res) => {
                if (res.success) {
                    props.setSelectedQuestionType([res.records]);
                }
            })
        }
    }

    const orderReset = () => {
        var parentSection = document.querySelector('#allquestions')
        
        var children_list_heading = parentSection.querySelectorAll('.question-serial')
        var children_hidden_field = parentSection.querySelectorAll('.question-order')
        
        if (children_list_heading.length > 0) {
            for (let i = 0; i < parentSection.childElementCount; i++) {
                if(children_list_heading[i] && children_hidden_field[i]){
                    children_list_heading[i].innerText = 'Question ' + (i + 1)
                    children_hidden_field[i].value = i + 1
                }
            }
        }
    }

    useEffect(() => {
        if(props.workbookInfo){
            setQuestionList([]);
            fetchData(QUESTIONS_LIST + '/' + props.workbookInfo.id, 'GET', '', true, true, (res) => {
                if (res.records) {
                    setQuestionList([...res.records]);
                }
            })
        }
        // eslint-disable-next-line
    }, [props.reloadQuestions, reloadQuestions])

    useEffect(() => {
        setQuestionList([...questionList, ...props.selectedQuestionType])
        // eslint-disable-next-line
    }, [props.selectedQuestionType])

    useEffect(() => {
        Sortable.create(document.getElementById('allquestions'), {
            group: 'allquestions',
            handle: '.sortable-handler',
            animation: 150,
            onSort: function () {
                setRefresh(now)
            }
        });
    }, [refresh]);

    useEffect(() => {
        orderReset()
    });

    return (
        <div className="scrool_width_responsive tab-pane fade " id="queriesBox" role="tabpanel" tabIndex="0">
            {/* <form action="" className="needs-validation" id="addQuestionForm" noValidate autoComplete="off"> */}
                <div className="float-right ">
                    <div className="text-md-end mb-4 pb-2 mmt-md-80">
                        <button type="button" className="btn btn-green btn-lg px-4" data-bs-toggle="modal" data-bs-target="#querytypemodal">+&nbsp; Add new question</button>
                    </div>
                </div>
            
                <div className="d-block" id="allquestions" >
                    { questionList && questionList.length > 0 && questionList.map((ele, i) => {
                        return <div id={ele.id ? `add_${i}` : `edit_${i}`} className="rounded-3 p-3 border border-dark mb-4 tab_width-t" key={ele.id ? `add_${i}` : `edit_${i}`}><Question questionElementId={ele.id ? `add_${i}` : `edit_${i}`} questionIndex={i} questionTypeList={props.questionTypeList} questionType={ele.question_type} questionAction={questionAction} setDataToDelete={setDataToDelete} questionData={ele} workbookInfo={props.workbookInfo} setReloadQuestions={setReloadQuestions} /></div>
                    }) }
                </div>
            {/* </form> */}
            <ConfirmationModal msg="Delete this question?" method={() => questionAction('delete') } />
            
            <div className="modal fade" id="imagemodal" tabIndex="-1" style={{zIndex: '11111'}}>
                <div className="modal-dialog modal-sm">
                    <div className="modal-content overflow-hidden">
                        <div className="modal-header bg-grey p-4">
                            <h3 className="modal-title fw-bold">Select Image</h3>
                            <button type="button" id="image_upload_close" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body p-4">
                            <div className="form-floating mt-32">
                                <input type="file" className="form-control form-control-md" />
                                <label>Upload Image</label>
                            </div>
                            <div className="form-floating mt-32">
                                <input type="text" id="alt" className="form-control form-control-md" />
                                <label>Alternate Text</label>
                            </div>
                            <div className="form-floating mt-32">
                                <input type="number" id="height" className="form-control form-control-md" />
                                <label>Height</label>
                            </div>
                            <div className="form-floating mt-32">
                                <input type="number" id="width" className="form-control form-control-md" />
                                <label>Width</label>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between p-4">
                            <button type="button" id="image_upload" className="btn btn-green btn-sm">save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageQuestion