import React, { useState } from 'react'
import { __echoText, noImageHandle } from "./CommonMethod";
import { fetchData, ASSET_ENDPOINT, LISTING_LIST } from "./Service";
import { DataTable } from "./DataTable";
import { format } from 'date-fns';
import { now } from 'lodash'
import { useEffect } from 'react';

const AssignWorkbookModal = (props) => {
    const [refresh, setRefresh] = useState(true);
    let group_code = props.group_code
    let student_id = props.student_id ?? null
    
    const publishedListing = {
        id: 'publishedListing',
        fetchurl: LISTING_LIST + '/published',
        query_string: student_id ? `assign_student_id=${student_id}` : `assign_group_code=${group_code}`,
        columns: [
            { data: 'image', name: 'image', title: '' },
            { data: 'title', name: 'title', title: 'LISTING TITLE' },
            { data: 'course_abbr', name: 'course_id', title: 'COURSE' },
            { data: 'topic_name', name: 'course_topic_id', title: 'COURSE FILTERS' },
            { data: 'type', name: 'type', title: 'TYPE' },
            { data: 'updated_at', name: 'updated_at', title: 'LAST EDITED' },
            { data: 'action', name: 'action', title: 'ACTION', sorting: false, searching: false }
        ]
    }

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);

    }

    const assignWorkbook = (id) => {
        let formdata = student_id ? {student_id: student_id, shop_listing_id: id} : { group_code: group_code, shop_listing_id: id}
        fetchData(props.api, 'POST', formdata, true, false, (res) => {
            if (res.success) {
                setRefresh(now)
                props.setRefresh(now)
                document.querySelector('#addWorkbooks_modal .btn-close').click();
            }
        });
    }

    useEffect(() => {
        if(props.assignWorkbook)
        setRefresh(now)
    }, [props.assignWorkbook])
    
    return (
        <div className="modal fade" id="addWorkbooks_modal" tabIndex="-1">
            <div className="modal-dialog modal-xl modal-dialog-scrollable">

                <div className="modal-content overflow-hidden border border-2 border-blue rounded-4">
                    <div className="modal-header bg-grey p-4">
                        <h3 className="modal-title fw-bold">Select workbooks to assign</h3>
                        <div className="d-flex align-items-center">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body p-4">
                        <DataTable
                            execute={refresh}
                            dt={publishedListing}
                            edit_column={{
                                image: (records) =>
                                    <div className="ratio ratio-1x1 mmw-56 rounded-3 shadow-md overflow-hidden" >
                                        <img src={ASSET_ENDPOINT + records.image} alt={records.title} onError={noImageHandle} />
                                    </div>
                                ,

                                title: (records) =>
                                    __echoText(`${records.title}`)

                                ,
                                course_abbr: (records) => {
                                    return records.additional_data.course && records.additional_data.course.length > 0 &&  
                                    records.additional_data.course.map((item, index) => {
                                        return (
                                            <div key={index} className="minw-75 text-white fw-bold text-uppercase d-inline-block rounded-pill px-3 py-1 m-2 text-center" style={{ background: `hsl(${item.accent_color}, 100%, 50%)` }}>
                                                {__echoText(`${item.course_abbr}`)}
                                            </div>
                                        )
                                    })
                                },


                                type: (records) =>
                                    Capitalize(__echoText(`${records.type}`)),

                                topic_name: (records) => {
                                    return records.additional_data.course_filter && records.additional_data.course_filter.length > 0 && 
                                    records.additional_data.course_filter.map((item, index) => <div key={index} className="mb-2" dangerouslySetInnerHTML={{__html: item}}></div>)
                                },

                                updated_at: (records) =>
                                    __echoText(`${format(new Date(records.updated_at), 'MM / dd / yyyy')}`),
                                action: (records) =>
                                    <div className="d-flex">
                                        <button type="button" className="btn btn-lg btn-outline-default minw-160" onClick={() => assignWorkbook(records.id)}>Assign</button>
                                    </div>

                            }}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AssignWorkbookModal