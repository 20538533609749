import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchData, UPSERT_SHOPT_CONTENT, SETTING_PAGE, GET_WEBSITE_CONTENT} from "../../components/Service";
import { validateForm, ButtonSpinner, removeMsg} from "../../components/CommonMethod";
import ImageDropdown from "../../components/ImageDropdown";

const Shop = () => {
    window.document.title = "Shop Page";
    const [tinyloader, setTinyloader] = useState('')
    const [shopList, setShopList] = useState([]) 
    const [imageData, setImageData] = useState('');
    
    const addShopContent = (e) => {
        let formdata = new FormData(document.getElementById('shopContentForm'))
        if (validateForm(e, "shopContentForm")) {
            setTinyloader(true);
            fetchData(UPSERT_SHOPT_CONTENT, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);
                if (res.success) {
                    removeMsg()
                }
            });
        };
    }

    useEffect(() => {
        fetchData(GET_WEBSITE_CONTENT +'/shop-content', 'GET', '', true, false, (res) => {
            if (res.records) {
                setShopList(JSON.parse(res.records.value))
            }
        });

        fetchData(SETTING_PAGE, 'GET', '', true, false, (res) => {
            if(res.records){ 
                setImageData(JSON.parse(res.records.value));
            }
        });

    }, [])

    return (
        <>
            <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row">
                    <div className="col-sm-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                                <li className="breadcrumb-item"><Link to="/cms/website-content">Website Content</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Shop page</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row gx-4 mb-1 align-items-lg-end align-items-md-end  align-items-center">
                    <div className="col-lg-6 col-sm mb-3 col-8">
                        <h1 className="h3 text-dim mb-0">Edit ‘Shop’ content</h1>
                    </div>
                    <div className="col-lg-6 col-auto mb-3 col-4">
                        <button type="button" className="btn btn-theme-primary btn-lg float-end" onClick={addShopContent} disabled={tinyloader}>
                            <ButtonSpinner load={tinyloader} btnName="Save" />
                        </button>
                    </div>
                </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="maxw-560 mb-2">
                            <h4 className="fw-bold">Listings Page</h4>
                        </div>
                        <form className="needs-validation" noValidate id="shopContentForm">
                            <div className="maxw-560 mb-5 pt-3">
                                {shopList && shopList.testimonials && shopList.testimonials.length > 0 && shopList.testimonials.map((ele, num) => {
                                    return (
                                        <div className="row mb-4 pb-2" key={num}>
                                            <div className="col-md-12">
                                                <p className="fs-18 fw-bold mb-2">Testimonial {num + 1} <strong className="text-danger">*</strong></p>
                                                <textarea className="form-control form-control-md mb-4" required name={`testimonial[${num}][student_message]`} defaultValue={ele.student_message ?? ''} placeholder={`Testimonial ${num + 1}`} rows="4"></textarea>
                                                <div className="invalid-feedback">Please fill testimonial message.</div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating mt-32">
                                                    <input type="text" required className="form-control form-control-md" name={`testimonial[${num}][student_name]`} defaultValue={ele.student_name ?? ''} placeholder="Testimonial giver first name" />
                                                    <div className="invalid-feedback">Please fill testimonial giver name.</div>
                                                    <label>Testimonial giver first name <strong className="text-danger">*</strong></label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="fs-12 text-dim fw-bold mt-14 ps-3">Choose Image</div>
                                                <ImageDropdown value={ele.student_image} imageList={imageData} name={`testimonial[${num}][student_image]`} id={`testimonial.${num}.student_image`} />
                                            </div>
                                        </div>
                                    )
                                })}

                                <div className="row mb-4">
                                    <h4 className="fw-bold mb-3">Details Page</h4>
                                    <div className="col-md-12">
                                        <p className="fs-18 fw-bold mb-2">Testimonial <strong className="text-danger">*</strong></p>
                                        <textarea className="form-control form-control-md mb-4" id="detail_message" name="detail_message" defaultValue={shopList && shopList.detail_message ? shopList.detail_message : ''} placeholder="Testimonial message" rows="4" required></textarea>
                                        <div className="invalid-feedback">Detail message field is required.</div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mt-32">
                                            <input type="text" className="form-control form-control-md" id="detail_name" name="detail_name" defaultValue={shopList && shopList.detail_name ? shopList.detail_name : ''} placeholder="Testimonial giver first name" required/>
                                            <div className="invalid-feedback">Testimonial giver first name is required.</div>
                                            <label>Testimonial giver first name <strong className="text-danger">*</strong></label>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="fs-12 text-dim fw-bold mt-14 ps-3">Choose Image</div>
                                            <ImageDropdown value={ shopList && shopList.detail_image } imageList={imageData} name="detail_image" id="detail_image" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Shop
