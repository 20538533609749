import { useContext } from "react";
import { Context } from "../../../components/Context";
import { Link } from "react-router-dom";
const HomeBanner = (props) => {
    const [context] = useContext(Context)
    return(
        <section className="home-section1 bg-grey" id="padding-top">
            <img src="images/vector/shape1.svg" className="homebg-cover" alt="shape" />
            <div className="container container-1040 position-relative">
                <div className="row g-4 align-items-center">
                    <div className="col-sm-6 order-sm-1">
                        <h1 className="display-3 fw-bold mb-3">{ props.homeContent && props.homeContent.title}</h1>
                        <p className="h3 fw-normal maxw-360 mb-4">
                            { props.homeContent.sub_title}
                        </p>
                        <div className="home-sec-content d-flex align-items-start justify-content-center mt-5 pt-5">
                            <svg className="icon mmwh-35 sm-mmwh-28 text-blue">
                                <use href="#icon_subtract"></use>
                            </svg>
                            <div className="px-3">
                                <h2 className="fw-bold mb-3">Get started today!</h2>
                                <h4 className="fw-medium mb-4">Receive a free workbook when you create an account.</h4>
                                <div className="d-flex manage_sign_guest_btn gap-3">
                                {context && context.auth ?
                                    <Link to={`${context.auth.user_type === '2' ? '/my-workbooks' : '/cms'}`} className="btn btn-theme-primary btn-lg w-100 mt-2">{context.auth.user_type === '2' ? 'My Workbook' : 'Dashboard'}</Link>
                                    : <Link to="/sign-up" className="btn btn-theme-primary white-space btn-lg w-100"> Sign up</Link>
                                }
                                  {context && context.auth ? '': <Link to="/shop" className="btn btn-theme-success white-space btn-lg w-100">Stay as a guest</Link>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="ratio ratio-1x1 position-relative">
                            <div className="hey-man text-center">
                                <div className="circle1"></div>
                                <div className="circle2"></div>
                                <img src="images/male.png" className="img-fluid boy_img" alt="male" style={{transform: 'translateY(25px)'}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeBanner