import { useEffect, useState } from "react";
import { fetchData, DROPDOWN_DATA } from "../components/Service"

export const ShowPrice = ({currencyData, price}) => {
    return ( 
        <>
            {currencyData && currencyData.currency_type ? (<>
                {currencyData.currency_type === 'AUD' && 'AUD $'}
                {currencyData.currency_type === 'USD' && 'USD $'}
                {currencyData.currency_type === 'EUR' && 'EUR €'}
                {currencyData.currency_type === 'GBP' && 'GBP £'}
                {currencyData.currency_type === 'INR' && 'INR ₹'}
            </>) : 'AUD $'} 
            {currencyData && currencyData.currency_price ? parseFloat(currencyData.currency_price * price).toFixed(2) : price}
        </> 
    )
}

export const ShowCountry = (props) => {
    return ( 
        <>
            <img 
                src={
                    props.currencyType === 'INR' ? `images/vector/flag-india.svg` :
                    props.currencyType === 'USD' ? `images/vector/flag-usa.svg` : 
                    props.currencyType === 'GBP' ? `images/vector/flag-british.svg` : 
                    props.currencyType === 'EUR' ? `images/vector/flag-euro.svg` : `images/vector/flag-australia.svg`
                }
                className="img-fluid me-2" style={{ maxWidth: '20%' }} alt="country-flags" 
            />
            {props.currencyType ?? 'AUD'}
        </> 
    )
}

function CountryFilter(props){
    const [currency, setCurrency] = useState('');

    useEffect(() => {
        fetchData(DROPDOWN_DATA + '?type=currency', 'GET', '', true, true, (res) => {
            if (res.records) {
                if(res.records){
                    setCurrency(res.records.currency)
                }
            }
        })
    }, []);

    return(
        <>
            <div className="col-lg-12 col-md-10  col-10 d-lg-flex justify-content-between ">
                <div>
                    <h3 className="fw-bold">{props.title ?? ''}</h3>
                </div>
                <div className="dropdown">
                    <button className="btn border-dim bg-white text-dark" style={{ width: '127px', height: '48px' }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <ShowCountry currencyType={props.currencyData && props.currencyData.currency_type ? props.currencyData.currency_type : null} />
                        <svg className="icon ms-2"><use href="#icon_arrowdown"></use></svg>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton" style={{zIndex:1}}>
                        <li>
                            <span role="button" className="dropdown-item" onClick={() => props.setCurrencyData({currency_price: 0.0, currency_type: "AUD", id: 1})}>
                                <ShowCountry currencyType="AUD" />
                            </span>
                        </li>
                    { currency && currency.length > 0 && currency.map((item, index) => {
                        return (
                            <li key={index}>
                                <span role="button" className="dropdown-item" onClick={() => props.setCurrencyData(item)}>
                                    <ShowCountry currencyType={item.currency_type} />
                                </span> 
                            </li>
                        )
                    }) }
                    </ul>
                </div>
            </div>
        </>
    )

}

export default CountryFilter
