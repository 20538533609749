import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { validateForm, initialFormState, blobToBase64, noImageHandle, ConfirmationModal, ButtonSpinner } from "../../components/CommonMethod";
import { fetchData, COURSE_CREATE, COURSE_UPDATE, COURSE_DELETE, ASSET_ENDPOINT } from "../../components/Service";
import CountryDropDown from "../../components/CountryDropDown";

const CreateCourse = () => {
    const location = useLocation();
    const navigate = useNavigate();

    let courseInfo = location.state
    let title = courseInfo ? "Update Course" : "Create Course"
    window.document.title = title

    const [course] = useState(courseInfo ?? '');
    const [tinyloader, setTinyloader] = useState(false);
    const [imageb64, setImageb64] = useState('')
    const [calculator, setCalculator] = useState(courseInfo && courseInfo.calculator ? courseInfo.calculator : 'no')

    const saveCourse = (e) => {
        let formdata = new FormData(document.getElementById('createCourseForm'));

        if (validateForm(e, 'createCourseForm')) {
            setTinyloader(true);

            fetchData(courseInfo ? COURSE_UPDATE : COURSE_CREATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);

                if (res.success) {
                    initialFormState('createCourseForm')
                    navigate('../manage-courses')
                }
            });
        }
    }

    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        setImageb64(imgb64)
    }

    const deleteRecords = (id) => {
        fetchData(COURSE_DELETE + '?id=' + id, 'GET', '', true, false, (res) => {
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                navigate('../manage-courses')
            }
        });
    }

    useEffect(() => {
        const rangeInput = document.querySelector('.js-range-input');
        const output = document.querySelector('.js-range-output');
        const colorcode = document.querySelector('#js-color-code');
        const root = document.documentElement;

        function setHue() {
            document.getElementById('color').value = rangeInput.value;
            output.value = rangeInput.value + '°';
            root.style.setProperty('--hue', rangeInput.value);
            colorcode.innerHTML = `hsl(${rangeInput.value}, 100%, 50%)`;
        }

        rangeInput.addEventListener('input', function () {
            setHue();
        });

        setHue();

        setCalculator(course.calculator ?? 'no')
    }, [course])

    return (
        <>
            <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row">
                    <div className="col-sm-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                                <li className="breadcrumb-item"><Link to="/cms/manage-courses">Manage courses</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{title}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                    <div className="row gx-4 mb-1 align-items-end">
                        <div className="col-lg-6 col-sm mb-3 col-6">
                            <h1 className="h3 text-dim mb-0">{title}</h1>
                        </div>
                        <div className="col-lg-6 col-auto mb-3 col-6">
                            <button type="button" className="btn btn-theme-primary btn-lg px-3 mt-2 float-end" onClick={(e) => saveCourse(e)}>
                                <ButtonSpinner load={tinyloader} btnName="Save and exit" />
                            </button>
                        </div>  
                    </div>
                    </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="maxw-620">
                            <form className="needs-validation" id="createCourseForm" noValidate autoComplete="off">
                                <input type="hidden" name="id" value={course.id} />
                                <h4 className="fw-bold mb-1">Display title</h4>
                                <p className="mb-3">The display titles for this course.</p>
                                <div className="row mb-5">
                                    <div className="col-sm-4">
                                        <div className="form-floating mt-30">
                                            <input type="text" name="abbreviation" id="abbreviation" className="form-control form-control-md mb-3" defaultValue={course && course.course_abbr} placeholder="Abbreviation" required />
                                            <div className="invalid-feedback">
                                                The abbreviation field is required .
                                            </div>
                                            <label>Abbreviation <strong className="text-danger">*</strong></label>
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="form-floating mt-30">
                                            <input type="text" name="full_term" id="full_term" className="form-control form-control-md text-capitalize" defaultValue={course && course.course_term} placeholder="Full term" required />
                                            <div className="invalid-feedback">
                                                The  full term field is required .
                                            </div>
                                            <label>Full Term <strong className="text-danger">*</strong></label>
                                        </div>
                                    </div>
                                </div>

                                <h4 className="fw-bold mb-1">Country</h4>
                                <p className="mb-lg-0 mb-md-0 mb-3">The country associated with this course.</p>
                                <div className="form-floating mt-32">
                                    <CountryDropDown selected={course.country} />

                                    <div className="invalid-feedback">
                                        The country field is required.
                                    </div>
                                    <label>Country <strong className="text-danger">*</strong></label>
                                </div>

                                <h4 className="fw-bold mb-1 mt-5">Calculator <strong className="text-danger">*</strong></h4>
                                <div className="d-flex ">
                                    <label className="form-check form-check-custom">
                                        <input className="form-check-input" type="radio" name="calculator" value="yes" checked={calculator === 'yes'} onChange={(e) => setCalculator(e.target.value)} />
                                        <div className="form-check-label fw-bold">Yes</div>
                                    </label>
                                    <label className="form-check form-check-custom ms-3">
                                        <input className="form-check-input" type="radio" name="calculator" value="no" checked={calculator === 'no'} onChange={(e) => setCalculator(e.target.value)} />
                                        <div className="form-check-label fw-bold">No</div>
                                    </label>
                                </div>

                                <h4 className="fw-bold mb-1 mt-5">Periodic Table</h4>
                                <small className="m-0 text-muted">Upload a custom periodic table for students to view on this course only .jpeg, .jpg, & .png format.</small>
                                <div className="d-flex flex-column mt-3">
                                    <label htmlFor="periodic_table" className="maxw-200 mb-3">
                                        <img src={imageb64 ? imageb64 : (course && course.image ? ASSET_ENDPOINT + course.image : '../images/chemistry-formulae.png')} className="img-fluid" alt="Periodic Table" onError={noImageHandle} />
                                    </label>
                                    <div className="overflow-hidden position-relative">
                                        <input type="file" name="periodic_table" id="periodic_table" accept=".jpg,.jpeg,.png" className="position-absolute opacity-0" onChange={async (e) => imageSet(e)} />
                                    </div>
                                    <label htmlFor="periodic_table" className="btn btn-theme-primary px-4 maxw-250">{courseInfo ? 'Reupload an image' : 'Upload an image'}</label>
                                </div>

                                <div className="row my-4">
                                    <h4 className="fw-bold mt-2">Accent colour <strong className="text-danger">*</strong></h4>
                                    <small className="mb-0 text-muted">Choose an accent colour for this course type.</small>
                                    <div className="col-md-8 pt-3">
                                        <h6 className="text-uppercase fs-12">Color Picker</h6>
                                        <div className="ratio ratio-1x1 rounded-3 maxw-200" style={{ background: 'var(--color-alpha)' }}>
                                            <div className="d-flex align-items-center justify-content-center text-white fw-bold fs-12 p-2" id="js-color-code"></div>
                                        </div>
                                        <div className="color-range">
                                            <div className="color-range-holder d-grid">
                                                <input type="hidden" required id="color" defaultValue={'50'} />
                                                <input className="color-range-slider js-range-input" name="accent_color" id="accent_color" type="range" max="360" defaultValue={course.accent_color ?? 50} />
                                                <output className="color-range-output js-range-output" htmlFor="accent_color"></output>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 pt-3">
                                        <h6 className="text-uppercase fs-12">Sample Visuals</h6>
                                        <div className="bg-grey2 p-3 rounded-3">
                                            <div className="d-flex flex-wrap gap-2 mb-31 pt-1">
                                                <div className="d-flex">
                                                    <div className="mmwh-24 text-white d-flex justify-content-center align-items-center rounded-circle me-2" style={{ background: 'var(--color-alpha)' }}>
                                                        <svg className="icon ts-1p1"><use href="#icon_class"></use></svg>
                                                    </div>
                                                    <span className="fw-bold">Section 2.2</span>
                                                </div>
                                                <div className="d-flex me-3">
                                                    <div className="mmwh-24 text-white d-flex justify-content-center align-items-center rounded-circle me-2" style={{ background: 'var(--color-alpha)' }}>
                                                        <svg className="icon ts-1p1"><use href="#icon_help"></use></svg>
                                                    </div>
                                                    <span className="fw-bold">15 questions</span>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="mmwh-24 text-white d-flex justify-content-center align-items-center rounded-circle me-2" style={{ background: 'var(--color-alpha)' }}>
                                                        <svg className="icon"><use href="#icon_books"></use></svg>
                                                    </div>
                                                    <span className="fw-bold">20 minutes</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {courseInfo && <div className="maxw-620 my-5">
                            <h4 className="fw-bold">Delete course</h4>
                            <p>
                                This is a destructive action and cannot be reversed. Any listings under this course will be deleted forever.
                            </p>
                            <button type="button" className="btn btn-danger px-3 btn-lg" data-bs-toggle="modal" data-bs-target="#deletesection_modal">
                                Delete course
                            </button>
                        </div>}
                    </div>
                </div>
            </div>

            <ConfirmationModal msg={`Delete ${course.course_term} ?`} method={() => deleteRecords(course.id)} />
        </>
    )
}
export default CreateCourse;