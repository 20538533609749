import { useEffect } from 'react';
import ReactApexChart from "react-apexcharts";


const UsersMap = (props) => {
    const linechartcustomerColors = ["#405189", "#0ab39c", "#f06548"];
    const series = [
        {
            name: "Users",
            type: "area",
            data: props.data && props.data.count,
        },
    ];
    var options = {
        chart: {
            height: 370,
            type: "line",
            toolbar: {
                show: false,
            },
        },
        xaxis: {
            categories: props.data && props.data.months,
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
            },
        },
        legend: {
            show: true,
            horizontalAlign: "center",
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "30%",
                barHeight: "70%",
            },
        },
        colors: linechartcustomerColors,
        tooltip: {
            shared: true,
            y: [
                {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0);
                        }
                        return y;
                    },
                },
            ],
        },
    };
    useEffect(() => {

    }, [props])
    return (
        <div className='ourmap card-height-100'>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="300"
                className="apex-charts"
            />
        </div>
    )
}
export default UsersMap;