import { useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Select from 'react-select'
import { GET_WORKBOOK_ANALYTIC_FILTER, GROUP_SELECTION, REVIEW_DETAILS, WORKBOOK_ANALYTICS, fetchData } from "../../../components/Service";
import { Context } from "../../../components/Context";
import { formatDuration } from "../../../components/CommonMethod";
import { upperFirst } from "lodash";

const WorkbookAnalytics = () => {
    const [context] = useContext(Context);
    const [data, setData] = useState(null);
    const [group, setGroup] = useState('');
    const [students, setStudents] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [workbooks, setWorkbooks] = useState([]);
    const [selectedWworkbooks, setSelectedWworkbooks] = useState([]);
    const [groups, setGroups] = useState([]);
    const [reviewInfo, setReviewInfo] = useState(null);
    const [reviews, setReviews] = useState(null);
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (context && context.auth) {
            if (context.auth.group_details) {
                let group_code = context.auth.group_details.group_code;
                onGroupChange(group_code);
                setQuery(`group_code=${group_code}`);
            } else {
                fetchData(GROUP_SELECTION, 'GET', '', true, false, (res) => {
                    if (res.records) {
                        setGroups(res.records);
                    }
                })

                let group_code = '';
                onGroupChange(group_code);
                setQuery(`group_code=${group_code}`);
            }
            setReady(true);
        }
    }, [context])

    const onGroupChange = (group_code) => {
        setGroup(group_code);
        setSelectedStudents([]);
        setSelectedWworkbooks([]);

        fetchData(`${GET_WORKBOOK_ANALYTIC_FILTER}?group_code=${group_code}`, 'GET', '', true, false, (res) => {
            if (res.records) {
                setStudents(res.records.users);
                setWorkbooks(res.records.workbooks)
            }
        })
    }

    const chartFilter = () => {
        let student_ids = selectedStudents.map(item => item.value);
        let workbook_ids = selectedWworkbooks.map(item => item.value);
        setQuery(`group_code=${group}&student_ids=${student_ids}&workbook_ids=${workbook_ids}`)
    }

    const reviewDetails = (url) => {
        fetchData(url, 'GET', '', true, false, (res) => {
            if (res && res.records) {
                document.querySelector('[data-bs-target="#reviewModal"]').click()
                setReviews({
                    series: res.records.attempt,
                    options: {
                        chart: {
                            width: 380,
                            type: 'pie',
                        },
                        labels: res.records.range,
                        dataLabels: {
                            enabled: false
                        },
                        legend: {
                            formatter: function (val, opts) {
                                return "Attempt " + val;
                            }
                        },
                        tooltip: {
                            onDatasetHover: {
                                highlightDataSeries: true,
                            },

                            y: {
                                title: {
                                    formatter: (seriesName) => '',
                                },
                                style: {
                                    fontSize: '10px',
                                    padding: 0,
                                },

                                // formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                                //     return value;
                                // }
                            },

                            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                let exam_record = res.records.review[seriesIndex];

                                let tooltip = '';
                                tooltip += `<div id="custom-tooltip" class="p-0 border text-dark">`;
                                tooltip += `<p class="m-0 p-1 ps-2 border bg-light text-center fs-14"><b>Attempt ${seriesIndex + 1}</b></p>`;
                                tooltip += `<div class="p-1 px-2 bg-white fw-medium1">`;
                                tooltip += `<div class="exam-tooltip-info"><span class=""><span class="apexcharts-legend-marker"></span>Exam Mode: </span><span class="ms-2"><b>${upperFirst(exam_record.exam_mode)}</b></span></div>`;
                                tooltip += `<div class="exam-tooltip-info"><span class=""><span class="apexcharts-legend-marker"></span>Exam Times: </span><span class="ms-2"><b>${exam_record.total_time} Minutes</b></span></div>`;
                                tooltip += `<div class="exam-tooltip-info"><span class=""><span class="apexcharts-legend-marker"></span>Consume Times: </span><span class="ms-2"><b>${formatDuration(exam_record.consume_time)}</b></span></div>`;
                                tooltip += `<div class="exam-tooltip-info"><span class=""><span class="apexcharts-legend-marker"></span>Total Question: </span><span class="ms-2"><b>${exam_record.total_question}</b></span></div>`;
                                tooltip += `<div class="exam-tooltip-info"><span class=""><span class="apexcharts-legend-marker"></span>Total Attempt Question: </span><span class="ms-2"><b>${exam_record.total_attempt}</b></span></div>`;
                                tooltip += `<div class="exam-tooltip-info"><span class=""><span class="apexcharts-legend-marker"></span>Total Correct Answer: </span><span class="ms-2"><b>${exam_record.correct_answer}</b></span></div>`;
                                tooltip += `<div class="exam-tooltip-info"><span class=""><span class="apexcharts-legend-marker"></span>Mark: </span><span class="ms-2"><b>${exam_record.mark_percentage.toFixed(2)}%</b></span></div>`;
                                tooltip += `<div class="exam-tooltip-info"><span class=""><span class="apexcharts-legend-marker"></span>Complete Question: </span><span class="ms-2"><b>${exam_record.completion_percentage.toFixed(2)}%</b></span></div>`;
                                tooltip += `<div class="exam-tooltip-info"><span class=""><span class="apexcharts-legend-marker"></span>Confidence Score: </span><span class="ms-2"><b>${exam_record.performance_percentage.toFixed(2)}%</b></span></div>`;
                                tooltip += `</div></div>`;
                                return tooltip;
                            },
                        },
                        title: {
                            text: 'Workbook Analytics'
                        },
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                },
                                legend: {
                                    position: 'bottom'
                                }
                            }
                        }]
                    },
                })
            }
        })
    }

    useEffect(() => {
        if (ready) {
            setData(null);
            setLoading(true);
            fetchData(`${WORKBOOK_ANALYTICS}?${query}`, 'GET', '', true, false, (res) => {
                if (res && res.records) {
                    setData({
                        series: res.records.users,
                        options: {
                            chart: {
                                type: 'bar',
                                stacked: true,
                                toolbar: {
                                    show: false,
                                    offsetX: 0,
                                    offsetY: 0,
                                },
                                events: {
                                    mounted: function (chartContext, config) {
                                        setLoading(false);
                                    },
                                    updated: function (chartContext, config) {
                                        setLoading(false);
                                    },
                                    dataPointSelection: function (event, chartContext, config) {
                                        setReviews(null)
                                        let user = config.w.config.series[config.seriesIndex];
                                        let workbook = config.w.config.xaxis.categories[config.dataPointIndex];

                                        setReviewInfo({ user: user, workbook: workbook });
                                        reviewDetails(`${REVIEW_DETAILS}/${user.id}/${workbook.id}`);
                                    }
                                }
                            },
                            dataLabels: {
                                enabled: false,
                            },
                            // forecastDataPoints: {
                            //     count: 0,
                            //     fillOpacity: 0.5,
                            //     strokeWidth: 2,
                            //     dashArray: 4,
                            // },

                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                    // borderRadius: 10,
                                    // borderRadiusApplication: 'around', // 'around', 'end'
                                    // borderRadiusWhenStacked: 'all', // 'all', 'last'
                                    dataLabels: {
                                        total: {
                                            enabled: true,
                                        }
                                    }
                                },
                            },
                            stroke: {
                                width: 0,
                                colors: ['#fff']
                            },
                            xaxis: {
                                categories: res.records.workbooks,
                                labels: {
                                    formatter: function (val) {
                                        return val.title;
                                    },
                                    trim: true,
                                    rotate: -80,
                                    style: {
                                        cssClass: "custom-label",
                                        fontSize: "12px",
                                        fontWeight: "bold"
                                    }
                                }
                            },
                            yaxis: {
                                title: {
                                    text: "Number of Attempt"
                                },
                                labels: {
                                    formatter: function (val) {
                                        return '';
                                    },
                                }
                            },
                            grid: {
                                borderColor: '#f1f1f1',
                                xaxis: {
                                    lines: {
                                        show: true
                                    }
                                },
                                yaxis: {
                                    lines: {
                                        show: false
                                    }
                                },
                                column: {
                                    colors: undefined,
                                    opacity: 1
                                },
                                padding: {
                                    top: 5,
                                    bottom: 5,
                                    left: 20,
                                    right: 20,
                                },
                            },
                            tooltip: {
                                y: {
                                    formatter: function (val) {
                                        return val
                                    }
                                },
                            },
                            noData: {
                                text: "No data available",
                                align: 'center',
                                verticalAlign: 'middle',
                                offsetX: 0,
                                offsetY: 0,
                                style: {
                                    color: 'undefined',
                                    fontSize: '16px',
                                    fontFamily: undefined,
                                }
                            },
                            legend: {
                                position: 'top',
                            }
                        },
                    });
                }
            })
        }
    }, [query, ready])

    return (
        <div className="container-fluid">
            <style jsx="true">{`
            #custom-tooltip .exam-tooltip-info{
                display: flex;
                justify-content: space-between;
                margin-bottom: 4px;
            }
            #custom-tooltip .exam-tooltip-info .apexcharts-legend-marker{
                background: black !important;
                color: black;
                height: 10px;
                width: 10px;
                border-radius: 10px;
            }
        `}</style>
            <div className="page-head sticky_top_padding">
                <div className="row">
                    <div className="col-sm-6">
                        <h1 className="h3 text-dim">Workbook Analytics</h1>
                    </div>
                    <div className="col-sm-6 mb-2">
                        {(context?.auth?.user_type === '1' || context?.auth?.user_type === '3') &&
                            <select value={group} onChange={(e) => onGroupChange(e.target.value)} className="form-select form-select-md maxw-300 ms-auto">
                                <option value="">No Group</option>
                                {groups.map((item, index) => <option key={index} value={item.group_code} >{`${item.group_name} (${item.group_code})`}</option>)}
                            </select>
                        }
                    </div>
                </div>
            </div>
            <div className="row mx-2 mt-4 border bg-light py-4">
                <div className="col-sm-5">
                    <Select isMulti value={selectedStudents} isClearable options={students} placeholder={"Students"} onChange={(data) => setSelectedStudents(data)} />
                </div>
                <div className="col-sm-5">
                    <Select isMulti value={selectedWworkbooks} isClearable options={workbooks} placeholder={"Workbooks"} onChange={(data) => setSelectedWworkbooks(data)} />
                </div>
                <div className="col-sm-2 text-end">
                    <button className="btn btn-warning" title="Filter" onClick={chartFilter}>Filter</button>
                </div>
            </div>
            <div className="row mx-2 g-4 pt-4">
                {loading && <div className="w-100 text-center">
                    <div className="spinner-border spinner-border-sm position-absolute top-50" role="status"></div>
                </div>}
                <div className={`border bg-light ${loading && 'opacity-25'}`}>
                    {data && <ReactApexChart options={data.options} series={data.series} type="bar" />}
                </div>

                <button data-bs-target="#reviewModal" data-bs-toggle="modal" className="d-none"></button>
                <div className="modal fade" id="reviewModal" tabIndex="-1">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content border border-2 border-blue p-2 rounded-4">
                            <div className="modal-header border-0">
                                <h3 className="fw-bold text-blue">{reviewInfo?.user?.name} - {reviewInfo?.workbook?.title}</h3>
                            </div>
                            <div className="modal-body">
                                <div className="border bg-light p-2">
                                    {reviews && <ReactApexChart options={reviews.options} series={reviews.series} type="pie" height={500} />}
                                </div>
                            </div>
                            <div className="modal-footer border-0">
                                <button className="btn btn-outline-default btn-lg me-2" title="Close" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WorkbookAnalytics;