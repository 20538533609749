import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { validateForm, initialFormState, ButtonSpinner, generateId, copyToClipboard, PasswordShow, blobToBase64, noImageHandle } from "../../components/CommonMethod";
import { fetchData, GROUP_CREATE, GROUP_UPDATE, GROUP_DELETE, GROUP_STATUS_CHANGE, ASSET_ENDPOINT, DROPDOWN_DATA } from "../../components/Service";

const CreateGroup = () => {
    const location = useLocation();
    let groupInfo = location.state

    let title = groupInfo && groupInfo.groupDetail && groupInfo.groupDetail.item ? 'Update Group' : 'Create Group';
    window.document.title = title;

    const navigate = useNavigate();
    const [course, setCourse] = useState('');
    const [tinyloader, setTinyloader] = useState(false);
    const [imageb64, setImageb64] = useState('')

    const createGroup = (e) => {
        let formdata = new FormData(document.getElementById('addGroupForm'))

        if (validateForm(e, 'addGroupForm')) {
            setTinyloader(true);

            fetchData(groupInfo ? GROUP_UPDATE : GROUP_CREATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);
                if (res.success) {
                    initialFormState('addGroupForm')
                    navigate('../groups')
                }
            });
        }
    }

    const removeGroup = (group_id) => {
        fetchData(GROUP_DELETE, 'POST', { group_id: group_id }, true, false, (res) => {
            setTinyloader(false);
            if (res.success) {
                document.querySelector('#deletegroup_modal [data-bs-dismiss="modal"]').click()
                navigate('../groups')
            }
        });
    }

    const updateStatus = (id) => {
        fetchData(GROUP_STATUS_CHANGE, 'POST', { id: id }, true, false, (res) => {
            setTinyloader(false);
            if (res.success) {
                navigate('../groups')
            }
        });
    }

    useEffect(() => {
        fetchData(DROPDOWN_DATA + '?type=course', 'GET', '', true, false, (res) => {
            if (res.records) {
                setCourse(res.records.courses)
            }
        })
    }, [])

    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        setImageb64(imgb64)
    }

    return (
        <>
            <div className="container-fluid">
                <div className="page-head sticky_top_padding">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                                    <li className="breadcrumb-item"><Link to="/cms/groups">Manage Groups</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row gx-4 mb-1 align-items-lg-end align-items-md-end  align-items-center up-date-g">
                        <div className="col-lg-6 col-md-4 col-sm-6 col-6 mb-3">
                            <h1 className="h3 text-dim mb-0">{title}</h1>
                        </div>
                        <div className="col-lg-6 col-md-8 col-sm-6 col-6 mb-3">
                            <div className="float-end text-end">
                                {groupInfo && groupInfo.groupDetail && groupInfo.groupDetail.item &&
                                    <button type="button" className={`px-3 btn-lg  me-lg-3 me-md-3 me-2 btn  margin_bottom_responsivet btn-${groupInfo.groupDetail.item.status === 'active' ? 'danger' : 'green'}`} onClick={() => updateStatus(groupInfo.groupDetail.item.id)}>
                                        {groupInfo.groupDetail.item.status === 'active' ? 'Deactivate' : 'Activate'}
                                    </button>}
                                <button type="button" className="btn btn-theme-primary btn-lg  px-3 me-lg-3 me-md-3 me-2" onClick={(e) => createGroup(e)} disabled={tinyloader}>
                                    <ButtonSpinner load={tinyloader} btnName="Save and Exit" />
                                </button></div>

                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="maxw-400 mb-5">
                            <form action="" className="needs-validation" id="addGroupForm" noValidate autoComplete="off">
                                <h4 className="fw-bold mb-4">Group details</h4>
                                <input type="hidden" name="group_id" defaultValue={groupInfo && groupInfo.groupDetail && groupInfo.groupDetail.item && groupInfo.groupDetail.item.id} />

                                <div className="form-floating">
                                    <input type="text" name="group_name" id="group_name" defaultValue={groupInfo && groupInfo.groupDetail && groupInfo.groupDetail.item && groupInfo.groupDetail.item.group_name} className="form-control form-control-md mb-4" placeholder="Group name" required />
                                    <div className="invalid-feedback">
                                        The Group name field is required.
                                    </div>
                                    <label>Group Name <strong className="text-danger">*</strong></label>
                                </div>

                                <div className="form-floating mt-32">
                                    <input type="number" name="user_limit" id="user_limit" defaultValue={groupInfo && groupInfo.groupDetail && groupInfo.groupDetail.item && groupInfo.groupDetail.item.user_limit} className="form-control form-control-md mb-4" placeholder="Group name" required />
                                    <div className="invalid-feedback">
                                        The enrollment size field is required .
                                    </div>
                                    <label>Enrollment Size <strong className="text-danger">*</strong></label>
                                </div>

                                <div className="form-floating mt-32">
                                    <select className="form-select form-select-md mt-sm-2" name="course_type" id="course_type" required>
                                        {course && course.length > 0 ? course.map((item, i) => {
                                            return (
                                                <option value={item.id} selected={item.id === (groupInfo && groupInfo.groupDetail && groupInfo.groupDetail.item && groupInfo.groupDetail.item.course_id) ? true : false} key={i}>{item.course_abbr} ({item.name})</option>
                                            )
                                        }) : <option value="">Course Type</option>}
                                    </select>
                                    <div className="invalid-feedback">
                                        The Course type field is required .
                                    </div>
                                    <label>Course Type<strong className="text-danger">*</strong></label>
                                </div>

                                <div className="d-flex mt-32">
                                    <div className="form-floating w-100">
                                        <input type="text" name="group_code" id="group_code" className="form-control form-control-md text-dim" placeholder="Group code" defaultValue={groupInfo && groupInfo.groupDetail && groupInfo.groupDetail.item && groupInfo.groupDetail.item.group_code ? groupInfo.groupDetail.item.group_code : generateId(6)} readOnly required />
                                        <div className="invalid-feedback">
                                            The Group code field is required .
                                        </div>
                                        <label>Group Code</label>
                                    </div>
                                    <button type="button" className="btn btn-outline-default minw-90 ms-3" onClick={() => copyToClipboard('group_code')}>Copy</button>
                                </div>
                                <div className="d-flex flex-column mb-3 mt-32">
                                    <label htmlFor="group_logo" className="maxw-200 mb-3 img-circle">
                                        <img src={imageb64 ? imageb64 : groupInfo && groupInfo.groupDetail && groupInfo.groupDetail.item && groupInfo.groupDetail.item.image ? ASSET_ENDPOINT + groupInfo.groupDetail.item.image : '../images/groups.png'} className="rounded-circle mmwh-200" alt="Group Logo" onError={noImageHandle} />
                                    </label>
                                    <label className="btn btn-theme-primary px-4 maxw-250">
                                        <div className="overflow-hidden position-relative">
                                            <input type="file" name="group_logo" id="group_logo" accept=".jpg,.jpeg,.png" className="position-absolute opacity-0" onChange={(e) => imageSet(e)} />
                                        </div>
                                        {groupInfo && groupInfo.groupDetail && groupInfo.groupDetail.item ? 'Reupload an image' : 'Upload an image'}
                                    </label>
                                    <small className="text-muted mt-3">Please Upload Logo image .jpg, .jpeg, & .png format only. </small>
                                </div>
                                <hr />

                                <h4 className="fw-bold mb-1 mt-5">Account details</h4>
                                <p>
                                    You’ll be able to access your groups later by using these details.
                                </p>
                                <div className="form-floating mt-32">
                                    <input type="email" name="group_email" id="group_email" className="form-control form-control-md mb-4 mt-lg-0 mt-md-0 mt-4" placeholder="Email address" defaultValue={groupInfo && groupInfo.groupDetail && groupInfo.groupDetail.item && groupInfo.groupDetail.item.email} required autoComplete="off" />
                                    <div className="invalid-feedback">
                                        The group email field is required.
                                    </div>
                                    <label>Group Email <strong className="text-danger">*</strong></label>
                                </div>


                                <div className="form-floating mt-32 password-wrapper">
                                    <input type="password" autoComplete="new-password" name="group_password" placeholder="Password" id="group_password" className="form-control form-control-md" defaultValue="" required={groupInfo && groupInfo.groupDetail && groupInfo.groupDetail.item ? false : true} />
                                    <div className="invalid-feedback  position-absolute">
                                        The password field is required.
                                    </div>
                                    <button type="button" className="btn" onClick={(e) => PasswordShow(e, 'group_password')}>
                                        <svg className="icon pe-none"><use href="#icon_eye"></use></svg>
                                    </button>

                                    <label>Group Password <span className="text-danger" id="required_attr">*</span></label>

                                </div>
                            </form>
                        </div>
                        {groupInfo && groupInfo.groupDetail && groupInfo.groupDetail.item &&
                            <div className="mb-5" id="removeDiv">
                                <h4 className="fw-bold">Delete group</h4>
                                <p>This is a destructive action and cannot be reversed.</p>
                                <button type="button" className="btn btn-danger px-3 btn-lg" data-bs-toggle="modal" data-bs-target="#deletegroup_modal">
                                    Delete group
                                </button>
                            </div>}
                    </div>
                </div>
            </div>

            {/* Delete confirmation #2 - Modal */}
            <div className="modal fade " id="deletegroup_modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content overflow-hidden border border-2 border-danger rounded-4">
                        <div className="modal-body p-4">
                            <h3 className="fw-bold text-danger">Delete  {groupInfo && groupInfo.groupDetail && groupInfo.groupDetail.item && groupInfo.groupDetail.item.group_name} ?</h3>
                            <p className="m-0 pt-2">
                                This is a destructive action and cannot be reversed.
                            </p>
                        </div>
                        <div className="modal-footer justify-content-between px-4 pt-0 pb-4 border-0">
                            <button type="button" className="btn btn-outline-default btn-lg" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-lg minw-120" onClick={() => removeGroup(groupInfo && groupInfo.groupDetail && groupInfo.groupDetail.item && groupInfo.groupDetail.item.id)}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CreateGroup;
