import { useEffect, useState } from "react";
import { Popover } from "bootstrap";
import { split } from "lodash";


const Analytics = (props) => {
    const [data] = useState(props.data)
    
    useEffect(() => {
        Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
    })

    return (<>
        <td className="">
            {data && data.exam_records && data.exam_records.length > 0 ? data.exam_records.map((exam_record, i) => {
                let exam_time = exam_record.end_time
                if (exam_record.exam_mode === 'exam') {
                    let total_time = exam_record.total_time ?? data.time_limit
                    let end_time = split(exam_record.end_time, ":")
                    let second = (60 - parseInt(end_time[1]))
                    let minute = (parseInt(total_time) - parseInt(end_time[0]))
                    if (second < 59) minute--
                    exam_time = `${minute}:${second}`
                }
                return (
                    <div className={`${((exam_record.correct_answer / exam_record.total_question) * 3) > 2 ? 'exam-green' : (((exam_record.correct_answer / exam_record.total_question) * 3) > 1 ? 'exam-yellow' : 'exam-red')} mb-2 py-1 maxw-200 minw-200 me-2 d-inline-block shadow-sm rounded`} key={i}>
                        <div className="w-100 d-flex">
                            <div className="w-50 ps-2 fw-bold">{exam_record.exam_mode.toUpperCase()}</div>
                            <div className="w-50 text-end pe-2">
                                {(JSON.parse(exam_record.answer_details).length) === exam_record.total_question &&
                                    <button type="button" className="btn p-0 m-0 ms-2" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-template={`<div class='popover shadow' role='popover'><div class='popover-arrow'></div><div class='popover-inner py-3 px-3 fw-medium'>Assignment Completed<div class='row'><div class='col-sm-12'><div></div></div></div>`}>
                                        <svg className="icon ts-1p3"><use href="#icon_checkcircle"></use></svg>
                                    </button>
                                }
                            </div>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 ps-2">Time</div>
                            <div className="w-50 text-end pe-2 fw-bold">{exam_time}</div>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 ps-2">Total</div>
                            <div className="w-50 text-end pe-2 fw-bold">{exam_record.total_question}</div>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 ps-2">Attempt</div>
                            <div className="w-50 text-end pe-2 fw-bold">{(JSON.parse(exam_record.answer_details).length)}</div>
                        </div>
                        <div className="w-100 d-flex">
                            <div className="w-50 ps-2">Correct</div>
                            <div className="w-50 text-end pe-2 fw-bold">{exam_record.correct_answer}</div>
                        </div>
                    </div>
                )
            }) : <div className="text-danger">Not yet started</div>}
        </td>
    </>)
}

export default Analytics