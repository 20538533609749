
const SalesDetails = () => {

    window.document.title = "Order Details | WAAE Educational";

    return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <h1 className="h3 text-dim pb-4">Order Details</h1>
                    </div>
                </div>
                <div className="row gx-4 gy-3 mb-3 mb-md-4">
                    <div className="col-sm">
                        <h4 className="fw-bold m-0">Under development</h4>
                    </div>
                </div>
            </div>
    )
}
export default SalesDetails;