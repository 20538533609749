import React, { useState, useEffect } from 'react';
import { fetchData, ADD_UPDATE_TERMS_CONDITIONS } from '../../components/Service'

function PrivacyPolicy() {
    window.document.title = "Privacy Policy";

    const [terms, setTerms] = useState()

    useEffect(()=> {
        fetchData(ADD_UPDATE_TERMS_CONDITIONS, 'GET', '', false, false, (res) => {
            if (res.records) {
                let record = JSON.parse(res.records.value);
                setTerms(record)
            } else {
                setTerms('')
            }
        });
    },[])

    return (
        <section className="py-5">
            { terms && Object.keys(terms) && Object.keys(terms).map((ele, num)=> { 
                return(
                        <div className="container container-1040" key={num}>
                            <div className="row">
                                <div className="col-sm-12 mb-5">
                                
                                {ele === 'Privacy' &&  terms[ele].text && <div dangerouslySetInnerHTML={{__html:terms[ele].text}}/>}
                                
                                </div>
                            </div>
                        </div>
                    )
                 })}
        </section>
    )
}
export default PrivacyPolicy