const StudyMode = () => {
    return(
        <section className="home-section3 l1">
            <img src="images/vector/shape2.svg" className="homebg-cover" alt="shape" />
            <div className="container container-1040 position-relative">
                <div className="row g-4 align-items-center">
                    <div className="col-md-6">
                        <div className="d-flex align-items-start justify-content-center">
                            <svg className="icon mmwh-35 sm-mmwh-28 text-orange">
                                <use href="#icon_subtract"></use>
                            </svg>
                            <div className="px-3">
                                <h2 className="fw-bold mb-3  f-28_size">Study the way you like it</h2>
                                <p className="h4 fw-normal">
                                    Choose between practice and exam modes to complete your workbooks.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="practice-exam">
                            <span className="circle"></span>
                            <span className="pill"></span>
                            <div className="pe-card">
                                <img src="images/vector/fire.svg" alt="Practice" />
                                <h4>Practice</h4>
                                <p>
                                    Check your answers and learn as you go. Build a streak for every correct answer.
                                </p>
                            </div>
                            <div className="pe-card pr-cardend">
                                <img src="images/vector/clock.svg" alt="Practice" />
                                <h4>Exam</h4>
                                <p>
                                    Complete under timed conditions then check your answers at the end.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StudyMode