import ReactHtmlParser from 'react-html-parser'
import { useEffect } from 'react';

const LearningOutcomes = (props) => {

    useEffect(() => {
        let collapseOne = document.querySelectorAll('[data-toggle = "collapse"]')
        if(collapseOne.length > 0) {
            collapseOne.forEach((el, i) => {
                i === 0 ? el.classList.remove('collapsed') : el.classList.add('collapsed')
                el.ariaExpanded = (i === 0) ? true : false
            })
        }

        let collapseTwo = document.querySelectorAll('[data-show = "collapse"]')
        if(collapseTwo.length > 0) {
            collapseTwo.forEach((el, i) => i === 0 ? el.classList.add('show') : el.classList.remove('show'))
        }
    })

    return(
        <>
            <div className="modal fade" id="learningOutcomesModal" tabIndex="-1" data-backdrop="static">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content border border-2 border-blue p-2 rounded-4 mt-auto">
                        <div className="modal-header py-2 border-0 ">
                            <h3 className="fw-bold text-blue">Learning Outcomes</h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {props && props.record && props.record.map((item,key) => {
                                return(
                                    <div className="filter-checkwrap bg-grey2 mb-1" key={key}>
                                        <div className="filter-checkarrow">
                                            <label className="fw-bold">
                                                <h5 className="form-check-label fw-bold text-green">{item.title}</h5>
                                            </label>
                                            <button type="button" data-toggle="collapse" data-bs-toggle="collapse" data-bs-target={`#collapseBoxOutcome${key}`} className="collapsed" aria-expanded={true}>
                                                <svg className="icon"><use href="#icon_arrowdown"></use></svg>
                                            </button>
                                        </div>
                                        <div className='collapse show' data-show="collapse" id={`collapseBoxOutcome${key}`}>
                                            <div className="form-check-label fw-bold">{item && item.learning_outcomes ? ReactHtmlParser(item.learning_outcomes) : 'No outcomes available.'}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default LearningOutcomes;