import { useEffect, useState } from "react";
import { ASSET_ENDPOINT } from './Service';

function ImageDropdown(props){

    const [showvalue, setShowvalue] = useState(props.value ?? '');
    const [showtext, setShowtext] = useState('');

    useEffect(() => {
        if(props.value){
            setShowvalue(props.value)
            setTimeout(()=> {
                document.querySelectorAll('[data-getvalue]').forEach((e)=>{
                    let getv = e.getAttribute('data-getvalue');
                    e.querySelectorAll('[data-setvalue]').forEach((e2) => {
                        if (getv === e2.getAttribute('data-setvalue')) {
                            e2.click();
                        }
                    });
                });
            },500)
        }
    }, [props.value])
    
    const setValue_Image = (value, name) => {
        setShowvalue(value);
        setShowtext(name);
    }

    return(<>
        <div className="dropdown form-dropdown" data-getvalue={showvalue} style={props.style ?? {}}>
            <input type="hidden" className="d-none" value={showvalue ?? ''} name={props.name} id={props.id} />
            <div className="form-control form-control-md form-select" data-bs-toggle="dropdown" aria-expanded="false" role="button">
                <img src={ showvalue!==''? ASSET_ENDPOINT+showvalue : "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktaW1hZ2UtZmlsbCIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNLjAwMiAzYTIgMiAwIDAgMSAyLTJoMTJhMiAyIDAgMCAxIDIgMnYxMGEyIDIgMCAwIDEtMiAyaC0xMmEyIDIgMCAwIDEtMi0yVjN6bTEgOXYxYTEgMSAwIDAgMCAxIDFoMTJhMSAxIDAgMCAwIDEtMVY5LjVsLTMuNzc3LTEuOTQ3YS41LjUgMCAwIDAtLjU3Ny4wOTNsLTMuNzEgMy43MS0yLjY2LTEuNzcyYS41LjUgMCAwIDAtLjYzLjA2MkwxLjAwMiAxMnptNS02LjVhMS41IDEuNSAwIDEgMC0zIDAgMS41IDEuNSAwIDAgMCAzIDB6Ii8+Cjwvc3ZnPg=="} alt="..."/>
                <span>{showtext !== '' ? showtext : 'No records'}</span>
            </div>
            <ul className="dropdown-menu w-100 shadow">
                {
                    props.imageList && props.imageList.length > 0 ? props.imageList.map((e,i) => {
                        return(
                            <li className="dropdown-item" key={i} data-setvalue={e.image} onClick={() => setValue_Image(e.image, e.name)}>
                                <img src={ASSET_ENDPOINT +e.image} alt="Avatar" />
                                <span>{e.name}</span>
                            </li>
                        )
                    })
                    :
                    <li className="dropdown-item pe-none text-dim">No Records</li> 
                }
            </ul>
        </div>
    </>)
}


export default ImageDropdown