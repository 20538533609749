import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchData, TOPIC_CREATE, TOPIC_LIST, TOPIC_UPDATE, TOPIC_DELETE, DROPDOWN_DATA } from "../../components/Service";
import { validateForm, initialFormState, toastNotify, ButtonSpinner, ConfirmationModal } from "../../components/CommonMethod";
import { DataTable } from "../../components/DataTable";
import { now } from 'lodash'

const Topic = () => {
    window.document.title = "Topic";

    const [tinyloader, setTinyloader] = useState('')
    const [course, setCourse] = useState('')
    const [topic, setTopic] = useState('')
    const [deleteSections, setDeleteSections] = useState({ record_id: '', name: '' })
    const [refresh, setRefresh] = useState(now)

    const dt = {
        id: 'topiclist',
        fetchurl: TOPIC_LIST,
        columns: [
            { data: 'id', name: 'id', title: 'Id' },
            { data: 'course_abbr', name: 'courses.course_abbr', title: 'Course' },
            { data: 'topic_name', name: 'topic_name', title: 'Topic' },
            { data: 'action', name: 'action', title: 'Action', sorting: false, searching: false }
        ]
    }

    const saveTopic = (e) => {
        if (document.getElementById('topic_value_div').childElementCount > 0) {
            let formData = new FormData(document.getElementById('createTopicForm'))
            setTinyloader(true)

            fetchData(TOPIC_CREATE, 'POST', formData, true, true, (res) => {
                setTinyloader(false)

                if (res.success) {
                    document.getElementById('topic_value_div').style.display = "none";
                    document.getElementById('topic_value_div').innerHTML = "";
                    initialFormState('createTopicForm')
                    setRefresh(now)
                }
            });
        } else {
            toastNotify('danger', 'Please select a topic.')
        }
    }

    const updateTopic = () => {
        let formData = new FormData(document.getElementById('editTopicForm'))
        setTinyloader(true)

        fetchData(TOPIC_UPDATE, 'POST', formData, true, true, (res) => {
            setTinyloader(false)

            if (res.success) {
                initialFormState('editTopicForm')
                document.querySelector('#editTopicModal [data-bs-dismiss="modal"]').click()
                setRefresh(now)
            }
        });
    }

    const addTopic = (e) => {
        if (validateForm(e, 'addTopicForm')) {
            let topic_value = document.getElementById('topic_value').value;
            var select = document.getElementById('course_type');
            var course_name = select.options[select.selectedIndex].getAttribute('data-edit')

            let topic_value_div = document.getElementById('topic_value_div');
            topic_value_div.style.display = "block";
            topic_value_div.insertAdjacentHTML('afterbegin',
                `<div class="d-flex justify-content-between align-items-center fw-bold py-1 bg-light rounded">
                    <input type="hidden" name="course_id[]" value=${select.value}>
                    <input type="hidden" name="topic_name[]" readonly value="${topic_value}" />
                    <span class="form-control fw-bold border-0 ms-1">${course_name}</span>
                    <span class="ms-1 form-control fw-bold border-0">${topic_value}</span>
                    <button class="btn btn-sm mx-1 remove" title="Delete">
                        <svg class="icon ts-1p4"><use href="#icon_delete"></use></svg>
                    </button>
                </div>`);

            document.getElementById('addTopicForm').classList.remove('was-validated')
            document.getElementById("topic_value").value = ''
            document.querySelectorAll(".remove").forEach(function (el) {
                el.addEventListener('click', (e) => {
                    el.parentElement.remove();

                    if (topic_value_div.childElementCount === 0) {
                        topic_value_div.style.display = "none";
                    }
                })
            });
        }
    }

    const deleteRecords = (id) => {
        fetchData(TOPIC_DELETE + '?id=' + id, 'GET', '', true, false, (res) => {
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                setRefresh(now)
            }
        });
    }

    useEffect(() => {
        fetchData(DROPDOWN_DATA + '?type=course', 'GET', '', true, false, (res) => {
            if (res.records) {
                setCourse(res.records.courses)
            }
        })
    }, [])

    return (
        <>
            <div className="container-fluid">
                <div className="page-head sticky_top_padding">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                                    <li className="breadcrumb-item"><Link to="/cms/manage-courses">Manage courses</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Topic</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row gx-4 mb-1 align-items-center">
                        <div className="col-lg-6 col-6 mb-3">
                            <h1 className="h3 text-dim mb-0">Topic</h1>
                        </div>
                        <div className="col-lg-6 col-6 mb-3">
                            <button type="button" className="btn btn-theme-primary btn-lg px-3 float-end" onClick={(e) => saveTopic(e)}>
                                <ButtonSpinner load={tinyloader} btnName="Save" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="maxw-620">
                            <form className="needs-validation" id="addTopicForm" noValidate>
                                <div className="w-100" id="section_div">
                                    <h6 className="text-uppercase mb-4 fw-bolder">Create <span className="text-primary">Topic</span> Categories</h6>
                                    <div className="d-flex mb-3 mt-30">
                                        <div className="form-floating w-100">
                                            <select className="form-select form-select-md" id="course_type" required>
                                                {course && course.length > 0 ? course.map((item, i) => {
                                                    return <option value={item.id} data-edit={item.course_abbr} key={i}>{item.course_abbr}</option>
                                                }) : <option value="">Select Course Type</option>}
                                            </select>
                                            <div className="invalid-feedback">
                                                The course type field is required.
                                            </div>
                                            <label>Course <strong className="text-danger">*</strong></label>
                                        </div>
                                        <div className="form-floating w-100">
                                            <input type="text" id="topic_value" className="form-control form-control-md ms-2" placeholder="Topic" required />
                                            <div className="invalid-feedback ms-2">
                                                The topic field is required .
                                            </div>
                                            <label>Topic <strong className="text-danger">*</strong></label>
                                        </div>
                                        <div className="form-floating w-100">
                                            <button type="button" className="btn btn-outline-default minw-90 ms-3 zindex-minus" onClick={(e) => addTopic(e)}>Add</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <form className="needs-validation" id="createTopicForm" noValidate>
                                <div className="card border-dark rounded-3 px-3 py-2 mb-3" id="topic_value_div" style={{ display: 'none' }}></div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <DataTable
                            execute={refresh}
                            dt={dt}
                            edit_column={{
                                action: (records) =>
                                    <div className="d-flex">
                                        <button className="btn btn-sm text-dark btn-lg border border-1 border-dark me-2" title="Edit" data-bs-toggle="modal" data-bs-target="#editTopicModal" onClick={() => { initialFormState('editTopicForm', setTopic); setTopic(records) }}>
                                            <svg className="icon fs-5" role="img"><use href="#icon_pencil" /></svg>
                                        </button>
                                        <button className="btn btn-sm text-danger btn-lg border border-1 border-danger" title="Delete" data-bs-toggle="modal" data-bs-target="#deletesection_modal" onClick={() => setDeleteSections({ record_id: records.id, name: records.topic_name })}>
                                            <svg className="icon fs-5" role="img"><use href="#icon_delete" /></svg>
                                        </button>
                                    </div>
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className="modal fade" id="editTopicModal" tabIndex="-1" data-bs-backdrop="static" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content overflow-hidden border border-2 border-primary rounded-4">
                        <div className="modal-header p-3">
                            <h4 className="text-primary fw-bold mb-0">Edit Topic</h4>
                        </div>
                        <div className="modal-body p-4">
                            <form id="editTopicForm">
                                <input type="hidden" defaultValue={topic && topic.course_id} name="course_id" />
                                <input type="hidden" defaultValue={topic && topic.id} name="id" />
                                <div className="form-floating mt-2 mb-5">
                                    <input type="text" defaultValue={topic && topic.course_term ? topic.course_term : ''} className="form-control form-control-md" placeholder="Course" disabled />
                                    <label>Course</label>
                                </div>
                                <div className="form-floating">
                                    <input type="text" id="topic_name" name="topic_name" defaultValue={topic && topic.topic_name} className="form-control form-control-md" placeholder="Topic" />
                                    <label>Topic  <strong className="text-danger">*</strong></label>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-default" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-theme-primary" onClick={() => updateTopic()}>
                                <ButtonSpinner load={tinyloader} btnName="Save Changes" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmationModal msg={`Delete ${deleteSections.name} ?`} method={() => deleteRecords(deleteSections.record_id)} />
        </>
    )
}

export default Topic;