import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const StatisticsMap = (props) => {
    const linechartcustomerColors = ["#405189", "#0ab39c", "#f06548"];
    const series = [
        {
            name: "Orders",
            type: "area",
            data: props.data && props.data.order,
        },
        {
            name: "Earnings",
            type: "bar",
            data: props.data && props.data.earning,
        },
        // {
        //     name: "Refunds",
        //     type: "line",
        //     data: [8, 12, 7, 17, 21, 11, 5, 9, 7],
        // },
    ];
    var options = {
        chart: {
            height: 370,
            type: "line",
            toolbar: {
                show: false,
            },
        },
        stroke: {
            curve: "straight",
            dashArray: [0, 0, 8],
            width: [2, 0, 2.2],
        },
        fill: {
            opacity: [0.1, 0.9, 1],
        },
        markers: {
            size: [0, 0, 0],
            strokeWidth: 2,
            hover: {
                size: 4,
            },
        },
        xaxis: {
            // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",],
            categories: props.data && props.data.month,
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
            },
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return "$" + value.toFixed(2);
                }

            },
            tickAmount: 5,
            min: 0,
            max: props.data.max_Yvalue,
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                top: 0,
                right: -2,
                bottom: 15,
                left: 10,
            },
        },
        legend: {
            show: true,
            horizontalAlign: "center",
            offsetX: 0,
            offsetY: -5,
            markers: {
                width: 9,
                height: 9,
                radius: 6,
            },
            itemMargin: {
                horizontal: 10,
                vertical: 0,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "30%",
                barHeight: "70%",
            },
        },
        colors: linechartcustomerColors,
        tooltip: {
            shared: true,
            y: [
                {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y.toFixed(0);
                        }
                        return y;
                    },
                },
                {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return "$" + y.toFixed(2);
                        }
                        return y;
                    },
                },
                // {
                //     formatter: function (y) {
                //         if (typeof y !== "undefined") {
                //             return y.toFixed(0) + " Sales";
                //         }
                //         return y;
                //     },
                // },
            ],
        },
    };
    useEffect(() => {

    }, [props])
    return (
        <div className='ourmap card-height-100'>
            <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="300"
                className="apex-charts"
            />
        </div>
    )
}
export default StatisticsMap;