import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
    window.document.title = "404 Error | Page not found!";
    
    return(
        <section className="pt-5" id="trangle_bottom">
            <div className="container container-1180">
                <div className="row justify-content-center gap-4">
                    <div className="col-md-6 pt-md-4">
                        <div className="p-4 rounded-4 border border-blue border-2 position-relative bg-white">
                            <div className="triangle border border-blue rounded border-2 mmwh-44 position-absolute" style={{transform: 'rotate(316deg)',right:'-12px',top:'22%',zIndex:'-1'}}></div>
                            <h2 className="text-blue fs-3 fw-bold ">404 Page not found</h2>
                            <p className="fs-4 text-blue mb-4">Whoops! that page doesn't seem to exist...</p>
                            <Link to="/shop" role="button" className="btn btn-theme-primary btn-lg mb-4 w-100">Visit the shop</Link>
                            <Link to="./" role="button" className="btn btn-outline-secondary btn-lg mb-2 w-100">Return to home</Link>
                        </div> 
                    </div>
                    <div className="col-sm-5 col-lg-4 text-center text-lg-start">
                        <img src="/images/think-male.png" className="img-fluid cls-img-responsive" alt="Male"/>
                    </div>
                </div>  
            </div>
        </section>   
    )
}

export default ErrorPage