import { now } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { showAlertMsg } from './CommonMethod';

export const isGuest = () => {
    let guest = localStorage.getItem('guest');
    return guest ? JSON.parse(guest) : false;
}

export const logoutGuest = () => {
    localStorage.removeItem('guest');
    return true;
}

export const loginGuest = () => {
    let guest = {
        auth: {
            user_id: uuidv4(),
            user_type: 2,
            first_name: 'Guest',
            last_name: 'User',
            user_image: '/images/guest.png',
            timestamp: now(),
        },
        cart_items: {
            count: 0,
            shop_listing_id: []
        },
        biller_details: {
            address: '',
            suburb: '',
            state: '',
            biller_email: '',
            biller_first_name: 'Guest',
            biller_last_name: 'User',
            country: '',
            currency_type: '',
            postcode: '',
            price: '',
            complete: false,
        }
    }

    localStorage.setItem('guest', JSON.stringify(guest));

    return guest;
}

export const updateCartValue = (value) => {
    let cart = document.getElementById('cart-item-count')
    if (cart) cart.innerText = value
    let cart_mobile = document.getElementById('cart-item-mobile-count')
    if (cart_mobile) cart_mobile.innerText = value
}

export const addGuestCart = (id) => {
    let guest = isGuest()
    let shop_listing_id = guest.cart_items.shop_listing_id
    let count = shop_listing_id.length + 1
    guest.cart_items.count = count
    updateCartValue(count)
    shop_listing_id.push(id);
    guest.cart_items.shop_listing_id = shop_listing_id
    localStorage.setItem('guest', JSON.stringify(guest));
    showAlertMsg({ success: "Workbook added to cart successfully." })

    return guest;
}

export const removeGuestCart = (id) => {
    let guest = isGuest()
    let shop_listing_id = guest.cart_items.shop_listing_id
    let count = shop_listing_id.length - 1
    guest.cart_items.count = count
    updateCartValue(count)
    let index = shop_listing_id.indexOf(id);
    if (index > -1) shop_listing_id.splice(index, 1);
    guest.cart_items.shop_listing_id = shop_listing_id
    localStorage.setItem('guest', JSON.stringify(guest));
    showAlertMsg({ success: "Workbook removed from cart successfully." })

    return guest;
}

export const addGuestBillingDetails = (data) => {
    let guest = isGuest()
    guest.biller_details = { ...data, complete: true };
    localStorage.setItem('guest', JSON.stringify(guest));

    return guest;
}