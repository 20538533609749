import { useState, useEffect } from "react";
import { GET_AVERAGE_INDIVIDUAL_STUDENT_WORKBOOK_ANALYTIC, fetchData } from "../../components/Service";
import { Link, useParams } from "react-router-dom";
import { decodedText, encodedText, formatDuration } from "../../components/CommonMethod";
import Canvas from "./Component/Canvas";
import { ItemBox } from "./Component/Component";

const WorkbookReview = () => {
    window.document.title = "Workbook Review";
    const [data, setData] = useState(null);
    const [query, setQuery] = useState('');
    const params = useParams()
    const [paramsData, setParamsData] = useState(null);
    const [bgcolors] = useState(['#6ce5e8', '#ff66c4', '#ff4e7b', '#d84c39', '#ed9a4c']);

    useEffect(() => {
        try {
            setParamsData(JSON.parse(decodedText(params.query, process.env.REACT_APP_ENCRYPTED_KEY)));
        } catch (error) {
            window.history.go(-1);
        }
    }, [params])

    useEffect(() => {
        if (paramsData && query) {
            fetchData(`${GET_AVERAGE_INDIVIDUAL_STUDENT_WORKBOOK_ANALYTIC}?module_id=${paramsData.module_id}&student_id=${paramsData.student_id}&${query}`, 'GET', '', true, false, (res) => {
                if (res && res.records) {
                    setData(res.records);
                }
            })
        }
    }, [paramsData, query])


    return (
        <>
            <style>
                {`
                table, th, td {
                    border:3px solid black;
                    padding: 15px 5px;
                  }
                `}
            </style>

            <div className="container-fluid">
                <div className="page-head sticky_top_padding">
                    <div className="row  gy-2">
                        <div className="col-sm-6">
                            {data && <>
                                <div className="h3 text-dim">
                                    <span className="fw-normal">
                                        <Link to={`/cms/class-review/${encodedText({ query: query }, process.env.REACT_APP_ENCRYPTED_KEY)}`} className="text-decoration-none">Class Review </Link>
                                        <i className="fa fa-angle-double-right"></i> <Link to={`/cms/student-review/${encodedText({ class_id: data.student.student_section.id, query: query }, process.env.REACT_APP_ENCRYPTED_KEY)}`} className="text-decoration-none">{`${data.student.student_section.section_name} (${data.student.student_group.group_code})`} </Link>
                                        <i className="fa fa-angle-double-right"></i> <Link to={`/cms/individual-student-review/${encodedText({ student_id: data.student.id, query: query }, process.env.REACT_APP_ENCRYPTED_KEY)}`} className="text-decoration-none">{`${data.student.first_name} ${data.student.last_name}`} </Link>
                                    </span>
                                    <i className="fa fa-angle-double-right"></i> <span className="">{data.module.topic_name}</span>
                                </div>
                            </>}
                        </div>
                        <div className="col-sm-6 mb-2 text-end">
                            <button className="btn btn-lg border" style={{whiteSpace:'nowrap'}} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                <svg className="icon me-2"><use href="#icon_filter"></use></svg> Filter
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row px-0 mx-2 pt-4 justify-content-center text-center" id="box-container">
                    <ItemBox title={"Average Mark"} data={data?.student_marks} bgcolor={bgcolors[2]} />
                    <ItemBox title={"Workbook Completion Rate"} data={data?.completion_rate} bgcolor={bgcolors[1]} />
                    <ItemBox title={"Average Completion Time"} data={data?.consume_time} bgcolor={bgcolors[3]} time />
                    <ItemBox title={"Average Confidence Score"} data={data?.confidence_score} bgcolor={bgcolors[4]} />
                </div>
                <div className="row mt-4 pt-4">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="text-center w-100">
                                <tr>
                                    <th style={{ minWidth:'200px'}}>Workbook</th>
                                    <th style={{ minWidth:'200px'}}>Average Mark</th>
                                    <th style={{ minWidth:'200px'}}>Average Completion Time</th>
                                    <th style={{ minWidth:'200px'}}>Average Completion Rate</th>
                                    <th style={{ minWidth:'200px'}}>Average Confidence Score</th>
                                </tr>
                                {data?.exam_records?.length > 0 ? data?.exam_records?.map((item, key) => {
                                    return <tr key={key}>
                                        <td><Link to={`/cms/question-review/${encodedText({ student_id: data.student.id, workbook_id: item.workbook_id, module_id: paramsData.module_id, query: query }, process.env.REACT_APP_ENCRYPTED_KEY)}`} className="">{item.workbook?.title}</Link></td>
                                        <td>{item.student_marks ?? 0}%</td>
                                        <td>{formatDuration(item.consume_time) ?? 0}</td>
                                        <td>{item.completion_rate ?? 0}%</td>
                                        <td>{item.confidence_score ?? 0}%</td>
                                    </tr>
                                }) : <tr><td colSpan={5}>No Record found.</td></tr>}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Canvas setQuery={setQuery} />
        </>
    )
}
export default WorkbookReview;