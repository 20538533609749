import { Link } from "react-router-dom";
import { useState } from "react";
import { __echoText, ConfirmationModal, ButtonSpinner, initialFormState, validateForm } from "../../components/CommonMethod";
import { fetchData, ENQUIRY_LIST, ENQUIRY_UPDATE, ENQUIRY_DELETE, STATUS_ENQUIRY_UPDATE } from "../../components/Service";
import { now } from 'lodash'
import { format } from 'date-fns';
import { DataTable } from "../../components/DataTable";

const Enquiry = () => {
    window.document.title = "Customer Enquiries";

    const [refresh, setRefresh] = useState(now);
    const [enquiriesId, setEnquiriesId] = useState([])
    const [tinyloader, setTinyloader] = useState(false);
    const [enquiry, setEnquiry] = useState('')

    const enquiries = {
        id: 'enquiries',
        fetchurl: ENQUIRY_LIST,
        columns: [
            { data: 'id', name: 'id', title: '', width: 20, sorting: false, searching: false },
            { data: 'name', name: 'name', title: 'Name' },
            { data: 'phone', name: 'phone', title: 'Phone' },
            { data: 'email', name: 'email', title: 'Email' },
            { data: 'subject', name: 'subject', title: 'Subject' },
            { data: 'description', name: 'description', title: 'Description' },
            { data: 'remarks', name: 'remarks', title: 'Remarks' },
            { data: 'status', name: 'status', title: 'Status' },
            { data: 'created_at', name: 'created_at', title: 'DATE CREATED' },
            { data: 'updated_at', name: 'updated_at', title: 'LAST EDITED' },
            { data: 'action', name: 'action', title: 'ACTION', sorting: false, searching: false },
        ]
    }

    const handleCheckboxChange = (e) => {
        e.target.checked ? setEnquiriesId([...enquiriesId,e.target.value]) : setEnquiriesId(enquiriesId.filter(i => i !== e.target.value));
    }

    const deleteEnquiries = (e) => {
        fetchData(ENQUIRY_DELETE, 'DELETE', { id: enquiriesId }, true, false, (res) => {
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                setEnquiriesId([])
                setRefresh(now)

                document.querySelectorAll('.my_checkboxes').forEach((el) => el.checked = false)
            }
        })
    }

    
    const updateStatus = (id) => {
        fetchData(STATUS_ENQUIRY_UPDATE + '/' + id, 'PATCH', '', true, false, (res) => {
            setRefresh(now)
        });
    }

    const editEnquiry = (e) => {
        var formdata = new FormData(document.getElementById('enquiryModalForm'));
        if (validateForm(e, "enquiryModalForm")) {
            setTinyloader(true);
            fetchData(ENQUIRY_UPDATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);
                if (res.success) {
                    document.querySelector('#updateEnquiry [data-bs-dismiss="modal"]').click()
                    setRefresh(now)
                }
            })
        }
    }

    return (
        <>
            <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row g-2 align-items-center">
                    <div className="col">
                        <h1 className="h3 text-dim pb-17px">Manage Enquires</h1>
                    </div>
                </div>
                </div>

                <div className="row bg-grey2 p-2 rounded mx-0 align-items-center my-3" id="enquiries_action" style={{ display: enquiriesId.length > 0 ? 'flex' : 'none' }}>
                    <div className="col-auto text-dim fs-18">
                        <strong>{enquiriesId && enquiriesId.length} Enquiries selected</strong>
                    </div>
                    <div className="col text-end">
                        <button type="button" className="btn btn-link fw-bold text-decoration-none pe-0 text-danger" title="Delete selected enquiries" data-bs-toggle="modal" data-bs-target="#deletesection_modal">
                            <svg className="icon ts-1p3 me-2"><use href="#icon_delete"></use></svg>
                            Delete selected enquiries
                        </button>
                    </div>
                </div>

                <div className="row mt-3">
                    <DataTable
                        execute={refresh}
                        dt={enquiries}
                        edit_column={{
                            id: (records) => <input className="form-check-input mmwh-20 m-0 my_checkboxes" type="checkbox" value={records.id} name="enquiries[]" onChange={(e) => handleCheckboxChange(e)} />,
                            
                            status: (records) =>
                                            <button type="button" className={`px-3 btn-sm m-3 btn btn-${records.status === 'pending' ? 'danger' : records.status === 'processing' ? 'theme-primary' : 'green' }`} onClick={() => updateStatus(records.id)} >
                                                 {records.status === 'pending' ? 'Pending' : records.status === 'processing' ? 'Processing' : 'Resolved'}
                                            </button>,

                            updated_at: (records) =>
                                __echoText(`${format(new Date(records.updated_at), 'MM/dd/yyyy')}`),

                            created_at: (records) =>
                                __echoText(`${format(new Date(records.created_at), 'MM/dd/yyyy')}`),

                            action: (records) => <Link to="/cms/customer-enquiries" state={records} className="text-black mmwh-48 d-inline-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#updateEnquiry" title="Edit" onClick={(e) => {initialFormState('enquiryModalForm', setEnquiry);setEnquiry(records)}}><svg className="icon ts-1p5"><use href="#icon_pencil"></use></svg></Link>
                        }}
                    />
                </div>
            </div>

            {/* Delete confirmation - Modal */}
            <ConfirmationModal msg="Delete These Enquiries" method={() => deleteEnquiries()} />

            {/* Edit - Modal */}
            <div className="modal fade" id="updateEnquiry" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content border border-4 border-primary rounded-4 p-4">
                        <form id="enquiryModalForm" method="post" className="needs-validation" autoComplete="off" noValidate>
                            <h1 className="h3 pb-2 fw-bold">Reply to enquiry</h1>
                            <div className="row g-4 mt-2" >
                                <div className="col-sm pt-2">
                                    <div className="form-floating">
                                        <input type="hidden" name="id" defaultValue={enquiry.id} />
                                        <input type="text" name="subject" className="form-control form-control-md" placeholder="Subject" defaultValue={enquiry.subject} disabled/>
                                        <label>Subject <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-sm-12 pt-2">
                                    <div className="form-floating">
                                        <textarea type="text" name="message" className="form-control form-control-md h-140" rows="5" cols="30" placeholder="Message" defaultValue={enquiry.description} disabled></textarea>
                                        <label>Description <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-sm-12 pt-2">
                                    <div className="form-floating">
                                        <textarea type="text" name="remarks" id="remarks" className="form-control form-control-md h-140" rows="5" cols="30" placeholder="Remarks" defaultValue={enquiry && enquiry.remarks ? enquiry.remarks : ''} required></textarea>
                                        <div className="invalid-feedback">
                                            The remarks field is required.
                                        </div>
                                        <label>Remarks <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => document.querySelector('#enquiryModalForm').classList.remove('was-validated')}>Close</button>
                                    <button type="button" className="btn btn-theme-primary ms-2" disabled={tinyloader} onClick={(e) => editEnquiry(e)}  >
                                        <ButtonSpinner load={tinyloader} btnName="Reply" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Enquiry