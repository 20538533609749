import { useState, useEffect } from "react";
import { ButtonSpinner, blobToBase64, noImageHandle, validateForm } from '../../../components/CommonMethod'
import { ASSET_ENDPOINT, QUESTION_CREATE, fetchData } from '../../../components/Service'
import { CKEditor } from 'ckeditor4-react';
import ReactHtmlParser from "react-html-parser"
import QuestionCard from "./QuestionCard";
import _, { now } from "lodash"
import { MathElementForQuestion } from "../../../components/MathElements";

const Question = (props) => {
    const [tinyloader, setTinyloader] = useState(false)
    const [timestamp] = useState(Date.now() + '' + Math.floor((Math.random() * 100000) + 1))
    const [imageb64, setImageb64] = useState('')

    const [qsLabel1, setQsLabel1] = useState('Input name')
    const [qsLabel2, setQsLabel2] = useState('Answer')
    const [qsLabel3, setQsLabel3] = useState(false)
    const [qsLabel4, setQsLabel4] = useState('Draggable options')
    const [ckeditorInstance1, setCkeditorInstance1] = useState();
    const [ckeditorInstance2, setCkeditorInstance2] = useState();
    const [ckeditorInstance3, setCkeditorInstance3] = useState();

    const [questionTitle, setQuestionTitle] = useState(props.questionData && props.questionData.question ? props.questionData.question : '')
    const [questionPhrase, setQuestionPhrase] = useState(props.questionData && props.questionData.question_phrase ? props.questionData.question_phrase : '')
    const [cardDetails, setCardDetails] = useState({})

    const [optionList, setOptionList] = useState(props.questionData && props.questionData.option ? JSON.parse(props.questionData.option) : [])
    const [phrase, setPhrase] = useState()
    const [reasoning, setReasoning] = useState()

    const [answer, setAnswer] = useState(props.questionData && props.questionData.option && props.questionData.option.length > 0 ? props.questionData.option[0] : '')
    const [option, setOption] = useState()


    const [questionSummary, setQuestionSummary] = useState([])

    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        setImageb64(imgb64)
    }

    const addOptionList = () => {
        setOptionList(prevState => [...prevState, option]);
        setAnswer(option)
        setOption('')
        ckeditorInstance1 && ckeditorInstance1.setData('')
    }

    const removeOptionList = (value) => {
        let indexOfValue = optionList.indexOf(value)
        let updatedOption = optionList.filter((ele, i) => (i !== indexOfValue))
        setOptionList(updatedOption);
        setAnswer(updatedOption[0])
    }

    const addQuestionSummary = () => {
        setQuestionSummary([...questionSummary, { phrase: phrase, answer: answer, reasoning: reasoning }])
        setPhrase('')
        setReasoning('')
        ckeditorInstance2 && ckeditorInstance2.setData('')
        ckeditorInstance3 && ckeditorInstance3.setData('')

        if (props.questionType === 'fill_in_blanks') {
            setAnswer('')
        }
    }

    const removeQuestionSummary = (value) => {
        let indexOfValue = questionSummary.indexOf(value)
        setQuestionSummary(questionSummary.filter((ele, i) => i !== indexOfValue));
    }

    useEffect(() => {
        if (props.questionType === 'true_or_false') {
            setOptionList(['True', 'False']);
            setQsLabel1(props.questionData.changes ?? 'Statement')
            setQsLabel2(props.questionData.changes_type ?? 'Statement is')
            setAnswer('True')
        }

        if (props.questionType === 'drag_and_match') {
            setQsLabel1(props.questionData.changes ?? 'Explanation')
            setQsLabel2(props.questionData.changes_type ?? 'Matching option')
            setAnswer(optionList[0])
        }

        if (props.questionType === 'drag_and_fill') {
            setQsLabel2(props.questionData.changes_type ?? 'Matching option')
            setAnswer(optionList[0])
        }

        if (props.questionType === 'drag_to_zone') {
            setQsLabel1(props.questionData.changes ?? 'Draggable options')
            setQsLabel2(props.questionData.changes_type ?? 'Matching option')
            setQsLabel4('Zone type')
            setAnswer(optionList[0])
        }

        if (props.questionType === 'single_choice') {
            setQsLabel4('Phrase')
        }

        setQsLabel3(props.questionType === 'drag_and_fill' || props.questionType === 'fill_in_blanks' ? false : 'Reasoning')

        if (props.questionData && props.questionData.answers && props.questionData.answers.length > 0) {
            let questionSummaryArr = []
            let reasoningDecode = JSON.parse(props.questionData.reasoning);
            props.questionData.answers.forEach((ele, i) => {
                questionSummaryArr.push({ phrase: ele.phrase, answer: ele.answer, reasoning: reasoningDecode ? reasoningDecode[ele.phrase] : '' })

                if (props.questionType === 'single_choice') {
                    setAnswer(ele.answer)
                }
            });

            setQuestionSummary(questionSummaryArr)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        props.questionTypeList && props.questionTypeList.forEach((item, i) => {
            if (props.questionType === item.slug) {
                setCardDetails(item)
            }
        })
    }, [props.questionType, props.questionTypeList])


    const saveQuestions = (e, formId) => {
        let formdata = new FormData(document.getElementById(formId))
        formdata.append('workbook_id', props.workbookInfo && props.workbookInfo.id)

        if (validateForm(e, formId)) {
            setTinyloader(true);

            fetchData(QUESTION_CREATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);
                props.setReloadQuestions(now);
            })
        }else{
            document.getElementById(formId).parentElement.classList.add('border-danger')
            document.getElementById(formId).parentElement.classList.remove('border-dark')
        }
    }

    return (
        <>
            <style>
                {`
                #allquestions p{
                    font-size: 18px;
                }
                `}
            </style>
            {/* Question Header Section */}
            <form action="" className="needs-validation" id={`#question_form_${timestamp}`} noValidate autoComplete="off">
            {props.from_home_page ?
                <div className="question-controls">
                    <h3 className="fw-bolder question-serial">Question</h3>
                    <h4>{props.title}&nbsp;&nbsp;</h4>
                    <div className="text-nowrap ms-auto">
                        <button type="button" className="btn mmwh-48" data-bs-toggle="collapse" data-bs-target={`#question_box_${timestamp}`}>
                            <svg className="icon ts-2"><use href="#icon_caretdown"></use></svg>
                        </button>
                    </div>
                </div>
                :
                <div className="question-controls">
                    <h3 className="fw-bolder question-serial">Question 1</h3>
                    <h4>{cardDetails && cardDetails.title}&nbsp;&nbsp;</h4>
                    <div className="text-nowrap ms-auto d-flex gap-2">
                        <div className="d-flex justify-between align-items-center gap-2">
                            <label><input className="form-check-input mt-1" type="radio" name={`question[${timestamp}][purpose]`} value="practice" defaultChecked={props.questionData && props.questionData.purpose && props.questionData.purpose && props.questionData.purpose === 'practice' && true} /> Practice</label>
                            <label><input className="form-check-input mt-1" type="radio" name={`question[${timestamp}][purpose]`} value="exam" defaultChecked={props.questionData && props.questionData.purpose && props.questionData.purpose && props.questionData.purpose === 'exam' && true} /> Exam</label>
                            <label><input className="form-check-input mt-1" type="radio" name={`question[${timestamp}][purpose]`} value="both" defaultChecked={((props.questionData && props.questionData.purpose && props.questionData.purpose === 'both') || (props.questionData && !props.questionData.purpose)) && true} /> Both</label>
                        </div>
                        <div>
                            <button type="button" className="btn mmwh-48" data-bs-toggle="collapse" data-bs-target={`#question_box_${timestamp}`}>
                                <svg className="icon ts-2"><use href="#icon_caretdown"></use></svg>
                            </button>
                            <button type="button" className="btn btn-outline-danger mmwh-48 ms-2 delete-element" data-bs-toggle="modal" data-bs-target="#deletesection_modal" onClick={() => props.setDataToDelete({ questionElementId: props.questionElementId, questionIndex: props.questionIndex, questionId: props.questionData && props.questionData.id })}>
                                <svg className="icon ts-1p5"><use href="#icon_delete"></use></svg>
                            </button>
                            {props.questionData && props.questionData.id && <button type="button" className="btn btn-outline-dark mmwh-48 ms-2" onClick={(e) => props.questionAction('copy', props.questionData && props.questionData.id)}>
                                <svg className="icon ts-1p5"><use href="#icon_copy"></use></svg>
                            </button>}
                            <button type="button" className="btn btn-theme-primary mmwh-48 ms-2" disabled={tinyloader} onClick={(e) => saveQuestions(e, `#question_form_${timestamp}`)}>
                                {tinyloader ? <div className="spinner-border spinner-border-sm" role="status"></div> : <svg className="icon ts-1p5"><use href="#icon_upload"></use></svg>}
                            </button>
                            <button type="button" className="btn minh-48 text-dim ms-2 px-1 sortable-handler" >
                                <svg className="icon fs-3"><use href="#icon_drag"></use></svg>
                            </button>
                        </div>
                    </div>
                </div>
            }

            {/* Question Body Section */}
            <div className="collapse" id={`question_box_${timestamp}`} data-bs-parent="#queriesBox">
                <div className="pt-4">
                    <input type="hidden" name={`question[${timestamp}][order]`} className="question-order" />
                    <input type="hidden" name={`question[${timestamp}][type]`} value={props.questionType} />
                    <input type="hidden" name={`question[${timestamp}][question_id]`} value={props.questionData && props.questionData.id} />
                    <input type="hidden" name={`question[${timestamp}][changes]`} value={qsLabel1} />
                    <input type="hidden" name={`question[${timestamp}][changes_type]`} value={qsLabel2} />

                    {/* Question Type Card Section */}
                    <div className="maxw-480 mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <span className="fs-14 fw-bold text-uppercase">Question Type</span>
                        </div>
                        <QuestionCard border="border-dark" cardDetails={cardDetails} />
                    </div>

                    {/* Question Title Section */}
                    <div>
                        <span className="fs-14 fw-bold text-uppercase">Question</span>
                        <div>
                            <label className="fs-12 fw-bold text-muted">Please enter a question <strong className="text-danger">*</strong></label>
                            <CKEditor
                                initData={ReactHtmlParser(questionTitle)}
                                editorUrl='../ckeditor/ckeditor.js'
                                onChange={(e) => setQuestionTitle(e.editor.getData())}
                            />
                        </div>
                    </div>
                    <div className="maxw-820 mb-4">
                        <div className="form-floating">
                            <input type="text" className="form-control form-control-md d-none" name={`question[${timestamp}][question]`} placeholder="Please enter one line question" value={questionTitle} required />
                            <div className="invalid-feedback">Please enter a question.</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-8 col-sm-12">
                            {/* Question | Answer title */}
                            <span className="fs-14 mb-4 fw-bold text-uppercase">
                                {['fill_in_blanks', 'drag_and_fill', 'single_choice', 'energy_diagram'].includes(props.questionType) && 'Answer'}
                                {props.questionType === 'drag_and_match' && 'Draggable Options'}
                                {props.questionType === 'drag_to_zone' && 'Zones'}
                                {props.questionType === 'true_or_false' && 'Phrase'}
                            </span>
                            {/* Question | Answer explainations */}
                            <p className="fs-14 mb-4">
                                {props.questionType === 'drag_and_fill' && 'From the dropdown, choose a number of words from the phrase to act as `missing words`. Explain the correct answer through reasoning.'}
                                {props.questionType === 'drag_and_match' && 'These are the options that students will drag.'}
                                {props.questionType === 'drag_to_zone' && 'Type in zone. Students will drag options to match with these zones.'}
                                {props.questionType === 'single_choice' && 'Enter four phrases. From the dropdown select the correct response. Explain the correct answer through reasoning.'}
                                {props.questionType === 'true_or_false' && 'Type in a phrase, and select if the phrase is true or false. Explain the answer in reasoning, then click add.'}
                                {props.questionType === 'energy_diagram' && 'Enter the correct answers corresponding to the relevant boxes. Write the reasoning below for the answer.'}
                            </p>

                            {['drag_and_fill', 'drag_and_match', 'drag_to_zone', 'single_choice'].includes(props.questionType) && <>
                                <div className="row gx-3 gy-4 mb-3">
                                    <div className="col-sm-10 pb-2 no-p-margin-bottom mt-0">
                                        <label className="fs-12 fw-bold text-muted">{qsLabel4}</label>
                                        <CKEditor
                                            type="inline"
                                            editorUrl='../ckeditor/ckeditor.js'
                                            onChange={(e) => { setOption(e.editor.getData()); setCkeditorInstance1(e.editor) }}
                                        />
                                    </div>

                                    <div className="col-sm-2 pb-2 text-end">
                                        <button type="button" className={`btn btn-outline-default minw-90 ms-3 ${!option && 'disabled'}`} onClick={() => addOptionList()}>Add</button>
                                    </div>
                                    {optionList && optionList.length > 0 && <div className="col-sm-12 pb-2">
                                        <div className="card border-dark rounded-3 px-2 py-2">
                                            {optionList.map((ele, i) => {
                                                return <div className="d-flex justify-content-between align-items-center fw-bold py-1 bg-light rounded my-1" key={`${i}_${timestamp}`}>
                                                    <input type="hidden" name={`question[${timestamp}][option][]`} value={ele} />
                                                    <span className="mx-2 text-nowrap fw-bold border-0 text-muted">Option {i + 1}</span>
                                                    <span className="ms-1 form-control border-0 no-p-margin-bottom">
                                                        <MathElementForQuestion ele_id={`${props.questionType}_${i}_option_div`} elements={ele} />
                                                    </span>
                                                    <button type="button" className="btn btn-sm mx-1" title="Delete" onClick={(e) => removeOptionList(ele)}>
                                                        <svg className="icon ts-1p4 pe-none"><use href="#icon_delete"></use></svg>
                                                    </button>
                                                </div>
                                            })
                                            }
                                        </div>
                                    </div>}
                                </div>
                            </>}

                            {/* Question | Fill in blanks */}
                            {['fill_in_blanks'].includes(props.questionType) && <>
                                <div className="row gx-3 gy-4">
                                    <div className="col-sm-5 pb-2">
                                        <div className="form-floating w-100">
                                            <input type="text" className="form-control form-control-md" value={phrase} onChange={(e) => setPhrase(e.target.value)} placeholder={qsLabel1} />
                                            <label>{qsLabel1}</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-5 pb-2">
                                        <div className="form-floating w-100">
                                            <input type="text" className="form-control form-control-md" value={answer} onChange={(e) => setAnswer(e.target.value)} placeholder={qsLabel2} />
                                            <label>{qsLabel2}</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 pb-2 text-end">
                                        <button type="button" className={`btn btn-outline-default minw-120 ${(!phrase || !answer) ? 'disabled' : ''}`} onClick={() => addQuestionSummary()}>Add</button>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>

                    {props.questionType === 'drag_and_fill' &&
                        <div className="mb-4">
                            <span className="fs-14 fw-bold text-uppercase">Question Phrase</span>
                            <div>
                                <label className="fs-12 fw-bold text-muted">Please enter a question phrase<strong className="text-danger">*</strong></label>
                                <CKEditor
                                    initData={ReactHtmlParser(questionPhrase)}
                                    editorUrl='../ckeditor/ckeditor.js'
                                    onChange={(e) => setQuestionPhrase(e.editor.getData())}
                                />
                            </div>
                            <input type="text" className="form-control form-control-md d-none" name={`question[${timestamp}][question_phrase]`} value={questionPhrase} />
                        </div>
                    }

                    <div className="row mb-4">
                        <div className="col-md-8 col-sm-12">
                            {/* Question | True & False */}
                            {['drag_and_fill', 'drag_and_match', 'true_or_false', 'drag_to_zone'].includes(props.questionType) && <>
                                {props.questionType !== 'true_or_false' && <><span className="fs-14 fw-bold text-uppercase">{qsLabel1} & {qsLabel2}</span>
                                    <p className="fs-14 mb-4">
                                        Type in <strong>{_.lowerCase(qsLabel1)}</strong>, choose the corresponding <strong>{_.lowerCase(qsLabel2)}</strong> and include reasoning. Click add the <strong>{_.lowerCase(qsLabel1)}</strong> and <strong>{_.lowerCase(qsLabel2)}</strong> pair to the question.
                                    </p></>}
                                <div className="row gx-3 gy-4">
                                    {props.questionType === 'drag_and_fill' ?
                                        <div className="col-md-8 col-lg-9 pb-2">
                                            <div className="form-floating w-100">
                                                <input type="text" className="form-control form-control-md" value={phrase} onChange={(e) => setPhrase(e.target.value)} placeholder={qsLabel1} />
                                                <label>{qsLabel1}</label>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-md-8 col-lg-9 pb-2 no-p-margin-bottom mt-0">
                                            <label className="fs-12 fw-bold text-muted">{qsLabel1}</label>
                                            <CKEditor
                                                type="inline"
                                                editorUrl='../ckeditor/ckeditor.js'
                                                onChange={(e) => { setPhrase(e.editor.getData()); setCkeditorInstance2(e.editor) }}
                                            />
                                        </div>
                                    }
                                    <div className="col-md-4 col-lg-3 pb-2">
                                        <div className="form-floating">
                                            <select className="form-select form-select-md" onChange={(e) => setAnswer(e.target.value)} value={answer} required>
                                                {optionList && optionList.length > 0 ? optionList.map((ele, i) => {
                                                    return <option value={ele} key={`${i}_${timestamp}`}>{props.questionType === 'true_or_false' ? ele : `Option ${i + 1}`}</option>
                                                }) : <option value="">Select Correct Answer</option>}
                                            </select>
                                            <label>{qsLabel2}</label>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 mt-0 no-p-margin-bottom reasoning-height">
                                        <label className="fs-12 fw-bold text-muted">{qsLabel3}</label>
                                        {props.questionType !== 'drag_and_fill' &&
                                            <CKEditor
                                                type="inline"
                                                editorUrl='../ckeditor/ckeditor.js'
                                                onChange={(e) => { setReasoning(e.editor.getData()); setCkeditorInstance3(e.editor) }}
                                            />
                                        }
                                        <button type="button" className={`btn btn-outline-default minw-100 ${props.questionType !== 'drag_and_fill' ? 'mt-3' : ''} ${(!phrase) ? 'disabled' : ''}`} onClick={() => addQuestionSummary()}>Add</button>
                                    </div>
                                </div>
                            </>}

                            {/* Question | Single Choice Question */}
                            {props.questionType === 'single_choice' && <>
                                <div className="form-floating mt-32 mb-4">
                                    <input type="hidden" name={`question[${timestamp}][answers][0][phrase]`} value={questionTitle} />
                                    <select className="form-select form-select-md" name={`question[${timestamp}][answers][0][answer]`} onChange={(e) => setAnswer(e.target.value)} value={answer} required>
                                        {optionList && optionList.length > 0 ? optionList.map((ele, i) => {
                                            return <option value={ele} key={`${i}_${timestamp}`}>Option {i + 1}</option>
                                        })
                                            : <option value="">Select Correct Answer</option>}
                                    </select>
                                    <div className="invalid-feedback">Please select correct response.</div>
                                    <label>Correct response <strong className="text-danger">*</strong></label>
                                </div>

                                <div className="form-floating mt-32 mb-4">
                                    <select className="form-select form-select-md" name={`question[${timestamp}][one_row_option]`} defaultValue={props.questionData && props.questionData.one_row_option ? props.questionData.one_row_option : ''}>
                                        <option value="">Default</option>
                                        <option value="1">1X1</option>
                                        <option value="2">2X1</option>
                                        <option value="4">4X1</option>
                                    </select>
                                    <label>Option show in a row<strong className="text-danger">*</strong></label>
                                </div>
                            </>}

                            {/* Question | Energy Diagram */}
                            {props.questionType === 'energy_diagram' && <>
                                <div className="row align-items-center mb-3">
                                    <div className="col-auto fw-bold minw-40 pe-0">4p</div>
                                    <div className="col-auto d-flex gap-3">
                                        <input type="hidden" name={`question[${timestamp}][answers][0][phrase]`} value="4p" />
                                        <input type="text" name={`question[${timestamp}][answers][0][answer]`} defaultValue={props.questionData && props.questionData.answers && props.questionData.answers.length === 8 && props.questionData.answers[0].answer} className="form-control maxw-90" />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-auto fw-bold minw-40 pe-0">3d</div>
                                    <div className="col-auto d-flex gap-3">
                                        <input type="hidden" name={`question[${timestamp}][answers][1][phrase]`} value="3d" />
                                        <input type="text" name={`question[${timestamp}][answers][1][answer]`} defaultValue={props.questionData && props.questionData.answers && props.questionData.answers.length === 8 && props.questionData.answers[1].answer} className="form-control maxw-90" />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-auto fw-bold minw-40 pe-0">4s</div>
                                    <div className="col-auto d-flex gap-3">
                                        <input type="hidden" name={`question[${timestamp}][answers][2][phrase]`} value="4s" />
                                        <input type="text" name={`question[${timestamp}][answers][2][answer]`} defaultValue={props.questionData && props.questionData.answers && props.questionData.answers.length === 8 && props.questionData.answers[2].answer} className="form-control maxw-90" />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-auto fw-bold minw-40 pe-0">3p</div>
                                    <div className="col-auto d-flex gap-3">
                                        <input type="hidden" name={`question[${timestamp}][answers][3][phrase]`} value="3p" />
                                        <input type="text" name={`question[${timestamp}][answers][3][answer]`} defaultValue={props.questionData && props.questionData.answers && props.questionData.answers.length === 8 && props.questionData.answers[3].answer} className="form-control maxw-90" />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-auto fw-bold minw-40 pe-0">3s</div>
                                    <div className="col-auto d-flex gap-3">
                                        <input type="hidden" name={`question[${timestamp}][answers][4][phrase]`} value="3s" />
                                        <input type="text" name={`question[${timestamp}][answers][4][answer]`} defaultValue={props.questionData && props.questionData.answers && props.questionData.answers.length === 8 && props.questionData.answers[4].answer} className="form-control maxw-90" />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-auto fw-bold minw-40 pe-0">2p</div>
                                    <div className="col-auto d-flex gap-3">
                                        <input type="hidden" name={`question[${timestamp}][answers][5][phrase]`} value="2p" />
                                        <input type="text" name={`question[${timestamp}][answers][5][answer]`} defaultValue={props.questionData && props.questionData.answers && props.questionData.answers.length === 8 && props.questionData.answers[5].answer} className="form-control maxw-90" />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-auto fw-bold minw-40 pe-0">2s</div>
                                    <div className="col-auto d-flex gap-3">
                                        <input type="hidden" name={`question[${timestamp}][answers][6][phrase]`} value="2s" />
                                        <input type="text" name={`question[${timestamp}][answers][6][answer]`} defaultValue={props.questionData && props.questionData.answers && props.questionData.answers.length === 8 && props.questionData.answers[6].answer} className="form-control maxw-90" />
                                    </div>
                                </div>
                                <div className="row align-items-center mb-4">
                                    <div className="col-auto fw-bold minw-40 pe-0">1s</div>
                                    <div className="col-auto d-flex gap-3">
                                        <input type="hidden" name={`question[${timestamp}][answers][7][phrase]`} value="1s" />
                                        <input type="text" name={`question[${timestamp}][answers][7][answer]`} defaultValue={props.questionData && props.questionData.answers && props.questionData.answers.length === 8 && props.questionData.answers[7].answer} className="form-control maxw-90" />
                                    </div>
                                </div>
                            </>}

                            {/* Question Summary Section */}
                            {!['energy_diagram', 'single_choice'].includes(props.questionType) &&
                                <div className="mt-3 mb-4">
                                    <span className="fs-14 fw-bold text-uppercase">Question Summary <strong className="text-danger">*</strong></span>
                                    <p className="fs-14 mb-3"></p>
                                    {questionSummary && questionSummary.length > 0 && <div className="table-responsive border border-dark rounded-3 p-2">
                                        <table className="table table-borderless table-hover m-0">
                                            <colgroup>
                                                <col width="45%" />
                                            </colgroup>
                                            <thead className="fs-12 fw-bold">
                                                <tr>
                                                    <td><input type="text" name="qsLabel1" className="form-control maxw-320 border-0 px-0 fw-bold" defaultValue={qsLabel1} required onChange={(e) => setQsLabel1(e.target.value)} /></td>
                                                    <td><input type="text" name="qsLabel2" className="form-control maxw-320 border-0 px-0 fw-bold" defaultValue={qsLabel2} required onChange={(e) => setQsLabel2(e.target.value)} /></td>
                                                    {qsLabel3 && <td><span className="form-control maxw-320 border-0 px-0 fw-bold">{qsLabel3}</span></td>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {questionSummary.map((ele, num) => {
                                                    return (
                                                        <tr key={`qs_${num}_${timestamp}`}>
                                                            <td>
                                                                <input type="hidden" name={`question[${timestamp}][phrase][]`} value={ele.phrase} />
                                                                <span className="no-p-margin-bottom">
                                                                    <MathElementForQuestion ele_id={`${props.questionType}_${num}_phrase_div`} elements={ele.phrase} />
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <input type="hidden" name={`question[${timestamp}][answers][${num}][phrase]`} value={ele.phrase} />
                                                                <input type="hidden" name={`question[${timestamp}][answers][${num}][answer]`} value={ele.answer} />
                                                                <span className="no-p-margin-bottom">
                                                                    <MathElementForQuestion ele_id={`${props.questionType}_${num}_answers_div`} elements={ele.answer} />
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <input type="hidden" name={`question[${timestamp}][reasoning][${num}][phrase]`} value={ele.phrase} />
                                                                <input type="hidden" name={`question[${timestamp}][reasoning][${num}][reasoning]`} value={ele.reasoning} />
                                                                <span className="no-p-margin-bottom">
                                                                    <MathElementForQuestion ele_id={`${props.questionType}_${num}_reasoning_div`} elements={ele.reasoning} />
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <button type="button" className="btn btn-sm remove" title="Delete" onClick={(e) => removeQuestionSummary(ele)}>
                                                                    <svg className="icon ts-1p4"><use href="#icon_delete"></use></svg>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>}

                                    {questionSummary && questionSummary.length === 0 && <>
                                        <input type="text" className="form-control form-control-md d-none" defaultValue={questionSummary.length > 0 ? 'question_summary' : ''} required />
                                        <div className="invalid-feedback">Please add question summary.</div></>}
                                </div>}

                            {/* Question Reasoning Section */}
                            {['fill_in_blanks', 'drag_and_fill', 'single_choice', 'energy_diagram'].includes(props.questionType) &&
                                <div className={`row gx-3 gy-4 ${!props.from_home_page && 'mb-4'}`}>
                                    <div className="col-md-12 col-sm-12">
                                        <span className="fs-14 fw-bold text-uppercase">Reasoning</span>
                                        <p className="fs-14 mb-3"></p>
                                        <div className="form-floating">
                                            <textarea type="text" name={`question[${timestamp}][reasoning]`} defaultValue={props.questionData && props.questionData.reasoning ? JSON.parse(props.questionData.reasoning) : ''} className="form-control form-control-md h-90" placeholder="Reasoning"></textarea>
                                            <label>Reasoning</label>
                                        </div>
                                    </div>
                                </div>}

                            {/* Question Video URL Section */}
                            {!props.from_home_page &&
                                <div className="row gx-3 gy-4">
                                    <div className="col-md-12 col-sm-12">
                                        <p className="fs-14 mb-3"></p>
                                        <div className="form-floating">
                                            <input type="text" name={`question[${timestamp}][video_url]`} defaultValue={props.questionData && props.questionData.video_url ? props.questionData.video_url : ''} className="form-control form-control-md" placeholder="Video URL" />
                                            <label>Video URL</label>
                                        </div>
                                    </div>
                                </div>}
                        </div>

                        <div className="col-md-4 col-sm-12 d-flex flex-column">
                            {/* Question Solution Image Section  */}
                            <div className="d-flex d-flex aligns-items-center justify-content-center mt-auto">
                                <div className="form-floating text-center">
                                    <span className="fs-14 fw-bold text-uppercase">Solution Image</span>
                                    <div className="d-flex align-items-center justify-content-center flex-column">
                                        <label htmlFor={`solution_image_${timestamp}`} className="ratio ratio-1x1 maxw-120 rounded-2 overflow-hidden shadow-md my-3 d-block">
                                            <img src={imageb64 ? imageb64 : (props.questionData && props.questionData.solution_image ? ASSET_ENDPOINT + props.questionData.solution_image : '../images/no-image.png')} className="img-fluid" alt="Solution" onError={noImageHandle} />
                                        </label>
                                        <div className="overflow-hidden position-relative">
                                            <input type="file" name={`solution_image_${timestamp}`} id={`solution_image_${timestamp}`} accept=".jpg,.jpeg,.png" className="position-absolute opacity-0" onChange={async (e) => imageSet(e)} />
                                        </div>
                                        <label htmlFor={`solution_image_${timestamp}`} className="btn btn-theme-primary px-4 maxw-250">{props.questionData && props.questionData.solution_image ? 'Reupload solution image' : 'Upload solution image'}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 d-flex">
                    <button type="button" className="btn btn-theme-primary btn-lg float-end" disabled={tinyloader} onClick={(e) => saveQuestions(e, `#question_form_${timestamp}`)}>
                        <ButtonSpinner load={tinyloader} btnName="Save Question" />
                    </button>
                </div>
            </div>
            </form>
        </>
    )
}

export default Question;