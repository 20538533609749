import { useState, useContext } from 'react'
import { Link } from "react-router-dom";
import { PasswordShow, validateForm, ButtonSpinner } from "../../components/CommonMethod";
import { fetchData, LOGIN } from '../../components/Service';
import { Context } from '../../components/Context';
import ForgetPassword from './ForgetPassword';
import { isGuest, logoutGuest } from '../../components/GuestHelper';

const SignIn = () => {
    window.document.title = "Sign In";
    const [, setContext] = useContext(Context)
    const [tinyloader, setTinyloader] = useState(false);

    const changeHandler = (e) => {
        if (e.keyCode === 13) {
            adminLogin(e)
        }
    }

    const adminLogin = async (e) => {
        let formdata = new FormData(document.getElementById('adminLogin'));
        formdata.append('cms', true)
        let guest = isGuest()
        formdata.append('guest', JSON.stringify(guest))

        if (validateForm(e, "adminLogin")) {
            setTinyloader(true);

            fetchData(LOGIN, 'POST', formdata, false, true, (res) => {
                setTinyloader(false);

                if (res.records) {
                    localStorage.setItem('waae-token', res.records.access_token)
                    logoutGuest()
                    
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.records
                    }))
                }  
            });
        };
    }

    return (
        <section className="d-flex justify-content-center align-items-center w-100 min-vh-100">
            <div className="container">
                <div className="row justify-content-center">
                    <form className="col-sm-7 col-md-6 col-lg-5 p-4 px-lg-5 needs-validation" id="adminLogin" noValidate>
                        <Link to="/" className="text-decoration-none text-dark">
                            <div className="text-center mb-5 pe-none">
                                <img src="/images/logo.svg" alt="Logo" />
                                <p className="fs-18 fw-bold pt-3">Content management system</p>
                            </div>
                        </Link>

                        <h3 className="fw-bold text-blue">Log in</h3>

                        <p className="fw-bold py-2">
                            Don't have an account yet? <Link to="/cms/create-new-admin" className="text-body blue-hover">Create a new administrator's account</Link>
                        </p>

                        <div className="form-floating mt-32 mb-4" >
                            <input type="email" name="email" id="email" placeholder="Email address" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" className="form-control form-control-md" onKeyUp={(e) => changeHandler(e)} autoComplete="username" required />
                            <div className="invalid-feedback">
                                The email field is required and must be a valid email address.
                            </div>
                            <label>Email Address <strong className="text-danger">*</strong></label>
                        </div>

                        <div className="form-floating password-wrapper mt-32 mb-4">
                            <input type="password" name="password" id="password" placeholder="Password" className="form-control form-control-md" onKeyUp={(e) => changeHandler(e)} autoComplete="current-password" required />
                            <button type="button" className="btn" onClick={(e) => PasswordShow(e, 'password')}>
                                <svg className="icon pe-none"><use href="#icon_eye"></use></svg>
                            </button>
                            <div className="invalid-feedback position-absolute">
                                The password field is required.
                            </div>
                            <label>Password <strong className="text-danger">*</strong></label>
                        </div>

                        <div className="text-muted">
                            <Link to="#forgotPasswordModal" data-bs-toggle="modal" title="Log in"> Forgot Password</Link>
                        </div>

                        <div className="text-end">
                            <button type="button" className="btn btn-theme-primary btn-lg minw-110 mb-3 mb-sm-2 me-auto me-sm-0 order-sm-1" onClick={(e) => adminLogin(e)} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName="Log in" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <ForgetPassword/>
        </section>
    )
}

export default SignIn