import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCountryFlagSrc } from "../../components/CommonMethod";
import { fetchData, COURSE_LIST } from "../../components/Service";
import { PageNavigation } from "../../components/DataTable"

const ManageCourses = () => {
    window.document.title = "Manage Courses";

    const [course, setCourse] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        fetchData(`${COURSE_LIST}?page=${currentPage}`, 'GET', '', true, false, (res) => {
            if (res.records) {
                setCourse(res.records ?? [])
            }else{
                setCourse(false)
            }
        })
    }, [currentPage]);

    return (
        <div className="container-fluid">
            <div className="page-head sticky_top_padding">
            <div className="row g-2 align-items-center">
                <div className="col-lg-6 col-6 mb-3">
                    <h1 className="h3 text-dim mb-0">Manage Courses</h1>
                </div>
                <div className="col-lg-6 col-6 mb-3">
                    <Link to="/cms/create-course" role="button" className="btn btn-green btn-lg px-3 float-end">+ Create new course</Link>
                </div>
            </div>
            </div>

            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mt-1">
                {course && course.data && course.data.length > 0 && course.data.map((item, i) => {
                    return (
                        <div className="col" key={i}>
                            <div className="card border-blue-hover rounded-3 p-4">
                                <div className="d-flex justify-content-between">
                                    <div className="pe-3">
                                        <h4 className="fw-bold">{item.course_abbr}</h4>
                                        <p className="fw-bold mb-2">{item.course_term}</p>

                                        <div className="d-flex">
                                            <div className="mmwh-24 text-white d-flex justify-content-center align-items-center rounded-circle me-2" style={{ background: `hsl(${item.accent_color}, 100%, 50%)` }}>
                                                <svg className="icon ts-1p1"><use href="#icon_books"></use></svg>
                                            </div>
                                            <span className="fw-bold">{item.topic_count} Topics</span>
                                        </div>

                                        <div className="d-flex mt-2">
                                            <div className="mmwh-24 text-white d-flex justify-content-center align-items-center rounded-circle me-2" style={{ background: `hsl(${item.accent_color}, 100%, 50%)` }}>
                                                <svg className="icon ts-1p1"><use href="#icon_class"></use></svg>
                                            </div>
                                            <span className="fw-bold">{item.section_count} Sections</span>
                                        </div>
                                    </div>

                                    <div className="ms-auto">
                                        <Link to="/cms/update-course" state={ item } role="button" className="btn btn-outline-dark mmwh-48 d-inline-flex justify-content-center align-items-center" title="Edit">
                                            <svg className="icon ts-1p5"><use href="#icon_pencil"></use></svg>
                                        </Link>
                                    </div>
                                </div>

                                <div className="d-flex align-items-center mt-3"> 
                                    <div className="mmwh-36 me-2">
                                        <img src={`../images/vector/${getCountryFlagSrc(item.country_code)}`} className="w-100" alt="Flag" />
                                    </div>
                                    <span className="text-uppercase text-dim fs-18 fw-bold">
                                        {item.country_name}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )
                }) }
            {course && course.total ? <PageNavigation active_page={course.current_page - 1} per_page={course.per_page} filter_recoards={course.total} setCurrentPage={setCurrentPage} /> : ''}

            </div>
            { (!course.data || course.data.length === 0)  && <div className="row mt-1"><h4 className="fw-bold text-danger text-center">Courses Not Found</h4></div> }
        </div>
    )
}

export default ManageCourses;