import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Sortable from 'sortablejs';
import { now } from 'lodash'
import { validateForm, blobToBase64 } from "../../components/CommonMethod";
import { fetchData, WORKBOOK_LIST, LISTING_LIST, GET_SINGLE_WORKBOOK, LISTING_CREATE, LISTING_UPDATE, DROPDOWN_DATA } from '../../components/Service';
import { Listing, ListingDetails, ListingHeader, ListingType, SelectListing, SelectWorkbook, SingleListingCourseDetails, SingleListingDetails, Workbook } from "./ListingComponent";

const CreateListing = () => {
    const navigate = useNavigate();
    const location = useLocation();

    let listInfo = location.state
    let listTitle = listInfo ? "Update Listing" : "Create Listing"

    window.document.title = listTitle;

    const [tinyloader, setTinyloader] = useState(false);
    const [course, setCourse] = useState('')
    const [topic, setTopic] = useState('')
    const [workbook, setWorkbook] = useState('')
    const [listing, setListing] = useState('')
    const [section, setSection] = useState('')
    const [imageb64, setImageb64] = useState('')
    const [listData, setListData] = useState([])
    const [listType, setListType] = useState('single')
    const [refresh] = useState(now)
    const [outcome, setOutcomeStatus] = useState('')
    const [currency, setCurrency] = useState()
    const [price, setPrice] = useState(0)

    const workbookList = {
        id: 'workbooks',
        fetchurl: WORKBOOK_LIST,
        columns: [
            { data: 'title', name: 'title', title: 'WORKBOOK TITLE' },
            { data: 'total_questions', name: 'total_questions', title: 'QUESTIONS', searching: false },
            { data: 'time_limit', name: 'time_limit', title: 'TIME LIMIT' },
            { data: 'created_at', name: 'created_at', title: 'DATE CREATED' },
            { data: 'updated_at', name: 'updated_at', title: 'LAST EDITED' },
            { data: 'action', name: 'action', title: 'ACTION', sorting: false, searching: false },
        ]
    }

    const listings = {
        id: 'listings',
        fetchurl: LISTING_LIST,
        columns: [
            { data: 'id', name: 'id', title: '', sorting: false, searching: false },
            { data: 'image', name: 'image', title: '', sorting: false, searching: false },
            { data: 'title', name: 'title', title: 'LISTING TITLE' },
            { data: 'course_abbr', name: 'course_id', title: 'COURSE', searching: false },
            { data: 'topic_name', name: 'course_topic_id', title: 'COURSE FILTERS', searching: false },
            { data: 'status', name: 'status', title: 'STATUS' },
        ],
        query_string: listInfo ? 'type=single&except_id=' + listInfo.id : 'type=single'
    }

    const getCourses = () => {
        fetchData(DROPDOWN_DATA + '?type=course', 'GET', '', true, false, (res) => {
            if (res.records) {
                setCourse(res.records.courses)
            }
        })
    }

    const getCourseTopics = (id) => {
        fetchData(DROPDOWN_DATA + '?type=topicByCourse&course_id=' + id, 'GET', '', true, false, (res) => {
            if (res.records) {
                setTopic(res.records.topicByCourse)
            }
        })
    }

    const getTopicSections = (id) => {
        fetchData(DROPDOWN_DATA + '?type=sectionByTopic&topic_id=' + id, 'GET', '', true, false, (res) => {
            if (res.records) {
                setSection(res.records.sectionByTopic)
            }
        })
    }

    const getCurrencyValue = () => {
        fetchData(DROPDOWN_DATA + '?type=currency', 'GET', '', true, false, (res) => {
            if (res.records) {
                setCurrency(res.records.currency)
            }
        })
    }

    const getListing = (ids) => {
        if (ids) {
            fetchData(LISTING_LIST + '?id=' + ids + '&paginate=noPaginate', 'GET', '', true, false, (res) => {
                if (res) {
                    setListing(res.records)
                }
            })
        }
    }

    const selectList = (e) => {
        e.target.checked ? setListData(prevState => [...prevState, e.target.value]) : setListData(listData.filter(i => i !== e.target.value));
    }

    const imageSet = async (e) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        setImageb64(imgb64)
    }

    const createListing = (e) => {
        let formdata = new FormData(document.getElementById('listingForm'));

        if (validateForm(e, 'listingForm')) {
            setTinyloader(true);

            fetchData(listInfo ? LISTING_UPDATE : LISTING_CREATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);

                if (res.success) {
                    navigate('../listings')
                }
            });
        }
    }

    useEffect(() => {
        getListing(listData.toString())
    }, [listData])

    useEffect(() => {
        Sortable.create(document.getElementById('packageSORT'), {
            handle: '.handler',
            animation: 150
        });

        getCourses()
        getCurrencyValue()

        if (listInfo) {
            setPrice(listInfo.price)
            setOutcomeStatus(listInfo.learning_outcomes_status)
            getCourseTopics(listInfo.course_id)
            getTopicSections(listInfo.course_topic_id)
            setListType(listInfo.type)

            if (listInfo.type === "single") {
                fetchData(GET_SINGLE_WORKBOOK, 'POST', { id: listInfo.workbook_id }, true, false, (res) => {
                    if (res.records) {
                        setWorkbook([res.records])
                    }
                })
            } else {
                setListData(listInfo.workbook_id.split(','))
                // getListing(listInfo.workbook_id)
            }
        }

    }, [listInfo])

    return (
        <>
            <div className="container-fluid">
                <form autoComplete="off" action="" id="listingForm" className="needs-validation grid-css_desktop" noValidate>
                    <ListingHeader listType={listType} listInfo={listInfo} listTitle={listTitle} tinyloader={tinyloader} createListing={createListing} />
                    <div className="row" id="accordionTabs">
                        <ListingType listType={listType} setListType={setListType} />

                        <div className="col-md-12">
                            <Workbook workbook={workbook} listType={listType} />
                            <Listing listType={listType} listing={listing} />

                            <h4 className="fw-bold pt-3 mt-5">Serial No.</h4>
                            <div className="row mt-24">
                                <div className="col-12 col-md-6">
                                    <div className="form-floating w-100">
                                        <input className="form-control form-control-md" placeholder="Serial No." name="serial_no" id="serial_no" required defaultValue={listInfo && listInfo.serial_no} />
                                        <div className="invalid-feedback">
                                            Serial No. field is required.
                                        </div>
                                        <label>Serial No.<span className="text-danger">*</span></label>
                                    </div>
                                </div>
                            </div>

                            <div className="maxw-700 my-5">
                                {listType === 'single' && <SingleListingCourseDetails course={course} topic={topic} section={section} listInfo={listInfo} getCourseTopics={getCourseTopics} getTopicSections={getTopicSections} />}
                                <ListingDetails setPrice={setPrice} listInfo={listInfo} currency={currency} price={price} imageb64={imageb64} imageSet={imageSet} >
                                    {listType === 'single' && <SingleListingDetails listInfo={listInfo} outcome={outcome} setOutcomeStatus={setOutcomeStatus} />}
                                </ListingDetails>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {/* modal*/}
            <SelectWorkbook workbookList={workbookList} workbook={workbook} setWorkbook={setWorkbook} />
            <SelectListing listings={listings} listData={listData} selectList={selectList} refresh={refresh} />
        </>
    )
}
export default CreateListing;
