import React, { useState } from 'react';
import { Popover, Tooltip } from "bootstrap";
import { useEffect } from 'react';
import { DropTarget } from 'react-drag-drop-container';
import shortid from 'shortid';
import MathElement from '../../../../../components/MathElements';

const DragZone = (props) => {
    const [items, setItems] = useState(props.optionItems ?? [])

    const dropItem = (e) => {
        let itemsArr = items.slice();

        if(props.isMultiDrop || (!props.isMultiDrop && itemsArr.length !== 1)){ 
            itemsArr.push({label: e.dragData.label, uid: shortid.generate(), dragged_option_id: e.dragData.dragDataId});
            setItems(itemsArr);
        }

        if(!props.isMultiDrop){
            e.srcElement.classList.remove('query-place')
        }

        if(props.isMultiDrop){
            e.containerElem.style.display = 'none';
        }

        setAnswer(itemsArr)
    };
  
    const deleteItem = (e, uid, draggged_option_id = '') => {
        let itemsArr = items.slice();
        let index = items.findIndex((item) => { return item.uid === uid });

        if (index !== -1) { itemsArr.splice(index, 1); }

        if(itemsArr.length === 0){
            e.target.closest('.query-place-checked').classList.add('query-place')
        }

        setItems(itemsArr);
        setAnswer(itemsArr)

        if(props.isMultiDrop && draggged_option_id){
            document.getElementById(draggged_option_id).closest('.ddcontainer').style.display = 'inline-block'
        }
    };

    const setAnswer = (itemsArr) => {
        props.setAnswerArr(prevState => ({
            ...prevState,
            [props.matchWidth]: itemsArr
        }))
    }

    useEffect(() => {
        Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
        Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
    }, [props.questionStatus, props.correctAnswer])
  
    return (
        <DropTarget onHit={dropItem} targetKey={props.targetKey} dropData={{name: props.name}}>
            <div className={`query-place-checked h-100 ${(!props.optionItems || props.isMultiDrop) && 'query-place'} ${!props.isNoWidth ? 'w-100' : 'd-inline-flex m-2'} ${props.isHalfDragButton && 'half-drag'} ${props.isMultiDrop && 'multi-drop'}`}>
                { items.map((item, i) => {
                    return (
                        <div className={`d-flex ${!props.isMultiDrop && 'minh-100p'}`} key={i}>
                            <span key={i} className="inner-span p-2 w-100" onClick={(e) => deleteItem(e, item.uid, item.dragged_option_id)}>
                                <MathElement ele_id={`question_${props.id}_label_${item.uid}`} elements={item.label}/>
                                <svg className="icon opacity-50 ps-2">
                                    <use href="#icon_drag"></use>
                                </svg>
                            </span>
                            { props.correctAnswer && <div className={`col-lg-2 col-md-2 col-sm-2 col-xs-1 d-flex align-items-center ms-2 ${props.questionStatus === 'default' ? 'd-none' : 'd-block'}`}>
                                <img src={ ((!props.isMultiDrop && props.correctAnswer[props.matchWidth] === item.label) || (props.isMultiDrop && props.correctAnswer[item.label] === props.matchWidth)) ? '/images/checkbox.png' : '/images/crossbox.png' } style={{width:"20px",height:"20px"}} alt="Right" id={item} />
                                { ((!props.isMultiDrop && props.questionReasoning[props.matchWidth]) || (props.isMultiDrop && props.questionReasoning[item.label])) && <button type="button"  className={`btn border-white text-blue text-decoration-underline fw-bold ${props.questionStatus === 'default' ? 'd-none' : 'd-block'}`} data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="focus hover" data-bs-template={`<div class="popover shadow maxw-400 border-blue border border-2 show" role="popover"><div class="popover-arrow  border-blue "></div><div class="popover-inner"><div class="row "><div class="col-sm-4"><img src='/images/why-male.png'  alt='Male'/></div><div class="col-sm-8"><p class='mb-0 pt-2 px-3 fw-medium text-blue'></p><p class='mb-0 px-3 text-blue fw-medium' style="background-color:#000">${props.isMultiDrop ? props.questionReasoning[item.label] : props.questionReasoning[props.matchWidth]} </p></div></div></div></div>`}>
                                    Why?
                                </button> }
                            </div> }
                        </div>
                    )
                })}
            </div>
        </DropTarget>
    );
}

export default DragZone