import { noImageHandle, RatingShow } from "../../components/CommonMethod";
import { useEffect, useContext, useState } from "react";
import Exam from "../../components/Exam"
import { Context } from '../../components/Context';
import { fetchData, ASSET_ENDPOINT, RESTART_EXAM, MY_ASSIGNMENT_WORKBOOKS } from "../../components/Service";
import { PageNavigation } from "../../components/DataTable";
import _ from "lodash";
import { TooltipMsg } from "../../components/WorkbookFilter";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns/esm";

function AssignmentWorkbook() {
    const [context] = useContext(Context)
    const [data, setData] = useState([])
    const [workbookInfo, setWorkbookInfo] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [workbookAttemptType] = useState('assignment')
    const navigate = useNavigate()

    const location = useLocation();
    let assignmentInfo = location.state

    window.document.title = assignmentInfo && assignmentInfo.title;

    useEffect(() => setCurrentPage(1), [])
    useEffect(() => {
        if (context && context.auth) {
            fetchData(`${MY_ASSIGNMENT_WORKBOOKS}?page=${currentPage}`, 'POST', { assignment_id: (assignmentInfo && assignmentInfo.id) }, true, false, (res) => {
                if (res.records) {
                    setData(res.records ?? [])
                }
            });
        }
    }, [context, currentPage, assignmentInfo])

    const operationPerform = (data, action) => {
        let mode = data.workbook.exam_records.exam_mode
        if (action === 'restart') {
            fetchData(RESTART_EXAM, 'POST', { workbook_id: data.workbook.id, workbook_attempt_type: workbookAttemptType, assignment_id: assignmentInfo.id }, true, false, (res) => {
                if (res.records) {
                    navigate(`/workbook/${_.kebabCase(data.workbook && data.workbook.title)}/${mode}`, { state: { action: action, mode: mode, course: data.course, workbook: data.workbook, workbook_attempt_type: workbookAttemptType, assignment: assignmentInfo } })
                }
            });
        } else {
            navigate(`/workbook/${_.kebabCase(data.workbook && data.workbook.title)}/${mode}`, { state: { action: action, mode: mode, course: data.course, workbook: data.workbook, workbook_attempt_type: workbookAttemptType, assignment: assignmentInfo } })
        }
    }

    return (
        <>
            <section>
                <div className="container container-1180 py-5">
                    {context && context.auth &&
                        <div className="d-flex pb-4">
                            <svg className="icon mmwh-35 sm-mmwh-28 text-lime"><use href="#icon_subtract"></use></svg>
                            <h6 className="title ms-3 text-blue">Hello! {context.auth.first_name} {context.auth.last_name}</h6>
                        </div>}
                    <div className="row g-3">
                        <div className="col-lg-12 body-over_flowx">
                            <div className="d-lg-flex justify-content-between">
                                <div className="d-flex justify-content-between mb-4 mb-md-3 order-1">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                                            <li className="breadcrumb-item"><Link to="/my-assignments">My assignments</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{assignmentInfo && assignmentInfo.title}</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>

                            {data && data.data && data.data.length > 0 && data.data.map((item, index) => {
                                return (
                                    <div className="col-lg-12 mb-4" key={index}>
                                        <div className="card border-blue-hover rounded-3">
                                            <div className="card-body p-md-4 d-sm-flex">
                                                <div className="mmwh-180 d-sm-block shadow-md rounded-3 position-relative overflow-hidden me-3 me-md-4 l">
                                                    <img src={item.image && ASSET_ENDPOINT + item.image} className="h-100 ratio hm-180" alt="Workbook" onError={noImageHandle} />
                                                    <span className="position-absolute start-0 top-0 text-white fw-bold rounded-pill px-2 minw-70 py-1 ms-2 mt-2 text-center" style={{ background: `hsl(${item.course && item.course.accent_color}, 100%, 50%)` }}>{item.course && item.course.course_abbr}</span>
                                                </div>
                                                <div className="d-block mt-3 mt-sm-0 w-100">
                                                    <div className="d-flex align-items-center">
                                                        <RatingShow fontsize="fs-4" rating={item.workbook && item.workbook.exam_records && item.workbook.exam_records.status === 'completed' ? JSON.parse(item.workbook.exam_records.question_details) : '0'} />
                                                        <h6 className="ms-auto text-green bold mt-1  ps-2">{item.workbook && item.workbook.exam_records && item.workbook.exam_records.status === 'updated' && `Workbook updated on ${format(new Date(item.workbook.exam_records.updated_at), 'MM/dd/yyyy')}.`}</h6>
                                                    </div>
                                                    <h4 className="fw-bold workbook-heading-smallsize ">
                                                        {item.workbook && item.workbook.title}
                                                        {item.user_id && item.expired_on && <TooltipMsg message={`Workbook is valid till ${format(new Date(item.expired_on), 'MM/dd/yyyy')}.`} />}
                                                    </h4>
                                                    <span className="d-flex gap-2 pb-3">
                                                        <div className="circle-progress ratio ratio-1x1 mmwh-24">
                                                            <svg viewBox="0 0 36 36">
                                                                <path stroke="#eeeeee" strokeWidth="3" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                                {item.workbook && item.workbook.exam_records ?
                                                                    <path strokeWidth="4" stroke="#1E90FF" id="fillStroke" strokeDasharray={`${((JSON.parse(item.workbook.exam_records.answer_details).length) * 100) / (item.workbook.exam_records.exam_mode === 'exam' ? item.workbook.exam_questions_count : item.workbook.practice_questions_count)}, 100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                                    : <path strokeWidth="4" stroke="" id="fillStroke" strokeDasharray="0, 100" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                                                                }

                                                                <text x="18" y="21.2" fill="#1E90FF"></text>
                                                            </svg>
                                                        </div>
                                                        {item.workbook && item.workbook.exam_records ? <>
                                                            <span className="fw-normal">{JSON.parse(item.workbook.exam_records.answer_details).length} / {item.workbook.exam_records.exam_mode === 'exam' ? item.workbook.exam_questions_count : item.workbook.practice_questions_count} questions completed</span>
                                                        </> : <span className="fw-normal">{0} / {item.workbook && item.workbook.questions_count} questions completed</span>}
                                                    </span>
                                                    {item.workbook && item.workbook.exam_records && <p className="fw-normal">Last opened {item.workbook.exam_records.ago === 0 && 'Today'} {item.workbook.exam_records.ago === 1 && '1 day ago'} {item.workbook.exam_records.ago > 1 && `${item.workbook.exam_records.ago} days ago`}</p>}

                                                    {item.workbook && item.workbook.exam_records ?
                                                        <>
                                                            {item.workbook.exam_records.status === 'completed' && <button data-bs-target="#examModal" data-bs-toggle="modal" className={`${item.workbook && item.workbook.questions_count > 0 ? '' : 'disabled'} btn btn-theme-primary w-70 w-sm-50`} title="Restart" onClick={() => setWorkbookInfo(item)}>Restart</button>}
                                                            {item.workbook.exam_records.status === 'updated' && <button data-bs-target="#examModal" data-bs-toggle="modal" className={`${item.workbook && item.workbook.questions_count > 0 ? '' : 'disabled'} btn btn-theme-primary w-70 w-sm-50`} title="Restart" onClick={() => setWorkbookInfo(item)}>Restart</button>}

                                                            {item.workbook.exam_records.status === 'in-progress' && <>
                                                                <button className={`${item.workbook && item.workbook.questions_count > 0 ? '' : 'disabled'} btn btn-outline-default`} title="Restart" onClick={() => operationPerform(item, 'restart')}>Restart</button>
                                                                <button className={`${item.workbook && item.workbook.questions_count > 0 ? '' : 'disabled'} btn btn-theme-primary w-70 w-sm-50 ms-2`} title="Resume Practice" onClick={() => operationPerform(item, 'resume')}>Resume Practice</button> </>
                                                            }

                                                        </>
                                                        : <button data-bs-target="#examModal" data-bs-toggle="modal" className={`${item.workbook && item.workbook.questions_count > 0 ? '' : 'disabled'} btn btn-theme-primary w-70 w-sm-50`} title="Start" onClick={() => setWorkbookInfo(item)}>Start</button>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            {data && data.data && data.data.length === 0 && <div className="row mt-1"><h4 className="fw-bold my-3 text-danger">Workbook Not Found</h4></div>}

                            {data && data.total ? <PageNavigation active_page={data.current_page - 1} per_page={data.per_page} filter_recoards={data.total} setCurrentPage={setCurrentPage} /> : ''}
                        </div>
                    </div>
                </div>
                <Exam data={workbookInfo} workbookAttemptType={workbookAttemptType} assignment={assignmentInfo}></Exam>
            </section>
        </>
    )
}
export default AssignmentWorkbook