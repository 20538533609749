import { useState, useEffect } from "react";
import { COURSE_SHOP_LISTING_FILTER, fetchData } from "../../../components/Service"
import { Link } from "react-router-dom";

const Workbook = () => {
    const [courseList, setCourseList] = useState('')

    useEffect(() => {
        fetchData(COURSE_SHOP_LISTING_FILTER, 'GET', '', true, false, (res) => {
            if (res.records) {
                setCourseList(res.records)
            }
        })
    }, [])
    
    
    return(
        <section className="home-section4  py-5">
            <div className="container container-1040">
                <div className="row align-items-center g-4">
                    <div className="col-md-6">
                        <div className="tab-content position-relative">
                            <div className="ratio ratio-1x1 position-absolute rounded-circle bg-blue" style={{ top: '40%', left: '20%', maxWidth: '45%' }}></div>
                            <div className="" role="tabpanel" tabIndex="0">
                                <div className="ratio ratio-1x1">
                                    <div className="row mx-0 d-flex flex-nowrap align-items-center justify-content-center gx-3">
                                        <div className="col">
                                            <div className="ratio ratio-1x1 card-workbook ms-auto">
                                                <img src="images/home_workbook1.png" alt="poster" />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="ratio ratio-1x1 card-workbook">
                                                <img src="images/home_workbook2.png" alt="poster" />
                                            </div>
                                            <div className="ratio ratio-1x1 card-workbook mt-3">
                                                <img src="images/home_workbook3.png" alt="poster" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-start justify-content-center">
                            <svg className="icon mmwh-35 sm-mmwh-28 text-blue">
                                <use href="#icon_subtract"></use>
                            </svg>
                            <div className="px-3">
                                <h2 className="fw-bold mb-3 f-28_size">Workbooks for your studies </h2>
                                <p className="h4 fw-normal mb-4">
                                    Discover workbooks by course and syllabus learning outcomes to fill your knowledge gaps.
                                </p>
                                <div className="mb-3">
                                {courseList && courseList.length > 0 && courseList.map((ele, index) => {
                                    return (
                                        index < 5 && <Link to="/shop-list" state={{item: ele, currencyData: {currency_price: 1.00, currency_type: "AUD", id: 1}}} key={index} title={ele.course_abbr} className="btn btn-outline-default btn-lg minw-75 m-1" role="button">{ele.course_abbr}</Link>
                                    )
                                })}
                                {courseList && courseList.length > 4 && <Link to="/shop" title="View more ..." className="text-blue fw-bold minw-75 my-3 m-2 text-decoration-none d-block" role="button">View more ...</Link>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Workbook