import { memo } from "react"
import { noImageHandle } from "../../../components/CommonMethod"

const ExamQuestionList = (props) => {

    const navigateQuestion = (index) => {
        if(props.mode !== 'exam'){
            props.setActiveQuestion(index + 1)
        }
    }

    return (
        <div className={`exammode border border-2 p-4 ${props.mode !== 'review' && 'mt-32'} pb-0 rounded-4 border-blue-hover`} style={{ position: 'relative' }}>
            <span className="text-muted fw-bold fs-24 exam-title">Questions</span>
            <div className="p-0">
                <div className="ms-3 mb-2" style={{ overflow: 'auto', height: '215px' }}>
                    {props && props.questions && props.questions.map((item, key) => {
                        let check_answer = props.prevQuestionDetails.filter(q => q.order === (key + 1))

                        return (
                            <p key={key} onClick={() => navigateQuestion(key)}>
                                {
                                    props.mode === 'exam' ? 
                                        <span className={`${key+1 === props.activeQuestion ? 'fw-bold' : key+1 < props.activeQuestion ? 'opacity-50' : ''}`} >Question {item.order}  {key+1 < props.activeQuestion && <img className="filer-grayscale ms-2" src="/images/check.png" alt="check" onError={noImageHandle} />}</span>
                                    :   check_answer && check_answer[0] ?
                                        <>
                                        {check_answer[0].correct ? 
                                            <span role="button" className={`text-green ${(key+1 === props.activeQuestion) ? 'fw-bold' : 'fw-normal'}`}> Question {item.order} <img className="ms-2" src="/images/check.png" alt="check" onError={noImageHandle} /></span>
                                        :   <span role="button" className={`text-danger ${(key+1 === props.activeQuestion) ? 'fw-bold' : 'fw-normal'}`}> Question {item.order} <img className="ms-2" src="/images/cross.png" alt="cross" onError={noImageHandle} /></span>
                                        }
                                        </>
                                        :   <span role="button" className={`${key+1 === props.activeQuestion ? 'fw-bold' : 'fw-normal'}`} > Question {item.order} </span>
                                }
                            </p>
                        )
                    })}
                    
                </div>
            </div>
        </div>
    )
}

export default memo(ExamQuestionList)
