import { memo } from "react"
import TrueAndFalse from "./QuestionType/TrueAndFalse";
import DragAndFill from "./QuestionType/DragAndFill"
import DragAndMatch from "./QuestionType/DragAndMatch";
import MultipleOption from "./QuestionType/MultipleOption";
import DragToZone from "./QuestionType/DragToZone";
import FillInBlanks from "./QuestionType/FillInBlanks";
import EnergyDiagram from "./QuestionType/EnergyDiagram";
import _ from "lodash";

const ExamQuestion = (props) => {

    return (
        <>
            {props.questions && props.questions.length > 0 && props.questions.map((ele,key) => {
                let questions_details = props.questions_details && props.questions_details.length > 0 && props.questions_details.filter(q => q.order === (key + 1))
                let answer_details = null
                if(props.questions_details && props.questions_details.length > 0 && questions_details && questions_details[0]){
                    let aa = _.findIndex(props.questions_details, questions_details[0]);
                    answer_details = props.answer_details && props.answer_details[aa]
                }

                return (
                    <div className="col-sm-12 question-box-exam" key={key}>
                        <div className="col-12 position-relative">

                            {ele.order === props.activeQuestion && ele.question_type === "true_or_false" && 
                                <TrueAndFalse 
                                    serialNo={ele.order} 
                                    question={ele} 
                                    setActiveQuestion={props.setActiveQuestion} 
                                    setPrevQuestionDetails={props.setPrevQuestionDetails} 
                                    setPrevAnswerDetails={props.setPrevAnswerDetails} 
                                    saveAnswer={props.saveAnswer} 
                                    setProgress={props.setProgress} 
                                    progressData={props.progressData} 
                                    mode={questions_details && questions_details[0] ? 'review' : props.mode} 
                                    questions_details={questions_details && questions_details[0]}
                                    answer_details={answer_details && answer_details.answerArr}
                                    progressText={questions_details && questions_details[0] && questions_details[0].progress_text}
                                /> 
                            } 

                            {ele.order === props.activeQuestion && ele.question_type === "drag_and_fill" && 
                                <DragAndFill 
                                    serialNo={ele.order} 
                                    question={ele} 
                                    setActiveQuestion={props.setActiveQuestion} 
                                    setPrevQuestionDetails={props.setPrevQuestionDetails} 
                                    setPrevAnswerDetails={props.setPrevAnswerDetails} 
                                    saveAnswer={props.saveAnswer} 
                                    setProgress={props.setProgress} 
                                    progressData={props.progressData}
                                    mode={questions_details && questions_details[0] ? 'review' : props.mode} 
                                    questions_details={questions_details && questions_details[0]}
                                    answer_details={answer_details}
                                    progressText={questions_details && questions_details[0] && questions_details[0].progress_text}
                                /> 
                            } 

                            {ele.order === props.activeQuestion && ele.question_type === "drag_and_match" && 
                                <DragAndMatch 
                                    serialNo={ele.order} 
                                    question={ele} 
                                    setActiveQuestion={props.setActiveQuestion} 
                                    setPrevQuestionDetails={props.setPrevQuestionDetails} 
                                    setPrevAnswerDetails={props.setPrevAnswerDetails} 
                                    saveAnswer={props.saveAnswer} 
                                    setProgress={props.setProgress} 
                                    progressData={props.progressData}
                                    mode={questions_details && questions_details[0] ? 'review' : props.mode} 
                                    questions_details={questions_details && questions_details[0]}
                                    answer_details={answer_details}
                                    progressText={questions_details && questions_details[0] && questions_details[0].progress_text}
                                /> 
                            } 

                            {ele.order === props.activeQuestion && ele.question_type === "single_choice" && 
                                <MultipleOption 
                                    serialNo={ele.order} 
                                    question={ele} 
                                    setActiveQuestion={props.setActiveQuestion} 
                                    setPrevQuestionDetails={props.setPrevQuestionDetails} 
                                    setPrevAnswerDetails={props.setPrevAnswerDetails} 
                                    saveAnswer={props.saveAnswer} 
                                    setProgress={props.setProgress} 
                                    progressData={props.progressData} 
                                    mode={questions_details && questions_details[0] ? 'review' : props.mode} 
                                    questions_details={questions_details && questions_details[0]}
                                    answer_details={answer_details && answer_details.answerArr && answer_details.answerArr[0]}
                                    progressText={questions_details && questions_details[0] && questions_details[0].progress_text}
                                /> 
                            } 

                            {ele.order === props.activeQuestion && ele.question_type === "drag_to_zone" && 
                                <DragToZone 
                                    serialNo={ele.order} 
                                    question={ele} 
                                    setActiveQuestion={props.setActiveQuestion} 
                                    setPrevQuestionDetails={props.setPrevQuestionDetails} 
                                    setPrevAnswerDetails={props.setPrevAnswerDetails} 
                                    saveAnswer={props.saveAnswer} 
                                    setProgress={props.setProgress} 
                                    progressData={props.progressData} 
                                    mode={questions_details && questions_details[0] ? 'review' : props.mode} 
                                    questions_details={questions_details && questions_details[0]}
                                    answer_details={answer_details}
                                    progressText={questions_details && questions_details[0] && questions_details[0].progress_text}
                                /> 
                            } 

                            {ele.order === props.activeQuestion && ele.question_type === "fill_in_blanks" && 
                                <FillInBlanks 
                                    serialNo={ele.order} 
                                    question={ele} 
                                    setActiveQuestion={props.setActiveQuestion} 
                                    setPrevQuestionDetails={props.setPrevQuestionDetails} 
                                    setPrevAnswerDetails={props.setPrevAnswerDetails} 
                                    saveAnswer={props.saveAnswer} 
                                    setProgress={props.setProgress} 
                                    progressData={props.progressData} 
                                    mode={questions_details && questions_details[0] ? 'review' : props.mode} 
                                    questions_details={questions_details && questions_details[0]}
                                    answer_details={answer_details}
                                    progressText={questions_details && questions_details[0] && questions_details[0].progress_text}
                                /> 
                            } 

                            {ele.order === props.activeQuestion && ele.question_type === "energy_diagram" && 
                                <EnergyDiagram 
                                    serialNo={ele.order} 
                                    question={ele} 
                                    setActiveQuestion={props.setActiveQuestion} 
                                    setPrevQuestionDetails={props.setPrevQuestionDetails} 
                                    setPrevAnswerDetails={props.setPrevAnswerDetails} 
                                    saveAnswer={props.saveAnswer} 
                                    setProgress={props.setProgress} 
                                    progressData={props.progressData} 
                                    mode={questions_details && questions_details[0] ? 'review' : props.mode} 
                                    questions_details={questions_details && questions_details[0]}
                                    answer_details={answer_details}
                                    progressText={questions_details && questions_details[0] && questions_details[0].progress_text}
                                /> 
                            } 
                        
                            {props.animation && props.animation === 'amazing_work' &&
                                <div className="overlay-bgbl-exam" id="result-bg">
                                    <div className="amazing_work  d-flex align-items-center ">
                                        <img src="/images/studnt-h.png" className="img-fluid" alt="Great Work"></img>
                                        <div className="right-text">  <h3>Amazing work! <br/> You got 3 questions  <br/>
                                        in a row!</h3></div>
                                    </div>
                                </div>
                             }
                    </div>
                </div>
                ) 
            })}
        </>
    )
}

export default memo(ExamQuestion)
