import { useEffect, useState } from "react";
import { toastNotify } from "../components/CommonMethod";
import { fetchData } from '../components/Service';
import ReactPaginate from 'react-paginate';

export const PageNavigation = (props) => {
    const paginateFilter = (no, setDt) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if(setDt){
            setDt(prevState => ({
                ...prevState,
                start: no.selected >= 0 ? (props.per_page * no.selected) : 0
            }))
        }else{
            props.setCurrentPage(no.selected +1);
        }
        
    }

    return(
        <>
            <div className={`col w-100 d-flex justify-content-end mt-3 p-padding-responsive`}>
                <nav aria-label="navigation">
                    <ReactPaginate
                        pageCount={Math.ceil(props.filter_recoards / props.per_page)}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={2}
                        forcePage={props.active_page ?? 1}
                        previousLabel="Previous"
                        nextLabel="Next"
                        containerClassName={`pagination pagination-sm justify-content-end `}
                        pageClassName="page-item"
                        nextClassName="next page-item"
                        previousClassName="previous page-item"
                        activeClassName="active"
                        disabledClassName="disabled"
                        breakLinkClassName="page-link text-center"
                        pageLinkClassName="page-link text-center"
                        previousLinkClassName="page-link text-center"
                        nextLinkClassName="page-link text-center"
                        onPageChange={(no) => paginateFilter(no, props.setDt ?? false)}
                        disableInitialCallback={true}
                        renderOnZeroPageCount={null}
                    />
                </nav>
            </div>
        </> 
    );
}

export const initDataTable = (dt, signal, callback) => {
    var QUERY = '';
    let sort = dt.sorting ?? (dt.orderby ? `order[0][column]=${dt.orderby[0]}&order[0][dir]=${dt.orderby[1]}` : `order[0][column]=${0}&order[0][dir]=desc`);
    QUERY = `?start=${dt.start ?? 0}&length=${dt.length ?? 10}&search[value]=${dt.search ?? ''}&${sort}&filter=${dt.filter ?? ''}&${dt.query_string}&`
    dt.columns && dt.columns.map((item, key) => {
        return (
            QUERY +=`columns[${key}][data]=${item.data}&columns[${key}][name]=${item.name}&columns[${key}][searchable]=${item.searching !== false && item.visible !== false ? true : false}&columns[${key}][orderable]=${item.sorting !== false && item.visible !== false ? true : false}&`
        )
    })

    fetchData(dt.fetchurl + QUERY, 'GET', '', true, false, (res) => {
        if (res) {
            callback(res)
        } else {
            toastNotify('danger', res.message)
        }
    }, signal);
}

export const DataTable = (props) => {
    const [dt, setDt] = useState(props.dt)
    const [columns] = useState(props.dt.columns);
    const [paging] = useState(props.paging ?? true)
    const [searching] = useState(props.searching ?? true)
    const [pagination] = useState(props.pagination ?? true)
    const [records, setRecords] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [perPage, setPerPage] = useState(props.perPage ?? 10);
    const [filterRecoards, setFilterRecoards] = useState(0);
    const [loading, setLoading] = useState(true);
    const pagelength = [5, 10, 25, 50, 100];

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        if(props.execute){
            setLoading(true)
            initDataTable(dt, signal, (result) => {
                setLoading(false)
                if(result.input){
                    setActivePage(Math.floor(parseInt(result.input.start) / parseInt(result.input.length)))
                    setPerPage(parseInt(result.input.length))
                }
                setRecords(result.data ?? [])
                setFilterRecoards(result.recordsFiltered ?? 0)
            });
        }
        return () => controller.abort()
    }, [dt, props.execute])

    const filter = (e, type) => {
        let value = type === 'length' ? e.target.value : e.target.value.replace(/\s\s+/g, ' ').trimLeft()
        setDt(prevState => ({
            ...prevState,
            [type]: value,
            start: 0
        }))
    }

    const sortingFilter = (id, index) => {
        let ele = document.querySelector(`#${id}`)
        let set_orderby = ele.classList.contains('sort-asc') ? 'desc' : 'asc';
        ele.classList.toggle('sort-asc')
        ele.classList.toggle('sort-des')
        document.querySelectorAll(`#${dt.id}_dt_head tr th span`).forEach((e) => e.classList.remove('active'));
        ele.classList.add('active');
        
        setDt(prevState => ({
            ...prevState,
            sorting: `order[0][column]=${index}&order[0][dir]=${set_orderby}`
        }))
    }

    return (
        <>
            <div className="col-sm-12" id={dt.id ?? 'outer_table'}>
                <div className="row">
                    {searching && <div className="col-md-auto col-7 mb-2 mb-md-0 pe-md-0">
                        <input type="search" className="form-control form-search minw-280" spellCheck="false" placeholder="Search" onInput={(e) => filter(e, 'search')} />
                    </div>}
                    <div className="col ms-auto d-flex">
                        {paging && <div className="col-md-auto col-4 mb-2 mb-md-0 pe-md-0">
                            <select value={perPage} className="form-control form-select width_selecto" onChange={(e) => filter(e, 'length')}>{pagelength && pagelength.map((length, num) => <option key={num} title={length}>{length}</option>)}</select>
                        </div>
                        }
                        <div className="w-100 d-inline-flex text-end">
                            <div className="ps-2">{props.outerbutton && props.outerbutton(records, setDt)}</div>
                        </div>
                    </div>
                </div>
                <div className="table-spinner active">
                    <div className="table-responsive">
                        <table className="table align-middle table-common" id={dt.id ? `${dt.id}_table`: 'dt_table'}>
                            <thead id={`${dt.id}_dt_head`}>
                                <tr>
                                    {columns && columns.map((item, key) => {
                                        return (
                                            item.sorting !== false ? 
                                            <th key={key} width={item.width ?? '100'} className={`text-nowrap ${item.class ?? ''} ${item.visible === false ? 'd-none' : ''}`}  onClick={() => sortingFilter(`${dt.id}_dt_column_${item.data}`, key)}>
                                                <span id={`${dt.id}_dt_column_${item.data}`} className={`text-uppercase ${item.order ?? "sort-asc"}`}>{item.title}</span>
                                            </th> :
                                            <th key={key} width={item.width ?? '100'} className={`text-nowrap ${item.class ?? ''} ${item.visible === false ? 'd-none' : ''}`}>
                                                <span  id={`${dt.id}_dt_column_${item.data}`} className="text-uppercase">{item.title}</span>
                                            </th> 
                                        )
                                    })}
                                </tr>
                            </thead>

                            <tbody id={`${dt.id}_dt_body`}>
                            {loading ? <tr role="row"><td colSpan={columns.length}><div className="d-flex align-items-center justify-content-center text-success">Loading...</div></td></tr> : <>
                                {filterRecoards ? records.map((ele, index) => {
                                    return (
                                        <tr role="row" key={index}>
                                            {columns && columns.map((item, key) => <td key={key} className={`${item.visible === false ? 'd-none' : ''} ${item.data === 'workbook_count' ? 'text-center' : ''}` }>{ props.edit_column && props.edit_column[item.data] ?  props.edit_column[item.data](ele) : item && ele[item.data]}</td>)}
                                        </tr>
                                    )
                                }) : 
                                <tr role="row">
                                    <td colSpan={columns.length}>
                                        <div className={`d-flex align-items-center justify-content-center ${filterRecoards === 0 ? 'text-danger' : 'text-success'}`}>
                                            {filterRecoards === 0 ? 'No Record Found' : 'Loading ...'}
                                        </div>
                                    </td>
                                </tr>}
                            </>}
                            </tbody>
                        </table>
                    </div>
                    {pagination && filterRecoards ? <PageNavigation setDt={setDt} active_page={activePage} per_page={perPage} filter_recoards={filterRecoards}  /> : ''}
                </div>
            </div>
        </>
    )
}
