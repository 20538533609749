import { Link } from "react-router-dom";
import { useState } from "react";
import { __echoText, ButtonSpinner } from "../../components/CommonMethod";
import { fetchData, WORKBOOK_LIST, WORKBOOK_DELETE, DUPLICATE_WORKBOOK } from "../../components/Service";
import { now } from 'lodash'
import { format } from 'date-fns';
import { DataTable } from "../../components/DataTable";

const ManageWorkbooks = () => {
    window.document.title = "Manage Workbooks";

    const [refresh, setRefresh] = useState(now);
    const [workbookData, setWorkbookData] = useState([])
    const [tinyloader, setTinyloader] = useState(false)

    const workbooks = {
        id: 'workbooks',
        fetchurl: WORKBOOK_LIST,
        orderby: [2, 'asc'],
        columns: [
            { data: 'id', name: 'id', title: '', width: 20, sorting: false, searching: false },
            { data: 'title', name: 'title', title: 'WORKBOOK TITLE' },
            { data: 'serial_no', name: 'serial_no', title: 'SERIAL NO', searching: false },
            { data: 'total_questions', name: 'total_questions', title: 'QUESTIONS', searching: false },
            { data: 'time_limit', name: 'time_limit', title: 'TIME LIMIT' },
            { data: 'expired_on', name: 'expired_on', title: 'EXPIRED IN' },
            { data: 'created_at', name: 'created_at', title: 'DATE CREATED' },
            { data: 'updated_at', name: 'updated_at', title: 'LAST EDITED' },
            { data: 'action', name: 'action', title: 'ACTION', sorting: false, searching: false },
        ]
    }

    const handleCheckboxChange = (e) => {
        e.target.checked ? setWorkbookData([...workbookData, e.target.value]) : setWorkbookData(workbookData.filter(i => i !== e.target.value));
    }

    const workbookAction = (action) => {
        setTinyloader(true)
        fetchData(action === 'delete' ? WORKBOOK_DELETE : DUPLICATE_WORKBOOK, 'POST', { id: workbookData }, true, false, (res) => {
            setTinyloader(false)
            if (res.success) {
                document.querySelector((action === 'delete' ? '#deletelisting_modal' : '#duplicate_modal') + ' [data-bs-dismiss="modal"]').click()
                setWorkbookData([])
                setRefresh(now)
            }
            document.querySelectorAll('.my_checkboxes').forEach((el) => el.checked = false)
        });
    }

    return (
        <>
            <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row g-2 align-items-center">
                    <div className="col-lg-6 col-6 mb-3">
                        <h1 className="h3 text-dim mb-0">Manage Workbooks </h1>
                    </div>
                    <div className="col-lg-6 col-6 mb-3">
                        <Link to="/cms/create-workbook" role="button" className="btn btn-lg btn-green float-end text-center">+ Create new workbook</Link>
                    </div>
                </div>
                </div>
                {workbookData.length > 0 && <div className="row bg-grey2 p-2 rounded mx-0 align-items-center mb-3">
                    <div className="col-auto text-dim fs-18">
                        <strong>{workbookData.length} lists selected</strong>
                    </div>
                    <div className="col text-end">

                        <button type="button" className="btn btn-link fw-bold text-decoration-none me-3" data-bs-toggle="modal" data-bs-target="#duplicate_modal">
                            <svg className="icon ts-1p5 me-2"><use href="#icon_copy"></use></svg>
                            Duplicate selected workbooks
                        </button> 
                        
                        <button type="button" className="btn btn-link fw-bold text-decoration-none pe-0 text-danger" title="Delete selected workbooks" data-bs-toggle="modal" data-bs-target="#deletelisting_modal">
                            <svg className="icon ts-1p3 me-2"><use href="#icon_delete"></use></svg>
                            Delete selected workbooks
                        </button>
                    </div>
                </div>}
                <div className="row mt-3">
                    <DataTable
                        execute={refresh}
                        dt={workbooks}
                        edit_column={{
                            id: (records) => records.delete && <input className="form-check-input mmwh-20 m-0 my_checkboxes" type="checkbox" defaultValue={records.id} name="workbooks[]" onChange={(e) => handleCheckboxChange(e)} checked={workbookData.includes(records.id.toString())} />,
                            time_limit: (records) =>
                                __echoText(`${records.time_limit}`) + ' Minutes',
                            expired_on: (records) =>
                                __echoText(`${records.expired_on}`) + ' Years',

                            updated_at: (records) =>
                                __echoText(`${format(new Date(records.updated_at), 'MM/dd/yyyy')}`),

                            created_at: (records) =>
                                __echoText(`${format(new Date(records.created_at), 'MM/dd/yyyy')}`),

                            action: (records) => <Link to="/cms/update-workbook" state={records} className="text-black mmwh-48 d-inline-flex justify-content-center align-items-center" title="Edit" href="#"><svg className="icon ts-1p5"><use href="#icon_pencil"></use></svg></Link>
                        }}
                    />
                </div>
            </div>

            {/* Delete confirmation - Modal */}

             {/* Delete confirmation - Modal */}
             <div className="modal fade" id="deletelisting_modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content overflow-hidden border border-2 border-danger rounded-4">
                        <div className="modal-body p-4">
                            <h3 className="fw-bold text-danger">Delete These Workbook?</h3>
                            <p className="m-0 pt-2">
                                This is a destructive action and cannot be reversed.
                            </p>
                        </div>
                        <div className="modal-footer justify-content-between px-4 pt-0 pb-4 border-0">
                            <button type="button" className="btn btn-outline-default btn-lg" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-lg minw-120" onClick={(e) => workbookAction('delete')} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName="Delete" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ConfirmationModal msg="Delete These Workbook" method={() => workbookAction('delete')} /> */}

            {/*Duplicate- Modal */}
            <div className="modal fade" id="duplicate_modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content overflow-hidden border border-2 border-danger rounded-4">
                        <div className="modal-body p-4">
                            <h3 className="fw-bold text-danger">Duplicate selected workbooks?</h3>
                            <p className="m-0 pt-2">
                                Once you confirm, selected lists will be cloned.
                            </p>
                        </div>
                        <div className="modal-footer justify-content-between px-4 pt-0 pb-4 border-0">
                            <button type="button" className="btn btn-outline-default btn-lg" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-lg minw-120" onClick={(e) => workbookAction('duplicate')} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName="Duplicate" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageWorkbooks