import { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { USERS_ANALYTICS, fetchData } from "../../components/Service";
import { ButtonSpinner, downloadPDF } from "../../components/CommonMethod";
import { Popover } from "bootstrap";
import Analytics from "./Analystics";

const UsersAnalytics = () => {
    const [assignmentAnalytics, setAssignmentAnalytics] = useState([])
    const [tinyloader, setTinyloader] = useState(false);
    const location = useLocation();
    window.document.title = "Users Analytics";

    useEffect(() => {
        if (location && location.state && location.state.id) {
            fetchData(`${USERS_ANALYTICS}?user_id=${location.state.id}&assignment_id=${location.state.assignment.id}`, 'GET', '', true, false, (res) => {
                if (res.records) {
                    setAssignmentAnalytics(res.records)
                }
            })
        }
    }, [location])

    useEffect(() => {
        Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
    })
    return (
        <div className="container-fluid">
            <div className="container-fluid">
                {location.state && <>
                    <div className="page-head sticky_top_padding">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="h3 text-dim pb-4">{location.state.first_name} {location.state.last_name} ({location.state.section_name})</h1>
                            </div>
                        </div>
                        <div className="row gx-4 gy-3 mb-3">
                            <div className="col-lg-6 col-sm mb-3 col-6">
                                <div className="d-lg-flex justify-content-between">
                                    <div className="d-flex justify-content-between mb-4 mb-md-3 order-1">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                                                <li className="breadcrumb-item"><Link to="/cms/user-assignment" state={location.state}>Student assignments</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page">{location.state.assignment.title}</li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm mb-3 col-6 text-end">
                                <button className="btn btn-green" disabled={tinyloader} onClick={() => downloadPDF(`${USERS_ANALYTICS}?user_id=${location.state.id}&assignment_id=${location.state.assignment.id}&generate_pdf=yes`, 'User-analytics', setTinyloader)}>
                                    <ButtonSpinner load={tinyloader} btnName="Download PDF" />
                                </button>
                            </div>
                        </div>
                    </div>
                </>}
                <div className="row">
                    <div className="col-sm-12">
                        <div className="anaystic-table">
                            <table className="table align-middle table-bordered">
                                {assignmentAnalytics && assignmentAnalytics.assignment_data && <thead style={{verticalAlign: "inherit"}}>
                                    <tr className="bg-gradient1 text-white">
                                        {assignmentAnalytics.workbook_title && assignmentAnalytics.workbook_title.length > 0 && assignmentAnalytics.workbook_title.map((workbook, i) => {
                                            return (
                                                <th key={i}><h5>{workbook.title}</h5></th>
                                            )
                                        })}
                                    </tr>
                                </thead>}
                                <tbody>

                                    {assignmentAnalytics && assignmentAnalytics.assignment_data && assignmentAnalytics.assignment_data.workbook && assignmentAnalytics.assignment_data.workbook.length > 0 ?
                                        <tr>
                                            {assignmentAnalytics.assignment_data.workbook.map((exam_info, i) => {
                                                return <Analytics data={exam_info} key={i} />
                                            })}
                                        </tr>
                                        :
                                        <tr><td colSpan="5" className="text-center text-danger"><input type='text' className="d-none" name='workbook' required />Workbook not available in this assignment.</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsersAnalytics