import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PasswordShow, validateForm, ButtonSpinner, initialFormState } from "../../components/CommonMethod";
import { fetchData, DROPDOWN_DATA, USER_CREATE, GET_TOKEN_VALUE } from "../../components/Service";
import queryString from 'query-string'
import $ from 'jquery'


const SignUp = () => {
    window.document.title = "Sign Up"
    const navigate = useNavigate()
    const location = useLocation()
    const [tinyloader, setTinyloader] = useState(false)
    const [dropdownData, setdropdownData] = useState('')
    const [checkAgeValue, setcheckAgeValue] = useState(0)
    const [token, setToken] = useState('')

    let query = queryString.parse(location.search)

    const createStudent = (e) => {
        let formData = new FormData(document.getElementById('studentForm'));
        formData.append('age_group', checkAgeValue) 
        if (validateForm(e, 'studentForm')) {
            setTinyloader(true);

            fetchData(USER_CREATE, 'POST', formData, false, true, (res) => {
                setTinyloader(false);

                if (res.success) {
                    initialFormState('studentForm')
                    setToken('')
                    document.getElementById('group_code_div').style.display = "none"
                    document.getElementById('school_group_code').required = false;
                    document.getElementById('school_group_code').value = "";
                    navigate('/sign-up')
                }
            }, '', false, 'studentForm');
        }
    }

    const checkAge = (e) => {
        if (e.target.checked) {
            document.getElementById('guardian_details_div').style.display = "block"
            document.getElementById('guardian_first_name').required = true;
            document.getElementById('guardian_last_name').required = true;
            document.getElementById('guardian_relationship').required = true;
        } else {
            document.getElementById('guardian_details_div').style.display = "none"
            document.getElementById('guardian_first_name').required = false;
            document.getElementById('guardian_last_name').required = false;
            document.getElementById('guardian_relationship').required = false;
            document.getElementById('guardian_first_name').value = "";
            document.getElementById('guardian_last_name').value = "";
            document.getElementById('guardian_relationship').value = "";
        }
    }

    const checkGroupCode = (e) => {
        if (e.target.checked) {
            document.getElementById('group_code_div').style.display = "block"
            document.getElementById('school_group_code').required = true;
        } else {
            document.getElementById('group_code_div').style.display = "none"
            document.getElementById('school_group_code').required = false;
            document.getElementById('school_group_code').value = "";
        }
    }

    useEffect(() => {
        fetchData(DROPDOWN_DATA + '?type=all', 'GET', '', false, false, (res) => {
            if (res.records) {
                setdropdownData(res.records)
            }
        })
        if(query.token){
            fetchData(GET_TOKEN_VALUE + '?token=' +query.token, 'GET', '', false, false, (res) => {
                if (res.records) {
                    document.getElementById('select_group').checked = true 
                    setToken(res.records)
                }
            })
        }
        // For Select Box Option Size

        $('option').each(function() {
            var optionText = this.text;
            var newOption = optionText.substring(0, 36);
            $(this).text(newOption + '...');
        });

    }, [query.token])

    return (<>
        <section className="bg-white py-md-5 mb-md-5">
            <div className="container container-1040">
                <div className="row g-4 d-flex align-items-start justify-content-center">
                    <div className="col-md-7 pe-lg-5">
                        <form className="needs-validation" method="post" id="studentForm" noValidate autoComplete="off">
                            <div className="d-flex align-items-start">
                                <svg className="icon mmwh-35 sm-mmwh-28 text-blue">
                                    <use href="#icon_subtract"></use>
                                </svg>
                                <div className="px-3 maxw-420">
                                    <h2 className="fw-bold text-blue">Sign up</h2>
                                </div>
                            </div>
                            <p className="pt-1">Unlock a sample workbook when you sign up. It’s free!</p>
                            <h5 className="line-heading text-blue py-2"><span>Student details</span></h5>
                            <div className="row g-4">
                                <div className="col-sm-6 pt-2">
                                    <div className="form-floating">
                                        <input type="hidden" name="front" id="front" defaultValue="front" />
                                        <input type="hidden" name="invitation_token" id="invitation_token" defaultValue={token && token.invitation_token} />
                                        <input type="text" name="first_name" id="first_name" className="form-control form-control-md" placeholder="First name" defaultValue="" required />
                                        <div className="invalid-feedback">
                                            The first name field is required.
                                        </div>
                                        <label>First name <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-sm-6 pt-2">
                                    <div className="form-floating">
                                        <input type="text" name="last_name" id="last_name" className="form-control form-control-md" placeholder="Last name" defaultValue="" required />
                                        <div className="invalid-feedback">
                                            The last name field is required.
                                        </div>
                                        <label>Last name <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-6 pt-2">
                                    <div className="form-floating">
                                        
                                        <select className="form-select form-select-md" name="course_type" id="course_type" required> 
                                            {dropdownData && dropdownData.courses && dropdownData.courses.length > 0 ? dropdownData.courses.map((item, i) => {
                                                return (
                                                    <option value={item.id} key={i}>{item.course_abbr} ({item.name})</option>
                                                )
                                            }) :  <option value="">Course Type</option>}
                                        </select>
                                        <div className="invalid-feedback">
                                            The course type field is required.
                                        </div>
                                        <label>Course type <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-md-6  pt-2">
                                    <div className="form-floating">
                                        <select className="form-select form-select-md" name="year_level" id="year_level" required>
                                            {dropdownData && dropdownData.levels &&  dropdownData.levels.length > 0 ? dropdownData.levels.map((item, i) => {
                                                return (
                                                    <option value={item.id} key={i} >{item.name}</option>
                                                )
                                            }) :  <option value="">Year level </option> }
                                        </select>
                                        <div className="invalid-feedback">
                                            The year level field is required.
                                        </div>
                                        <label>Year level <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="py-3 mt-1">
                                <label className="d-inline-block d-flex">
                                    <input className="form-check-input" name="" id="select_group" type="checkbox" value="" onClick={(e) => checkGroupCode(e)}  disabled={token && token.group_code ? true : false}/>
                                    <div className="form-check-label fw-bold label_top_margin"><span className="ms-2">I have a school group code to sign up with</span></div>
                                </label>
                                <div className="form-floating mt-25 maxw-200" id="group_code_div" style={{ display: token && token.group_code ? 'block' : 'none' }}>
                                  {/* <input type="hidden" name="school_group_code" defaultValue={token && token.group_code}/> */}
                                  <input type="text" className="form-control form-control-md" name="school_group_code" id="school_group_code" defaultValue={token && token.group_code} readOnly={token && token.group_code ? true : false} style={{textTransform: "uppercase"}} />
                                    {/* <select className="form-select form-select-md" name="school_group_code" id="school_group_code" disabled={token && token.group_code ? true : false}> 
                                    <option value="">Select group code</option> 
                                        {dropdownData && dropdownData.groups && dropdownData.groups.map((item1, i) => {
                                            return (
                                                <option value={item1.group_code} key={i} selected={token && token.group_code === item1.group_code ? true : false}>{item1.group_code}</option>
                                            )
                                        })}
                                    </select> */}
                                    <div className="invalid-feedback" id="grp_response">
                                        The group code field is required.
                                    </div> 
                                    <label>Group code <span className="text-danger" id="required_attr">*</span> </label>
                                </div>

                            </div>
                            <h5 className="line-heading text-blue pt-4"><span>Account details</span></h5>
                            <p>
                                You’ll be able to access your workbooks later by using these details.
                            </p>
                            <div className="row g-3">
                                <div className="col-lg-6 pt-2">
                                    <div className="form-floating">
                                        <input type="email" name="email" id="email" className="form-control form-control-md" placeholder="Email address" defaultValue={token && token.email} required readOnly={token && token.group_code ? true : false} />
                                        <div className="invalid-feedback">
                                            The email field is required.
                                        </div>
                                        <label>Email address <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="col-sm-12 m-0"></div>
                                <div className="col-lg-6 pt-2">
                                    <div className="form-floating password-wrapper">
                                        <input type="password" name="password" placeholder="Password" id="password" className="form-control form-control-md" defaultValue="" required autocomplete="new-password" />
                                        <div className="invalid-feedback position-absolute">
                                            The password field is required.
                                        </div>
                                        <div><button type="button" className="btn eye-btn"  onClick={(e) => PasswordShow(e, 'password')}>
                                            <svg className="icon pe-none"><use href="#icon_eye"></use></svg>
                                        </button></div>
                                        <label>Password <span className="text-danger" id="required_attr">*</span></label>
                                    </div>
                                </div>
                                <div className="text-secondary pt-1 fs-12">Password should be a minimum of 8 characters!</div>
                            </div>
                            <div className="checkbox_agree_condition py-3 mt-1">
                                <label className="form-check form-check-custom d-inline-block">
                                    <input className="form-check-input" name="age_group" type="checkbox" defaultValue={checkAgeValue} id="age_group" onClick={(e) => checkAge(e)} onChange={(e) => setcheckAgeValue(e.target.checked ? 1 : 0)} />
                                    <div className="form-check-label fw-bold">Is the user under 18 years old?</div>
                                </label>
                                <div className="py-3" id="guardian_details_div" style={{ display: 'none' }}>
                                    <p>
                                        If you are under 18 years old , you need to get your parent or guardian to agree to your use of the website. Your parent/guardian should provide the details below and tick the box saying “I agree”.
                                    </p>
                                    <h5 className="line-heading text-blue py-2"><span>Guardian Details</span></h5>
                                    <div className="row g-4 pt-1">
                                        <div className="col-md-6 pt-1">
                                            <div className="form-floating">
                                                <input type="text" name="guardian_first_name" id="guardian_first_name" className="form-control form-control-md" placeholder="Guardian’s first name" defaultValue="" />
                                                <div className="invalid-feedback">
                                                    The guardian’s first name field is required.
                                                </div>
                                                <label>Guardian’s first name <span className="text-danger">*</span></label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pt-1">
                                            <div className="form-floating">
                                                <input type="text" name="guardian_last_name" id="guardian_last_name" className="form-control form-control-md" placeholder="Guardian’s last name" defaultValue="" />
                                                <div className="invalid-feedback">
                                                    The guardian’s last name field is required.
                                                </div>
                                                <label>Guardian’s last name <span className="text-danger">*</span></label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pt-1">
                                            <div className="form-floating">
                                            <input type="text" name="guardian_relationship" id="guardian_relationship" className="form-control form-control-md text-capitalize" defaultValue="" placeholder="Relationship with student" />
                                            <div className="invalid-feedback">
                                                    The Relationship with student field is required.
                                                </div>
                                                    <label>Relationship with student <span className="text-danger">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="checkbox_agree_condition mb-4 ">
                                <label className="form-check form-check-custom ">
                                    <input className="form-check-input privacy-check" type="checkbox" value="" required />
                                    <div className="form-check-label fw-bold privacy-check">I agree with We are all Elemental's
                                    <Link to="/terms-of-use" className="ps-1">Terms of use</Link> and <Link to="/privacy-policy"className="ps-1">Privacy policy</Link></div>
                                    <div className="invalid-feedback position-absolute">
                                        You must agree before submitting.
                                    </div>
                                </label>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button type="button" className="btn btn-theme-primary btn-lg px-3" onClick={(e) => createStudent(e)} disabled={tinyloader}>
                                    <ButtonSpinner load={tinyloader} btnName="Sign up" />
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-5 pt-4">
                        <div className="signup-unlock">
                            <div className="signup-inner">
                                <h3 className="text-green fw-bold">Unlock a workbook when you sign up for free!</h3>
                            </div>
                            <svg className="icon signup-pill">
                                <use href="#icon_pill"></use>
                            </svg>
                            <svg className="icon signup-circle">
                                <use href="#icon_circle"></use>
                            </svg>
                        </div>
                        <img src="images/study-body.png" className="img-fluid ttym-70 pe-none" alt="Male" />
                    </div>
                </div>
            </div>
        </section>
    </>)
}
export default SignUp
