import { now } from "lodash";
import { useState, useEffect, useContext } from "react";
import { Context } from "../../components/Context";
import AssignWorkbookModal from "../../components/AssignWorkbookModal";
import { ConfirmationModal, noImageHandle, toastNotify } from "../../components/CommonMethod"
import { PageNavigation } from "../../components/DataTable"
import { ASSET_ENDPOINT, fetchData, UNASSIGN_GROUP_WORKBOOK, ASSIGN_GROUP_WORKBOOK, GET_GROUP_WORKBOOKS } from "../../components/Service"

export const WorkbookSection = (props) => {
    const [context] = useContext(Context)
    const [workbookData, setWorkbookData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [refresh, setRefresh] = useState(false)
    const [assignWorkbookRefresh, setAssignWorkbookRefresh] = useState(false)
    const [deleteSections, setDeleteSections] = useState({ id: '', title: '' });

    const unAssignWorkbook = () => {
        fetchData(UNASSIGN_GROUP_WORKBOOK, 'POST', { list_id: deleteSections.id, group_code: props.group_code }, true, false, (res) => {
            if (res.success) {
                document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
                setAssignWorkbookRefresh(now)
                setCurrentPage(1)
                setRefresh(now)
            }
        });
    }

    useEffect(() => {
        fetchData(`${GET_GROUP_WORKBOOKS}/${props.group_code}?page=${currentPage}`, 'GET', '', true, false, (res) => {
            if (res.records) {
                setWorkbookData(res.records ?? [])
            } else {
                toastNotify('danger', res.error)
            }
        })
    }, [refresh, currentPage, props.group_code, context])

    return (
        <>
            {context && context.auth && (context.auth.user_type === '1' || context.auth.user_type === '3') ?
                <div className="text-md-end mb-4 mmt-md-80">
                    <button type="button" className="btn btn-green btn-lg" data-bs-toggle="modal" data-bs-target="#addWorkbooks_modal">+ Assign workbooks</button>
                </div>
                : ''}

            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xxl-2 g-4 mb-5" id="flex_resp_dels">
                {workbookData && workbookData.data && workbookData.data.length > 0 && workbookData.data.map((item, index) => {
                    return (
                        <div className="col" key={index}>
                            <div className="card border-blue-hover rounded-3" >
                                <div className="card-body p-md-4 d-lg-flex ">
                                    <div className="mmwh-160 shadow-md rounded-3 position-relative overflow-hidden me-3 me-md-4">
                                        <img src={ASSET_ENDPOINT + item.image} alt={item.title} onError={noImageHandle} style={{ width: '100%', height: '100%' }} />
                                        <small className="position-absolute start-0 top-0 bg-purple text-white fw-bold rounded-pill px-2 minw-70 py-1 ms-1 mt-1 text-center" style={{ background: `hsl(${item.accent_color}, 100%, 50%)` }}>{item.course_abbr}</small>
                                    </div>
                                    <div className="d-block mt-3 mt-sm-0 pe-sm-3 content-width_heading">
                                        <h5 className="fw-bold">{item.title}</h5> 
                                        <p className="fw-bold">Total Workbooks: {item.total_workbook}</p>
                                    </div>
                                    {context && context.auth && context.auth.user_type !== '5' && context.auth.user_type !== '4' &&
                                        <div className="ms-auto">
                                            <button type="button" data-bs-toggle="modal" data-bs-target="#deletesection_modal" onClick={() => setDeleteSections({ id: item.id, title: item.title })} className="btn btn-outline-danger border-dark mmwh-48">
                                                <svg className="icon ts-1p5"><use href="#icon_delete"></use></svg>
                                            </button>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                    )
                })}

                {workbookData && workbookData.total ? <PageNavigation active_page={workbookData.current_page - 1} per_page={workbookData.per_page} filter_recoards={workbookData.total} setCurrentPage={setCurrentPage} /> : ''}
                <ConfirmationModal msg={`Delete ${deleteSections.title}`} method={unAssignWorkbook} />
                <AssignWorkbookModal api={ASSIGN_GROUP_WORKBOOK} group_code={props.group_code} setRefresh={setRefresh} assignWorkbook={assignWorkbookRefresh} />
            </div>
            {workbookData && workbookData.data && workbookData.data.length === 0 && <h2 className="text-center text-danger">No Workbook Assigned</h2>}
        </>
    )
}
