import { useEffect, useContext, useState } from "react";
import { BillerDetails, Cart, GuestSignUp, Payment } from "./CheckoutComponent";
import CountryFilter from "../../components/CountryFilter";
import { CART_LIST, fetchData, GET_ORDER_DETAILS, GUEST_CART_LIST } from "../../components/Service";
import { Context } from "../../components/Context";
import { isGuest } from "../../components/GuestHelper";

function CheckOut() {
    window.document.title = "Checkout";

    const [showModule, setShowModule]  = useState({biller_details: true})
    const [currencyData, setCurrencyData] = useState({currency_price: 1.00, currency_type: "AUD", id: 1})
    const [totalPrice, setTotalPrice] = useState(0)
    const [cartId, setCartId] = useState([])
    const [cartData, setCartData] = useState([])
    const [orderId, setOrderId] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const [data, setData] = useState('')
    const [context] = useContext(Context)
    const guest = isGuest()

    useEffect(() => {
        let guest_user = isGuest();
        if(context && context.auth){
            fetchData(GET_ORDER_DETAILS, 'GET', '', true, true, (res) => {
                if (res.records && res.records.id) {
                    setData({
                        id: res.records.id,
                        biller_first_name: res.records.billing_first_name, 
                        biller_last_name: res.records.billing_last_name, 
                        biller_email : res.records.billing_email, 
                        country: res.records.country, 
                        state: res.records.state, 
                        address: res.records.address, 
                        suburb: res.records.area, 
                        postcode: res.records.postalcode,
                    })
                    setOrderId(res.records.id)
                }else{
                    setData({
                        biller_first_name: context.auth.age_group === 1 && context.auth.guardian_details ? context.auth.guardian_details.first_name : context.auth.first_name, 
                        biller_last_name: context.auth.age_group === 1 && context.auth.guardian_details ? context.auth.guardian_details.last_name : context.auth.last_name, 
                        biller_email : context.auth.email, 
                        country: '', 
                        state: '', 
                        address: '', 
                        suburb: '', 
                        postcode: ''
                    })
                }
            })
        }else if(guest_user){
            let biller_details = guest_user.biller_details
            setData({
                biller_first_name: biller_details.biller_first_name, 
                biller_last_name: biller_details.biller_last_name, 
                biller_email : biller_details.biller_email, 
                country: biller_details.country, 
                state: biller_details.state, 
                address: biller_details.address, 
                suburb: biller_details.suburb, 
                postcode: biller_details.postcode,
            })
        }
    }, [context])

    useEffect(() => {
        let guest_user = isGuest();
        fetchData(guest_user ? `${GUEST_CART_LIST}?ids=${guest_user.cart_items.shop_listing_id}` : CART_LIST, 'GET', '', true, false, (res) => {
            if(res.records){
                let cart_item = document.getElementById('cart-item-count')
                let cart_item_in_mobile = document.getElementById('cart-item-mobile-count')
                if(cart_item){
                    cart_item.innerText = res.records.length
                }
                if(cart_item_in_mobile){
                    cart_item_in_mobile.innerText = res.records.length
                }
                setCartData(res.records)
                let total_price = 0
                let cart_id = []
                res.records.forEach(element => {
                    total_price += parseFloat(element.tax_rate) + parseFloat(element.price)
                    cart_id.push(element.id)
                });
                setTotalPrice(total_price)
                setCartId(cart_id)
            }
        }); 
    }, [refresh])

    return (<>
       <section className="py-5">
            <div className="container container-1180">
                <div className="row">
                    <CountryFilter title="Checkout" currencyData={currencyData} setCurrencyData={setCurrencyData} />
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12 col-md-12">
                                <div className="row">
                                    <div className="col-sm-12 newpage">
                                        {data && <>
                                            <BillerDetails data={data} section={1} showModule={showModule} setShowModule={setShowModule} totalPrice={totalPrice} currencyData={currencyData} setOrderId={setOrderId} />
                                            {guest ? <GuestSignUp section={2} showModule={showModule} /> : <Payment section={2} showModule={showModule} setShowModule={setShowModule} totalPrice={totalPrice} currencyData={currencyData} cartId={cartId} orderId={orderId} />}
                                        </>}
                                    </div>
                                </div>
                            </div>
                            <Cart cartData={cartData} currencyData={currencyData} totalPrice={totalPrice} setRefresh={setRefresh} />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}
export default CheckOut
