import React, { useState } from 'react';
import { PasswordShow, validateForm, ButtonSpinner, initialFormState } from '../../components/CommonMethod';
import { fetchData, CHANGE_PASSWORD } from '../../components/Service';

const ChangePassword = () => {
    document.title = "Change Password"

    const [tinyLoader, setTinyLoader] = useState(false);

    const changePassword = (e) => {
        let data = new FormData(document.getElementById('changePassword'));

        if (validateForm(e, 'changePassword')) {
            setTinyLoader(true)

            fetchData(CHANGE_PASSWORD, 'POST', data, true, true, (res) => {
                setTinyLoader(false)
                if (res.success) {
                    initialFormState('changePassword')
                }
            })
        }
    }

    return (
        <div className="container-fluid">
            <div className="page-head sticky_top_padding">
            <div className="row g-2 align-items-center">

                <div className="col-lg-6 col-6 mb-3">
                    <h1 className="h3 text-dim mb-0">Change Password</h1>
                </div>
                <div className="col-lg-6 col-6 mb-3">
                    <button type="button" className="btn btn-theme-primary btn-lg px-3 float-end" onClick={(e) => changePassword(e)} disabled={tinyLoader}>
                        <ButtonSpinner load={tinyLoader} btnName="Update Password" />
                    </button>
                </div>

            </div></div>
            <div className="row mt-3">
                <form className="needs-validation" id="changePassword" noValidate>
                    <div className="col-sm-12 col-lg-4">
                        <div className="form-floating mt-32">
                            <input type="password" placeholder="Current Password" name="current_password" id="current_password" className="form-control form-control-md" autoComplete='false' required />
                            <div className="invalid-feedback">The current password field is required.</div>
                            <label>Current Password <strong className="text-danger">*</strong></label>
                        </div>
                        <div className="form-floating mt-32">
                            <input type="password" placeholder="New Password" className="form-control form-control-md mt-4" name="new_password" id="new_password" autoComplete='false' required />
                            <div className="invalid-feedback">The new password field is required.</div>
                            <label>New Password <strong className="text-danger">*</strong></label>
                        </div>
                        <div className="form-floating password-wrapper mt-32">
                            <input type="password" placeholder="Confirm Password" id="confirm_password" name="confirm_password" className="form-control form-control-md mt-4" autoComplete='false' required />
                            <button type="button" className="btn" onClick={(e) => PasswordShow(e, 'confirm_password')}>
                                <svg className="icon pe-none"><use href="#icon_eye"></use></svg>
                            </button>
                            <div className="invalid-feedback">The confirm password and new password must match.</div>
                            <label>Confirm Password <strong className="text-danger">*</strong></label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ChangePassword