import  React, {useEffect, useState} from "react"
import ReactDOM from 'react-dom/client';
import { Popover, Tooltip } from "bootstrap";
import QuestionSolution from "../../../../components/QuestionSolution"
import DragOption from "./DragAndDrop/DragOption";
import DragZone from "./DragAndDrop/DragZone";
import MathElement from "../../../../components/MathElements";

const DragAndFill = (props) => {
    const [question] = useState(props.question)
    const [serialNo] = useState(props.serialNo ?? 0)
    const [questionReasoning] = useState(props.question && props.question.reasoning && JSON.parse(props.question.reasoning))
    const [answerArr, setAnswerArr] = useState(props && props.answer_details && props.answer_details.answerArr ? props.answer_details.answerArr : '')
    const [correctAnswer, setCorrectAnswer] = useState()
    const [enableAnswerButton, setEnableAnswerButton] = useState(false)
    const [questionStatus, setQuestionStatus] = useState(props.questions_details ? (props.questions_details.correct ? 'correct' : 'incorrect'): 'default');
    const [progressData] = useState(props.progressData ?? {})
    const [progressText, setProgressText] = useState(props.progressText ?? '')

    const checkQuestionAttempted = () => {
        if(answerArr && correctAnswer){
            let nonEmptyAnswer = Object.values(answerArr).filter(item => item.length);
            let answerLength = Object.values(nonEmptyAnswer).length
            let questionLength = Object.values(correctAnswer).length //question && question.answers && question.answers.length

            if(answerLength === questionLength){
                setEnableAnswerButton(true)
            }else{
                setEnableAnswerButton(false)
            }
        }else{
            if(props.mode && props.mode === 'review' && questionStatus === 'default'){
                setQuestionStatus('incorrect')
            }
        }
    }

    const checkAnswer = (check = true) => {
        let is_correct = 'correct'
        
        if(check){
            question && question.answers && question.answers.map((item, i) => {
                if(answerArr[item.phrase] && (answerArr[item.phrase][0].label !== item.answer)){
                    is_correct = 'incorrect'
                }
            })

            setQuestionStatus(is_correct)
        }

        if(props.mode !== 'review'){
            let check = is_correct === 'correct' ? true : false
            let index = progressData.value === check ? progressData.index + 1 : 0
            let progress_text = check ? (progressData.correctText[index] ?? progressData.correctText[0]) : (progressData.incorrectText[index] ?? progressData.incorrectText[0])
            let question_time = localStorage.getItem('question_counter');
            let submitAnswerInfo = {question_id: question.id, question: question.question, correct: is_correct === 'correct' ? true : false, order: question.order, question_type: question.question_type, progress_text: progress_text, time: question_time}
            setProgressText(progress_text)
            props.setPrevQuestionDetails((prev) => ([...prev, submitAnswerInfo]))
            props.setPrevAnswerDetails((prev) => ([...prev, {answerArr: answerArr}]))
            props.saveAnswer(submitAnswerInfo, {answerArr: answerArr})
            // props.setProgress(question.order)
        }

        setTimeout(() => {
            Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
            Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
        }, 100);
    }

    const submitAnswer = () => {
        if(props.mode && props.mode === 'exam'){
            checkAnswer(true)
        }
        props.setActiveQuestion((prev) => prev + 1)
    }

    const correctAnswerData = () => {        
        let correctAnswer = {};
        
        if(question && question.answers){
            for(let i = 0; i < question.answers.length; i++){
                let input = document.querySelector(`input[name="${question.answers[i].phrase}"]`)
                if(input){
                    correctAnswer[question.answers[i].phrase] = question.answers[i].answer
                }
            }
        }
        
        setCorrectAnswer(correctAnswer)
    }

    useEffect(() => {
        checkQuestionAttempted()
        // eslint-disable-next-line
    },[answerArr])

    useEffect(() => {
        correctAnswerData()

        Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
        Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(question && question.question_phrase && question.phrase){
            question && question.phrase && JSON.parse(question.phrase).forEach((item, i) => {
                let createDiv = document.createElement('span')
                createDiv.setAttribute("id", item)

                let input = document.querySelector(`input[name="${item}"]`)
                if(input){
                    input.replaceWith(createDiv)

                    let inputRoot = ReactDOM.createRoot(document.getElementById(item));

                    inputRoot.render(<DragZone id={`drag_${question.order}`} targetKey="optionDropZone" isNoWidth={true} isHalfDragButton={false} isMultiDrop={false} matchWidth={item} setAnswerArr={setAnswerArr} questionReasoning={questionReasoning} correctAnswer={correctAnswer} questionStatus={questionStatus} optionItems={props && props.answer_details && props.answer_details.answerArr[item]}/>)
                }
            })
        }
        // eslint-disable-next-line
    },[question])

    return (
        <>
            <div className={`fs-18 question border border-3 p-3 rounded-4 ${!props.tryAgain && 'mb-4'} ${questionStatus}_border_es`}>
                <h6 className={`fs-18 fw-bold pb-2 pt-2 ${questionStatus}_text_es`}>Question {serialNo}
                    <span><button type="button" className="btn border-0" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="focus hover" data-bs-template="<div class=&quot;popover shadow&quot; role=&quot;popover&quot;><div class=&quot;popover-arrow&quot;></div><div class=&quot;popover-inner pt-3 px-3 fw-medium &quot;>Click and drag a potential answer to a dropzone.To remove an answer you've dropped into a dropzone,click on it again.<div class=&quot;row &quot;><div class=&quot;col-sm-12 &quot;><div><img src='/images/ex-dragdrop.png'class='img-fluid  p-4'></img></div></div></div>"><svg className="icon ts-1p5"><use href="#icon_query"></use></svg></button></span>
                </h6>

                <span className="question_title raw-question" id="question_title"><MathElement ele_id={`question_${question.order}_div`} elements={question.question}/></span>

                <div className={`mt-4 ${questionStatus !== 'default' && 'disable-drag-option'}`}>
                    {question && question.option && JSON.parse(question.option).map((item, i) => {
                        return(
                            <DragOption d_id={`drag_${question.order}`} key={i} drag_option_id={`drag_option_${i}`} targetKey="optionDropZone" label={item} isHalfDragButton={false}/>
                        )
                    })}
                </div>

                <div className={`d-block mt-4 ${questionStatus !== 'default' && 'query-place-div'}`} id="fill_blanks_div"><MathElement ele_id={`question_${question.order}_phase`} elements={question.question_phrase}/></div>

                {questionStatus !== 'default' &&
                    <div className="d-flex mt-4" id="why-section">
                        <span className="answer-response"><img src={questionStatus === 'correct' ? '/images/checkbox.png' : '/images/crossbox.png'} alt={questionStatus} /></span>
                        {question.reasoning && question.reasoning !== 'null' && 
                            <span><button type="button" className="btn ms-0 p-0 border-white text-blue text-decoration-underline ms-2 fw-bold" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="focus hover" data-bs-template={`<div class="popover shadow maxw-900 border-blue border border-2" role="popover"><div class="popover-arrow  border-blue "></div><div class="popover-inner"><div class="row "><div class="col-sm-4"><img src="/images/why-male.png"  alt="Male"/></div><div class="col-sm-8"><p class="mb-0 pt-2 px-3 fw-medium text-blue"></p><p class="mb-0 px-3 text-blue fw-medium">${questionReasoning}</p></div></div></div></div>`}>Why?</button></span>
                        }
                    </div>
                }

                <div className="button d-flex justify-content-between mt-4">
                    { questionStatus !== 'default' && question && question.solution_image && <button className="btn btn-outline-default btn-lg minw-90 m-2 ms-0" data-bs-target="#solutionModal" data-bs-toggle="modal">Solution</button> }
                    { questionStatus !== 'default' && <span className={`fw-bold mt-3 fs-5 ${questionStatus}_text_es`}>{progressText}</span> }
                    { props.mode && props.mode === 'practice' && questionStatus === 'default' && <button type="button" className={`btn btn-lg ms-auto btn-theme-primary m-2 me-0 ${!enableAnswerButton && 'disabled'}`} onClick={() => checkAnswer()} >Check</button> }
                    { ((props.mode && props.mode === 'exam') || questionStatus !== 'default') && !props.tryAgain &&<button className={`btn btn-lg m-2 ms-auto me-0 ${props.mode === 'exam' && !enableAnswerButton && 'disabled'} ${props.mode === 'exam' && 'btn-theme-primary'} ${questionStatus === 'correct' ? 'btn-green' : (questionStatus === 'incorrect' ? 'btn-danger' : 'border-blue') }`} onClick={() => submitAnswer()}>Next</button> }
                    { questionStatus !== 'default' && props.tryAgain && <button className={`btn btn-lg m-2 ms-auto me-0 ${questionStatus === 'correct' ? 'btn-green' : (questionStatus === 'incorrect' ? 'btn-danger' : 'border-blue') }`} onClick={props.tryAgain}>Try Again</button> }
                </div>
            </div>

            <QuestionSolution image={props && props.question && props.question.solution_image ? props.question.solution_image : ''} />
        </>
    )
}
export default DragAndFill;