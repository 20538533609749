import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchData, SECTION_LIST, SECTION_CREATE, SECTION_UPDATE, SECTION_DELETE, DROPDOWN_DATA } from "../../components/Service";
import { validateForm, initialFormState, __echoText, toastNotify, ButtonSpinner, ConfirmationModal } from "../../components/CommonMethod";
import { DataTable } from "../../components/DataTable";
import { now } from 'lodash'

const Section = () => {
    window.document.title = "Section";

    const [tinyloader, setTinyloader] = useState('')
    const [topic, setTopic] = useState('')
    const [section, setSection] = useState('')
    const [deleteSections, setDeleteSections] = useState({ record_id: '', name: '' })
    const [refresh, setRefresh] = useState(now)

    const dt = {
        id: 'sectionlist',
        fetchurl: SECTION_LIST,
        columns: [
            { data: 'id', name: 'id', title: 'Id' },
            { data: 'topic_name', name: 'course_topics.topic_name', title: 'Course & Topic' },
            { data: 'section_name', name: 'section_name', title: 'Section' },
            { data: 'action', name: 'action', title: 'Action', sorting: false, searching: false },
        ]
    }

    const saveSection = (e) => {
        let formData = new FormData(document.getElementById('createSectionForm'))

        if (document.getElementById('section_value_div').childElementCount > 0) {
            setTinyloader(true)

            fetchData(SECTION_CREATE, 'POST', formData, true, true, (res) => {
                setTinyloader(false)

                if (res.success) {
                    document.getElementById('section_value_div').style.display = "none";
                    document.getElementById('section_value_div').innerHTML = "";
                    initialFormState('createSectionForm')
                    setRefresh(now)
                }
            });
        } else {
            toastNotify('danger', 'Please select a section.')
        }
    }

    const updateSection = (e) => {
        let formData = new FormData(document.getElementById('editSectionForm'))
        
        if (validateForm(e, 'editSectionForm')) {
            setTinyloader(true)

            fetchData(SECTION_UPDATE, 'POST', formData, true, true, (res) => {
                setTinyloader(false)

                if (res.success) {
                    initialFormState('editSectionForm')
                    document.querySelector('#editSectionModal [data-bs-dismiss="modal"]').click()
                    setRefresh(now)
                }
            });
        }
    }

    const addSection = (e) => {
        if (validateForm(e, 'addSectionForm')) {
            let section_value = document.getElementById('section_value').value;
            var select = document.getElementById('topic_type');
            var topic_name = select.options[select.selectedIndex].getAttribute('data-edit')

            let section_value_div = document.getElementById('section_value_div');
            section_value_div.style.display = "block";
            section_value_div.insertAdjacentHTML('afterbegin',
                `<div class="d-flex justify-content-between align-items-center fw-bold py-1 bg-light rounded">
                    <input type="hidden" name="topic_id[]" value=${select.value}>
                    <input type="hidden" name="section_name[]" value="${section_value}" />
                    <span class="form-control fw-bold border-0 ms-1">${topic_name}</span>
                    <span class="ms-1 form-control fw-bold border-0">${section_value}</span>
                    <button class="btn btn-sm mx-1 remove" title="Delete">
                        <svg class="icon ts-1p4"><use href="#icon_delete"></use></svg>
                    </button>
                </div>`);

            document.getElementById('addSectionForm').classList.remove('was-validated')
            document.getElementById("section_value").value = ''
            document.querySelectorAll(".remove").forEach(function (el) {
                el.addEventListener('click', (e) => {
                    el.parentElement.remove();

                    if(section_value_div.childElementCount === 0){
                        section_value_div.style.display = "none";
                    }
                })
            });
        }
    }

    const deleteRecords = (id) => {
        fetchData(SECTION_DELETE + '?id=' + id, 'GET', '', true, false, (res) => {
            document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
            if (res.success) {
                setRefresh(now)
            }
        });
    }

    useEffect(() => {
        fetchData(DROPDOWN_DATA + '?type=topic', 'GET', '', true, false, (res) => {
            if (res.records) {
                setTopic(res.records.topic)
            }
        })
    }, [])

    return (<>
        <div className="container-fluid">
        <div className="page-head sticky_top_padding">
          <div className="row">
                <div className="col-sm-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                            <li className="breadcrumb-item"><Link to="/cms/manage-courses">Manage courses</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Section</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="row gx-4 mb-1 align-items-center">
                <div className="col-lg-6 col-sm mb-3 col-6">
                    <h1 className="h3 text-dim mb-0">Section</h1>
                </div>
                <div className="col-lg-6 mb-3 col-6">
                    <button type="button" className="btn btn-theme-primary btn-lg px-3 float-end" onClick={(e) => saveSection(e)}>
                        <ButtonSpinner load={tinyloader} btnName="Save" />
                    </button>
                </div>
            </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="maxw-620">
                        <form className="needs-validation" id="addSectionForm" noValidate>
                            <div className="w-100" id="section_div">
                                <h6 className="text-uppercase mb-4 fw-bolder">Create <span className="text-primary">Section</span> Categories</h6>
                                <div className="d-flex mb-3 mt-30">
                                    <div className="form-floating w-100">
                                        <select className="form-select form-select-md" id="topic_type" required>
                                            {topic && topic.length > 0 ? topic.map((item, i) => {
                                                return (
                                                    <option value={item.id} data-edit={item.topic_name} key={i}>{`${item.course_abbr} >> ${item.topic_name}`}</option>
                                                )
                                            }) : <option value="">Select Topic Type</option>}
                                        </select>
                                        <div className="invalid-feedback">
                                            The topic type field is required .
                                        </div>
                                        <label>Topic <strong className="text-danger">*</strong></label>
                                    </div>
                                    <div className="form-floating w-100">
                                        <input type="text" id="section_value" className="form-control form-control-md ms-2" placeholder="Section" autoComplete="off" required/>
                                        <div className="invalid-feedback ms-2">
                                            The section field is required .
                                        </div>
                                        <label>Section <strong className="text-danger">*</strong></label>
                                    </div>
                                    <div className="form-floating w-100">
                                        <button type="button" className="btn btn-outline-default minw-90 ms-3" onClick={(e) => addSection(e)}>Add</button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form className="needs-validation" id="createSectionForm" noValidate>
                            <div className="card border-dark rounded-3 px-3 py-2 mb-3" id="section_value_div" style={{display: 'none'}}></div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <DataTable
                        execute={refresh}
                        dt={dt}
                        edit_column={{
                            topic_name: (records) =>
                                __echoText(`${records.course_abbr} >> ${records.topic_name}`),
                            action: (records) =>
                                <div className="d-flex">
                                    <button className="btn btn-sm text-dark btn-lg border border-1 border-dark me-2" title="Edit" data-bs-toggle="modal" data-bs-target="#editSectionModal" onClick={() => { initialFormState('editSectionForm', setSection); setSection(records) } }>
                                        <svg className="icon fs-5" role="img"><use href="#icon_pencil" /></svg>
                                    </button>
                                    <button className="btn btn-sm text-danger btn-lg border border-1 border-danger" title="Delete" data-bs-toggle="modal" data-bs-target="#deletesection_modal" onClick={ () => setDeleteSections({record_id: records.id, name: records.section_name }) }>
                                        <svg className="icon fs-5" role="img"><use href="#icon_delete" /></svg>
                                    </button>
                                </div>
                        }}
                    />
                </div>
            </div>
        </div>
        { /** Edit Section Modal */}
        <div className="modal fade" id="editSectionModal" tabIndex="-1" data-bs-backdrop="static" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content overflow-hidden border border-2 border-primary rounded-4">
                    <div className="modal-header p-3">
                        <h4 className="text-primary fw-bold mb-0">Edit section</h4>
                    </div>
                    <div className="modal-body">
                        <form id="editSectionForm" className="needs-validation" noValidate>
                            <input type="hidden" defaultValue={section && section.course_topic_id} name="topic_id" />
                            <input type="hidden" defaultValue={section && section.id} name="id" />
                            <div className="form-floating mt-3 mb-4">
                                <input type="text" defaultValue={section.topic_name} className="form-control form-control-md" placeholder="Topic" disabled/>
                                <label>Topic</label>
                            </div>
                            <div className="form-floating ">
                                <input type="text" id="section_name" name="section_name" defaultValue={section && section.section_name} className="form-control form-control-md" placeholder="Topic" />
                                <label>Section <strong className="text-danger">*</strong></label>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-default" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-theme-primary" onClick={(e) => updateSection(e)}>
                            <ButtonSpinner load={tinyloader} btnName="Save Changes" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        {/** Delete Section Modal */}
        <ConfirmationModal msg={`Delete ${deleteSections.name} ?`} method={() => deleteRecords(deleteSections.record_id)} />
    </>)
}

export default Section;
