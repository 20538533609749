import { format } from "date-fns";
import { useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { __echoText } from "../../components/CommonMethod";
import { Context } from "../../components/Context";
import { DataTable } from "../../components/DataTable";
import { GROUP_SECTION_LIST } from "../../components/Service";

const GroupSection = () => {
    window.document.title = "Group Sections";

    const [context] = useContext(Context)
    const [refreshGroupSection] = useState(true);

    let group_id = context && context.auth && context.auth.group_details && context.auth.group_details.group_id;

    const section = {
        id: 'group_section',
        fetchurl: GROUP_SECTION_LIST + '/' + group_id,
        columns: [
            { data: 'id', name: 'id', title: 'ID' },
            { data: 'section_name', name: 'section_name', title: 'SECTION NAME' },
            { data: 'student_count', name: 'student_count', title: 'NO OF STUDENTS' },
            { data: 'status', name: 'status', title: 'STATUS' },
            { data: 'created_at', name: 'created_at', title: 'CREATED DATE' },
            { data: 'action', name: 'action', title: 'Action', sorting: false, searching: false, visible: context && context.auth && context.auth.user_type === '4' ? false : true }
        ]
    }

    return (

        <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row g-2 align-items-center">
                    <div className="col-lg-6 col-6 mb-3">
                        <h1 className="h3 text-dim mb-0">Group Sections</h1>
                    </div>
                    <div className="col-lg-6 col-6 mb-3">
                        <Link to="/cms/create-group-section" role="button" className="btn btn-green btn-lg px-3 float-end">+ Create new section</Link>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <DataTable
                    execute={refreshGroupSection}
                    dt={section}
                    edit_column={{
                        section_name: (records) =>
                            __echoText(`${records.section_name}`),
                        status: (records) =>
                            <span className={`text-${records.status === 'active' ? 'green' : 'danger'}`}>
                                {records.status === 'active' ? 'Active' : 'Inactive'}
                            </span>,
                        created_at: (records) =>
                            __echoText(`${format(new Date(records.created_at), 'MM/dd/yyyy')}`),
                        action: (records) =>
                            <div className="d-flex">
                                <Link to="/cms/update-group-section" state={records} className="btn btn-sm text-black" title="Edit">
                                    <svg className="icon fs-5" role="img"><use href="#icon_pencil" /></svg>
                                </Link>
                            </div>
                    }}
                />
            </div>
        </div>
    )
}

export default GroupSection;