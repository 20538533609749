import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { ASSIGNMENTS_ANALYTICS_PDF, ASSIGNMENT_ANALYTICS, fetchData } from "../../components/Service";
import { PageNavigation } from "../../components/DataTable";
import { ButtonSpinner, downloadPDF } from "../../components/CommonMethod";
import { Popover } from "bootstrap";
import Analytics from "./Analystics";


const AssignmentAnalytics = () => {
    const location = useLocation();
    let assignmentInfo = location.state

    let title = assignmentInfo && assignmentInfo.title ? assignmentInfo.title : "Assignment Analytics"

    window.document.title = title;

    const [assignmentAnalytics, setAssignmentAnalytics] = useState([])
    const [tinyloader, setTinyloader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        if (location && location.state && location.state.id) {
            fetchData(`${ASSIGNMENT_ANALYTICS}?assignment_id=${location.state.id}&page=${currentPage}`, 'GET', '', true, false, (res) => {
                if (res.records) {
                    setAssignmentAnalytics(res.records)
                }
            })
        }
    }, [location, currentPage])
    useEffect(() => {
        Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
    })
    return (
        <div className="container-fluid">
            <div className="container-fluid">
                <div className="page-head sticky_top_padding">
                    <div className="row">
                        <div className="col-sm-12">
                            <h1 className="h3 text-dim pb-4">Assignment Analytics</h1>
                        </div>
                    </div>
                    <div className="row gx-4 gy-3 mb-3 mb-md-4">
                        <div className="col-lg-6 col-sm mb-3 col-6">
                            <h4 className="fw-bold m-0 text-green">{title}</h4>
                        </div>
                        <div className="col-lg-6 col-sm mb-3 col-6 text-end">
                            <button className="btn btn-green" disabled={tinyloader} onClick={() => downloadPDF(`${ASSIGNMENTS_ANALYTICS_PDF}/${location.state.id}`, 'Assignment-analytics', setTinyloader)}>
                                <ButtonSpinner load={tinyloader} btnName="Download PDF" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="anaystic-table">
                            <table className="table align-middle table-bordered">
                                {assignmentAnalytics && assignmentAnalytics.assignment_data && assignmentAnalytics.assignment_data.length > 0 && <thead style={{ verticalAlign: "inherit" }}>
                                    <tr className="bg-gradient1 text-white">
                                        <th className="maxw-200 minw-200"></th>
                                        {assignmentAnalytics.assignment_data[0].exam_data && assignmentAnalytics.assignment_data[0].exam_data.length > 0 && assignmentAnalytics.assignment_data[0].exam_data.map((workbook, i) => {
                                            return (
                                                <th className="maxw-200 minw-200" key={i}><h5>{workbook.workbook_title}</h5></th>
                                            )
                                        })}
                                    </tr>
                                </thead>}
                                <tbody>
                                    {assignmentAnalytics && assignmentAnalytics.assignment_data && assignmentAnalytics.assignment_data.length > 0 ? assignmentAnalytics.assignment_data.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className=""><h5>{item.first_name} {item.last_name}</h5><div>{item.section_name}</div></td>
                                                {item.exam_data && item.exam_data.length > 0 && item.exam_data.map((exam_info, i) => {
                                                    return <Analytics data={exam_info} key={i} />
                                                })}
                                            </tr>
                                        )
                                    })
                                        :
                                        <tr><td colSpan="5" className="text-center text-danger"><input type='text' className="d-none" name='workbook' required />Analytics not available.</td></tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        {assignmentAnalytics && assignmentAnalytics.total_records && assignmentAnalytics.total_records > 0 ? <PageNavigation active_page={currentPage - 1} per_page={5} filter_recoards={assignmentAnalytics.total_records} setCurrentPage={setCurrentPage} /> : ""}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssignmentAnalytics