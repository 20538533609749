import _ from "lodash";

const ExamTime = (props) => {
    return (
        <div className="exammode border border-2 p-4 rounded-4 border-blue-hover my-5 my-lg-0" style={{ position: 'relative' }}>
            <span className="text-muted fw-bold fs-24 exam-title">{_.startCase(props.mode)} mode</span>
            <div className="d-flex">
            <h2 className="col-6 time-counter">{props.timeCounter}</h2><span className="col-6 mb-0 fw-medium align-items-botton ms-3 pt-2">{ props.mode === 'exam' ? 'remaining' : 'elapsed' }</span>
            </div>
        </div>
    )
}

export default ExamTime