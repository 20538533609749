import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { validateForm, initialFormState, ButtonSpinner } from "../../components/CommonMethod";
import { Context } from "../../components/Context";
import { fetchData, GROUP_SECTION_CREATE, GROUP_SECTION_UPDATE, GROUP_SECTION_STATUS_CHANGE } from "../../components/Service";

const CreateGroupSection = () => {
    const location = useLocation();
    let groupSectionInfo = location.state

    let title = groupSectionInfo ? 'Update Group Section' : 'Create Group Section';
    window.document.title = title;

    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);
    const [context] = useContext(Context)

    const createGroup = (e) => {
        let formdata = new FormData(document.getElementById('addGroupSectionForm'))

        if (validateForm(e, 'addGroupSectionForm')) {
            setTinyloader(true);

            fetchData(groupSectionInfo ? GROUP_SECTION_UPDATE : GROUP_SECTION_CREATE, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);
                if (res.success) {
                    initialFormState('addGroupSectionForm')
                    navigate('../group-section')
                }
            });
        }
    }

    const updateStatus = (id) => {
        fetchData(GROUP_SECTION_STATUS_CHANGE, 'POST', { id: id }, true, false, (res) => {
            setTinyloader(false);
            if (res.success) {
                navigate('../group-section')
            }
        });
    }

    return (
        <>
            <div className="container-fluid">
                <div className="page-head sticky_top_padding">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                                    <li className="breadcrumb-item"><Link to="/cms/group-section">Manage Sections</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row gx-4 mb-1 align-items-lg-end align-items-md-end  align-items-center up-date-g">
                        <div className="col-lg-6 col-md-4 col-sm-6 col-6 mb-3">
                            <h1 className="h3 text-dim mb-0">{title}</h1>
                        </div>
                        <div className="col-lg-6 col-md-8 col-sm-6 col-6 mb-3">
                            <div className="float-end text-end">
                                {groupSectionInfo &&
                                    <button type="button" className={`px-3 btn-lg  me-lg-3 me-md-3 me-2 btn  margin_bottom_responsivet btn-${groupSectionInfo.status === 'active' ? 'danger' : 'green'}`} onClick={() => updateStatus(groupSectionInfo.id)}>
                                        {groupSectionInfo.status === 'active' ? 'Deactivate' : 'Activate'}
                                    </button>}
                                <button type="button" className="btn btn-theme-primary btn-lg  px-3 me-lg-3 me-md-3 me-2" onClick={(e) => createGroup(e)} disabled={tinyloader}>
                                    <ButtonSpinner load={tinyloader} btnName="Save and Exit" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="maxw-400 mb-5">
                            <form action="" className="needs-validation" id="addGroupSectionForm" noValidate autoComplete="off">
                                <h4 className="fw-bold mb-4">Section details</h4>
                                <input type="hidden" name="group_id" defaultValue={context && context.auth && context.auth.group_details && context.auth.group_details.group_id} />
                                <input type="hidden" name="section_id" defaultValue={groupSectionInfo && groupSectionInfo.id} />

                                <div className="form-floating">
                                    <input type="text" name="section_name" id="section_name" defaultValue={groupSectionInfo && groupSectionInfo.section_name} className="form-control form-control-md" placeholder="Section Name" required />
                                    <div className="invalid-feedback">
                                        The section name field is required.
                                    </div>
                                    <label>Section Name <strong className="text-danger">*</strong></label>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CreateGroupSection;
