import { useEffect, useState } from "react"
import { Popover, Tooltip } from "bootstrap";
import QuestionSolution from "../../../../components/QuestionSolution"
import MathElement from "../../../../components/MathElements";
import { calculateRangeAnswer } from "../../../../components/CommonMethod";

const FillInBlanks = (props) => {
    const [progressData] = useState(props.progressData ?? {})
    const [progressText, setProgressText] = useState(props.progressText ?? '')
    const [question] = useState(props.question)
    const [serialNo] = useState(props.serialNo ?? 0)
    const [saveAnswer, setSaveAnswer] = useState(false)
    const [enableAnswerButton, setEnableAnswerButton] = useState(false)
    const [questionStatus, setQuestionStatus] = useState(props.questions_details ? (props.questions_details.correct ? 'correct' : 'incorrect'): 'default');
   
    const checkCorrect = (ele) => {
        let check = question.answers.filter(op => (op.phrase === ele.name && calculateRangeAnswer(op.answer, ele.value) ));
        if(check && check.length > 0){
            if(ele.nextElementSibling && ele.nextElementSibling.classList.contains('check_on_blur')) ele.nextElementSibling.remove();
            ele.insertAdjacentHTML('afterend', `<img class="mx-1 check_on_blur image-mini" src="/images/checkbox.png" alt="check" />`)
        }else{
            if(ele.nextElementSibling && ele.nextElementSibling.classList.contains('check_on_blur')) ele.nextElementSibling.remove();
            ele.insertAdjacentHTML('afterend', `<img class="mx-1 check_on_blur image-mini" src="/images/crossbox.png" alt="cross" />`)
        }
    }

    const checkInput = (e) => {
        let attemptQuestion = 0;

        document.querySelectorAll('#question_box .form-control, #question_box .form-select').forEach(input => {
            if(input.value) attemptQuestion++ 
            setSaveAnswer ? setSaveAnswer(prev => ({ ...prev, [e.target.name] : e.target.value})) : setSaveAnswer({[e.target.name] : e.target.value}) 
        })
        if(Object.keys(question.answers).length === attemptQuestion){
            setEnableAnswerButton(true)
        }else{
            setEnableAnswerButton(false)
            
        }
    }

    const checkAnswer = (check = true) => {
        let is_correct = 'correct'
        if(check && saveAnswer){
            question.answers && question.answers.forEach(item => {
                let save_ans = saveAnswer && saveAnswer[item.phrase]
                let check_ans = item.answer
                if(!calculateRangeAnswer(check_ans, save_ans)){
                    is_correct = 'incorrect'
                }

            })

            setQuestionStatus(is_correct)
        }

        if(props.mode !== 'review'){
            let check = is_correct === 'correct' ? true : false
            let index = progressData.value === check ? progressData.index + 1 : 0
            let progress_text = check ? (progressData.correctText[index] ?? progressData.correctText[0]) : (progressData.incorrectText[index] ?? progressData.incorrectText[0])
            let question_time = localStorage.getItem('question_counter');
            let submitAnswerInfo = {question_id: question.id, question: question.question, correct: is_correct === 'correct' ? true : false, order: question.order, question_type: question.question_type, progress_text: progress_text, time: question_time}
            setProgressText(progress_text)
            props.setPrevQuestionDetails((prev) => ([...prev, submitAnswerInfo]))
            props.setPrevAnswerDetails((prev) => ([...prev, {saveAnswer: saveAnswer}]))
            props.saveAnswer(submitAnswerInfo, {saveAnswer: saveAnswer})
            // props.setProgress(question.order)
        }
        document.querySelectorAll('#question_box .form-control, #question_box .form-select').forEach(input => {
            input.setAttribute('disabled', true)
        })

        setTimeout(() => {
            Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
            Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
        }, 100);
    }

    const submitAnswer = () => {
        if(props.mode && props.mode === 'exam'){
            checkAnswer()
        }
        props.setActiveQuestion((prev) => prev + 1)
    }

    useEffect(() => {
        Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
        Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
    }, [])

    useEffect(() => {
        if(props && props.question){
            document.querySelectorAll('#question_box input').forEach(input => {
                input.classList.add('form-control', 'input-field-mini', 'maxw-25p', 'd-inline-block')
                input.addEventListener('input', checkInput)
                if(props.mode !== 'exam') input.addEventListener('blur', (e) => checkCorrect(e.target))
            })
            document.querySelectorAll('#question_box select').forEach(select => {
                select.classList.add('form-select', 'select-field-mini', 'form-select-md', 'w-auto', 'd-inline-block')
                select.addEventListener('change', checkInput)
                select.setAttribute('required', true)
                if(props.mode !== 'exam') select.addEventListener('change', (e) => checkCorrect(e.target))
            })
        }

        if(props && props.answer_details && props.answer_details.saveAnswer){
            Object.keys(props.answer_details.saveAnswer).forEach(item => {
                document.querySelectorAll(`#question_box input[name=${item}], #question_box select[name=${item}]`).forEach(ele => {
                    ele.value = props.answer_details.saveAnswer[item];
                    ele.setAttribute('disabled', true)
                    checkCorrect(ele)
                })
            })
        }else{
            if(props.mode && props.mode === 'review' && questionStatus === 'default'){
                setQuestionStatus('incorrect')
                document.querySelectorAll('#question_box .form-control, #question_box .form-select').forEach(input => {
                    input.setAttribute('disabled', true)
                })
            }
        }
        // eslint-disable-next-line
    }, [props])

    return (
        <>
            <div className={`fs-18 question border border-3 p-3 rounded-4 mb-4 ${questionStatus}_border_es`}>
                <h6 className={`fs-18 fw-bold pb-2 pt-2 ${questionStatus}_text_es`}>Question {serialNo}
                    <span><button type="button" className="btn border-0" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-template="<div class=&quot;popover shadow&quot; role=&quot;popover&quot;><div class=&quot;popover-arrow&quot;></div><div class=&quot;popover-inner pt-3 px-3 fw-medium &quot;>1. Type the correct response into the corresponding input fields.<br/><br/> 2. Click the drop down to reveal potential answers to the question. Select the one you think is correct.<div class=&quot;row mt-2 &quot;><div class=&quot;col-sm-6 &quot;><div class='d-flex gap-2'><div><img src='/images/Number in Circle.png'class='img-fluid'></img></div></div><div><img src='/images/ex-fillbox.png'class='img-fluid pt-2'></img></div></div><div class=&quot; col-sm-6 &quot;><div class='d-flex gap-2'><div><img src='/images/Number in Circle (2).png'class='img-fluid'></img></div></div><div><img src='/images/ex-dropdown.png'class='img-fluid pt-2'></img></div></div></div></div>"><svg className="icon ts-1p5 pe-none" aria-hidden="true" focusable="false"><use href="#icon_query"></use></svg></button></span>
                </h6>

                <span className="raw-question" id="question_box"><MathElement ele_id={`question_${question.order}_div`} elements={question.question}/></span>
                
                {questionStatus !== 'default' &&
                    <div className="d-flex mt-4" id="why-section">
                        <span className="answer-response"><img src={questionStatus === 'correct' ? '/images/checkbox.png' : '/images/crossbox.png'} alt={questionStatus} /></span>
                        {question.reasoning && question.reasoning !== 'null' && 
                             <span><button type="button" className="btn ms-0 p-0 border-white text-blue text-decoration-underline ms-2 fw-bold" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-template={`<div class="popover shadow maxw-800 border-blue border border-2" role="popover"><div class="popover-arrow  border-blue "></div><div class="popover-inner"><div class="row "><div class="col-sm-4"><img src="/images/why-male.png"  alt="Male"/></div><div class="col-sm-8"><p class="mb-0 pt-2 px-3 fw-medium text-blue"></p><p class="mb-0 px-3 text-blue fw-medium">${JSON.parse(question.reasoning)}</p></div></div></div></div>`}>Why?</button></span>
                        }
                    </div>
                }
                <div className="button d-flex justify-content-between mt-4">
                    { questionStatus !== 'default' && question && question.solution_image && <button className="btn btn-outline-default btn-lg minw-90 m-2 ms-0" data-bs-target="#solutionModal" data-bs-toggle="modal">Solution</button> }
                    { questionStatus !== 'default' && <span className={`fw-bold mt-3 fs-5 ${questionStatus}_text_es`}>{progressText}</span> }
                    { props.mode && props.mode === 'practice' && questionStatus === 'default' && <button type="button" className={`btn btn-lg ms-auto btn-theme-primary m-2 me-0 ${!enableAnswerButton && 'disabled'}`} onClick={() => checkAnswer()} >Check</button> }
                    { ((props.mode && props.mode === 'exam') || questionStatus !== 'default') && <button className={`btn btn-lg m-2 ms-auto me-0 ${props.mode === 'exam' && !enableAnswerButton && 'disabled'} ${props.mode === 'exam' && 'btn-theme-primary'} ${questionStatus === 'correct' ? 'btn-green' : (questionStatus === 'incorrect' ? 'btn-danger' : 'border-blue') }`} onClick={() => submitAnswer()}>Next</button> }
                </div>
            </div>

            <QuestionSolution image={props && props.question && props.question.solution_image ? props.question.solution_image : ''} />
        </>
    )
}
export default FillInBlanks