import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../components/Context";
import CountryFilter from "../../components/CountryFilter";
import { BEST_SHOP_LISTING, COURSE_SHOP_LISTING_FILTER, fetchData } from "../../components/Service"
import WorkbookSlider from "../../components/WorkbookSlider"
import { isGuest } from "../../components/GuestHelper";


const Product = () => {
    window.document.title = "Product";
    const [context] = useContext(Context)
    const guest = isGuest()
    const [courseList, setCourseList] = useState('')
    const [sliderData, setsliderData] = useState('')
    const [currencyData, setCurrencyData] = useState({ currency_price: 1.00, currency_type: "AUD", id: 1 })

    useEffect(() => {
        let url = context && context.auth && context.auth.id ? `${BEST_SHOP_LISTING}?user_id=${context.auth.id}` : BEST_SHOP_LISTING
        fetchData(url, 'GET', '', true, false, (res) => {
            if (res.records) {
                setsliderData(res.records)
            }
        })
    }, [context])

    useEffect(() => {
        fetchData(COURSE_SHOP_LISTING_FILTER, 'GET', '', true, false, (res) => {
            if (res.records) {
                setCourseList(res.records)
            }
        })
    }, [currencyData.id])


    return (<>
        <div className="container container-1180">
            <div className="row align-items-center py-3 py-sm-4">
                <CountryFilter title="Discover new workbooks" currencyData={currencyData} setCurrencyData={setCurrencyData} />
            </div>
        </div>
        <section className="py-5 bg-gradient1">
            <div className="container container-1180">
                <div className="row">
                    <div className="col-12">
                        <h4 className={`fw-bold text-white ${(guest || (context && context.auth && context.auth.individual_student)) && "pb-4"}`}>Explore by course</h4>
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                            {courseList && courseList.length > 0 && courseList.map((ele, index) => {
                                return (
                                    <div className="col" key={index}>
                                        <Link to="/shop-list" className="text-decoration-none text-dark" state={{ item: ele, currencyData: currencyData }} title={ele.course_term}>
                                            <div className="card card-shadow minh-100p">
                                                <div className="card-body">
                                                    <h2 className="fw-bold">{ele.course_abbr}</h2>
                                                    <h5 className="fw-bold">{ele.course_term}</h5>
                                                </div>
                                                <div className="card-footer py-3 bg-transparent border-0 text-end">
                                                    <img src={ele.country_code === 'IN' ? `../images/vector/flag-india.svg` : ele.country_code === 'AU' ? `../images/vector/flag-australia.svg` : ele.country_code === 'US' ? `../images/vector/flag-usa.svg` : ele.country_code === 'UK' ? `../images/vector/flag-british.svg` : `../images/vector/flag-global.svg`} className="mmwh-48 rounded-circle" alt="Flag" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="pt-5">
            <div className="container container-1180">
                <div className="row align-items-center justify-content-center g-4">
                    <div className="col-sm-6 col-md-4 text-center">
                        <img src="images/books.png" className="img-fluid" alt="Books" />
                    </div>
                    <div className="col-sm-6 col-md-8">
                        <div className="d-flex align-items-start justify-content-center">
                            <svg className="icon mmwh-35 sm-mmwh-28 text-blue">
                                <use href="#icon_subtract"></use>
                            </svg>
                            <div className="px-3">
                                <h2 className="fw-bold mb-3">Start Your Journey today!</h2>
                                <h4 className="fw-medium mb-4">Receive a free workbook when you create an account.</h4>
                                <div className="d-flex justify-content-center">
                                    {context && context.auth ?
                                        <Link to={`${context.auth.user_type === '2' ? '/my-workbooks' : '/cms'}`} className="btn btn-theme-primary btn-lg btn-manage-width w-100 mt-2">{context.auth.user_type === '2' ? 'My Workbook' : 'Dashboard'}</Link>
                                        : <Link to="/sign-up" className="btn btn-theme-primary btn-lg btn-manage-width w-100 mt-2"> Sign up</Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="container container-1180 py-4 mb-5">
            <div className="row">
                <div className="col-sm-12">
                    <h4 className="fw-bold fs-24 pb-4">{sliderData && sliderData.type === 'best-seller' ? 'Best sellers' : 'Most popular'}</h4>
                    {sliderData && sliderData.list && <WorkbookSlider data={sliderData && sliderData.list} total={sliderData && sliderData.list ? sliderData.list.length : 0} currencyData={currencyData} />}
                </div>
            </div>

        </div>
    </>)
}
export default Product;