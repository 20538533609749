import { useEffect } from "react";


const ExamCalculator = () => {
    useEffect(() => {
        if ('Desmos' in window) {
            window.Desmos.ScientificCalculator(document.getElementById('desmos_scientific_calculator')).focusFirstExpression()
        }
    }, [])

    return (
        <>
            <div className="modal fade" id="calculatorModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border border-2 border-blue p-2 rounded-4 mt-auto">
                        <div className="modal-header py-2 border-0 ">
                            <h3 className="fw-bold text-blue">Calculator</h3>
                        </div>
                        <div className="modal-body p-0">
                            <div id="desmos_scientific_calculator" style={{ width: '450px', height: '400px' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ExamCalculator;