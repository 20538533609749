import { now } from "lodash"
import { Popover, Tooltip } from "bootstrap";
import { useEffect } from "react";

export const TooltipMsg = (props) => {

    useEffect(() => {
        Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
        Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
    }, [props])

    return (
        <span>
            <button type="button" className="btn ms-2 p-0 border-0" data-bs-content="&nbsp;" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-template={`<div class='popover shadow' role='popover'><div class='popover-arrow'></div><div class='popover-inner py-2 px-3 maxw-200'>${props.message ?? ''}</div></div>`}><svg className="icon ts-1p1 rounded-circle"><use href="#icon_info_block"></use></svg></button>
        </span>
    )
}

export const FilterTopic = (props) => {

    return (
        <div className="filter-checkwrap pb-1">
            <div className="filter-checkarrow">
                <label className="form-check form-check-custom">
                    <input className="form-check-input form-check-green question_type" onClick={props.fun} name={props.name} type="checkbox" defaultValue={props.value} />
                    <div className="form-check-label fw-bold">{props.label}</div>
                </label>
            </div>
        </div>
    )
}

function WorkbookFilter(props){

    const topicFilter = (e, id) => {
        document.querySelectorAll(id).forEach((ele) => ele.checked=e.target.checked)
        props.filter(now)
    }

    return(
        <>
            <h5 className="fs-18 text-uppercase fw-bold text-blue">Module</h5>
            {props.module && props.module.length > 0 && props.module.map((topic, key) => {
                return (
                    <div className="filter-checkwrap bg-grey2 mb-1" data-active={`#collapseBox${key}`} key={key}>
                        <div className="filter-checkarrow">
                            <label className="form-check form-check-custom fw-bold">
                                <input className="form-check-input form-check-green" onClick={(e) => topicFilter(e, `#collapseBox${key} input[type='checkbox']`)}  name="topic_type[]" type="checkbox" defaultValue={topic.id} />
                                <div className="form-check-label fw-bold">{topic.topic_name}</div>
                            </label>
                            <button type="button" data-bs-toggle="collapse" data-bs-target={`#collapseBox${key}`} className="collapsed" aria-expanded={key === 0 ? true : false}>
                                <svg className="icon"><use href="#icon_arrowdown"></use></svg>
                            </button>
                        </div>
                        <div className={`collapse ${key === 0 ? 'show' : ''}`} id={`collapseBox${key}`} data-bs-parent="#asideFilterBox">
                            <h5 className="fs-18 fw-bold text-dim text-uppercase pt-2">Topic</h5>
                            {topic.section && topic.section.length > 0 && topic.section.map((section, index) => {
                            return(
                                <div key={index}>
                                    <label className="form-check form-check-custom mb-3">
                                        <input className="form-check-input form-check-green" onClick={() => props.filter(now)} name="section_type[]" type="checkbox" defaultValue={section.id} />
                                        <div className="form-check-label fw-bold">{section.section_name}</div>
                                    </label>
                                </div>
                            )})}
                        </div>
                    </div>
                )
            })}
        </>
    )

}

export default WorkbookFilter