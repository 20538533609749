import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { __echoText } from "../../components/CommonMethod";
import { ASSIGNMENT_LIST} from "../../components/Service";
import { format } from 'date-fns';
import { DataTable } from "../../components/DataTable";

const UserAssignment = () => {
    window.document.title = "Manage Assignments";
    const location = useLocation();

    const assignments = {
        id: 'assignments',
        fetchurl: `${ASSIGNMENT_LIST}/${location.state.id}`,
        orderby: [2, 'asc'],
        columns: [
            { data: 'id', name: 'id', title: '', width: 20, sorting: false, searching: false },
            { data: 'title', name: 'title', title: 'TITLE' },
            { data: 'allow_late_submission', name: 'allow_late_submission', title: 'Late Submission' },
            { data: 'section_count', name: 'section_count', title: 'NO OF SECTIONS' },
            { data: 'workbook_count', name: 'workbook_count', title: 'NO OF WORKBOOKS' },
            { data: 'deadline', name: 'deadline', title: 'DEADLINE' },
            { data: 'created_at', name: 'created_at', title: 'DATE CREATED' },
            { data: 'updated_at', name: 'updated_at', title: 'LAST EDITED' },
            { data: 'status', name: 'status', title: 'STATUS' },
            { data: 'action', name: 'action', title: 'ACTION', sorting: false, searching: false },
        ]
    }

    const statusFilter = (e, setDt) => {
        setDt(prevState => ({
            ...prevState,
            filter: e.target.value
        }))
    }

    return (
        <>
            <div className="container-fluid">
                <div className="page-head sticky_top_padding">
                    <div className="row g-2 align-items-center">
                        <div className="col-lg-6 col-6 mb-3">
                            <h1 className="h3 text-dim mb-0">{location.state.first_name} {location.state.last_name} {location.state.section_name && `(${location.state.section_name})`}</h1>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <DataTable
                        execute={true}
                        dt={assignments}
                        outerbutton={(records, setDt) => <select className="form-control form-select width_selecto border" onChange={(e) => statusFilter(e, setDt)}>
                            <option value="">All</option>
                            <option value="draft">Draft</option>
                            <option value="published">Published</option>
                            <option value="expired">Expired</option>
                        </select>}
                        edit_column={{
                            id: (records) => <span></span>,

                            deadline: (records) =>
                                __echoText(`${format(new Date(records.deadline), 'MM/dd/yyyy hh:mm aa')}`),

                            allow_late_submission: (records) =>
                                <span className={`text-${records.allow_late_submission === 'yes' ? 'green' : 'danger'}`}>
                                    {records.allow_late_submission === 'yes' ? 'Yes' : 'No'}
                                </span>,

                            updated_at: (records) =>
                                __echoText(`${format(new Date(records.updated_at), 'MM/dd/yyyy')}`),

                            created_at: (records) =>
                                __echoText(`${format(new Date(records.created_at), 'MM/dd/yyyy')}`),

                            status: (records) =>
                                <>
                                    {records.status === 'draft' && <span className="text-warning">Draft</span>}
                                    {records.status === 'published' && <span className="text-success">Published</span>}
                                    {records.status === 'expired' && <span className="text-danger">Expired</span>}
                                </>,

                            action: (records) =>
                                <>
                                    {records.workbook_count > 0 && <Link to="/cms/users-analytics" state={{...location.state, assignment: records}} className="text-black mmwh-48 d-inline-flex justify-content-center align-items-center" title="Analytics" href="#"><svg className="icon ts-1p5"><use href="#analytic_icon"></use></svg></Link>}
                                </>
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default UserAssignment