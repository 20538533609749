import React, { useEffect, useContext, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import Login from "./Login";
import { Context } from '../components/Context'
import { fetchData, LOGOUT, ASSET_ENDPOINT, UPDATE_GUARDAIAN_DETAILS } from '../components/Service'
import { getCartItem, initialFormState, validateForm, ButtonSpinner, showModel } from "./CommonMethod";
import { isGuest, loginGuest, updateCartValue } from "./GuestHelper";

const Header = () => {
    const { pathname } = useLocation()
    const [context, setContext] = useContext(Context)
    const [tinyloader, setTinyloader] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const guest = isGuest()

    const handleLogout = () => {
        fetchData(LOGOUT, 'GET', '', true, true, (res) => {
            if (res.success) {
                localStorage.removeItem("waae-token");
                localStorage.removeItem('waae-admin-token')
                loginGuest()
                setContext('')
                navigate(`/`);
            }
        });
    }

    const SaveGuardianDetails = (e) => {
        let formdata = new FormData(document.getElementById('guardianForm'));
        if (validateForm(e, 'guardianForm')) {
            setTinyloader(true);
            fetchData(UPDATE_GUARDAIAN_DETAILS, 'POST', formdata, true, true, (res) => {
                setTinyloader(false);
                if (res.success) {
                    initialFormState('guardianForm')
                    document.querySelector('#guardianModalForm .btn-close').click();
                    navigate(`/`);
                }
            });
        }
    }
    useEffect(() => {
        let guest = isGuest()
        document.querySelectorAll("#navbarCollapse ul li a").forEach(e => {
            e.addEventListener("click", () => {
                let is_collapse = window.matchMedia('(max-width: 990px)')
                let collapseBtn = document.querySelector("#collapseBtn");
                if (collapseBtn && is_collapse.matches) collapseBtn.click();
            })
        });

        if (context && context.auth && context.auth.age_group === 1 && context.auth.guardian_details === '') {
            showModel('guardianModalForm')
        } else {
            document.querySelector('#guardianModalForm .btn-close').click();
        }

        if (context && context.auth && context.auth.user_type === '2') {
            getCartItem((res) => { })
        }
        if(guest){
            updateCartValue(guest.cart_items.count)
        }
    }, [context]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.body.classList.remove('body-overflowhidden');
    }, [pathname, context]);

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-front bg-white position-sticky top-0 zindex-100" >
                <div className="container container-1180">
                    <Link to="/" className="navbar-brand">
                        <img src="/images/logo.svg" alt="Logo" />
                    </Link>
                    {context && context.auth && context.auth.user_type === '2' && !context.auth.group_details &&
                        <NavLink to="/checkout" className="border-0 btn p-2 ms-auto me-3 lh-1 d-block d-lg-none position-relative" title="Cart">
                            <span className="bg-danger px-1 text-white rounded-4 position-absolute top-0 end-0" id="cart-item-mobile-count">0</span>
                            <svg className="icon fs-3 text-black">
                                <use href="#icon_cart"></use>
                            </svg>
                        </NavLink>
                    }
                    <button id="collapseBtn" className="navbar-toggler d-flex d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ms-auto my-4 my-lg-0">
                            <li className="nav-item">
                                <NavLink to="/shop" className="nav-link" title="Shop">Shop</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/about-us" className="nav-link" title="About Us">About Us</NavLink>
                            </li>
                            {context && context.auth && context.auth.user_type === '2' &&
                                <>
                                    <li className="nav-item">
                                        <NavLink to="/my-workbooks" className="nav-link" title="My workbooks">My workbooks</NavLink>
                                    </li>
                                    {context.auth.group_details && context.auth.group_details.group_name &&
                                        <li className="nav-item">
                                            <NavLink to="/my-assignments" className="nav-link position-relative" title="My assignments">
                                                My assignments
                                                {/* <div class="position-absolute top-1 start-100 translate-middle p-2 spinner-grow spinner-grow-sm" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div> */}
                                            </NavLink>
                                        </li>
                                    }
                                </>
                            }
                            {context && context.auth ?
                                <>
                                    <li className="nav-item dropdown dropdown-user">
                                        <span className="nav-link dropdown-toggle p-0 mmwh-44" id="userDropdown" role="button" data-bs-toggle="dropdown">
                                            {context && context.auth && context.auth.user_image ?
                                                <img src={ASSET_ENDPOINT + context.auth.user_image} className="rounded-circle ratio w-100 h-100" alt="User Logo" title="My Account" style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "cover" }} />
                                                : context.auth.group_details && context.auth.group_details.group_name && context.auth.user_type === "5" ? context.auth.group_details.group_name.charAt(0) : context.auth.first_name.charAt(0)}
                                        </span>
                                        <ul className="dropdown-menu dropdown-menu-end shadow border-0 rounded-3 account-ul" aria-labelledby="userDropdown">
                                            <li>
                                                <Link to={`${((context && context.auth && context.auth.user_type === '1') || context.auth.user_type === '3' || context.auth.user_type === '4' || context.auth.user_type === '5') ? '/cms/profile' : '/my-account'} `} className="dropdown-item">My Account</Link>
                                            </li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><Link to="#" className="dropdown-item" onClick={() => handleLogout()}>Log out</Link></li>
                                        </ul>
                                    </li>
                                </>
                                :
                                <li className="nav-item">
                                    <Link to="#loginModal" data-bs-toggle="modal" className="btn btn-outline-default minw-90 me-3" title="Log in">Log in</Link>
                                    <Link to="/sign-up" className="btn btn-theme-primary minw-90" title="Sign up">Sign up</Link>
                                </li>
                            }
                            {((context && context.auth && context.auth.user_type === '2' && !context.auth.group_details) || guest) &&
                                <li className="nav-item cart-nav mt-1">
                                    <NavLink to="/checkout" className="nav-link d-none d-lg-block cart-nav-link position-relative" title="Cart">
                                        <span className="bg-danger text-white rounded-4" id="cart-item-count">0</span>
                                        <svg className="icon fs-5 text-black ms-lg-2">
                                            <use href="#icon_cart"></use>
                                        </svg>
                                    </NavLink>
                                </li>}

                        </ul>
                    </div>
                </div>
            </nav>

            {!location.pathname.match('my-account') && <Login />}

            {/* Guardian Modal Form */}
            <div className="modal fade" id="guardianModalForm" data-bs-backdrop="static" tabIndex="-1">
                <div className="modal-dialog">
                    <form className="modal-content border border-2 border-blue p-2 rounded-4 needs-validation" id="guardianForm" noValidate autoComplete='off'>
                        <div className="modal-header border-0">
                            <h3 className="modal-title fw-bold text-blue">Fill Guardian’s  Details</h3>
                            <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-2">
                                <div className="mb-3">
                                    <div className="form-floating">
                                        <input type="hidden" name="front" id="user_front" value="front" />
                                        <input type="hidden" name="id" defaultValue={context && context.auth && context.auth.id} />
                                        <input type="text" name="first_name" id="first_name" className="form-control form-control-md" placeholder="Guardian’s first name" required />
                                        <div className="invalid-feedback">
                                            The Guardian’s first field is required.
                                        </div>
                                        <label>Guardian’s first name <span className="text-danger" id="required_attr">*</span></label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="form-floating">
                                        <input type="text" name="last_name" id="last_name" defaultValue="" className="form-control form-control-md" placeholder="Guardian’s last name" required />
                                        <div className="invalid-feedback">
                                            The Guardian’s last field is required.
                                        </div>
                                        <label>Guardian’s last name <span className="text-danger" id="required_attr">*</span></label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className="form-floating">
                                        <input type="text" name="relation" id="relation" defaultValue="" className="form-control form-control-md" placeholder="Relationship with student" required />
                                        <div className="invalid-feedback">
                                            The Guardian’s last field is required.
                                        </div>
                                        <label>Relationship with student <span className="text-danger" id="required_attr">*</span></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer border-0">
                            <button type="button" className="btn btn-theme-primary btn-lg minw-110 mb-3 mb-sm-2 me-auto me-sm-0 order-sm-1" onClick={(e) => SaveGuardianDetails(e)} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName="Log in" />
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default Header
