import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import { fetchData, UPSERT_WORKBOOK_CONTENT, GET_WEBSITE_CONTENT } from "../../components/Service";
import { ButtonSpinner, validateForm, removeMsg, ConfirmationModal, toastNotify } from "../../components/CommonMethod";

const WorkbookSection = () => {
    document.title = "Workbooks";
    const [tinyloader, setTinyloader] = useState('')
    const [data, setData] = useState('')
    const [deleteSections, setDeleteSections] = useState({ imageIndex: '', name: '', type: ''})

    const saveWorkbookContent = (e) => {
        let formdata = new FormData(document.getElementById('addWorkbookContent'))
        if (validateForm(e, "addWorkbookContent")) {
            setTinyloader(true);
            fetchData(UPSERT_WORKBOOK_CONTENT, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    removeMsg()
                }
            });
        };
    }

    useEffect(() => { 
        fetchData(GET_WEBSITE_CONTENT +'/workbook-content', 'GET', '', true, false, (res) => {
            if (res.records) {
                setData(JSON.parse(res.records.value))
            }
        });
    }, [])

    const addCorrectPharse = () => {
        var correct_pharses = document.getElementById('correct_answer')
        if (correct_pharses.value.trim() === '') {
            correct_pharses.value = '';
            toastNotify('danger', 'Correct answer field is required.')
            return false;
        }
        let correct_value = document.getElementById('correct_section');
        correct_value.insertAdjacentHTML('afterbegin',
            `<div class="d-flex justify-content-between align-items-center fw-bold py-1 bg-light rounded my-1">
             <input type="hidden" name="correct_answer[]" class="ms-1 form-control fw-bold border-0" value="${correct_pharses.value}"> 
             <span class="form-control fw-bold border-0 ms-1">${correct_pharses.value}</span> 
            <button class="btn btn-sm ms-2 remove" title="Delete">
                <svg class="icon ts-1p4"><use href="#icon_delete"></use></svg>
            </button>
        </div>`);
        correct_pharses.value = '';

        document.querySelectorAll(".remove").forEach(function (el) {
            el.addEventListener('click', (e) => {
                el.parentElement.remove();
            })
        });
    }

    const addInCorrectPharse = () => {
        var incorrect_pharses = document.getElementById('incorrect_answer')
        if (incorrect_pharses.value.trim() === '') {
            incorrect_pharses.value = '';
            toastNotify('danger', 'Incorrect answer field is required.')
            return false;
        }
        let incorrect_value = document.getElementById('incorrect_section');
        incorrect_value.insertAdjacentHTML('afterbegin',
            `<div class="d-flex justify-content-between align-items-center fw-bold py-1 bg-light rounded my-1">
             <input type="hidden" name="incorrect_answer[]" class="ms-1 form-control fw-bold border-0" value="${incorrect_pharses.value}">
             <span class="form-control fw-bold border-0 ms-1">${incorrect_pharses.value}</span> 
            <button class="btn btn-sm ms-2 remove_section" title="Delete">
                <svg class="icon ts-1p4"><use href="#icon_delete"></use></svg>
            </button>
        </div>`);
        incorrect_pharses.value = '';

        document.querySelectorAll(".remove_section").forEach(function (el) {
            el.addEventListener('click', (e) => {
                el.parentElement.remove();
            })
        });
    }

    const removeElement = (id, type) => {
        if(type === 'Correct Answer'){
            var correctDivValue = document.getElementById('div_correct_answer'+id)
            correctDivValue.remove(correctDivValue)
        }else{
            var incorrectDivValue = document.getElementById('div_incorrect_answer'+id)
            incorrectDivValue.remove(incorrectDivValue)
        }
        saveWorkbookContent()
        document.querySelector('#deletesection_modal [data-bs-dismiss="modal"]').click()
    }  

    return (
        <>
            <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row">
                    <div className="col-sm-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                                <li className="breadcrumb-item"><Link to="/cms/website-content">WEBSITE CONTENT</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">WORKBOOK</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row gx-4 mb-1 align-items-center">
                    <div className="col-lg-6 col-sm-8 mb-3 col-9">
                        <h1 className="h3 text-dim mb-0">Edit ‘Workbook Page’ content</h1>
                    </div>
                    <div className="col-lg-6  col-sm-4 mb-3 col-3">
                        <button type="button" className="btn btn-theme-primary btn-lg float-end" onClick={saveWorkbookContent} >
                            <ButtonSpinner load={tinyloader} btnName="Save" />
                        </button>
                    </div>
                </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 scrool_width_responsive">
                        <div className="d-block" id="allquestions" >
                            <form className="needs-validation" noValidate id="addWorkbookContent">
                                {/* Question #4 */}
                                <div className="maxw-820 mb-5 ">
                                    <span className="h4 fw-bold">Phrases for correct answers</span>
                                    <p className="py-2">One of these messages will appear each time a student gets something right..</p>
                                    <div className="  maxw-480">
                                        <div className="d-flex mb-3">
                                            <div className="form-floating w-100">
                                                <input type="text" id="correct_answer" className="form-control form-control-md" placeholder="Potential answer" defaultValue="" />
                                                <label>Correct answer</label>
                                            </div>
                                            <button type="button" className="btn btn-outline-default minw-90 ms-3" onClick={() => addCorrectPharse()}>Add</button>
                                        </div>
                                        <div className="card border-dark rounded-3 px-3 py-2" id="correct_section">
                                            {data.correct_answer && data.correct_answer.length > 0 ? data.correct_answer.map((value, key) => {
                                                
                                                return (
                                                    <div className="d-flex justify-content-between align-items-center fw-bold py-1 bg-light rounded my-1" key={key} id={`div_correct_answer${key}`}>

                                                        <input type="hidden" name="correct_answer[]" readOnly  className="ms-1 form-control fw-bold border-0" defaultValue={value} />
                                                        <span className="form-control fw-bold border-0 ms-1">{value}</span>

                                                        <button type="button" className="btn btn-sm ms-2" title="Delete" data-bs-toggle="modal" data-bs-target="#deletesection_modal" onClick={(e) => setDeleteSections(prevState => ({ ...prevState, imageIndex: key, name: value, type: 'Correct Answer'}))}>
                                                            <svg className="icon ts-1p4"><use href="#icon_delete"></use></svg>
                                                        </button>
                                                    </div>
                                                )
                                            }) : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className="maxw-820 mb-5 ">
                                    <span className="h4 fw-bold">Phrases for incorrect answers</span>
                                    <p className="py-2">One of these messages will appear each time a student gets something wrong..</p>
                                    <div className=" maxw-480">
                                        <div className="d-flex mb-3">
                                            <div className="form-floating w-100">
                                                <input type="text" className="form-control form-control-md" id="incorrect_answer" placeholder="Potential answer" defaultValue="" />
                                                <label>Incorrect answer</label>
                                            </div>
                                            <button type="button" className="btn btn-outline-default minw-90 ms-3" onClick={() => addInCorrectPharse()}>Add</button>
                                        </div>
                                        <div className="card border-dark rounded-3 px-3 py-2" id="incorrect_section">
                                        {data.incorrect_answer && data.incorrect_answer.length > 0 ? data.incorrect_answer.map((value, key) => {
                                            return (
                                                <div className="d-flex justify-content-between align-items-center fw-bold py-1 bg-light rounded my-1" key={key} id={`div_incorrect_answer${key}`}>
                                                    <input type="hidden" name="incorrect_answer[]" readOnly  className="ms-1 form-control fw-bold border-0" defaultValue={value} />
                                                    <span className="form-control fw-bold border-0 ms-1">{value}</span>
                                                    
                                                    <button type="button" className="btn btn-sm ms-2" title="Delete" data-bs-toggle="modal" data-bs-target="#deletesection_modal" onClick={(e) => setDeleteSections(prevState => ({ ...prevState, imageIndex: key, name: value, type: 'Incorrect Answer'}))}>
                                                        <svg className="icon ts-1p4"><use href="#icon_delete"></use></svg>
                                                    </button>
                                                </div>
                                            )
                                        }) : ''}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationModal msg={`Delete ${deleteSections.type}: ${deleteSections.name} ?`} method={() => removeElement(deleteSections.imageIndex, deleteSections.type)} />
        </>
    )
}

export default WorkbookSection
