import { useEffect, useState } from "react";
import { fetchData, PAGE_CONTENT, ASSET_ENDPOINT } from "../../components/Service"
import { noImageHandle } from "../../components/CommonMethod";
import AboutAuthor from "./partials/AboutAuthor"
import StudentTestimonials from "./partials/StudentTestimonials";

const AboutUs = () => {
    window.document.title = "About Us";
    const [aboutContent, setAboutContent] = useState('')
    const [socialContent, setSocialContent] = useState('')

    useEffect(() => {
        fetchData(`${PAGE_CONTENT}?type=about-content`, 'GET', '', false, false, (res) => {
            if (res.records) {
                setAboutContent(JSON.parse(res.records))
            } else {
                setAboutContent([]);
            }
        });

        fetchData(`${PAGE_CONTENT}?type=social-media`, 'GET', '', false, false, (res) => {
            if (res.records) {
                setSocialContent(JSON.parse(res.records))
            } else {
                setSocialContent([]);
            }
        });
    },[])

    return (<>
        <section className="overflow-hidden py-5 l1">
            <div className="container container-1180">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="d-flex align-items-start justify-content-center py-4">
                            <svg className="icon mmwh-35 sm-mmwh-28 text-blue">
                                <use href="#icon_subtract"></use>
                            </svg>
                            <div className="px-3">
                                <h2 className="fw-bold fs-32">We are all Elemental</h2>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="row justify-content-center align-items-center gy-5 pt-4">
                    <div className="col-sm-10 col-md-7 text-center">
                        <div className="position-relative d-inline-block">
                            <svg className="icon position-absolute mmwh-150 text-sky zindex-0 top-0 star-0 mmt-10 mms-70"><use href="#icon_cutcircle"></use></svg>
                            <svg className="icon position-absolute mmwh-150 text-blue zindex-0 bottom-0 end-0 mme-30 mmb-30"><use href="#icon_circle"></use></svg>
                            <img src={ aboutContent && aboutContent.title_image ? ASSET_ENDPOINT + aboutContent.title_image :  'images/about-banner.png'} className="w-75 zindex-1 position-relative rounded" onError={noImageHandle} alt="Female"/>
                        </div>
                    </div>
                    <div className="col-md-5 ps-md-5">
                        <p className="h4 fw-normal">
                        { aboutContent && aboutContent.title_description }
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5">
            <div className="container container-1180">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="d-flex align-items-start justify-content-center">
                            <svg className="icon mmwh-35 sm-mmwh-28 text-sky">
                                <use href="#icon_subtract"></use>
                            </svg>
                            <div className="px-3">
                                <h1 className="fw-bold fs-32">Follow us on our socials</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 py-4" id="flex-social_ipad">
                        <div className="d-flex justify-content-around  flex-column flex-md-row align-items-start sm-ps-48">
                            <a href={socialContent && socialContent.instagram_link} rel="noreferrer" target="_blank" className="pb-3 d-inline-flex justify-content-center align-items-center text-decoration-none text-dark fw-bold blue-hover">
                                <div className=" mmwh-48 d-flex justify-content-center align-items-center rounded-circle border border-current text-dim">
                                    <svg className="icon fs-4"><use href="#icon_instagram"></use></svg>
                                </div>
                                <span className="ps-3 fs-18">INSTAGRAM</span>
                            </a>
                            <a href={socialContent && socialContent.facebook_link} rel="noreferrer" target="_blank" className="pb-3 d-inline-flex justify-content-center align-items-center text-decoration-none text-dark fw-bold blue-hover">
                                <div className=" mmwh-48 d-flex justify-content-center align-items-center rounded-circle border border-current text-dim">
                                    <svg className="icon fs-4"><use href="#icon_facebook"></use></svg>
                                </div>
                                <div className="facebook ps-3 fs-18 fw-large">FACEBOOK</div>
                            </a>
                            {socialContent && socialContent.twitter_link &&
                                <a href={socialContent.twitter_link} rel="noreferrer" target="_blank" className="pb-3 d-inline-flex justify-content-center align-items-center text-decoration-none text-dark fw-bold blue-hover">
                                    <div className=" mmwh-48 d-flex justify-content-center align-items-center rounded-circle border border-current text-dim">
                                        <svg className="icon fs-4"><use href="#icon_twitter"></use></svg>
                                    </div>
                                    <div className=" twitter  ps-3 fs-18 fw-large">TWITTER</div>
                                </a>
                            }
                        </div>
                   </div>
                </div>
            </div>
        </section>
        
        <AboutAuthor aboutContent={aboutContent} />

        <section className="shape-section8">
            <img src="images/vector/shape8.svg" className="homebg-cover" alt="shape" />
            <div className="container container-1180 position-relative">
                <div className="d-flex align-items-start justify-content-start">
                    <svg className="icon mmwh-35 sm-mmwh-28 text-blue">
                        <use href="#icon_subtract"></use>
                    </svg>
                    <div className="px-3">
                        <h2 className="fw-bold mb-3 f-28_size">How it works</h2>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12">
                    {aboutContent.video_url ? <iframe width="100%" src={`${aboutContent.video_url}`} title="Video Solution" style={{aspectRatio: '16/8'}}></iframe>
                    : <img src="images/lab.jpg" className="img-fluid rounded-3" alt="lab"/> }
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5">
            <div className="container container-1180">
                <div className="row align-items-center justify-content-center g-4">
                    <div className="col-sm-6 col-md-8">
                        <div className="d-flex align-items-start justify-content-center">
                            <svg className="icon mmwh-35 sm-mmwh-28 text-yellow">
                                <use href="#icon_subtract"></use>
                            </svg>
                            <div className="px-3">
                                <h2 className="fw-bold fs-32">Gain unlimited access</h2>
                                <p className="fs-24 pt-2 mb-0">
                                    All members receive unlimited access to content. Practice as many times as you want until you've mastered the elements!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 text-center">
                        <img src="images/books.png" className="img-fluid" alt="Books" />
                    </div>
                </div>
            </div>
        </section>

        <StudentTestimonials homeContent={aboutContent} />
        <div className="py-sm-5"></div>
    </>)
}
export default AboutUs