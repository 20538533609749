import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchData, UPSERT_ABOUT_CONTENT, ASSET_ENDPOINT, GET_WEBSITE_CONTENT, SETTING_PAGE } from "../../components/Service";
import { validateForm, ButtonSpinner, removeMsg, blobToBase64, noImageHandle, wordCount } from "../../components/CommonMethod";
import ImageDropdown from "../../components/ImageDropdown";

const AboutUs = () => {
    window.document.title = "About Us";
    const [tinyloader, setTinyloader] = useState('')
    const [data, setData] = useState('')
    const [imageData, setImageData] = useState('');
    const [formfield, setFormfield] = useState({
        title_imageb64: "",
        author_imageb64: "",
        featured_videob64: "",
        title_image: "",
        author_image: ""
    })

    const checkVideoSize = (e) => {
        var video_error = document.querySelectorAll('#video_error')
        var element = document.querySelector('#featured_video')
        var video_size = element.files[0].size
        if (video_size / 1024 > 5120) {
            video_error.forEach((ele) => {
                ele.classList.remove('text-muted')
                ele.classList.add('text-danger')
            })
            element.value = ''
        } else {
            video_error.forEach((ele) => {
                ele.classList.add('text-muted')
                ele.classList.remove('text-danger')
            })
            imageSet(e, 'featured_video')
        }
    }

    const addAboutUs = (e) => {
        let formdata = new FormData(document.getElementById('aboutUsForm'))
        if (validateForm(e, "aboutUsForm")) {
            setTinyloader(true);
            fetchData(UPSERT_ABOUT_CONTENT, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    removeMsg()
                }
            });
        };
    }

    useEffect(() => {
        fetchData(GET_WEBSITE_CONTENT +'/about-content', 'GET', '', true, false, (res) => {
            if (res.records) {
                setData(JSON.parse(res.records.value))
            }
        });

        fetchData(SETTING_PAGE, 'GET', '', true, false, (res) => {
            if(res.records){ 
                setImageData(JSON.parse(res.records.value));
            }
        });
    }, [])

    const imageSet = async (e, image_type) => {
        const imgb64 = await blobToBase64(e.target.files[0])
        setFormfield(prevState => ({ ...prevState, [`${image_type}b64`]: imgb64 }))
    }

    return (
        <>
            <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row">
                    <div className="col-sm-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb fw-bold text-uppercase mb-2 fs-14">
                                <li className="breadcrumb-item"><Link to="/cms/website-content">WEBSITE CONTENT</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">About us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="row gx-4 mb-1 align-items-lg-end align-items-md-end  align-items-center">
                    <div className="col-lg-6 col-sm mb-3 col-9">
                        <h1 className="h3 text-dim mb-0">Edit ‘About Us’ content</h1>
                    </div>
                    <div className="col-lg-6 col-auto mb-3 col-3">
                        <button type="button" className="btn btn-theme-primary btn-lg float-end" onClick={addAboutUs} disabled={tinyloader}><ButtonSpinner load={tinyloader} btnName="Save" /></button>
                    </div>
                </div>
                </div>
                <form className="needs-validation" noValidate id="aboutUsForm">
                    <div className="row mb-5">
                        <div className="col-sm-12">
                            <h4 className="fw-bold">About 'We are all elemental'</h4>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-3 mt-3">
                            <p className="fw-bold fs-18 text-uppercase mb-2">Image</p>
                            <div className="d-flex flex-column">
                                <label htmlFor="bannerImage" className="d-block form-control-md p-0 h-220 border">
                                    <img src={formfield.title_imageb64 ? formfield.title_imageb64 : data.title_image ? ASSET_ENDPOINT + data.title_image : '../images/about-banner.png'} className="w-100 h-100 img-cover" alt="Female" onError={noImageHandle} />
                                </label>
                                <label className="btn btn-theme-primary btn maxw-200 my-3">
                                    <div className="overflow-hidden position-relative error-button-msg">
                                        <input type="file" name="title_image" id="title_image" accept=".jpg,.jpeg,.png" className="position-absolute opacity-0" onChange={(e) => imageSet(e, 'title_image')} />
                                    </div>
                                    Reupload image
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <p className="fw-bold fs-18 text-uppercase mb-2">description - Max 500 words <strong className="text-danger">*</strong></p>
                            <textarea className="form-control form-control-md h-220" name="title_description" id="title_description" placeholder="Write your message..." rows="10" defaultValue={data.title_description ? data.title_description : ''} onKeyUp={(e) => wordCount(e, "remain_word1", 500)} onKeyPress={(e) => wordCount(e, "remain_word1", 500)} required></textarea>
                            <p className="text-end text-danger py-1 small fw-bold" id="remain_word1"></p>
                            </div>
                            <div className="invalid-feedback">Title Description field is required.</div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-sm-12">
                            <h4 className="fw-bold pt-4">About the author</h4>
                        </div>
                        <div className="col-md-5 col-xl-4 col-xxl-3 mt-3">
                            <p className="fw-bold fs-18 text-uppercase mb-2">Profile Image</p>
                            <div className="d-flex flex-column">
                                <label htmlFor="periodicImage" className="mmwh-260 rounded-circle overflow-hidden border">
                                    <img src={formfield.author_imageb64 ? formfield.author_imageb64 : data.author_image ? ASSET_ENDPOINT + data.author_image : '../images/vector/flag-australia.svg'} className="w-100 h-100 img-cover" alt="Flag" onError={noImageHandle} />
                                </label>
                                <label className="btn btn-theme-primary btn my-3 maxw-200">
                                    <div className="overflow-hidden position-relative error-button-msg">
                                        <input type="file" name="author_image" id="author_image" accept=".jpg,.jpeg,.png" onChange={(e) => imageSet(e, 'author_image')} className="position-absolute opacity-0" />
                                    </div>Reupload image
                                </label>
                            </div>
                        </div>
                        <div className="col-md-7 col-xxl-6 mt-3">
                            <p className="fw-bold fs-18 text-uppercase mb-2">Feature Video</p>
                            <div className="d-flex flex-column" >
                                <div htmlFor="ReuploadVideo" className="h-260">
                                    {data.featured_video || formfield.featured_videob64 ?
                                        <video controls className="h-260 w-sm100 rounded-2" src={formfield.featured_videob64 ? formfield.featured_videob64 : ASSET_ENDPOINT + data.featured_video} alt="Featured Video" type="video/mp4" />
                                    :   <img src="/images/about-banner.png" className="maxw-360 img-fluid" alt="Featured Video" onError={noImageHandle} />
                                    }
                                </div>
                                <label className="btn btn-theme-primary btn my-3 maxw-200">
                                    <div className="overflow-hidden position-relative">
                                        <input type="file" id="featured_video" name="featured_video" accept=".mp4" className="position-absolute opacity-0" onChange={checkVideoSize} />
                                    </div>Upload video
                                </label>

                            </div>
                            <small className="text-muted pt-2 fw-bold" id="video_error">Please upload .mp4 type video with maximum size 5 Mb.</small>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-sm-12">
                            <div className="maxw-560">
                                <div className="form-floating">
                                    <input type="text" name="video_url" id="video_url" className="form-control form-control-md" placeholder="Video URL" defaultValue={data && data.video_url ? data.video_url : ''} required />
                                    <div className="invalid-feedback">
                                        The video url field is required.
                                    </div>
                                    <label>VIDEO URL <span className="text-danger"></span></label>
                                </div>
                                <small className="text-muted pt-2 fw-bold">Please enter Embed URL- (https://www.youtube.com/embed/8lYpo7wT3-k).</small>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-sm-12">
                            <div className="maxw-560">
                                <div className="mb-5 ">
                                    <p className="fw-bold fs-18 ">DESCRIPTION-MAX 400 WORDS <strong className="text-danger">*</strong></p>
                                    <textarea className="form-control form-control-md h-200" name="author_description" id="author_description" placeholder="Write your message..." rows="7" defaultValue={data.author_description ? data.author_description : ''} onKeyPress={(e) => wordCount(e, "remain_word2", 400)}  onKeyUp={(e) => wordCount(e, "remain_word2", 400)} required></textarea>
                                    <p className="text-end text-danger py-1 small fw-bold" id="remain_word2"></p>
                                    <div className="invalid-feedback">Author description field is required.</div>
                                </div>
                            </div>

                            <div className="maxw-560 mb-5">
                                <h4 className="fw-bold">Testimonials</h4>
                                {data.testimonials && data.testimonials.length > 0 && data.testimonials.map((ele, num) => {
                                    return (
                                        <div className="row mb-4 pb-2 " key={num}>
                                            <div className="col-md-12">
                                                <p className="fs-18 fw-bold mb-2">Testimonial {num + 1} <strong className="text-danger">*</strong></p>
                                                <textarea className="form-control form-control-md  mb-4" required name={`testimonial[${num}][student_message]`} defaultValue={ele.student_message ?? ''} placeholder={`Testimonial ${num + 1}`} rows="4"></textarea>
                                                <div className="invalid-feedback">Please fill testimonial message.</div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating mt-32">
                                                    <input type="text" required className="form-control form-control-md" name={`testimonial[${num}][student_name]`} defaultValue={ele.student_name ?? ''} placeholder="Testimonial giver first name" />
                                                    <div className="invalid-feedback">Please fill testimonial giver name.</div>
                                                    <label>Testimonial giver first name <strong className="text-danger">*</strong></label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="fs-12 text-dim fw-bold mt-14 ps-3">Choose Image</div>
                                                <ImageDropdown value={ele.student_image} imageList={imageData} name={`testimonial[${num}][student_image]`} id={`testimonial.${num}.student_image`} />
                                            </div>
                                        </div>
                                    )
                                }) }

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AboutUs
