import { Link } from "react-router-dom";
import { useState } from "react";
import { __echoText, ButtonSpinner } from "../../components/CommonMethod";
import { fetchData, ASSIGNMENT_LIST, ASSIGNMENT_DELETE, ASSIGNMENT_DUPLICATE, ASSIGNMENT_STATUS_CHANGE } from "../../components/Service";
import { now } from 'lodash'
import { format } from 'date-fns';
import { DataTable } from "../../components/DataTable";

const ManageAssignment = () => {
    window.document.title = "Manage Assignments";

    const [refresh, setRefresh] = useState(now);
    const [assignmentData, setAssignmentData] = useState([])
    const [tinyloader, setTinyloader] = useState(false)

    const assignments = {
        id: 'assignments',
        fetchurl: ASSIGNMENT_LIST,
        orderby: [2, 'asc'],
        columns: [
            { data: 'id', name: 'id', title: '', width: 20, sorting: false, searching: false },
            { data: 'title', name: 'title', title: 'TITLE' },
            { data: 'allow_late_submission', name: 'allow_late_submission', title: 'Late Submission' },
            { data: 'section_count', name: 'section_count', title: 'NO OF SECTIONS' },
            { data: 'workbook_count', name: 'workbook_count', title: 'NO OF WORKBOOKS' },
            { data: 'deadline', name: 'deadline', title: 'DEADLINE' },
            { data: 'created_at', name: 'created_at', title: 'DATE CREATED' },
            { data: 'updated_at', name: 'updated_at', title: 'LAST EDITED' },
            { data: 'status', name: 'status', title: 'STATUS' },
            { data: 'action', name: 'action', title: 'ACTION', sorting: false, searching: false },
        ]
    }

    const handleCheckboxChange = (e) => {
        e.target.checked ? setAssignmentData([...assignmentData, e.target.value]) : setAssignmentData(assignmentData.filter(i => i !== e.target.value));
    }

    const assignmentAction = (action) => {
        setTinyloader(true)
        fetchData(action === 'delete' ? ASSIGNMENT_DELETE : ASSIGNMENT_DUPLICATE, 'POST', { id: assignmentData }, true, false, (res) => {
            setTinyloader(false)
            if (res.success) {
                document.querySelector((action === 'delete' ? '#deletelisting_modal' : '#duplicate_modal') + ' [data-bs-dismiss="modal"]').click()
                setAssignmentData([])
                setRefresh(now)
            }
            document.querySelectorAll('.my_checkboxes').forEach((el) => el.checked = false)
        });
    }

    const updateStatus = (id, status) => {
        fetchData(ASSIGNMENT_STATUS_CHANGE, 'POST', { assignment_id: id, status: status }, true, false, (res) => {
            setRefresh(now)
        });
    }

    const statusFilter = (e, setDt) => {
        setDt(prevState => ({
            ...prevState,
            filter: e.target.value
        }))
    }

    return (
        <>
            <div className="container-fluid">
                <div className="page-head sticky_top_padding">
                    <div className="row g-2 align-items-center">
                        <div className="col-lg-6 col-6 mb-3">
                            <h1 className="h3 text-dim mb-0">Manage Assignments</h1>
                        </div>
                        <div className="col-lg-6 col-6 mb-3">
                            <Link to="/cms/create-assignment" role="button" className="btn btn-lg btn-green float-end text-center">+ Create new assignment</Link>
                        </div>
                    </div>
                </div>
                {assignmentData.length > 0 && <div className="row bg-grey2 p-2 rounded mx-0 align-items-center mb-3">
                    <div className="col-auto text-dim fs-18">
                        <strong>{assignmentData.length} lists selected</strong>
                    </div>
                    <div className="col text-end">

                        <button type="button" className="btn btn-link fw-bold text-decoration-none me-3" data-bs-toggle="modal" data-bs-target="#duplicate_modal">
                            <svg className="icon ts-1p5 me-2"><use href="#icon_copy"></use></svg>
                            Duplicate selected assignments
                        </button>

                        <button type="button" className="btn btn-link fw-bold text-decoration-none pe-0 text-danger" title="Delete selected assignments" data-bs-toggle="modal" data-bs-target="#deletelisting_modal">
                            <svg className="icon ts-1p3 me-2"><use href="#icon_delete"></use></svg>
                            Delete selected assignments
                        </button>
                    </div>
                </div>}
                <div className="row mt-3">
                    <DataTable
                        execute={refresh}
                        dt={assignments}
                        outerbutton={(records, setDt) => <select className="form-control form-select width_selecto border" onChange={(e) => statusFilter(e, setDt)}>
                            <option value="">All</option>
                            <option value="draft">Draft</option>
                            <option value="published">Published</option>
                            <option value="expired">Expired</option>
                        </select>}
                        edit_column={{
                            id: (records) => <input className="form-check-input mmwh-20 m-0 my_checkboxes" type="checkbox" defaultValue={records.id} name="assignments[]" onChange={(e) => handleCheckboxChange(e)} checked={assignmentData.includes(records.id.toString())} />,

                            deadline: (records) =>
                                __echoText(`${format(new Date(records.deadline), 'MM/dd/yyyy hh:mm aa')}`),

                            allow_late_submission: (records) =>
                                <span className={`text-${records.allow_late_submission === 'yes' ? 'green' : 'danger'}`}>
                                    {records.allow_late_submission === 'yes' ? 'Yes' : 'No'}
                                </span>,

                            updated_at: (records) =>
                                __echoText(`${format(new Date(records.updated_at), 'MM/dd/yyyy')}`),

                            created_at: (records) =>
                                __echoText(`${format(new Date(records.created_at), 'MM/dd/yyyy')}`),

                            status: (records) =>
                                <>
                                    {records.status === 'draft' &&
                                        <button type="button" disabled={records.status_change} className="px-3 btn-sm m-3 btn btn-yellow" onClick={() => updateStatus(records.id, 'published')}>
                                            Draft
                                        </button>
                                    }
                                    {records.status === 'published' &&
                                        <button type="button" disabled={records.status_change} className="px-3 btn-sm m-3 btn btn-green" onClick={() => updateStatus(records.id, 'expired')}>
                                            Published
                                        </button>
                                    }
                                    {records.status === 'expired' &&
                                        <button type="button" disabled={records.status_change} className="px-3 btn-sm m-3 btn btn-danger" onClick={() => updateStatus(records.id, 'draft')}>
                                            Expired
                                        </button>
                                    }
                                </>,

                            action: (records) =>
                                <>
                                    <Link to="/cms/update-assignment" state={records} className="text-black mmwh-48 d-inline-flex justify-content-center align-items-center" title="Edit" href="#"><svg className="icon ts-1p5"><use href="#icon_pencil"></use></svg></Link>
                                    {records.workbook_count > 0 && <Link to="/cms/assignment-analytics" state={records} className="text-black mmwh-48 d-inline-flex justify-content-center align-items-center" title="Analytics" href="#"><svg className="icon ts-1p5"><use href="#analytic_icon"></use></svg></Link>}
                                </>
                        }}
                    />
                </div>
            </div>

            {/* Delete confirmation - Modal */}
            <div className="modal fade" id="deletelisting_modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content overflow-hidden border border-2 border-danger rounded-4">
                        <div className="modal-body p-4">
                            <h3 className="fw-bold text-danger">Delete these assignments?</h3>
                            <p className="m-0 pt-2">
                                This is a destructive action and cannot be reversed.
                            </p>
                        </div>
                        <div className="modal-footer justify-content-between px-4 pt-0 pb-4 border-0">
                            <button type="button" className="btn btn-outline-default btn-lg" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-lg minw-120" onClick={(e) => assignmentAction('delete')} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName="Delete" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/*Duplicate- Modal */}
            <div className="modal fade" id="duplicate_modal" tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content overflow-hidden border border-2 border-danger rounded-4">
                        <div className="modal-body p-4">
                            <h3 className="fw-bold text-danger">Duplicate selected assignments?</h3>
                            <p className="m-0 pt-2">
                                Once you confirm, selected lists will be cloned.
                            </p>
                        </div>
                        <div className="modal-footer justify-content-between px-4 pt-0 pb-4 border-0">
                            <button type="button" className="btn btn-outline-default btn-lg" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-lg minw-120" onClick={(e) => assignmentAction('duplicate')} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName="Duplicate" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageAssignment