import { ASSET_ENDPOINT } from "../../../components/Service"
import { noImageHandle } from "../../../components/CommonMethod";

const AboutAuthor = (props) => {
    return(
        <section className="home-section6">
            <div className="container container-1180">
                <div className="row align-items-center justify-content-center g-4">
                    <div className="col-sm-6 col-md-6 pe-md-5">
                        <div className="ratio ratio-1x1">
                            <div className="w-100">
                                <div className="ratio ratio-1x1 position-absolute bg-green rounded-circle top-0 start-0" style={{maxWidth: '50%'}}></div>
                                <div className="ratio ratio-1x1 position-absolute bg-lime rounded-circle bottom-0 end-0" style={{ maxWidth: '75%' }}>
                                    <img className="ratio rounded-circle" src={ props.aboutContent && props.aboutContent.author_image ? ASSET_ENDPOINT + props.aboutContent.author_image : 'images/fly.png' } onError={noImageHandle} style={{objectFit:"cover"}} alt="fly"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex align-items-start justify-content-center">
                            <svg className="icon mmwh-35 sm-mmwh-28 text-green">
                                <use href="#icon_subtract"></use>
                            </svg>
                            <div className="px-3">
                                <h2 className="fw-bold mb-3 f-28_size">About the author</h2>
                                <p className="h4 fw-normal mb-4">{ props.aboutContent && props.aboutContent.author_description }</p>
                                {/* <button type="button" className="btn btn-theme-primary btn-lg mt-2 me-3">Info #1</button>
                                <button type="button" className="btn btn-outline-default btn-lg mt-2">Info #2</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutAuthor