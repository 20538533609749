import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataTable } from "../../components/DataTable";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import CountUp from 'react-countup';
import UsersMap from './partials/UsersMap';
import BalanceOverview from './partials/BalanceOverview';
import StatisticsMap from './partials/StatisticsMap';
import { fetchData, ORDER_SHORT_LIST, USER_LIST_GRAPH, GET_ORDER_WEEK_MONTH_YEAR } from "../../components/Service";

const Analytics = () => {
    window.document.title = "Analytics";
    const [refreshSales] = useState(true);
    const [user, setUser] = useState(null)
    const [totalData, setTotalData] = useState(null)
    const [type, setType] = useState('thisYear')

    const newSaleList = {
        id: 'new_sales_table',
        fetchurl: ORDER_SHORT_LIST,
        orderBy: [0, 'asc'],
        length: 5,
        columns: [
            { data: 'order_id', name: 'payments.order_id', title: 'Order ID', },
            { data: 'customer', name: 'customer', class: "w-100 text-break", title: 'Customer' },
            { data: 'product', name: 'product', class: "w-100 text-break", title: 'Listing Title' },
            { data: 'amount', name: 'amount', title: 'Amount' },
            { data: 'status', name: 'status', title: 'Status' }
        ]
    }

    const weekMonthYearWiseData = (type) => {
        fetchData(`${GET_ORDER_WEEK_MONTH_YEAR + '/' + type}`, 'GET', '', true, false, (res) => {
            if (res.records) {
                setTotalData(res.records ?? [])
            }
        })

    }

    useEffect(() => {
        fetchData(USER_LIST_GRAPH, 'GET', '', true, false, (res) => {
            if (res.records) {
                setUser(res.records ?? [])
            }
        })
        weekMonthYearWiseData(type)

    }, [type])
    return (
        <div className="container-fluid">
            <div className="page-head sticky_top_padding">
                <div className="row">
                    <div className="col-sm-12">
                        <h1 className="h3 text-dim">Analytics</h1>
                    </div>
                </div></div>
            <div className="row g-4 pt-4">
                <div className="col-sm-12 col-md-7">
                    <BalanceOverview />
                </div>
                <div className="col-sm-12 col-md-5">
                    <div className="card card-height-110">
                        <div className="align-items-center d-flex card-header">
                            <h4 className="card-title mb-0 flex-grow-1">
                                New User Listing
                            </h4>
                            <div className="flex-shrink-0">
                                <Link to="/cms/users" title="View All">
                                    <span><i className="fa fa-arrow-circle-right"></i></span>
                                </Link>
                            </div>
                        </div>
                        <div className="card-body px-0">
                            {user && <UsersMap data={user} />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-4 pt-4">
                <div className="col-sm-12 col-md-7">
                    <div className="card p-0">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h4 className="card-title fs-18 mb-0">Statistics</h4>
                            <div className="align-items-center d-flex">
                                <div className="flex-shrink-0">
                                    <UncontrolledDropdown className="card-header-dropdown">
                                        <DropdownToggle className="text-reset dropdown-btn" tag="a" role="button">
                                            <span className="fw-semibold text-uppercase fs-12">Sort by: </span><span className="text-muted">{type === 'thisDay' ? 'Today' : type === 'thisWeek' ? 'This Week' : type === 'thisMonth' ? 'This Month' : 'Current Year'}<i className="mdi mdi-chevron-down ms-1"></i></span>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem onClick={() => setType('thisWeek')} >This Week</DropdownItem>
                                            <DropdownItem onClick={() => setType('thisMonth')} >This Month</DropdownItem>
                                            <DropdownItem onClick={() => setType('thisYear')} >Current Year</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </div>+
                            </div>
                        </div>

                        <div className="card-content">
                            <div className="card-body pt-0">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <ul className="list-inline main-chart text-center mb-0 pt-3">
                                            <li className="list-inline-item chart-border-left me-0 border-0">
                                                <h4 className="text-primary">
                                                    {totalData && <CountUp end={totalData.total_order} delay={0} duration={0.5} />}
                                                    <span className="text-muted d-inline-block fs-13 align-middle ms-2">Orders</span>
                                                </h4>
                                            </li>
                                            <li className="list-inline-item chart-border-left me-0">
                                                <h4>
                                                    {totalData && <CountUp
                                                        end={totalData.total_earning}
                                                        delay={0} duration={0.5}
                                                        separator=","
                                                        decimals={2}
                                                        decimal="."
                                                        prefix="$"
                                                        suffix=""
                                                    />}
                                                    <span className="text-muted d-inline-block fs-13 align-middle ms-2">Earnings</span>
                                                </h4>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 py-1">
                                {totalData && <StatisticsMap data={totalData} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-5">
                    <div className="card pb-2">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h4 className="card-title mb-0 fs-18">Recent Orders</h4>
                            <div className="flex-shrink-0">
                                {/* <Link to="/cms/sales" className="py-0" title="View All">
                                    <span><i className="fa fa-arrow-circle-right"></i></span>
                                </Link> */}
                            </div>
                        </div>
                        <div className="bordered short-sammery-table head-black">
                            <DataTable
                                execute={refreshSales}
                                dt={newSaleList}
                                searching={false}
                                paging={false}
                                pagination={false}
                                edit_column={{
                                    amount: (records) => `$${records.amount.toFixed(2)}`,
                                    status: (records) =>
                                        <button type="button" className={`pe-none px-3 m-2 text-decoration-none btn btn-link badge badge-soft-${records.status === 'success' ? 'success' : (records.status === 'failed' ? 'warning' : 'danger')}`}>
                                            {records.status}
                                        </button>,
                                    order_id: (records) =>
                                        <div className="d-flex">
                                            <Link to="/order-details" title="View Details">
                                                {records.order_id}
                                            </Link>
                                        </div>,

                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Analytics;