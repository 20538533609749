import React, { useEffect } from "react";

const MathElement = (props) => {
    const element = props.elements;
    const id = (props.ele_id + "_" + Date.now() + "_" + Math.floor((Math.random() * Date.now()) + 1))

    useEffect(() => {
        if (element && 'com' in window && 'wiris' in window.com && 'js' in window.com.wiris && 'JsPluginViewer' in window.com.wiris.js) {
            window.com.wiris.js.JsPluginViewer.parseDocument();
        }
    }, [element]);

    return <span id={id} dangerouslySetInnerHTML={{ __html: element }}></span>;
};

const MathElementForQuestion = (props) => {
    const element = props.elements;
    const id = (props.ele_id + "_" + Date.now() + "_" + Math.floor((Math.random() * Date.now()) + 1))

    useEffect(() => {
        if (document.getElementById(id) && element && 'com' in window && 'wiris' in window.com && 'js' in window.com.wiris && 'JsPluginViewer' in window.com.wiris.js) {
            window.com.wiris.js.JsPluginViewer.parseElement(document.getElementById(id), true)
        }
    }, [element, id]);

    return <div id={id} dangerouslySetInnerHTML={{ __html: element }}></div>;
};

export default MathElement;
export { MathElementForQuestion };